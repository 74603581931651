import React from 'react';

export default () => {
  return(
    <svg width="149" height="130" viewBox="0 0 149 130" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="72" cy="124.5" rx="72" ry="5.5" fill="#0097DC" fillOpacity="0.1"/>
      <path d="M16.7232 12.8106C17.7134 6.25759 23.8284 1.74801 30.3814 2.73818L148.046 20.5175L138.185 85.7767C137.195 92.3297 131.08 96.8393 124.527 95.8491L6.86245 78.0698L16.7232 12.8106Z" fill="#F4633A"/>
      <path d="M0.454102 37.2939C0.454102 30.6665 5.82669 25.2939 12.4541 25.2939H131.454V91.2939C131.454 97.9214 126.082 103.294 119.454 103.294H0.454102V37.2939Z" fill="#FBB034"/>
      <rect x="55.4541" y="31.2324" width="19" height="3" rx="1.5" fill="white"/>
      <path d="M15.0698 79.7012C14.6066 79.7012 14.2215 79.5477 13.9146 79.2408C13.6076 78.9338 13.4541 78.5487 13.4541 78.0856V74.8542C13.4541 73.734 13.7422 72.7134 14.3185 71.7925C14.8947 70.8715 15.6837 70.1794 16.6855 69.7163C18.9259 68.693 21.0209 67.9471 22.9705 67.4786C24.9201 67.01 26.9182 66.7757 28.9647 66.7757C31.0113 66.7757 33.0039 67.01 34.9428 67.4786C36.8816 67.9471 38.9712 68.693 41.2116 69.7163C42.2134 70.1794 43.0078 70.8715 43.5948 71.7925C44.1818 72.7134 44.4754 73.734 44.4754 74.8542V78.0856C44.4754 78.5487 44.3219 78.9338 44.0149 79.2408C43.7079 79.5477 43.3228 79.7012 42.8596 79.7012H15.0698ZM28.9647 64.1583C26.832 64.1583 25.0601 63.4528 23.6491 62.0417C22.238 60.6307 21.5325 58.8588 21.5325 56.7261C21.5325 54.5934 22.238 52.8215 23.6491 51.4105C25.0601 49.9995 26.832 49.2939 28.9647 49.2939C31.0974 49.2939 32.8693 49.9995 34.2803 51.4105C35.6914 52.8215 36.3969 54.5934 36.3969 56.7261C36.3969 58.8588 35.6914 60.6307 34.2803 62.0417C32.8693 63.4528 31.0974 64.1583 28.9647 64.1583Z" fill="white"/>
      <rect x="55.4541" y="52.2939" width="22" height="6" rx="3" fill="#CBCBCB" style={{mixBlendMode:'multiply'}}/>
      <rect x="80.4541" y="52.2939" width="33" height="6" rx="3" fill="#CBCBCB" style={{mixBlendMode:'multiply'}}/>
      <rect x="55.4541" y="61.2939" width="65" height="6" rx="3" fill="#CBCBCB" style={{mixBlendMode:'multiply'}}/>
      <rect x="55.4541" y="70.2939" width="25" height="6" rx="3" fill="#CBCBCB" style={{mixBlendMode:'multiply'}}/>
      <rect x="82.4541" y="70.2939" width="25" height="6" rx="3" fill="#CBCBCB" style={{mixBlendMode:'multiply'}}/>
      <rect x="109.454" y="70.2939" width="8" height="6" rx="3" fill="#CBCBCB" style={{mixBlendMode:'multiply'}}/>
    </svg>
  );
};