import { ICreator, IFilter, IModel } from ".";
import { IHasLookPermissions } from "./IHasLookPermissions";
import { IHasTitle } from "./IHasTitle";
import { IHasFilters } from "./IHasFilters";
import {IHasLookerModelExplore} from "@src/interfaces/IHasLookerModelExplore";

export interface ILook extends IHasLookPermissions, IHasTitle, IHasFilters, IHasLookerModelExplore {
  id: string;
  contentMetadataId: number;
  contentFavoriteId?: any;
  creator: ICreator;
  folderId: string;
  description?: any;
  createdAt: number;
  updatedAt: number;
  lastAccessedAt: number;
  favoriteCount: number;
  viewCount: number;
  fields: IFilter[];
  query_filters: { [K: string]: [V: string] };
}

export function isInstanceOfILook(object: any): object is ILook {
  return "explore" in object;
}
