
import { ITimezone } from "@src/interfaces";
import moment from 'moment-timezone';
let internalTzs = [
    {   
        abbr: "GMT",
        fullabbr: "Greenwich Mean Time (UTC)",
        link: "Etc/GMT-0"
    },
    {
        abbr: "CET",
        fullabbr: "Central European Time",
        link: "CET"
    },
    {
        abbr: "EET",
        fullabbr: "Eastern European Time",
        link: "EET"
    },
    {
        abbr: "PST",
        fullabbr: "Pacific Standard Time",
        link: "America/Los_Angeles"
    },
    {
        abbr: "MST",
        fullabbr: "Mountain Standard Time",
        link: "America/Denver"
    },
    {
        abbr: "CST",
        fullabbr: "Central Standard Time",
        link: "America/Chicago"
    },
    {
        abbr: "EST",
        fullabbr: "Eastern Standard Time",
        link: "America/New_York"
    },
    {
        abbr: "EGY",
        fullabbr: "(Arabic) Egypt Standard Time",
        link: "Africa/Cairo"
    },
    {
        abbr: "EAT",
        fullabbr: "Eastern African Time",
        link: "Europe/Moscow"
    },
    {
        abbr: "MET",
        fullabbr: "Middle East Time",
        link: "Asia/Tehran"
    },
    {
        abbr: "NET",
        fullabbr: "Near East Time",
        link: "Asia/Dubai"
    },
    {
        abbr: "PLT",
        fullabbr: "Pakistan Lahore Time",
        link: "Asia/Tashkent"
    },
    {
        abbr: "IST",
        fullabbr: "India Standard Time",
        link: "Asia/Kolkata"
    },
    {
        abbr: "BST",
        fullabbr: "Bangladesh Standard Time",
        link: "Asia/Almaty"
    },
    {
        abbr: "VST",
        fullabbr: "Vietnam Standard Time",
        link: "Asia/Jakarta"
    },
    {
        abbr: "CTT",
        fullabbr: "China Taiwan Time",
        link: "Asia/Shanghai"
    },
    {
        abbr: "JST",
        fullabbr: "Japan Standard Time",
        link: "Asia/Tokyo"
    },
    {
        abbr: "ACT",
        fullabbr: "Australia Central Time",
        link: "Australia/Adelaide"
    },
    {
        abbr: "AET",
        fullabbr: "Australia Eastern Time",
        link: "Australia/Sydney"
    },
    {
        abbr: "SST",
        fullabbr: "Solomon Standard Time",
        link: "Pacific/Pago_Pago"
    },
    {
        abbr: "NZST",
        fullabbr: "New Zealand Standard Time",
        link: "Pacific/Auckland"
    },
    {
        abbr: "MIT",
        fullabbr: "Midway Islands Time",
        link: "Pacific/Midway"
    },
    {
        abbr: "HST",
        fullabbr: "Hawaii Standard Time",
        link: "HST"
    },
    {
        abbr: "AST",
        fullabbr: "Alaska Standard Time",
        link: "America/Anchorage"
    },
    {
        abbr: "PNT",
        fullabbr: "Phoenix Standard Time",
        link: "America/Phoenix"
    },

    {
        abbr: "PRT",
        fullabbr: "Puerto Rico and US Virgin Islands Time",
        link: "America/Puerto_Rico"
    },
    {
        abbr: "CNT",
        fullabbr: "Canada Newfoundland Time",
        link: "America/St_Johns"
    },
    {
        abbr: "ART",
        fullabbr: "Argentina Time",
        link: "America/Argentina/Buenos_Aires"
    },
    {
        abbr: "CAT",
        fullabbr: "Central African Time",
        link: "Africa/Johannesburg"
    }
];

moment.tz.add([
    "Pacific/Midway|SST LMT|bm.M b0|01|-2nDMB.c|37e2", // need to add midway to moment
]);
moment.tz.link(internalTzs.map(tz => `${tz.link}|${tz.fullabbr}`));

export const Timezones: ITimezone[] = internalTzs.map(val => {
    let tz = {
        moment: moment.tz(val.fullabbr), 
        momentZone: moment.tz.zone(val.fullabbr)
    };

    
    const abbr = moment.tz(val.link).zoneAbbr();
    /**
     * moment-timezone does not store alphabetical abbreviations for all timezones and will
     * instead provide a UTC offset, so in these cases, we will use our own abbr from internalTzs[]
     */
    const isTzOffset = !isNaN(parseInt(abbr));
    const displayAbbr = isTzOffset ? val.abbr : abbr;
    return {
        'link': val.link, 
        'abbr': displayAbbr, 
        longname: tz.momentZone?.name, 
        difference: tz.moment.format('Z'), 
        display: `(${displayAbbr}) ${tz.momentZone?.name} ${tz.moment.format('Z')}`, 
        moment: tz
    };
});

/**
 * remove when deprecating analytics-app-cra-9015-schedule-form-timezone-abbreviations
 */
export const Timezones_deprecated: ITimezone[] = internalTzs.map(val => {
    let tz = {moment: moment.tz(val.fullabbr), momentZone: moment.tz.zone(val.fullabbr)};
    return {'link': val.link, 'abbr': tz.momentZone?.abbrs[0] as string, longname: tz.momentZone?.name, difference: tz.moment.format('Z'), display: `(${tz.momentZone?.abbrs[0]}) ${tz.momentZone?.name} ${tz.moment.format('Z')}`, moment: tz};
});