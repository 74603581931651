import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MoreActions } from '@emburse/embark-core';
import { showDeleteFolderModal, showDeleteModal, showModal, showScheduleModal } from "../../store";
import { showCopyModal, showMoveModal } from "../../store/actions/modalActions";
import {
  DELETE_DASHBOARD,
  DELETE_LOOK,
  COPY_DASHBOARD,
  COPY_LOOK,
  MOVE_LOOK,
  MOVE_DASHBOARD,
} from "@src/store/types";
import {
  RENAME_FOLDER_MODAL,
  RENAME_MODAL,
} from "../../store/constants";
import {
  EditIcon,
  CopyIcon,
  DeleteIcon,
} from "../Icons/Icons";
import  {DriveFileMoveOutlined} from '@mui/icons-material';
import { IFolder, IState } from "@src/interfaces";
import { CalendarIcon } from '@emburse/embark-icons';
import { FalsyPermissions } from "../../objects/Permissions";
import { useContext } from "react";
import { AuthContext } from "../AuthProvider/AuthProvider";
import { AnalyticsRole } from "../../enums/AnalyticsRole";
import { IUseAuthInterface } from "../../hooks/useAuth";

export default () => {
  const { folderContent, allFolders } = useSelector(
    (state: IState) => state.folders
  );

  const dispatch = useDispatch();
  const authContext: IUseAuthInterface = useContext(AuthContext);
  const currentFolder: IFolder = allFolders[folderContent.id];
    folderContent;

  const selectedFolder = folderContent.folders.find(
    (f) => f.contentMetadataId === folderContent.selectedItemId
  );
  const selectedDashboard = folderContent.dashboards.find(
    (d) => d.contentMetadataId === folderContent.selectedItemId
  );
  const selectedLook = folderContent.looks.find(
    (l) => l.contentMetadataId === folderContent.selectedItemId
  );

  // What is selected
  const selectedFolderPermissions = selectedFolder
  ? selectedFolder.permissions
  : FalsyPermissions;

  const actionsConfig = {
    edit: {
      iconText: 'Rename',
      closeOnClick: true,
      icon: EditIcon,
      onClick: () => {
        if (selectedFolderPermissions.update) {
          return dispatch(showModal(RENAME_FOLDER_MODAL));
        } 
        if (selectedLook || selectedDashboard) {
          return dispatch(showModal(RENAME_MODAL));
        }
      }
    },
    duplicate: {
      iconText: 'Copy',
      icon: CopyIcon,
      onClick: () => {
        if (selectedDashboard) dispatch(showCopyModal(selectedDashboard, COPY_DASHBOARD));
        if (selectedLook) dispatch(showCopyModal(selectedLook, COPY_LOOK));
      },
    },
    move: {
      iconText: 'Move',
      icon: <DriveFileMoveOutlined/>,
      onClick: () => {
        if (selectedDashboard) return dispatch(showMoveModal(selectedDashboard, MOVE_DASHBOARD));
        if (selectedLook) return dispatch(showMoveModal(selectedLook, MOVE_LOOK));
      },
    },
    delete: {
      iconText: 'Delete',
      icon: DeleteIcon,
      onClick: () => {
        if (selectedFolder) dispatch(showDeleteFolderModal(selectedFolder));
        if (selectedDashboard) dispatch(showDeleteModal(selectedDashboard, DELETE_DASHBOARD));
        if (selectedLook) dispatch(showDeleteModal(selectedLook, DELETE_LOOK));
      },
    },
    schedule: {
      iconText: 'Schedule',
      icon: CalendarIcon,
      onClick: () => {
        if (selectedDashboard || selectedLook) dispatch(showScheduleModal()); 
      },
    }
  };
  // folder permissions determine if the actions will appear for the tenant user
  const actionItems: {}[] = [];
  const moreActionsMenu = {sections: [{items: actionItems}]};

  const buildMoreActionsMenu = () => {
    const isFreeCreator = authContext.user?.analytics_role === AnalyticsRole.FreeCreator;
    const isProCreator = authContext.user?.analytics_role === AnalyticsRole.ProCreator;
    if (isProCreator || isFreeCreator) {
      if (selectedFolder) {
        if (selectedFolderPermissions.update) actionItems.push(actionsConfig.edit);
        if (selectedFolderPermissions.destroy) actionItems.push(actionsConfig.delete);
      }
    
      if (selectedLook) {
        if (selectedLook.can.update) actionItems.push(actionsConfig.edit);
        if (selectedLook.can.copy) actionItems.push(actionsConfig.duplicate);
        if (selectedLook.can.move) actionItems.push(actionsConfig.move);
        if (selectedLook.can.destroy) actionItems.push(actionsConfig.delete);
        
        actionItems.push(actionsConfig.schedule);
      }

      if (selectedDashboard) {
          // free creators cannot edit, move, copy, delete dashboards
        if (!isFreeCreator) {
          if (selectedDashboard.can.update) actionItems.push(actionsConfig.edit);
          if (selectedDashboard.can.copy) actionItems.push(actionsConfig.duplicate);
          if (selectedDashboard.can.move) actionItems.push(actionsConfig.move);
          if (selectedDashboard.can.destroy) actionItems.push(actionsConfig.delete);
        }
        actionItems.push(actionsConfig.schedule);
      }
    }
    return moreActionsMenu;
  };

  return (
    <Fragment>
      <MoreActions className="da-more-actions" moreActionsMenu={buildMoreActionsMenu()}/>
    </Fragment>
  );
};