import { IAlert, IError } from '@src/interfaces';
import { push } from 'connected-react-router';
import { Dispatch } from 'react';
import { BASE_DASHBOARDS_ROUTE, BASE_EXPLORE_ROUTE, BASE_FOLDERS_ROUTE, BASE_LOOKS_ROUTE } from '../routs';
import { ADD_ALERT, ADD_ERROR, REMOVE_ALERT } from '../types';
import {ErrorTypes, Severity} from "@enums";

/**
 * Opens a folder with a specific id
 * @param id id of folder to open
 */
export function openFolder(id) {
    return async dispatch => {
        dispatch(push(BASE_FOLDERS_ROUTE + id))
    }
}

/**
 * Opens a dashboard with a specific id
 * 
 * @param id dashboard id to open
 */
export function openDashboard(id) {
    return async dispatch => {
        dispatch(push(BASE_DASHBOARDS_ROUTE + id))
    }
}

/**
 * Opens a look with a specific id
 * 
 * @param id look id to open
 */
export function openLook(id) {
    return async dispatch => {
        dispatch(push(BASE_LOOKS_ROUTE + id))
    }
}
/**
 * Opens an explore using a model of the modelname
 * 
 * @param modelName name of model to use
 * @param exploreName name of of explore to open
 */
export function openExplore(modelName, exploreName): Dispatch<any>{
    return async dispatch => {
        dispatch(push(BASE_EXPLORE_ROUTE + `${modelName}::${exploreName}`))
    }
}

export function addUnauthorizedError(statusText: string): Dispatch<any>{
    return async dispatch => {
        let error: IError = {
            timestamp: new Date().getTime(),
            type: ErrorTypes.UNAUTHORIZED,
            message: statusText,
            severity: Severity.ERROR
        }

        dispatch(addError(error))
    }
}

export function addError(error: IError): Dispatch<any>{
    return async dispatch => {
        dispatch({type: ADD_ERROR, payload: error})
    }
}

export function addAlert(alert: IAlert): Dispatch<any>{
    return async dispatch => {
        dispatch({type: ADD_ALERT, payload: alert})
    }
}

export function removeAlert(alert: IAlert): Dispatch<any>{
    return async dispatch => {
        dispatch({type: REMOVE_ALERT, payload: alert})
    }
}
