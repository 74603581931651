import { IUser } from "../interfaces/IUser";
import { useState, useEffect } from "react";
import jwt from "jsonwebtoken";
import { SessionStatus } from "../enums/SessionStatus";
import { callEndpoint } from "@src/utility";
import { ISession, IUserWithSession } from "@src/interfaces";

export interface IUseAuthInterface {
  user: IUser | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
  updateContext: Function;
  session: ISession | undefined;
}

function useAuth(useAuthProvider: boolean = true): IUseAuthInterface {
  const [response, setResponse] = useState<IUseAuthInterface>({
    isAuthenticated: false,
    isLoading: true,
    user: undefined,
    session: undefined,
    updateContext: () => {},
  });

  const updateContext = (newContext) => {
    setResponse(newContext);
  };

  useEffect(() => {

    fetch("/app/user/data").then(async (res: Response) => {
      if (res.ok) {
        const json: IUserWithSession = await res.json();

        setResponse({
          isAuthenticated:
              json.session?.status === SessionStatus.AUTHORIZED
                  ? true
                  : false,
          isLoading: false,
          user: json.user,
          session: json.session,
          updateContext: response.updateContext,
        });
      } else {
        setResponse({
          isAuthenticated: false,
          isLoading: false,
          user: undefined,
          session: undefined,
          updateContext: response.updateContext,
        });
      }
    });

  }, []);

  return { ...response, updateContext: updateContext };
}

export default useAuth;
