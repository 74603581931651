export default () => {
  return <svg width="129" height="57" viewBox="0 0 129 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2959_1192)">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.5 0.5C4.52944 0.5 0.5 4.52944 0.5 9.5V56.5H33.5C38.4706 56.5 42.5 52.4706 42.5 47.5V0.5H9.5ZM26.5 12.5H33.5V34.5H26.5V12.5ZM24.5 18.4999H17.5V34.4999H24.5V18.4999ZM15.5 24.4999H8.5V34.4999H15.5V24.4999Z" fill="#0097DC"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M50.8565 19.5L57.5964 19.5545L57.6509 26.2944L55.103 23.7465L50.7691 28.0804L55.1026 32.5549L57.6505 30.007L57.596 36.7469L50.8561 36.8014L53.404 34.2535L47.5 28.1573L47.6572 28.0001L47.5547 27.8976L53.4044 22.0479L50.8565 19.5Z" fill="#0097DC"/>
      <path d="M102.5 13L98.5 9.5V12.125H91.5V13.875H98.5V16.5" fill="#0097DC"/>
      <path d="M102.5 45L98.5 41.5V44.125H91.5V45.875H98.5V48.5" fill="#0097DC"/>
      <path d="M117.5 1.5C118.959 1.5 120.358 2.05312 121.389 3.03769C122.421 4.02226 123 5.35761 123 6.75C123 8.14239 122.421 9.47775 121.389 10.4623C120.358 11.4469 118.959 12 117.5 12C116.041 12 114.642 11.4469 113.611 10.4623C112.579 9.47775 112 8.14239 112 6.75C112 5.35761 112.579 4.02226 113.611 3.03769C114.642 2.05312 116.041 1.5 117.5 1.5ZM117.5 4.125C116.771 4.125 116.071 4.40156 115.555 4.89384C115.04 5.38613 114.75 6.05381 114.75 6.75C114.75 7.44619 115.04 8.11387 115.555 8.60615C116.071 9.09844 116.771 9.375 117.5 9.375C118.229 9.375 118.929 9.09844 119.445 8.60615C119.96 8.11387 120.25 7.44619 120.25 6.75C120.25 6.05381 119.96 5.38613 119.445 4.89384C118.929 4.40156 118.229 4.125 117.5 4.125ZM117.5 13.3125C121.171 13.3125 128.5 15.0581 128.5 18.5625V22.5H106.5V18.5625C106.5 15.0581 113.829 13.3125 117.5 13.3125ZM117.5 15.8062C113.416 15.8062 109.113 17.7225 109.113 18.5625V20.0063H125.888V18.5625C125.888 17.7225 121.584 15.8062 117.5 15.8062Z" fill="#0097DC"/>
      <path d="M117.5 33.5C118.959 33.5 120.358 34.0531 121.389 35.0377C122.421 36.0223 123 37.3576 123 38.75C123 40.1424 122.421 41.4777 121.389 42.4623C120.358 43.4469 118.959 44 117.5 44C116.041 44 114.642 43.4469 113.611 42.4623C112.579 41.4777 112 40.1424 112 38.75C112 37.3576 112.579 36.0223 113.611 35.0377C114.642 34.0531 116.041 33.5 117.5 33.5ZM117.5 36.125C116.771 36.125 116.071 36.4016 115.555 36.8938C115.04 37.3861 114.75 38.0538 114.75 38.75C114.75 39.4462 115.04 40.1139 115.555 40.6062C116.071 41.0984 116.771 41.375 117.5 41.375C118.229 41.375 118.929 41.0984 119.445 40.6062C119.96 40.1139 120.25 39.4462 120.25 38.75C120.25 38.0538 119.96 37.3861 119.445 36.8938C118.929 36.4016 118.229 36.125 117.5 36.125ZM117.5 45.3125C121.171 45.3125 128.5 47.0581 128.5 50.5625V54.5H106.5V50.5625C106.5 47.0581 113.829 45.3125 117.5 45.3125ZM117.5 47.8062C113.416 47.8062 109.113 49.7225 109.113 50.5625V52.0063H125.888V50.5625C125.888 49.7225 121.584 47.8062 117.5 47.8062Z" fill="#0097DC"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M68.5 0.5C65.7386 0.5 63.5 2.73858 63.5 5.5V24.5H79.5C82.2614 24.5 84.5 22.2614 84.5 19.5V0.5H68.5ZM77.3182 6.49999H81.1364V18.5H77.3182V6.49999ZM76.2274 9.77274H72.4092V18.5H76.2274V9.77274ZM71.3182 13.0454H67.5V18.4999H71.3182V13.0454Z" fill="#0097DC"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M68.5 32.5C65.7386 32.5 63.5 34.7386 63.5 37.5V56.5H79.5C82.2614 56.5 84.5 54.2614 84.5 51.5V32.5H68.5ZM78.9628 41.7527C78.9628 41.0611 79.5547 40.5 80.2859 40.5H80.288C81.0192 40.5 81.6111 41.0611 81.6111 41.7527C81.6111 42.4444 81.0192 43.0055 80.288 43.0055C80.2728 43.0055 80.2581 43.0034 80.2434 43.0014C80.2287 42.9993 80.214 42.9973 80.1988 42.9973L77.4308 45.9639C77.4438 46.0394 77.4547 46.1149 77.4547 46.1944C77.4547 46.8861 76.8628 47.4472 76.1316 47.4472C75.4004 47.4472 74.8085 46.8861 74.8085 46.1944C74.8085 46.1863 74.8107 46.17 74.8107 46.17L72.2515 44.0297C72.1623 44.046 72.0709 44.0562 71.9774 44.0562H71.9599L69.1288 47.0922L69.1288 47.0922C69.1375 47.1432 69.1462 47.1942 69.1462 47.2472C69.1462 47.9389 68.5543 48.5 67.8231 48.5C67.0919 48.5 66.5 47.9389 66.5 47.2472C66.5 46.5556 67.0919 45.9945 67.8231 45.9945C67.8949 45.9945 67.9624 46.0047 68.0298 46.0149L68.0298 46.0149L70.7087 43.1422C70.676 43.034 70.6543 42.9218 70.6543 42.8055C70.6543 42.1139 71.2462 41.5528 71.9774 41.5528C72.7085 41.5528 73.3004 42.1139 73.3004 42.8055C73.3004 42.8252 73.2976 42.8435 73.2948 42.862C73.2927 42.8762 73.2905 42.8904 73.2896 42.9055L75.7834 44.9907C75.8944 44.9621 76.0097 44.9417 76.1316 44.9417C76.1617 44.9417 76.1909 44.9453 76.2205 44.9488C76.2357 44.9507 76.251 44.9526 76.2665 44.9539L78.9976 42.0261C78.9758 41.9384 78.9628 41.8466 78.9628 41.7527Z" fill="#0097DC"/>
    </g>
    <defs>
      <clipPath id="clip0_2959_1192">
        <rect width="128" height="56" fill="white" transform="translate(0.5 0.5)"/>
      </clipPath>
    </defs>
  </svg>;

};
