import React, { useEffect, useState } from "react";
import "./Navigation.scss";
import { EmburseAnalytics } from "../../assets/logo/bu/";
import { IUser, IState, IEmburseFolderStructure, IFolder } from "../../interfaces";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import ExitToAppIcon from "@assets/exit_to_app-24px.svg";
import { push } from "connected-react-router";
import HelpOutlineIcon from "@assets/help-24px.svg";
import { TopNav, TopNavProps } from "@emburse/embark-header";
import { SettingsIcon, TeamFieldsIcon, CalendarIcon, HomeIcon, FolderIcon } from "@emburse/embark-icons";
import { IFFlags, FFlags, getFlag } from "../../enums/FFlags";
import {ADMIN_SCHEDULE_MANAGEMENT, ADMIN_BURST_SCHEDULE_MANAGEMENT, BASE_FOLDERS_ROUTE} from "../../store/routs";
import { useContext } from "react";
import { AuthContext } from "../AuthProvider/AuthProvider";
import { IUseAuthInterface } from "../../hooks/useAuth";
export default React.forwardRef(() => {
  const dispatch = useDispatch();
  const [topNavProps, setTopNavProps] = useState<TopNavProps>({});

  const {user}: IUseAuthInterface = useContext(AuthContext);
  const flags: IFFlags = useSelector(
    (state: RootStateOrAny) => state.user?.userData.flags
  );

  let folders;
  const { folderContent, folderStructure } = useSelector((state: IState) => state.folders);
    if (flags?.[getFlag(FFlags.ShowArchiveInEmbarkHeaderMenu)]) {
      folders = folderContent?.folders.reduce((acc:{folders: IFolder[],templateFolders: IFolder[]}, item) => {
        let itemData: IFolder = item as IFolder;
        if (itemData.isPersonal
          || itemData.name === user?.company_name
          || itemData.name.toLocaleLowerCase() === 'archive'
        ) {
          
            acc.folders.push(itemData);
          } else {
            acc.templateFolders.push(itemData);
          }
          return acc;
          }, {
            folders: [],
            templateFolders: []
        });
    } else {
      folders = folderStructure?.children.reduce((acc:{folders: IFolder[],templateFolders: IFolder[]}, item) => {
      let itemData: IFolder = item.data as IFolder;
      if (itemData.isPersonal
        || itemData.name === user?.company_name
        || itemData.name.toLocaleLowerCase() === 'archive'
      ) {
        
          acc.folders.push(itemData);
        } else {
          acc.templateFolders.push(itemData);
        }
        return acc;
        }, {
          folders: [],
          templateFolders: []
      });
    }

  useEffect(() => {
    const topNav: TopNavProps = {};

    let settingsURL = "/app/p/admin/users";
    if (flags?.[getFlag(FFlags.AdminViewSchedules)]) {
      settingsURL = "/app/p/admin";
    }

    const settingsMenuObj = {
            sections: [
              {
                showOnlyOnMobile: true,

                items: [
                  {
                    icon: HelpOutlineIcon,
                    iconText: "Help",
                  },
                  {
                    icon: SettingsIcon,
                    iconText: "Settings",
                    onClick: () => {
                      dispatch(push(settingsURL));
                    },
                  },
                  {
                    icon: ExitToAppIcon,
                    iconText: "Logout",
                    onClick: () => {
                      dispatch(push("/logout"));
                    },
                  },
                ],
              },
              {
                showOnlyOnDesktop: true,
                hideDivider: true,
                items: [
                  {
                    icon: ExitToAppIcon,
                    iconText: "Logout",
                    key: "logout",
                    onClick: () => {
                      dispatch(push("/logout"));
                    },
                  },
                ],
              }
            ],
          };

    if (localStorage.getItem('auth_status') === 'true') {
      if (flags?.[getFlag(FFlags.EnablePendoHelpMenu)]) {
        topNav.helpOnClick = () => {
          // console.log("Help is delegated to emburse-assistant");
        };
        topNav.openHelpInNewTab = false;
        topNav.helpCustAttr = {
          "data-tag": "emburse-assistant"
        };
      } else {
        topNav.helpUrl = "https://help.analytics.emburse.com/hc/en-us";
      }

      topNav.settingsMenu = settingsMenuObj;

      if (user?.tenant_admin) {
        topNav.settingsUrl = settingsURL;
        topNav.settingsOnClick = () => dispatch(push(settingsURL));
      }

      topNav.companyName = `${
        user?.company_name != null ? user?.company_name : "Company Name"
      }`; // Need to get the company name in App.py
      topNav.userName = `${user?.first_name} ${user?.last_name}`;
    } else {
      topNav.helpUrl = undefined;
      topNav.settingsMenu = undefined;

      if (user?.tenant_admin) {
        topNav.settingsUrl = undefined;
        topNav.settingsOnClick = undefined;
      }

      topNav.companyName = undefined;
      topNav.userName = undefined;
    }
    topNav.logo = EmburseAnalytics;
    topNav.homeOnClick = !user?.license_limit_exceeded ? () => dispatch(push("/app/p/folders/all")) : undefined;
    
    topNav.sidebarMenu = {

      menus:[
        {
        
          menuTabLabel: "personal",
          tabMenu: {
            sections: [
              {
                header: user?.tenant_admin ? undefined : 'personal', 
                items: [
                  {
                    iconText: "Home",
                    icon: HomeIcon,
                    onClick: () => {
                      if (user?.license_limit_exceeded) {
                        return undefined;
                      }
                      const landing_page = user?.landing_page || '/folders/all';
                      return dispatch(push("/app/p" + landing_page));
                    }
                  },
                  {
                    iconText: "Folders",
                    icon: FolderIcon,
                    isCollapsible: true,
                    items: folders?.folders.map((item:IFolder) => (
                      {
                        iconText: item.name,
                        onClick: () => {
                          dispatch(push(`${BASE_FOLDERS_ROUTE}${item.id}`));
                        }
                      })
                    ),
                  },
                  {
                    iconText: "Templates",
                    icon: FolderIcon,
                    isCollapsible: true,
                    items: folders?.templateFolders.map((item:IFolder) => ({
                      iconText: item.name,
                      onClick: () => {
                        dispatch(push(`${BASE_FOLDERS_ROUTE}${item.id}`));
                      }
                    }))
                  }
                ]
              },
            ]
          }
        }
      ]
    };
    if (user?.tenant_admin) {
      topNav.sidebarMenu?.menus?.push({
        menuTabLabel: "admin",
        tabMenu: {
          sections: [
            {
              items: [
                {
                  iconText: "User Management",
                  icon: TeamFieldsIcon,
                  onClick: () => dispatch(push(settingsURL))
                },
                {
                  iconText: "Schedules",
                  icon: CalendarIcon,
                  onClick: () => dispatch(push(ADMIN_SCHEDULE_MANAGEMENT))
                },
                {
                  iconText: "Burst Schedules",
                  icon: CalendarIcon,
                  onClick: () => dispatch(push(ADMIN_BURST_SCHEDULE_MANAGEMENT))
                }
              ]
            },
          ]
        }
      });
    }

    setTopNavProps(topNav);
  }, [user, flags, folderStructure]);

  return <TopNav {...topNavProps} hasSideBar={true}/>;
});
