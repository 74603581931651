import React, { createContext, useEffect } from "react";
import { IState } from "@src/interfaces";
import {
  IUserState,
} from "../../store";

import {useSelector } from "react-redux";
import { IHasChildrenProps } from "@src/interfaces/IHasChildrenProps";
import useAuth from "@src/hooks/useAuth";
import { IUseAuthInterface } from "@src/hooks/useAuth";
import { FFlags, getFlag } from "@src/enums";
import {flagsSelector} from "@src/store/selector";

export interface IAuthProvider extends IHasChildrenProps {}

export const AuthContext = createContext<IUseAuthInterface>({
  isAuthenticated: false,
  isLoading: true,
  user: undefined,
  session: undefined,
  updateContext: () => {},
});

export default (props: IAuthProvider) => {
  const flags = useSelector(flagsSelector);
  let values: IUseAuthInterface = useAuth();

  useEffect(() => {
    if (flags?.[getFlag(FFlags.InitializePendo)]) {
      const runtimeEnvironment = (window as any).localStorage.getItem("env");
      const pendoIsInitialized =
        (window as any).localStorage.getItem("pendoToken") !== "";
      const userIsReviewer = String(values.user?.bu_user_unique_id)?.startsWith(
        "review:"
      );

      if (pendoIsInitialized && runtimeEnvironment === "prod") {
        if (values.isAuthenticated && !values.isLoading) {
          if (flags?.[getFlag(FFlags.SendPendoAccountID)]) {
            (window as any).pendo.initialize({
              visitor: {
                id: values.user?.pendo_visitor_id,
                analytics_role: values.user?.analytics_role,
                analytics_admin: values.user?.admin,
                analytics_reviewer: userIsReviewer,
              },
              account: {
                id: values.user?.pendo_account_id,
              },
            });
          } else {
            (window as any).pendo.initialize({
              visitor: {
                id: values.user?.pendo_visitor_id,
                analytics_role: values.user?.analytics_role,
                analytics_admin: values.user?.admin,
                analytics_reviewer: userIsReviewer,
              },
            });
          }
        }
      }
    }
  }, [
    flags,
    values.isAuthenticated,
    values.isLoading,
    values.user?.admin,
    values.user?.analytics_role,
    values.user?.bu_user_unique_id,
    values.user?.pendo_account_id,
    values.user?.pendo_visitor_id,
  ]);

  return (
    <AuthContext.Provider value={values}>
      {!values.isAuthenticated && values.isLoading ? <></> : props.children}
    </AuthContext.Provider>
  );

};
