import { Button, Card, TextField, Typography } from '@emburse/embark-core';
import React from 'react';
import {useState} from 'react';
import { IDashboard } from '@interfaces';
import { Field } from 'formik';
import { v4 } from 'uuid';
import { loadingData } from '../../store/actions/lookerFrameActions';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { flagsSelector } from '../../store/selector';
import { FFlags, getFlag } from '@src/enums';

export interface SaveDescriptionProps {

}

const useStyles = createUseStyles({
    fetchButton: {
        marginLeft: "15px"
    },
    descriptionBox: {
        marginTop: "20px"
    },
    saveButton:{
        marginTop: "20px"
    }
});

export default (props: SaveDescriptionProps) => {
    let styles = useStyles();
    const flags = useSelector(flagsSelector);
    const [dashboard, setDashboard] = useState<IDashboard | null>();
    const [dashboardID, setDashboardID] = useState<number|string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [newDescription, setNewDescription] = useState("");
    const [isOK, setIsOK] = useState<boolean | undefined>();
    const [isDashFound, setIsDashFound] = useState<boolean | undefined>();

    const handleFetch = (dashboardID: number | string | undefined) => {

        if(dashboardID){
            setLoading(true);
            fetch(`/app/dashboards/${dashboardID}/info`).then(async (res) => {
                if(res.ok){
                    let body = await res.json() as IDashboard;
                    setIsDashFound(true);
                    setDashboard(body);
                    setNewDescription(body.description === null ? "" : body.description);
                    setLoading(false);
                }
                else{
                    setIsDashFound(false);
                    setLoading(false);
                    setDashboard(null);
                    setNewDescription("");
                }
            } ).catch(err => {
                console.log(err);
            });
        }

    };

    const handleSave = () => {
        if(dashboard?.can.update){
            setIsOK(undefined);
            fetch(`/app/dashboards/${dashboard?.id}`,  {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    id: dashboard.id,
                    dashboard_name: dashboard.title,
                    dashboard_description: newDescription
                })}).then(res => {
                    if(res.ok){
                        setIsOK(true);
                    }else{
                        setIsOK(false);
                    }
            });
        }
    };

    return (
        <>
           <Card variant="elevation">
               <h2>Dashboard</h2>
                <TextField id={v4()} label={"Dashboard ID"} onChange={(e) => {setDashboardID(e.target.value);}}/>
                <span className={styles.fetchButton}>
                    <Button onClick={() => {handleFetch(dashboardID); }}  disabled={loading} >Fetch</Button>
                </span>
                <br />
                {flags?.[getFlag(FFlags.ShowDescStatusInternal)] && isDashFound !== undefined && !isDashFound &&
                    <Typography variant="caption" color="error">Not Found</Typography>
                }
                <div className={styles.descriptionBox}>
                    <TextField
                        label={`Dashboard Description`}
                        id={v4()}
                        value={newDescription}
                        onChange={(e) => {setNewDescription(e.target.value);}}
                        fullWidth
                        multiline
                        rows={3}
                        maxRows={3}
                    />

                    {flags?.[getFlag(FFlags.ShowDescStatusInternal)] && isOK === true &&
                        <Typography variant="caption">Saved</Typography>
                    }
                    {flags?.[getFlag(FFlags.ShowDescStatusInternal)] && isOK === false && isOK !== undefined &&
                        <Typography variant="caption" color="error">Error Saving</Typography>
                    }
                </div>
                <div className={styles.saveButton}>
                    <Button size="small" variant="outlined" onClick={handleSave}>Save</Button>
                </div>
            </Card>
        </>
    );
};
