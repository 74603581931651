import React from "react";
import {useDispatch} from "react-redux";
import './FolderBreadcrumb.scss';
import {openFolder} from '../../store/actions/appActions';
import {Breadcrumbs, Link} from '@emburse/embark-core'


const FOLDERS_WHILE_LOADING: any[] = [{
    "id": "all",
    "parentId": null,
    "name": "..."
}]

const FOLDER_AFTER_ERRORS: any[] = [{
    "id": "all",
    "parentId": null,
    "name": "All"
}]

export default ({folderId, folders}) => {
    /**
     * folders may be empty object {} before API has returned value. If empty uses FOLDERS_WHILE_LOADING as
     * the breadcrumb.
     * If folderId is not present in folders, uses FOLDER_AFTER_ERRORS
     * Objective is there is no state where user doesn't have at least the root breadcrumb.
     */
    const dispatch = useDispatch()
    const foldersPath: any[] = calculatePath(folderId, folders)
    const toFolderView = (folder) => (<FolderItem key={folder.id} dispatch={dispatch} folder={folder}/>);
    return (
        <div className="da-breadcrumbs" data-tag="breadcrumbs">
            <Breadcrumbs>{foldersPath.map(toFolderView)}</Breadcrumbs>
        </div>
    )
}

const FolderItem = ({dispatch, folder}) => {
    return (
        <Link onClick={() => dispatch(openFolder(folder.id))} color="inherit" data-tag={folder.name}>
            {folder.name}
        </Link>
    )
}


const calculatePath = (folderId, folders) => {
    if (Object.keys(folders).length === 0) {
        return FOLDERS_WHILE_LOADING
    }
    if (!folderId || !folders) {
        return FOLDER_AFTER_ERRORS
    }
    const foldersPath: any[] = []
    let currentFolder = folders[folderId]
    if (!currentFolder) {
        return FOLDER_AFTER_ERRORS
    }
    do {
        foldersPath.push(currentFolder)
        currentFolder = folders[currentFolder.parentId]
    } while (currentFolder)
    return foldersPath.reverse()
}
