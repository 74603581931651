import { RequestCache } from "../common";
import { Entity, BaseResource } from "./BaseResource";

export interface IRole extends Entity {
  name: string;
  label: string;
  group: string;
}
/**
 * The Role resource. Used for fetch entities of IRole
 *
 * @export
 * @class Role
 * @extends {BaseResource<IRole>}
 */
export class RoleResource extends BaseResource<IRole> {
  public readonly expiry = 300000; //5 minutes random...
  public readonly urlRoot = "/app/users/roles";
  public readonly cache = true;
  public readonly cacheType = RequestCache.FORCE_CACHE;
}

export const Role = new RoleResource();
