import React, { ReactElement } from 'react';
import {
  Card, GridRow, GridItem, Typography, Spacer
} from '@emburse/embark-core';
import BurstReport from '../Icons/burstReport';
import StandardReport from '../Icons/standardReport';
import {SCHEDULE_BURST_FORM_VIEW, SCHEDULE_FORM_VIEW} from './constants';
import {ScheduleAPIProvider} from "@src/components";

const config = {
  burst: {
    type: 'burst',
    iconComponent: <BurstReport/>,
    title: 'bursting',
    description: 'Run a report to get a distribution list, then run a report for each recipient on the list. Each recipient receives a different copy of the report.',
  },
  standard: {
    type: 'standard',
    iconComponent: <StandardReport/>,
    title: 'standard',
    description: 'Run & send a report on a schedule. Each recipient receives a copy of the same report.',
  }
};
interface ScheduleTypesProps {
  setModalState: React.Dispatch<React.SetStateAction<string>>;
}
export default (props: ScheduleTypesProps) => {

  return (
    <>
      <Typography variant='h6'>Choose the type of schedule to create.</Typography>
      <Spacer size='16px'/>
      <ReportTypeCard config={config.standard} setModalState={props.setModalState}/>
      <Spacer size='16px'/>
      <ReportTypeCard config={config.burst} setModalState={props.setModalState}/>
    </>
    );
};


interface ReportTypeCardProps {
    config: {
      type: string;
      iconComponent: ReactElement;
      title: string;
      description: string;
    }
    setModalState
}
const ReportTypeCard = (props: ReportTypeCardProps) => {
  const handleReportTypeClick = () => {
    if (props.config.type === "burst") {
      props.setModalState(SCHEDULE_BURST_FORM_VIEW);
    } else {
      props.setModalState(SCHEDULE_FORM_VIEW);
    }

  };

  return (
    <Card
      cardActionAreaOnClick={handleReportTypeClick}
    >
      <GridRow>
        <GridItem xs={3} sx={{height:'100%', paddingTop:'16px'}}>
          {props.config.iconComponent}
        </GridItem>
        <GridItem xs={9} sx={{padding: '8px 12px'}}>
          <Typography  variant='body2' sx={{textTransform:'capitalize', fontWeight: '500'}}>{props.config.title}</Typography>
          <Typography variant='body2' >{props.config.description}</Typography>
        </GridItem>
      </GridRow>
    </Card>
  );
};