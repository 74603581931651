// import { Endpoint } from 'src/Utility';
import { RequestCache } from "../common";
import { Entity, BaseResource } from "./BaseResource";

export type IUserRole = Entity;

/**
 * The UserRole resource. Used for fetch entities of IUserRole
 *
 * @export
 * @class Schedule
 * @extends {BaseResource<IUserRole>}
 */
export class UserRoleResource extends BaseResource<IUserRole> {
  public readonly cache: boolean = false;
  public readonly expiry = 300000; //5 minutes random...
  public readonly urlRoot = "/app/users";
  public readonly cacheType = RequestCache.RELOAD;
}

export const UserRole = new UserRoleResource();
