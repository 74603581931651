import React, { useState, useEffect } from "react";
import { Formik, useFormikContext, Form, Field } from "formik";
import { Card, Button, TextField } from "@emburse/embark-core";
import "./ChangeCustomerAssociation.scss";
import { v4 } from "uuid";
import { IGroup } from "@src/interfaces";
import { BusinessUnits } from "@src/enums/BusinessUnits";

const ActionButton = (props: { buttonText: string; disabled: boolean }) => {
  const { submitForm } = useFormikContext();

  return (
    <Button
      size="small"
      variant="outlined"
      onClick={submitForm}
      disabled={props.disabled}
    >
      {props.buttonText}
    </Button>
  );
};

export const userIdField = ({ field, form: { touched, errors }, ...props }) => {
  return (
    <TextField {...field} {...props} label="User ID" helperText="The user id" />
  );
};

export default () => {
  const initialValues = {
    user_id: 29,
  };
  const [groups, setGroups] = useState<number[]>([]);
  const [user, setUser] = useState<{
    looker_user_id?: number;
    group_ids: number[];
  }>({
    group_ids: [],
  });
  const [allGroups, setAllGroups] = useState<IGroup[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [listDisabled, setListDisabled] = useState(false);
  const [listUserError, setListUserError] = useState(false);

  useEffect(() => {
    fetch("/app/admin/groups").then(async (res: Response) => {
      if (res.ok) {
        let json = await res.json();
        setAllGroups(json);
      }
    });
  }, []);

  const listGroupFromUser = (values) => {
    setGroups([]);
    setUser({
      looker_user_id: undefined,
      group_ids: [],
    });
    setListDisabled(true);
    setListUserError(false);

    fetch(`/app/admin/users/${values.user_id}`).then(async (res: Response) => {
      if (res.ok) {
        let json = await res.json();
        setUser(json["user"]);

        setGroups(json["user"]["group_ids"]);

        setListDisabled(false);
      } else {
        setListDisabled(false);
        setListUserError(true);
      }
    });
  };
  const removeGroup = (group: IGroup) => {
    let findName = group.name.substring(0, 2) as BusinessUnits;
    if (!(findName in BusinessUnits))
      setGroups(
        groups.filter((gr) => {
          return gr !== group.id;
        })
      );
  };

  const addGroup = (group: IGroup) => {
    let findName = group.name.substring(0, 2) as BusinessUnits;
    if (!(findName in BusinessUnits)) setGroups(groups.concat([group.id]));
  };

  const saveUser = () => {
    let newGroups = groups;
    let oldGroups = user.group_ids;
    let toRemove: number[] = [];
    let toAdd: number[] = [];

    for (let i = 0; i < oldGroups.length; i++) {
      const el = oldGroups[i];
      if (!newGroups.includes(el)) {
        toRemove.push(el);
      }
    }

    for (let i = 0; i < newGroups.length; i++) {
      const el = newGroups[i];

      if (!oldGroups.includes(el)) {
        toAdd.push(el);
      }
    }

    let addRemoveGroups = {
      remove: toRemove,
      add: toAdd,
      looker_user_id: user.looker_user_id,
    };

    setSaveDisabled(true);

    fetch("/app/admin/users", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(addRemoveGroups),
    }).then(async (res: Response) => {
      if (res.ok) {
        let json = await res.json();
        console.log(json);

        let updatedGroups = json["group_ids"];
        setGroups(updatedGroups);
        user.group_ids = updatedGroups;
        setUser(user);
      }

      setSaveDisabled(false);
    });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => listGroupFromUser(values)}
      >
        <Card variant="elevation">
          <h2>Add or remove Groups from User</h2>
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => listGroupFromUser(values)}
          >
            <Form>
              <div>
                <Field
                  component={userIdField}
                  id="user_id"
                  name="user_id"
                  label="User ID"
                />
              </div>
              <div>
                <ActionButton buttonText="List" disabled={listDisabled} />
              </div>
              {listUserError && (
                <div>
                  <span>Invalid User ID</span>
                </div>
              )}
            </Form>
          </Formik>
          {user["first_name"] && user["last_name"] && (
            <h3>
              Groups for {user["first_name"]} {user["last_name"]}
            </h3>
          )}
          {groups.length > 0 && (
            <>
              <h4>Remove Group</h4>
              <ul className="da-transfer-list">
                {allGroups.map((group) => {
                  if (groups.includes(group.id))
                    return (
                      <li
                        value={group.id}
                        key={`option-add-${group.id}`}
                        onDoubleClick={() => {
                          removeGroup(group);
                        }}
                      >
                        {group.id} - {group.name}
                      </li>
                    );
                })}
              </ul>
            </>
          )}
          {groups.length > 0 && (
            <>
              <h4>Add Group</h4>
              <TextField
                id={v4()}
                label="Group Name"
                helperText="Search for group"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <ul className="da-transfer-list">
                {allGroups.map((group) => {
                  if (!groups.includes(group.id) && searchTerm === "")
                    return (
                      <li
                        value={group.id}
                        key={`option-add-${group.id}`}
                        onDoubleClick={() => {
                          addGroup(group);
                        }}
                      >
                        {group.id} - {group.name}
                      </li>
                    );

                  if (
                    !groups.includes(group.id) &&
                    searchTerm !== "" &&
                    group.name.toLowerCase().includes(searchTerm.toLowerCase())
                  )
                    return (
                      <li
                        value={group.id}
                        key={`option-add-${group.id}`}
                        onDoubleClick={() => {
                          addGroup(group);
                        }}
                      >
                        {group.id} - {group.name}
                      </li>
                    );
                })}
              </ul>
              <small>
                Double click on groups to add and remove them then click save.
              </small>
              <br />
              <br />
              <Button onClick={saveUser} disabled={saveDisabled}>
                {saveDisabled ? "Saving" : "Save Groups"}
              </Button>
            </>
          )}
        </Card>
      </Formik>
    </>
  );
};
