import { IUseCustomerList } from "@src/interfaces";
import React, { createContext, useState } from "react";
import { IHasChildrenProps } from "../../interfaces/IHasChildrenProps";
import { useEffect } from "react";

export interface CustomerListProviderProps extends IHasChildrenProps {}

export const CustomerListContext = createContext<IUseCustomerList>({
  tenants: [],
  total: 0,
  counts: {
    dvi: 0,
    ce: 0,
    cr: 0,
  },
});

export default (props: CustomerListProviderProps) => {
  const [customers, setCustomers] = useState<IUseCustomerList>({
    tenants: [],
    total: 0,
    counts: {
      dvi: 0,
      ce: 0,
      cr: 0,
    },
  });

  useEffect(() => {
    fetch(
      "/app/admin/tenants?search=&fields=tenant_id,name,business_unit,bu_env,bu_shard,bu_unique_id"
    ).then(async (res) => {
      let body = await res.json();
      setCustomers(body);
    });
  }, []);

  return (
    <CustomerListContext.Provider value={customers}>
      {props.children}
    </CustomerListContext.Provider>
  );
};
