import { useTheme } from "@emburse/embark-core";
import { ComponentsProvider, Theme } from "@looker/components";
// import { useTheme } from "@material-ui/core";
import { IHasChildrenProps } from "@src/interfaces";
import styled from "styled-components";
import React, { Fragment } from "react";

export interface LookerProviderProps extends IHasChildrenProps {}

export interface LookerTheme extends Theme {}
const StyledDiv = styled.div
  .withConfig({
    componentId: "my-app",
    displayName: "my-app",
  })
  .attrs({ className: "root-div" })``;

export default (props: LookerProviderProps) => {
  // let embark = useTheme();
  const embark = useTheme();

  let lTheme: LookerTheme = {
    breakpoints: Object.values(embark.breakpoints.values).map((v) =>
      v.toString()
    ),
    colors: {
      pageBackground: embark.palette.common.white,
      background: embark.palette.common.white,
      key: embark.palette.primary.main,
      link: embark.palette.primary.main,
      critical: embark.palette.error.main,
      warn: embark.palette.warning.main,
      positive: embark.palette.primary.main,
      inform: embark.palette.info.main,
      calculation: embark.palette.common.white,
      dimension: embark.palette.common.white,
      measure: embark.palette.common.white,
      text: embark.palette.text.primary,
      title: embark.palette.text.secondary,
      body: embark.palette.text.secondary,
      // illustration1: "blue",
      // illustration2: "blue",
      // illustration3: "blue",
      // illustration4: "blue",
      // illustration5: "blue",
      warnAccent: embark.palette.warning.main,
      positiveAccent: embark.palette.primary.main,
      informAccent: embark.palette.info.main,
      field: embark.palette.common.white,
      inverse: embark.palette.common.black,
      inverseOn: embark.palette.common.white,
      neutral: embark.palette.primary.light,
      linkInteractive: embark.palette.text.primary,
      text1: embark.palette.text.primary,
      text2: embark.palette.text.primary,
      text3: embark.palette.text.primary,
      text4: embark.palette.text.primary,
      text5: embark.palette.text.primary,
      ui1: embark.palette.grey[50],
      ui2: embark.palette.grey[200],
      ui3: embark.palette.grey[400],
      ui4: embark.palette.grey[600],
      ui5: embark.palette.grey[800],
      // subtle: "blue",
      // accent: "blue",
      // focus: "blue",
      // interactive: "blue",
      // pressed: "blue",
      criticalSubtle: embark.palette.common.black,
      criticalAccent: embark.palette.common.black,
      criticalFocus: embark.palette.common.black,
      criticalInteractive: embark.palette.common.black,
      criticalPressed: embark.palette.common.black,
      criticalText: embark.palette.common.black,
      criticalBorder: embark.palette.common.black,
      neutralSubtle: embark.palette.common.black,
      neutralAccent: embark.palette.common.black,
      neutralFocus: embark.palette.common.black,
      neutralInteractive: embark.palette.common.black,
      neutralPressed: embark.palette.common.black,
      neutralText: embark.palette.common.black,
      neutralBorder: "blue",
      calculationSubtle: "white",
      calculationAccent: "white",
      calculationFocus: "white",
      calculationInteractive: "white",
      calculationPressed: "white",
      calculationText: "black",
      calculationBorder: "black",
      dimensionSubtle: "white",
      dimensionAccent: "white",
      dimensionFocus: "white",
      dimensionInteractive: "white",
      dimensionPressed: "white",
      dimensionText: "black",
      dimensionBorder: "black",
      measureSubtle: "white",
      measureAccent: "white",
      measureFocus: "white",
      measureInteractive: "white",
      measurePressed: "white",
      measureText: "black",
      measureBorder: "black",
      keySubtle: "white",
      keyAccent: "white",
      keyFocus: "white",
      keyInteractive: "white",
      keyPressed: "white",
      keyText: "black",
      keyBorder: "black",
      positiveSubtle: "white",
      positiveFocus: "white",
      positiveInteractive: "white",
      positivePressed: "white",
      positiveText: "black",
      positiveBorder: "black"
    },
    dashboardAppearance: {
      show_dashboard_header: true,
      show_dashboard_menu: true,
      title: false,
      filters: false,
      show_filters_toggle: true,
      show_last_updated_indicator: true,
      show_reload_data_icon: true,
      center_dashboard_title: true,
      tiles: {
        title_alignment: "left" as any,
        background: "blue",
        title: "blue",
        body: "blue",
        text_tile_background_color: "red"
      },
    },
    defaults: {
      brandAnimation: true,
      density: 0,
      externalLabel: true,
    },
    easings: {
      ...embark.transitions.easing,
      ease: embark.transitions.easing.easeIn,
      easeInBack: embark.transitions.easing.easeIn,
      easeOutBack: embark.transitions.easing.easeOut,
    },
    elevations: {
      plus0: `${embark.shadows[4]}`,
      plus1: `${embark.shadows[8]}`,
      plus2: `${embark.shadows[12]}`,
      plus3: `${embark.shadows[16]}`,
      plus4: `${embark.shadows[20]}`,
      plus5: `${embark.shadows[24]}`,
    },
    fontSizes: {
      xxsmall: "16px",
      xsmall: "16px",
      small: "16px",
      medium: "16px",
      large: "16px",
      xlarge: "16px",
      xxlarge: "16px",
      xxxlarge: "16px",
      xxxxlarge: "16px",
      xxxxxlarge: "16px",
    },

    fonts: {
      body: embark.typography.fontFamily as string,
      brand: embark.typography.fontFamily as string,
      code: embark.typography.fontFamily as string,
    },
    // fontSources?: embark.typography.fontFamily,
    fontWeights: {
      medium: embark.typography.fontWeightMedium as number,
      normal: embark.typography.fontWeightRegular as number,
      semiBold: embark.typography.fontWeightLight as number,
      bold: embark.typography.fontWeightBold as number,
    },
    lineHeights: {
      xxsmall: "16px",
      xsmall: "16px",
      small: "16px",
      medium: "16px",
      large: "16px",
      xlarge: "16px",
      xxlarge: "16px",
      xxxlarge: "16px",
      xxxxlarge: "16px",
      xxxxxlarge: "16px",
    },
    radii: {
      none: "0",
      xsmall: `${embark.shape.borderRadius.toString()}px`,
      small: `${embark.shape.borderRadius.toString()}px`,
      medium: `${embark.shape.borderRadius.toString()}px`,
      large: `${embark.shape.borderRadius.toString()}px`,
    },
    sizes: {
      xxxsmall: "16px",
      xxsmall: "16px",
      xsmall: "16px",
      small: "16px",
      medium: "16px",
      large: "16px",
    },
    shadows: {
      1: `${embark.shadows[4]}`,
      2: `${embark.shadows[8]}`,
      3: `${embark.shadows[12]}`,
      4: `${embark.shadows[16]}`,
      5: `${embark.shadows[20]}`,
      6: `${embark.shadows[24]}`,
    },
    space: {
      xxxsmall: "16px",
      xxsmall: "16px",
      xsmall: "16px",
      small: "16px",
      medium: "16px",
      large: "16px",
      xlarge: "16px",
      xxlarge: "16px",
      xxxlarge: "16px",
      xxxxlarge: "16px",
      none: `${embark.spacing(0).toString()}px`,
      u05: `${embark.spacing(0.5).toString()}px`,
      u1: `${embark.spacing(1).toString()}px`,
      u2: `${embark.spacing(2).toString()}px`,
      u3: `${embark.spacing(3).toString()}px`,
      u4: `${embark.spacing(4).toString()}px`,
      u5: `${embark.spacing(5).toString()}px`,
      u6: `${embark.spacing(6).toString()}px`,
      u7: `${embark.spacing(7).toString()}px`,
      u8: `${embark.spacing(8).toString()}px`,
      u9: `${embark.spacing(9).toString()}px`,
      u10: `${embark.spacing(10).toString()}px`,
      u11: `${embark.spacing(11).toString()}px`,
      u12: `${embark.spacing(12).toString()}px`,
      u13: `${embark.spacing(13).toString()}px`,
      u14: `${embark.spacing(14).toString()}px`,
      u15: `${embark.spacing(15).toString()}px`,
      u16: `${embark.spacing(16).toString()}px`,
    },
    transitions: {
      none: 0,
      rapid: embark.transitions.duration.shortest,
      quick: embark.transitions.duration.shorter,
      simple: embark.transitions.duration.short,
      moderate: embark.transitions.duration.standard,
      complex: embark.transitions.duration.complex,
      intricate: embark.transitions.duration.short - 25,
    },
    zIndexFloor: 2000,
  };

  return (
    <Fragment>
      <ComponentsProvider themeCustomizations={lTheme}>
        <StyledDiv>{props.children}</StyledDiv>
      </ComponentsProvider>
    </Fragment>
  );
};