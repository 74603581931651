import React, {useContext, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {hideModal, hideModalError, showModalError} from '../../../store/actions/modalActions';
import {
    Button,
    ButtonContainer,
    Modal,
    ModalFooter,
    ModalHeader,
    TextField
} from '@emburse/embark-core';
import {v4} from 'uuid';
import {deleteFolder} from '../../../store/actions/foldersActions';
import { IState } from '@src/interfaces';
import { IEmburseFolderStructure } from '../../../interfaces/IEmburseFolderStructure';
import { StoreContext } from '../../StoreAccessor/StoreAccessor';

export default ({folder, modal}) => {
    const dispatch = useDispatch();
    const modalData = useSelector((state: IState) => state.modal.data);
    const folderStructureState = useContext<{state: IEmburseFolderStructure}>(StoreContext);
    const [folderName, setFolderName] = useState('');
    const closeModal = () => {
        dispatch(hideModal());
    };

    const setNewName = (e) => {
        if (modal.errorMessage) {
            dispatch(hideModalError());
        }
        setFolderName(e.target.value);
    };

    const handleDeleteFolderClick = () => {
        if (folderName !== folder.name) {
            dispatch(showModalError('Entered name does not match to folder name'));
        } else {
            dispatch(deleteFolder(folder, folderStructureState.state));
        }
    };

    const modalHeader = <ModalHeader title="Delete Folder" showClose={true} onCloseClicked={closeModal}/>;

    const modalFooter = (
        <ModalFooter>
            <ButtonContainer alignButtons={'right'}>
                <Button onClick={closeModal} variant="outlined" disabled={modal.loading}>Cancel</Button>
                <Button color="error" variant="contained" onClick={handleDeleteFolderClick} disabled={modal.loading || !folderName}>
                    Delete Folder
                </Button>
            </ButtonContainer>
        </ModalFooter>
    );

    const folderTitle = folder ? folder.name : '';
    const {foldersCount = 0, dashboardsCount = 0, looksCount = 0} = modalData;
    return (
        <Modal open modalHeader={modalHeader} modalFooter={modalFooter} onClose={closeModal} isLoading={modal.loading}>
            <div className="da-modal-form">
                <p>
                    Are you sure you want to delete {folderTitle}? This will also
                    remove {foldersCount} sub-folder(s), {dashboardsCount} dashboard(s) and {looksCount} look(s). <b>This
                    cannot be undone.</b> Type the name of the folder below to confirm.
                </p>
                <TextField label="Folder Name"
                        id={v4()}
                        value={folderName}
                        onChange={setNewName}
                        fullWidth
                        required
                        helperText={modal.errorMessage}
                        error={Boolean(modal.errorMessage)}
                />
            </div>
        </Modal>
    );
};
