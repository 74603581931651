import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {hideModal, hideModalError, showModalError} from '../../../store/actions/modalActions';
import {
    Button,
    ButtonContainer,
    Modal,
    ModalFooter,
    ModalHeader,
    TextField
} from '@emburse/embark-core';
import {v4} from 'uuid';
import { IDashboard } from '../../../interfaces/IDashboard';
import { ILook } from '../../../interfaces/ILook';
import { deleteDashboard, deleteLook } from '@src/store';

export interface DeleteModalProps{
    lookerObj:IDashboard|ILook, 
    modal: any,
    type: "dashboard" | "look"
}

export default ({lookerObj, modal, type}: DeleteModalProps) => {
    const dispatch = useDispatch();
    const [objectName, setObjectName] = useState('');
    const closeModal = () => {
        dispatch(hideModal());
    };

    const setNewName = (e) => {
        if (modal.errorMessage) {
            dispatch(hideModalError());
        }
        setObjectName(e.target.value);
    };
    const handleDeleteDashboardClick = () => {
        if (objectName !== lookerObj.title) {
            dispatch(showModalError(`Entered name does not match to ${type} name`));
        } else {
            if(type === "dashboard"){
                dispatch(deleteDashboard(lookerObj));
            }
            if(type === 'look'){
                dispatch(deleteLook(lookerObj));
            }
        }
    };
    const typeCapitalized: string = type.charAt(0).toUpperCase() + type.slice(1);
    const modalHeader = <ModalHeader title={`Delete ${typeCapitalized}`} showClose={true} onCloseClicked={closeModal}/>;

    const modalFooter = (
        <ModalFooter>
            <ButtonContainer alignButtons={'right'}>
                <Button onClick={closeModal} variant="outlined" disabled={modal.loading}>Cancel</Button>
                <Button color="error" variant="contained" onClick={handleDeleteDashboardClick} disabled={modal.loading || !objectName}>
                    Delete {typeCapitalized}
                </Button>
            </ButtonContainer>
        </ModalFooter>
    );

    const objectTitle = lookerObj ? lookerObj.title : '';
    return (
        <Modal open modalHeader={modalHeader} modalFooter={modalFooter} onClose={closeModal} isLoading={modal.loading}>
            <div className="da-modal-form">
                <p>
                    Are you sure you want to delete {objectTitle}? <b>This
                    cannot be undone.</b> Type the name of the {type} below to confirm.
                </p>
                <TextField label={`${typeCapitalized} Name`}
                        id={v4()}
                        value={objectName}
                        onChange={setNewName}
                        fullWidth
                        required
                        helperText={modal.errorMessage}
                        error={Boolean(modal.errorMessage)}
                />
            </div>
        </Modal>
    );
};
