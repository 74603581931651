import { IState } from '../interfaces/IState';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { ErrorTypes } from '../enums/ErrorTypes';
import { IError } from '@src/interfaces';

export function useErrorManager(){
    const errors: IError[] = useSelector((state: IState) => state.app.errors)
    const dispatch = useDispatch()
    
    useEffect(() => {
        console.log(errors)
        if(errors.length > 0){
            // if there are any errors that aren't silent errors...
            if(errors.filter((err: IError) => err.type !== ErrorTypes.SILENT).length > 0)
                dispatch(push("/error"))
        }
    }, [dispatch, errors])
}