import { Button, Menu, MenuItem } from '@emburse/embark-core';
import React, { ReactNode, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { v4 } from 'uuid';
import { IDropDownMenuItem } from '../../interfaces/IDropDownMenuItem';

export interface DropDownMenuProps {
    onSelect: (item: IDropDownMenuItem) => void, 
    menuList: IDropDownMenuItem[],
    startIcon?: ReactNode
}



const DropDownMenu = (props: DropDownMenuProps) => {

    const [anchor, setAnchor] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [value, setValue] = useState(props.menuList[0].value);
    const [label, setLabel] = useState(props.menuList[0].label);

    const openMenu = event => {
        setAnchor(event.currentTarget);
        setMenuOpen(true);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    const menuItemClick = (item: IDropDownMenuItem) => {
        setValue(item.value);
        setLabel(item.label);
        setMenuOpen(false);
        props.onSelect(item);   
    };
    
    return (
        <span>
            <Button startIcon={props.startIcon} endIcon={menuOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} color="secondary" onClick={openMenu}>{label}</Button>
            <Menu open={menuOpen} anchorEl={anchor} onClose={closeMenu}>
                {
                    props.menuList.map((item: IDropDownMenuItem, i) => {
                        return <MenuItem key={v4()} onClick={() => menuItemClick(item)}>{item.label}</MenuItem>;
                    })
                }
            </Menu>
        </span>
    );
};

export default React.memo(DropDownMenu);