import { SupportedFormats } from '@looker/sdk/lib/4.0/models';

export enum ExtraFormats{
    inline_table = "inline_table", 
    inline_visualizations = "inline_visualizations"
}

export type DataFormatEmail = SupportedFormats | ExtraFormats

export const DataFormatEmailLookList = [{
    "Excel (XLSX)": SupportedFormats.xlsx,
    "HTML (Attachment)": SupportedFormats.html,
    "HTML (Inline)": ExtraFormats.inline_table,
    "JSON": SupportedFormats.json,
    "Text (CSV)": SupportedFormats.csv,
    "Text (Tab Separated)":SupportedFormats.txt,
    "Visualization (PNG)": ExtraFormats.inline_visualizations
}]

export const DataFormatEmailDashboardList = [{
    "Text (CSV Zip)": SupportedFormats.csv_zip,
    "Visualization (PDF)": "pdf",
    "Visualization (PNG)": "png"
}]