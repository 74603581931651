import { useDispatch } from 'react-redux';
import {
    Button,
    ButtonContainer,
    Modal,
    ModalFooter,
    ModalHeader,
    TextField
} from '@emburse/embark-core';
import {v4} from 'uuid';

import React, { useState } from 'react';
import { copyDashboard, copyLook, createDashboard, hideModal, hideModalError, showModalError } from '../../../store';
import { ModalFolderSelect } from '@src/components';
import { IDashboard, IEmburseFolderStructure, ILook } from '@interfaces';
import { Menu, MenuDivider, MenuItem } from '@emburse/embark-core';
import { isInstanceOfILook } from '../../../interfaces/ILook';
import { IFolder } from '../../../interfaces/IFolder';
import { modalLoading } from '../../../store/actions/modalActions';

export interface CopyActionModalProps{
    item: ILook | IDashboard | undefined,
    modal: any
}

export default ({item, modal}: CopyActionModalProps) => {
    const dispatch = useDispatch();
    const [titleName, setTitleName] = useState('');
    const [currentFolder, setCurrentFolder] = useState<(IEmburseFolderStructure | undefined)[]>([]);
    const [selectedFolder, setSelectedFolder] = useState<IEmburseFolderStructure>();
    const isLook: boolean = isInstanceOfILook(item);


    const setNewName = (e) => {
        if (modal.errorMessage) {
            dispatch(hideModalError());
        }
        setTitleName(e.target.value);

        
    };

    const closeModal = () => {
        dispatch(hideModal());
    };

    const handleCopyActionClick = () => {
        isLook ? 
            dispatch(copyLook(item as ILook, selectedFolder?.data as IFolder, true)) : 
            dispatch(copyDashboard(item as IDashboard, selectedFolder?.data as IFolder, true));

        
    };

    const modalHeader = (<ModalHeader title={`Copy ${isLook ? 'Look' : 'Dashboard'} To`} showClose={true} onCloseClicked={closeModal}/>);

    const modalFooter = (
        <ModalFooter>
            <ButtonContainer alignButtons={'right'}>
                <Button onClick={closeModal} variant="outlined" disabled={modal.loading}>Cancel</Button>
                <Button variant="contained" onClick={handleCopyActionClick} disabled={modal.loading}>
                    Copy Here
                </Button>
            </ButtonContainer>
        </ModalFooter>
    );
    return (
        <Modal open modalHeader={modalHeader} modalFooter={modalFooter} onClose={closeModal} isLoading={modal.loading}>
            <div className="da-modal-form">
                <ModalFolderSelect currentFolder={currentFolder} setCurrentFolder={setCurrentFolder} selectedFolder={selectedFolder} setSelectedFolder={setSelectedFolder} />
            </div>
        </Modal>
    );
};