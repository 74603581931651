import React from "react";

import {
  DataGrid,
  ServerDataGrid,
  ServerDatagridProps,
  GridPaginationModel,
  GridCallbackDetails
} from "@emburse/embark-core";
import { IDataTableColumn } from "@src/interfaces/IDataTableColumn";

export interface DataTableProps extends Omit<ServerDatagridProps, "rowCount"> {
  rows: any[];
  columns: IDataTableColumn[];
  autoHeight?: boolean;
  allowSelection?: boolean;
  autoPageSize?: boolean;
  className?: string;
  disableRowSelectionOnClick?: boolean;
  hideFooterPagination?: boolean;
  // onPageChange?: (page: number, details: GridCallbackDetails, pageSize: number) => void;
  onPaginationModelChange?: (model: GridPaginationModel, details: GridCallbackDetails) => void;
  server?: boolean;
  rowCount?: number;
  rowHeight?: number;
}

export default (props: DataTableProps) => {
  if (!props.server) {
    return <DataGrid data-qa="datagrid" 
    pageSizeOptions={[10, 15, 20]}
    {...props} />;
  }
  return (
    <ServerDataGrid
      data-qa="datagrid"
      rowCount={props.rowCount ? props.rowCount : 0}
      {...props}
    />
  );
};
