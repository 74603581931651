import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { push } from "connected-react-router";
import { pluralize } from "@src/utility";
import { addAlert } from "../../store/actions/appActions";
import {
  DataTable,
  Page,
  TextWithCaption,
  TableFilterBar
} from "@src/components";
import {
  GridRow,
  Sidebar,
  Tooltip
} from "@emburse/embark-core";
import { IDataTableColumn } from "@src/interfaces/IDataTableColumn";
import {IFFlags, Severity, FFlags, getFlag} from "@src/enums";
import { Icon, IconButton } from '@emburse/embark-core';
import { EditIcon, DeleteIcon } from "@emburse/embark-icons";
import {
  Alert,
  Grid,
  Card,
  Typography,
  Chip,
  Spacer
} from "@emburse/embark-core";
import { createUseStyles } from "react-jss";
import { TeamFieldsIcon } from "@emburse/embark-icons";
import { uniqueId } from "lodash";
import { toUpperFirstLetter } from "@src/utility";
import { AdminUserManagementModal, EDIT_USER_TYPE,
  REMOVE_USER } from "../Modals/AdminUserManagementModal/AdminUserManagementModal";

import { IUser, ILicenseCount } from "../../interfaces";
import { ITenantTableUser, IState, IAlert } from "@src/interfaces";
import { IRole, Role, useResource } from "@src/middleware";
import { RowActions } from "./RowActions";
import {hasExceededLicenseLimits} from "../../utility/dataHelperFunctions";

const useStyles = createUseStyles({
  manageUserBar: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",

    "& > span:last-child": {
      marginLeft: "auto",
    },
    "& > :first-child": {
      marginRight: "15px",
    },
  },
  sidebar: {
    width: '251px',
  },
  pageWithSidebar: {
    flex: 'auto',
    padding: '3rem'
  },
  limitWarning: {
    maxWidth: '60%'
  }
});

export interface AdminUserManagementProps {}
const AdminUserManagement = (props: AdminUserManagementProps) => {
  const dispatch = useDispatch();
  const roles = useResource(Role.getAll());
  interface IFilterBarFilterOption {
    label: string;
    id: string;
    selected: boolean;
  }

  interface IFilterBarFilter {
    id: string;
    data: IFilterBarFilterOption[];
    defaultData?: IFilterBarFilterOption[];
  }


  // row state and menu
  const [anchor, setAnchor] = useState<EventTarget | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<ITenantTableUser | null>(
    null
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");

  // Table state
  const [pageSize, setPageSize] = useState<number>(100);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [filterString, setFilterString] = useState<string>("");
  const [searchString, setSearchString] = useState<string>("");
  const [searchChanged, setSearchChanged] = useState<boolean>(false);
  const [rowCount, setRowCount] = useState<number>(0);
  const [users, setUsers] = useState<ITenantTableUser[]>([]);
  let [licenseCount, setLicenseCount] = useState<ILicenseCount[]>();
  const [isLicenseCountLoading, setIsLicenseCountLoading] =
    useState<boolean>(true);

  const flags: IFFlags = useSelector((state: RootStateOrAny) => state.user.userData.flags); 

  // a table state for loading
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //use a state for the role filter
  const defaultRoleFilter: IFilterBarFilterOption[] = [
    {
      id: "admin",
      label: "Admin",
      selected: false,
    },
    {
      id: "user",
      label: "User",
      selected: false,
    },
  ];
  const [roleFilter, setRoleFilter] =
    React.useState<IFilterBarFilterOption[]>(defaultRoleFilter);

  //use a state for the type filter
  const defaultTypeFilter: IFilterBarFilterOption[] = [
    {
      id: "creator",
      label: "Creator",
      selected: false,
    },
    {
      id: "viewer",
      label: "Viewer",
      selected: false,
    },
  ];
  const [typeFilter, setTypeFilter] =
    React.useState<IFilterBarFilterOption[]>(defaultTypeFilter);

  const [filterBarOptions, setFilterBarOptions] = React.useState<
    IFilterBarFilter[]
  >([
    {
      id: "role",
      data: defaultRoleFilter,
      defaultData: defaultRoleFilter,
    },
    {
      id: "type",
      data: defaultTypeFilter,
      defaultData: defaultTypeFilter,
    },
  ]);

  async function fetchUsers(
    page,
    pageSize,
    filterString: string,
    searchString
  ) {

    fetch('/app/users')
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error("Failed to fetch users");
        }
      })
      .then((data: { users: ITenantTableUser[]; total: number }) => {
        setUsers(data.users);
        setRowCount(data.total);
        setIsLoading(false);
        setSearchChanged(false);
      })
      .catch((error) => {
        console.error('fetchUsers', error);
      });
  }

  useEffect(() => {
    setIsLoading(true);
    fetchUsers(page, pageSize, filterString, searchString);
  }, []);

  useEffect(() => {
    setFilterBarOptions([
      { id: "role", data: roleFilter, defaultData: defaultRoleFilter },
      { id: "type", data: typeFilter, defaultData: defaultTypeFilter },
    ]);
  }, [typeFilter, roleFilter]);

  useEffect(() => {
    fetchAndSetLicenseCounts();
  }, []);

  useEffect(() => {
    //get filters and return the ones that have data that is selected
    const filters = filterBarOptions
      .filter((filter) => {
        return filter.data.some((option) => option.selected);
      })
      .map((filter) => {
        filter.data = filter.data.filter((option) => option.selected);
        return filter;
      });
    // createa a query string from the filters
    const queryString = filters
      .map((filter) => {
        return filter.data
          .map((option) => {
            if (filter.id == "role") {
              return `tenant_admin=${option.id == "user" ? false : true}`;
            }
            if (filter.id == "type") {
              return `role_group=${
                option.id == "viewer" ? "VIEWER" : "CREATOR"
              }`;
            }
          })
          .join("&");
      })
      .join("&");

    setFilterString(queryString);
  }, [filterBarOptions]);

  const styles = useStyles();
  
  const fetchAndSetLicenseCounts = () => {
    try {
      fetch(`/app/users/licenses`)
        .then((res) => res.json())
        .then((body) => {
          setLicenseCount(body);
          setIsLicenseCountLoading(false);
        })
        .catch((error) => {
          console.error('fetchAndSetLicenseCounts', error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const updateLicenseBanner = () => {
    fetchAndSetLicenseCounts();
  };

  const doRowAction = (row: any, action: string) => {
    setSelectedUser(row);
    setModalType(action);
    setModalOpen(true);
  };

  const closeModal = (updated: boolean = false) => {
    setModalOpen(false);
    setModalType("");
    setSelectedUser(null);

    if (updated) {
      fetchUsers(page, pageSize, filterString, searchString);
    }
  };


  const handleModalSuccess = (message: string) => {
    updateLicenseBanner();
    let alert: IAlert = {
      timestamp: new Date().getTime(),
      severity: Severity.SUCCESS,
      message
    };
    dispatch(addAlert(alert));
  };

  const handleModalError = ({title, message}) => {
    const alert: IAlert = {
        timestamp: new Date().getTime(),
        severity: Severity.ERROR,
        title,
        message
      };
    dispatch(addAlert(alert));
  };

  const columns: IDataTableColumn[] = [
    {
      field: "name",
      headerName: "Name",
      renderCell: (params) => {
        return (
          <div
            style={{
              lineHeight: "normal",
              paddingTop: "8px",
              paddingBottom: "8px",
            }}
          >
            <Tooltip title={params.row.user_id}>
              <TextWithCaption
                text={params.formattedValue}
                caption={params.row.email}
              />
            </Tooltip>
          </div>
        );
      },
      flex: 2.5,
    },
    {
      field: "lastLogin",
      headerName: "Last Login",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>
              {new Date(
                (params.row.lastLogin * 1000) as number
              ).toLocaleDateString(navigator.language, {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })}
            </span>
          </>
        );
      },
    },
    {
      field: "role",
      headerName: "Admin",
      flex: 1,
      sortable: true,
      renderCell: params => params.row.raw.tenant_admin ? 'Admin' : ''
    },
    {
      field: "userType",
      headerName: "User Type",
      flex: 1,
      sortable: true,
    },
    // {
    //   field: "landingPage",
    //   headerName: "Landing Page",
    //   flex: 1.75,
    // },
    {
      field: 'editUser',
      headerName:'',
      flex: .5,
      renderCell: (params) => {
        return <IconButton 
        size="small"
        data-qa="editIcon"
        onClick={() => doRowAction(params.row, EDIT_USER_TYPE)}
         > 
          <Icon iconUrl={EditIcon} />
        </IconButton>;
      }
    },
    {
      field: 'deleteUser',
      headerName: '',
      flex: .5,
      renderCell: (params) => {
        const loggedInUser: IUser = useSelector((state: IState) => state.user.userData); 
        
        const useV4LookerApi = flags?.[getFlag(FFlags.LookerV4API)];
        const isNotLoggedInUser = useV4LookerApi ? loggedInUser?.looker_user_id !== params.row.raw.user_id : loggedInUser?.looker_user_id !== parseInt(params.row.raw.user_id);

        /**
         * admin users can deprovision any user except themselves so
         * delete user icon will not show up for the logged in user
         */
        if (isNotLoggedInUser) {
          return <IconButton 
            size="small"
            data-qa="deleteIcon"
            onClick={() => doRowAction(params.row, REMOVE_USER)}
            > 
              <Icon iconUrl={DeleteIcon} />
            </IconButton>;
        }
      }
    }
  ];

  const deprecatedColumns: IDataTableColumn[] = [
    {
      field: "name",
      headerName: "Name",
      renderCell: (params) => {
        return (
          <div
            style={{
              lineHeight: "normal",
              paddingTop: "8px",
              paddingBottom: "8px",
            }}
          >
            <Tooltip title={params.row.user_id}>
              <TextWithCaption
                  text={params.formattedValue}
                  caption={params.row.email}
              />
            </Tooltip>
          </div>
        );
      },
      flex: 2.5,
    },
    {
      field: "lastLogin",
      headerName: "Last Login",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <span>
              {new Date(
                (params.row.lastLogin * 1000) as number
              ).toLocaleDateString(navigator.language, {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })}
            </span>
          </>
        );
      },
    },
    {
      field: "role",
      headerName: "Admin",
      flex: 1,
      sortable: true,
    },
    {
      field: "userType",
      headerName: "User Type",
      flex: 1,
      sortable: true,
    },
    {
      field: "actions",
      headerName: " ",
      flex: 0.5,
      sortable: false,
      renderCell: (params) => {
        let menuItems = [
          {
            children: "Edit User Type",
            onClick: () => doRowAction(params.row, EDIT_USER_TYPE),
          }
        ];
        
        const loggedInUser: IUser = useSelector((state: IState) => state.user.userData);

        // admin users can deprovision any user except themselves
        if (loggedInUser?.looker_user_id !== parseInt(params.row.raw.user_id)) {
          menuItems.push({
          children: "Remove User",
          onClick: () => doRowAction(params.row, REMOVE_USER)
        });
      }

        return <RowActions menuItems={menuItems} />;
      },
    },
  ];

  let filterUser = function(user) {
    /** Use values of the FilterBarOptions to show or hide this user */
    const adminRoleFilter = roleFilter[0];
    const userRoleFilter = roleFilter[1];

    const creatorFilter = typeFilter[0];
    const viewerFilter = typeFilter[1];

    if (adminRoleFilter.selected && user.role !== "Admin") {
        return false;
    }
    if (userRoleFilter.selected && user.role !== "User") {
      return false;
    }
    if (creatorFilter.selected && user.userType !== "Creator") {
      return false;
    }
    if (viewerFilter.selected && user.userType !== "Viewer") {
      return false;
    }

    if (searchString && searchString.length > 0) {
      return user?.name?.toLowerCase().includes(searchString.toLowerCase());
    }

    return true;
  };

  let handleUserFilter = (): any[] => {
    return users
        ?.map((user: ITenantTableUser) => {
          return {
            name: `${user.first_name} ${user.last_name}`,
            id: uniqueId(),
            email: user.email,
            role: user.tenant_admin ? "Admin" : "User",
            landingPage: user.landing_page_title,
            lastLogin: user.last_login,
            userType: roles?.find((r) => r.name === user.role)?.label || "", //todo: change this to user.role string
            raw: user,
            // permissions: user.analytics_role,
          };
        })
        .filter((el) => {
          // client side filtering
          return filterUser(el);
        })
        // alphabetically sort by name field
        .sort((a,b) => a.name.localeCompare(b.name));
  };

  const classes = useStyles();
  let menu = {
    menus: [
      {
        sections: [
          {
            header: "Admin Settings",
            items: [
              {
                icon: TeamFieldsIcon,
                iconText: "User Management",
                onClick: () => dispatch(push("/app/p/admin/users")),
              },
              // {
              //   icon: SettingsIcon,
              //   iconText: "Landing Pages",
              // },
            ],
          },
        ],
      },
    ],
  };
  const licensesCountBlueBanner = () => {
    return (
      <Alert severity={"info"} style={{ width: "fit-content" }}>
        {licenseCount?.map((lc: ILicenseCount, idx) => {
          return (
            <Fragment key={lc.role_group}>
              <span
                style={{
                  color:
                    lc.limit != null && lc.count / lc.limit >= 1
                      ? "red"
                      : "inherit",
                  fontWeight: "bold",
                }}
              >
                {lc.limit ? Math.abs(lc.limit - lc.count) : lc.count}
              </span>
              {` ${toUpperFirstLetter(lc.role_group.toLowerCase())}`} license
              {(lc.limit != null && lc.count / lc.limit <= 1) ||
              lc.limit == null
                ? `s ${lc.limit ? "remaining" : "used"}`
                : ` overage`}
              {licenseCount && idx < licenseCount.length - 1 ? " | " : ""}
            </Fragment>
          );
        })}
      </Alert>
    );
  };
  const calculateLicenseCounts = () => {
    const cards = licenseCount?.map((lc: ILicenseCount) => {
      const {count: licenseCount, role_group: userType, limit} = lc;

      const isUnlimitedLicenses = limit === null;
      const licensesAvailable = !isUnlimitedLicenses ? Math.abs(limit - licenseCount) : null;
      const labelPrefix = `${licensesAvailable} ${pluralize(licensesAvailable, 'license')} `;

      const getChipLabel = () => {
        if (isUnlimitedLicenses) {
          return 'No License Limits';
        } if (licenseCount < limit || licensesAvailable === 0) {
          return `${labelPrefix} remaining`;
        } if (licenseCount > limit) {
          return `${labelPrefix} exceeded`;
        }
      };

      return (
        <Grid
          item
          md={3} 
          key={userType}
          style={{textTransform: 'uppercase', marginRight: '15px'}}
          >
          <Card data-qa='countCard'>
            <Typography variant="h4" data-qa='totalCount' >{licenseCount}</Typography>
            <Typography variant="caption" data-qa='userType'>{`${pluralize(licenseCount, userType)}`}</Typography>
            <Spacer size={10}/>
            <Chip 
              data-qa='countChip'
              color={
                (lc.limit === null || licensesAvailable === 0) ? "monochrome" : lc.count / lc.limit >= 1 ? "error" : "primary"
              }
              label={getChipLabel()}/>
          </Card>
        </Grid>
      );
    });
    return <Grid container>
      {cards}
    </Grid>;
  };

  const licenseLimitAlert = () => {
    /**
     * Render alert if a license limit is exceeded
     */
    if (hasExceededLicenseLimits(licenseCount)) {
      return (<>
        <Alert
            icon={null}
            severity="error"
            data-qa='licenseLimitWarning'
            style={{maxWidth: '68%'}}
        >Users are currently unable to access the product because the number of user licenses issued has been exceeded.
          To restore access, reduce the number of exceeded licenses by removing users or reassigning user types.
        </Alert>
        <Spacer size={16}/>
      </>);
    }
  };

  const usersTableAndFilter = () => {
    /**
     * Render the filter bar and users table
     */
    return (<>
      <TableFilterBar
          // filterText={"Filter by: "}
          resetText={"Reset Filters"}
          onSearchChange={(v) => setSearchString(v)}
          doSearchOnEnter
          doSearchOnChange
          data={[
            {
              id: "role",
              data: defaultRoleFilter,
            },
            {
              id: "type",
              data: defaultTypeFilter,
            },
          ]}
          onChange={(filters) => {
            let roles = filters.find((f) => f.id === "role");
            let types = filters.find((f) => f.id === "type");

            setRoleFilter(roles?.data as IFilterBarFilterOption[]);
            setTypeFilter(types?.data as IFilterBarFilterOption[]);
          }}
      />

      <DataTable
          autoHeight
          loading={isLoading}
          rowHeight={68}
          rows={handleUserFilter()}
          columns={flags?.[getFlag(FFlags.UserManagementInlineActions)] ? columns : deprecatedColumns}
      />

      {modalOpen && (
          <AdminUserManagementModal
              isOpen={modalOpen}
              modalType={modalType}
              onClose={closeModal}
              user={selectedUser}
              handleModalSuccess={handleModalSuccess}
              handleModalError={handleModalError}
          />
      )}
    </>);
  };

  const userManagementSidebar = <Sidebar className={classes.sidebar} variant={"permanent"} sidebarMenu={menu} />;

  return (
      <>
          <Fragment>
            <GridRow justifyContent={'space-between'}>
              <Typography variant="h4">User Management</Typography>
            </GridRow>
            <Spacer size={16} direction="vertical" />
          </Fragment>
          {licenseLimitAlert()}
          {licenseCount != null && licenseCount.length > 0 ? calculateLicenseCounts() : licensesCountBlueBanner()}
          {usersTableAndFilter()}
      </>
  );
};

export default AdminUserManagement;
