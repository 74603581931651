import React, {useEffect, useState, Fragment, useContext} from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import {addAlert, openDashboard, openLook} from "../../store/actions/appActions";

import {FFlags, getFlag, IFFlags, Severity} from "@src/enums";


import {IReportScheduleDefinition, IDashboardRef, IUserRef, ILookRef, ITenantTableUser} from "../../interfaces";
import {ViewBurstScheduleDrawer} from "@components/AdminBurstSchedules/ViewBurstScheduleDrawer";
import BurstSchedulesGrid from "@components/AdminBurstSchedules/BurstSchedulesGrid";

export interface AdminBurstSchedulesProps {}

const AdminBurstSchedules = (props: AdminBurstSchedulesProps) => {
  const dispatch = useDispatch();
  const flags: IFFlags = useSelector((state: RootStateOrAny) => state.user.userData.flags);

  const [selectedScheduleToView, setSelectedScheduleToView] = useState<IReportScheduleDefinition|null>(null);

  function toggleSelectedSchedule(schedule?: IReportScheduleDefinition) {
    setSelectedScheduleToView(schedule? schedule : null);
  }

  const onRowClick = (schedule: IReportScheduleDefinition) => {
    toggleSelectedSchedule(schedule);
  };

  return (
    <>
      <BurstSchedulesGrid
        onRowClick={onRowClick}
        schedule={selectedScheduleToView}
      />

      {flags?.[getFlag(FFlags.ScheduleAdminGridMoreActions)] ? null : selectedScheduleToView &&
        <ViewBurstScheduleDrawer schedule={selectedScheduleToView} onClose={toggleSelectedSchedule}/>
      }
    </>
  );
};

export default AdminBurstSchedules;
