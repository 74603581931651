import { RequestCache } from "../common";
import { Entity, BaseResource } from "./BaseResource";

export type IUser = Entity;

/**
 * The Schedule resource. Used for fetch entities of ISchedule
 *
 * @export
 * @class Schedule
 * @extends {BaseResource<ISchedule>}
 */
export class UserResource extends BaseResource<IUser> {
  public readonly cache: boolean = true;
  public readonly expiry = 60 * 5; //5 minutes random...
  public readonly urlRoot = "/app/users";
  public readonly cacheType = RequestCache.RELOAD;
}

export const User = new UserResource();
