import * as React from 'react';

import { Drawer } from '@emburse/embark-core';

import {IDashboardRef, ILookRef, IReportScheduleDefinition} from "@interfaces";
import {useContext, useEffect, useState} from "react";
import {IScheduleAPIContext, ScheduleAPIContext} from "@components/ScheduleAPIProvider/ScheduleAPIProvider";
import {IUserPublic} from "@looker/sdk/lib/4.0/models";
import {ViewBurstSchedule} from "@components/AdminBurstSchedules/ViewBurstSchedule";

const SCHEDULE_DRAWER_WIDTH = 500;  //500px

type ViewBurstScheduleDrawerProps = {
    schedule: IReportScheduleDefinition;
    onClose: () => void;
};

const ViewBurstScheduleDrawer = ({ schedule, onClose }: ViewBurstScheduleDrawerProps) => {
    const scheduleAPI = useContext<IScheduleAPIContext>(ScheduleAPIContext);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [scheduleExtraData, setScheduleExtraData] = useState<IReportScheduleDefinition>(schedule);

    function toggleDrawer(openState?: boolean) {
        if (!openState) {
            onClose();
        }
    }

    useEffect(() => {
        /** We need to fetch additional information about the schedule */
        if (!schedule) {
            return;
        }
        scheduleAPI.getSchedule(schedule)
            .then(async (res) => {
                setIsLoading(false);
                setScheduleExtraData(res);
            })
            .catch((e) => {
                setIsLoading(false);
            });
    }, []);

    return (
        <Drawer
            style={{ pointerEvents: 'none' }}
            open={!!scheduleExtraData}
            openUpdated={toggleDrawer}
            anchor="right"
            hideElevation={false}
            sideWidth={SCHEDULE_DRAWER_WIDTH}
            disableEnforceFocus
            disableCloseOnBackdropClick={false}
        >
            {schedule && (
                <div style={{ pointerEvents: 'all' }}>
                    <ViewBurstSchedule schedule={scheduleExtraData} onClose={onClose} />
                </div>
            )}
        </Drawer>
    );
};

export { ViewBurstScheduleDrawer };