import {
  FilterProps as XFilterProps,
  Filter as XFilter,
} from "@looker/filter-components";

import {
  Combobox as XComboBox,
  ComboboxMulti as XComboBoxMulti,
  ComboboxInput as XComboboxInput,
  InputChipsBase as XInputChipsBase,
  InputText as XInputText,
  Box as XBox,
  Icon as XIcon,
  ButtonBase as XButtonBase,
  IconButton as XIconButton,
  Span as XSpan,
  SpaceVertical as XSpaceVertical,
} from "@looker/components";

import Chip, { chipStyles } from "../core/looker/Chip";
import styled, { css } from "styled-components/macro";
import { maxWidth } from "styled-system";
import { reset } from "@looker/design-tokens";
import { MultiStringInput as XMultiStringInput } from "@looker/filter-components";

export type FilterProps = XFilterProps & {
  className?: string;
}

let filterStyles = css`
  ${reset}
  ${maxWidth}

  & {
    ${(Chip as any).attrs[0].parentComponentID} {
      ${(Chip as any).componentStyle.rules}
    }
  }

  * {
    box-sizing: border-box;
    margin-right: 0;
    padding-right: 0;
  }

  > ${XSpaceVertical} {
    > ${XBox} {
      width: 100%;
      margin-top: 16px;

      :first-of-type {
        margin-top: 0;
      }

      > ${XIconButton} {
        & :last-child {
          // display: none;
        }
      }
      > ${XBox} {
        text-align: left;

        > ${XSpan} {
          font-weight: 600;
          font-size: 12px;
        }
      }
    }

    ${XComboBox}, ${XComboBoxMulti}, ${XMultiStringInput} {
      flex-grow: .4;
      max-height: unset;
    }
  }

  ${XBox} {
    > ${XSpan} {
      align-self: center;
      justify-self: left;
    }

    ${XIcon} {
      & :last-child {
        // display: none;
      }
    }
  }

  ${XIcon} {
    ${reset}
    height: 24px;
    width: 24px;
    justify-self: center;
    align-self: content;
    color: rgba(0, 0, 0, 0.54);
  }

  ${XButtonBase}, ${XIconButton} {
    align-self: center;
  }

  ${XInputText}, ${XComboboxInput} {
    ${reset}

    min-height: 19px;
    padding: 10.5px;
    height: auto;
    border-radius: 4px;
    margin-left: 4px;
    margin-right: 4px;
    border: 1px solid ${(props) => props.theme.colors.ui3} !important;
    border-right-color: ${(props) => props.theme.colors.ui3};
    border-left-color: ${(props) => props.theme.colors.ui3};
    // box-sizing: border-box;
    align-items: center;
    justify-content: center;
    text-align: left;

    input {
      height: 19px;
      padding: 0;
    }
  }

  ${XInputChipsBase} {
    .inner {
      ${XIcon} {
        width: 16px;
        height: 16px;
      }
    }
  }

  [class^="InputChipsBase__HiddenInput"] {
    padding: 0;
    margin: 0;
    border: none;
    height: 0;
    width: 0;
    display: none;
  }
`;

export default styled((props: FilterProps) => {
  return (
    <div className={props.className}>
      <XFilter allowMultipleValues={true} dispatchConfigTypeChange={true} {...props} />
    </div>
  );
})`
  ${filterStyles}
`;
