import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { IHasChildrenProps } from "../../interfaces/IHasChildrenProps";
import { createUseStyles } from "react-jss";
import {
  GridRow,
  Sidebar,
  SidebarProps,
  Spacer,
  Typography,
} from "@emburse/embark-core";
import UTPButton from "../UTPButton/UTPButton";
import { AnalyticsRole } from "../../enums/AnalyticsRole";
import { IState } from "@src/interfaces";
import { CheckIcon, LaunchIcon, SettingsIcon } from "@emburse/embark-icons";
import { relative } from "path";

const useStyles = createUseStyles({
  page: {
    paddingLeft: "15%",
    paddingRight: "15%",
    paddingTop: "16px",
  },

  pageWithSidebar: {
    flex: 'auto',
    padding: '3rem'
  },
});

export interface PageProps extends IHasChildrenProps {
  pageTitle?: string;
  sidebar?: React.ReactElement<SidebarProps>;
}

export default (props: PageProps) => {
  const classes = useStyles();

  const { userData } = useSelector((state: IState) => state.user);
  const showUTPButton = (userData.tier === "0" && [AnalyticsRole.FreeCreator, AnalyticsRole.Viewer].includes(userData.analytics_role as AnalyticsRole));
  return (
    <Fragment>
      {props.sidebar && props.sidebar}

      <div className={props.sidebar ? classes.pageWithSidebar : classes.page}>
        {props.pageTitle && (
          <Fragment>
            <GridRow justifyContent={'space-between'}>
            <Typography variant="h4">{props.pageTitle}</Typography>
            {showUTPButton && <UTPButton/>}
            </GridRow>
            <Spacer size={16} direction="vertical" />
          </Fragment>
        )}
        {props.children}
      </div>
    </Fragment>
  );
};
