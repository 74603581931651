import React, { useContext } from "react";
import { Route, Redirect } from "react-router";
import { IUseAuthInterface } from "@src/hooks/useAuth";
import { AuthContext } from "../AuthProvider/AuthProvider";

export default ({ component: Component, ...rest }) => {
  const auth: IUseAuthInterface = useContext(AuthContext);

  const userAuthStatus = Boolean(localStorage.getItem("auth_status"));
  return (
    <Route
      {...rest}
      render={(props) =>
        userAuthStatus === true ||
        auth?.session?.error_code ===
          "TENANT_LICENSE_LIMIT_EXCEEDED_FOR_EXISTING_USER" ||
          auth?.session?.error_code ===
            "TENANT_LICENSE_LIMIT_EXCEEDED_FOR_NEW_USER" ? (
          <Component {...props} />
        ) : (
          <Redirect to="/unauthorized" />
        )
      }
    />
  );
};
