import { IState } from './../../interfaces/IState';
import { ErrorTypes, Severity } from "@src/enums";
import { push } from "connected-react-router";
import { addError, addUnauthorizedError, fetchFolderContent, hideModal, modalLoaded, modalLoading, openDashboard, showModalError } from ".";
import store from "..";
import { IDashboard, IFolder } from "../../interfaces";
import { callEndpoint, STATUS_CODES } from "../../utility/FetchErrorHandler";
import { BASE_FOLDERS_ROUTE } from "../routs";
import { DELETE_DASHBOARD, UPDATE_DASHBOARD } from "../types";

const API_HEADERS = { 'Content-Type': 'application/json;charset=utf-8' }
const DASHBOARD_ALREADY_EXIST_ERROR_CODE = 'DASHBOARD_ALREADY_EXIST'
const DASHBOARD_ALREADY_EXIST_ERROR_MESSAGE = 'Name already exists. Please enter a unique dashboard name'

/**
 * 
 * Creates a dashboard in a folder with the folder id
 * 
 * @param title title of the dashboard
 * @param space_id folder id of the folder the dashboard will be added to
 */
export function createDashboard(title: string, space_id: string) {
    console.log(space_id, title)
    return async dispatch => {
        dispatch(modalLoading())
        const response = await callEndpoint('/app/dashboards', {
            method: 'POST',
            headers: API_HEADERS,
            body: JSON.stringify({ title, space_id })
        });

        if (response.ok) {
            const dashboard: IDashboard = await response.json();

            await dispatch(fetchFolderContent(dashboard.folderId))

            dispatch(modalLoaded())
            dispatch(hideModal())
            dispatch(openDashboard(dashboard.id))

        } else if (response.status === 400) {
            dispatch(modalLoaded())
            const error = await response.json();
            if (error.errorCode === DASHBOARD_ALREADY_EXIST_ERROR_CODE) {
                dispatch(showModalError(DASHBOARD_ALREADY_EXIST_ERROR_MESSAGE))
            } else {
                dispatch(showModalError(error.message))
            }
        } else if (response.status === STATUS_CODES.Unauthorized) {
            dispatch(addUnauthorizedError(response.statusText))
        } else {
            dispatch(modalLoaded())
            console.error("Unexpected error when calling folder API")
        }
    }
}

export function deleteDashboard({ id }) {
    return async dispatch => {
        dispatch(modalLoading())
        const response = await callEndpoint('/app/dashboards/' + id, { method: 'DELETE' });
        dispatch(modalLoaded())
        if (response.ok) {
            dispatch(hideModal())
            dispatch({ type: DELETE_DASHBOARD, payload: { id } })
        } else if (response.status === STATUS_CODES.Unauthorized) {
            dispatch(addUnauthorizedError(response.statusText))
        } else {
            // TODO: show error message
            console.log("Error when calling delete folder API")
        }
    }
}

export function copyDashboard(dash: IDashboard, folder: IFolder, inModal: boolean = false) {
    return async dispatch => {
        if (inModal) // if this function is being called from a modal
            dispatch(modalLoading())


        const resp = await callEndpoint("/app/dashboards/copy", {
            method: 'POST',
            headers: API_HEADERS,
            body: JSON.stringify({
                dashboard_id: dash?.id,
                folder_id: folder?.id
            })
        })

        if (!resp.ok) {
            if (resp.status === STATUS_CODES.Unauthorized) {
                dispatch(addUnauthorizedError(resp.statusText))
            } else {
                dispatch(addError({
                    type: ErrorTypes.SILENT,
                    timestamp: Date.now(),
                    message: resp.statusText,
                    severity: Severity.ERROR
                }))
            }
        }
        else {
            let state: IState = store.getState()

            if (state.folders.activeFolderId === folder.id) {
                dispatch(fetchFolderContent(folder.id, state.folders.folderStructure))
            }
            else {
                dispatch(push(`${BASE_FOLDERS_ROUTE}${folder.id}`))
            }
        }

        if (inModal) // if this function is being called from a modal
            dispatch(modalLoaded())
        dispatch(hideModal())
    }
}

export function moveDashboard(dash: IDashboard, folder: IFolder, inModal: boolean = false) {
    return async dispatch => {
        if (inModal) // if this function is being called from a modal
            dispatch(modalLoading())


        const resp = await callEndpoint("/app/dashboards/move", {
            method: 'POST',
            headers: API_HEADERS,
            body: JSON.stringify({
                dashboard_id: dash?.id,
                folder_id: folder?.id
            })
        })

        if (!resp.ok) {
            if (resp.status === STATUS_CODES.Unauthorized) {
                dispatch(addUnauthorizedError(resp.statusText))
            } else {
                dispatch(addError({
                    type: ErrorTypes.SILENT,
                    timestamp: Date.now(),
                    message: resp.statusText,
                    severity: Severity.ERROR
                }))
            }
        }
        else {
            let state: IState = store.getState()

            if (state.folders.activeFolderId === folder.id) {
                dispatch(fetchFolderContent(folder.id, state.folders.folderStructure))
            }
            else {
                dispatch(push(`${BASE_FOLDERS_ROUTE}${folder.id}`))
            }
        }

        if (inModal) // if this function is being called from a modal
            dispatch(modalLoaded())
        dispatch(hideModal())
    }
}

export function renameDashboard({id, name, description}: {id: string | number | undefined, name: string, description?: string}) {
    return async dispatch => {
        dispatch(modalLoading())
    

        const response = await callEndpoint('/app/dashboards/' + id, {
            method: 'PATCH',
            headers: API_HEADERS,
            body: JSON.stringify({dashboard_name: name, dashboard_description: description})
        });
        dispatch(modalLoaded())
        if (response.ok) {
            const dashboard: {id: string, dashboard_name: string, dashboard_description: string} = await response.json();
            console.log(dashboard)
            
            dispatch({type: UPDATE_DASHBOARD, payload: {id: dashboard.id, title: dashboard.dashboard_name, description: dashboard.dashboard_description}})
            dispatch(hideModal())
        } else if (response.status === 400) {
            const error = await response.json()
            dispatch(showModalError(error.message))
        } else if (response.status === STATUS_CODES.Unauthorized){
            dispatch(addUnauthorizedError(response.statusText))
        } else {
            console.error("Unexpected error when calling dashboard API")
        }
    }
}