import { DBDatabases } from '../enums/DBDatabases';
import BaseDB from './BaseDB';

/**
 * We define a class for each store (table) in the BaseDB
 *
 * @export
 * @class APICalls
 * @extends {BaseDB}
 */
export class APICalls extends BaseDB {
  readonly keyPath: string = 'url';
  readonly tablename = DBDatabases.APICalls;
}

export default new APICalls();
