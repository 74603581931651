import { IFolder, IFolderContent, ILook } from "../../interfaces";
import { RootStateOrAny } from 'react-redux';
import { FalsyPermissions } from '../../objects/Permissions';
import { IDashboard } from '../../interfaces/IDashboard';
import { DELETE_LOOK, SET_FOLDER_STRUCTURE, UPDATE_DASHBOARD, UPDATE_LOOK } from '@src/store/types';
import { IEmburseFolderStructure } from '../../interfaces/IEmburseFolderStructure';
import {
    ADD_FOLDER,
    DELETE_FOLDER,
    FETCH_FOLDER_CONTENT,
    FETCH_FOLDERS,
    SELECT_FOLDER_ITEM,
    SET_ACTIVE_FOLDER,
    UPDATE_FOLDER,
    DELETE_DASHBOARD
} from "../types";
import { getNewRootNode } from "@src/utility";
export interface IFolderState extends RootStateOrAny{
    activeFolderId: string | null,
    allFolders: {[key: number]: IFolder},
    folderContent: IFolderContent,
    folderStructure?: IEmburseFolderStructure
}

const initialState: IFolderState = {
    activeFolderId: null,
    allFolders: {},
    folderContent: {
        id: "",
        selectedItemId: null,
        permissions: FalsyPermissions,
        folders: [],
        dashboards: [],
        looks: [],
    },
    folderStructure: getNewRootNode()
}

export const foldersReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_FOLDERS:
            return {...state, allFolders: action.payload}
        case ADD_FOLDER:
            return {...state, allFolders: {[action.payload.id]: action.payload, ...state.allFolders}}
        case UPDATE_FOLDER:
            return {
                ...state,
                allFolders: {...state.allFolders, [action.payload.id]: action.payload},
                folderContent: {
                    ...state.folderContent,
                    folders: state.folderContent.folders.map((f: any) => f.id === action.payload.id ? action.payload : f)
                }
            }
        case DELETE_FOLDER:
            const updatedFolders = {...state.allFolders}
            delete updatedFolders[action.payload.id]
            return {
                ...state,
                allFolders: updatedFolders,
                folderContent: {
                    ...state.folderContent,
                    folders: state.folderContent.folders.filter((f: any) => f.id !== action.payload.id)
                }
            }
        case DELETE_DASHBOARD:
            return {
                ...state,
                folderContent: {
                    ...state.folderContent,
                    dashboards: state.folderContent.dashboards.filter((d: IDashboard) => d.id !== action.payload.id)
                }
            }
        case DELETE_LOOK:
            return {
                ...state,
                folderContent: {
                    ...state.folderContent,
                    looks: state.folderContent.looks.filter((d: ILook) => d.id !== action.payload.id)
                }
            }
        case UPDATE_LOOK:
            return {
                ...state,
                folderContent: {
                    ...state.folderContent,
                    looks: state.folderContent.looks.map((l: ILook) => {

                        if(l.id === action.payload.id){
                            l.title = action.payload.title
                            
                            if(action.payload.hasOwnProperty("description")){                //cant make use of flags here.
                                l.description = action.payload.description
                            }
                        }

                        return l
                    })
                }
            }
        case UPDATE_DASHBOARD:
            return {
                ...state,
                folderContent: {
                    ...state.folderContent,
                    dashboards: state.folderContent.dashboards.map((d: IDashboard) => {

                        if(d.id === action.payload.id){
                            d.title = action.payload.title
                            
                            if(action.payload.hasOwnProperty("description")){                 //cant make use of flags here.
                                d.description = action.payload.description
                            }
                        }

                        return d
                    })
                }
            }
        case FETCH_FOLDER_CONTENT:
            return {...state, folderContent: action.payload}
        case SET_ACTIVE_FOLDER:
            return {...state, activeFolderId: action.payload}
        case SELECT_FOLDER_ITEM:
            return {...state, folderContent: {...state.folderContent, selectedItemId: action.payload}}
        case SET_FOLDER_STRUCTURE:
            return {...state, folderStructure: action.payload}
        default:
            return state
    }
}
