import { IDataTableColumn, ITenant } from "@src/interfaces";
import { useContext, useState } from "react";
import { createUseStyles } from "react-jss";
import { LoadingSpinner, Page } from "..";
import { DataTable, SubmitButton, DropDownMenu } from "@src/components";
import { TextField } from "@emburse/embark-core";
import { CustomerListContext } from "../CustomerListProvider/CustomerListProvider";
import { v4 } from "uuid";
import { IDropDownMenuItem } from "../../interfaces/IDropDownMenuItem";
import { flagsSelector } from "@store/selector";
import { useSelector } from "react-redux";

export interface CustomerSearchProps {}

const useStyles = createUseStyles({
  manageCustomerBar: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",

    "& > span:last-child": {
      marginLeft: "auto",
    },
    "& > :first-child": {
      marginRight: "15px",
    },
  },
});

export default (props: CustomerSearchProps) => {
  let { tenants } = useContext(CustomerListContext);
  const flags = useSelector(flagsSelector);
  const styles = useStyles();
  const [searchTerm, setSearchTerm] = useState("");
  const [buFilter, setBuFilter] = useState("CR");

  let menuList: IDropDownMenuItem[] = [
    {
      label: "Chrome River",
      value: "CR",
      key: "CR",
    },
    {
      label: "Certify",
      value: "CE",
      key: "CE",
    },
    {
      label: "Spend",
      value: "Spend",
      key: "AB",
    },
    {
      label: "DVI",
      value: "DVI",
      key: "DVI",
    },
    {
      label: "All Business Units",
      value: "ALL",
      key: "ALL",
    },
  ];

  function generateCustomerName(tenant: ITenant) {
    /**
     * Include the customer ID in tenant name for CR business unit
     */
    if (tenant.business_unit === 'cr') {
      if (tenant.bu_unique_id !== null) {
        return `${tenant.name} (${tenant.bu_unique_id})`
      }
    }
    return tenant.name
  }

  const mapTenantToDataTable = (tenant: ITenant) => {
    return {
      customerName: generateCustomerName(tenant),
      id: v4(),
      tenant_id: tenant.tenant_id,
      businessUnit: tenant.business_unit.toUpperCase(),
      businessUnitEnv: tenant.bu_env.toUpperCase(),
      businessUnitShard: tenant.bu_shard.toUpperCase(),
      // permissions: tenant.analytics_role
    };
  };

  let handleDropDownSelect = (item: IDropDownMenuItem) => {
    setBuFilter(item.value);
  };

  let handleTenantFilter = (): any[] => {
    return tenants
      .map((tenant) => {
        if (tenant.name.toLowerCase().includes(searchTerm.toLowerCase()) || tenant.bu_unique_id?.toLowerCase() === (searchTerm.toLowerCase())) {
          if (buFilter === "" || buFilter === "ALL") {
            return mapTenantToDataTable(tenant);
          } else if (tenant.business_unit === buFilter.toLowerCase()) {
            return mapTenantToDataTable(tenant);
          }
        }
      })
      .filter((el) => {
        if (el) {
          return true;
        } else {
          return false;
        }
      });
  };

  let columns: IDataTableColumn[] = [
    {
      field: "customerName",
      headerName: "Customer Name",
      flex: 1,
    },
    {
      field: "businessUnit",
      headerName: "Business Unit",
      flex: 1,
    },
    {
      field: "businessUnitEnv",
      headerName: "Business Unit Environment",
      flex: 1,
    },
    {
      field: "businessUnitShard",
      headerName: "Business Unit Shard",
      flex: 1,
    },
    {
      field: "review",
      headerName: "Review",
      renderCell: (val) => (
        <SubmitButton
          action={`/app/admin/tenants/${val.row.tenant_id}/review/login`}
          method="post"
          variant="outlined"
          color="secondary"
          data-qa={`reviewlogin-${val.row.tenant_id}`}
          data-tag="reviewlogin"
        >
          Review
        </SubmitButton>
      ),
      width: 175,
    },
  ];

  return (
    <Page>
      <h2>Customers</h2>

      <div className={styles.manageCustomerBar}>
        <span>
          <TextField
            id="customerSearch"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </span>
        <DropDownMenu
          menuList={menuList}
          onSelect={handleDropDownSelect}
        ></DropDownMenu>
      </div>

      {tenants.length > 0 && (
        <DataTable autoHeight rows={handleTenantFilter()} columns={columns} />
      )}
      {tenants.length === 0 && <LoadingSpinner />}
    </Page>
  );
};

// this is for logging. Linter formatted this file.
