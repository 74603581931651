import React, {useEffect, useState, Fragment, useContext} from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import {addAlert, openDashboard, openLook} from "../../store/actions/appActions";

import {FFlags, getFlag, IFFlags, Severity} from "@src/enums";


import {IReportScheduleDefinition, IDashboardRef, IUserRef, ILookRef, ITenantTableUser} from "../../interfaces";
import {ViewScheduleDrawer} from "@components/AdminSchedules/ViewScheduleDrawer";
import SchedulesGrid from "@components/AdminSchedules/SchedulesGrid";

export interface AdminSchedulesProps {}

const AdminSchedulesV2 = (props: AdminSchedulesProps) => {
  const dispatch = useDispatch();
  const flags: IFFlags = useSelector((state: RootStateOrAny) => state.user.userData.flags);

  const [selectedScheduleToView, setSelectedScheduleToView] = useState<IReportScheduleDefinition|null>(null);

  function toggleSelectedSchedule(schedule?: IReportScheduleDefinition) {
    setSelectedScheduleToView(schedule? schedule : null);
  }

  const onRowClick = (schedule: IReportScheduleDefinition) => {
    toggleSelectedSchedule(schedule);
  };

  const doOpenReferencedItem = (is_dashboard: boolean, ref_id: string) => {
    if (is_dashboard) {
      dispatch(openDashboard(ref_id));
    } else {
      dispatch(openLook(ref_id));
    }
  };

  return (
    <>
      <SchedulesGrid
        onRowClick={onRowClick}
        schedule={selectedScheduleToView}
      />

      {flags?.[getFlag(FFlags.ScheduleAdminGridMoreActions)] ? null : selectedScheduleToView &&
        <ViewScheduleDrawer schedule={selectedScheduleToView} onClose={toggleSelectedSchedule} owner={selectedScheduleToView.user}/>
      }
    </>
  );
};

export default AdminSchedulesV2;