import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {
    Button,
    ButtonContainer,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '@emburse/embark-core';
import { ViewBurstSchedule } from '@src/components/AdminBurstSchedules/ViewBurstSchedule';
import {IDashboardRef, ILookRef, IReportScheduleDefinition, IUserRef} from "@interfaces";
import {IUserPublic} from "@looker/sdk/lib/4.0/models";
import {IScheduleAPIContext, ScheduleAPIContext} from "@components/ScheduleAPIProvider/ScheduleAPIProvider";

interface ViewScheduleDetailsProps {
  isModalOpen: boolean;
  closeModalCb: () => void;
  schedule: IReportScheduleDefinition | null;
  owner?: IUserPublic
}

const ViewBurstScheduleModal = ({ schedule, isModalOpen, owner, closeModalCb }: ViewScheduleDetailsProps) => {
    const scheduleAPI = useContext<IScheduleAPIContext>(ScheduleAPIContext);
    // @ts-ignore
    const [scheduleExtraData, setScheduleExtraData] = useState<IReportScheduleDefinition>(schedule);
    const [dashboardInfo, setDashboardInfo] = useState<IDashboardRef | null>(null);
    const [lookInfo, setLookInfo] = useState<ILookRef | null>(null);
    const [distributionLookInfo, setDistributionLookInfo] = useState<ILookRef | null>(null);
    const [ownerInfo, setOwnerInfo] = useState<IUserRef | null>(null);

    function fetchLookInfo(look_id: string) {
        scheduleAPI.getReferencedLook(look_id)
            .then(async (res) => {
                setDashboardInfo(null);
                setLookInfo(res);
            })
            .catch((e) => {
                setDashboardInfo(null);
                setLookInfo({
                    id: look_id,
                    name: look_id
                });
            });
    }

    function fetchDashboardInfo(dashboard_id: string) {
        scheduleAPI.getReferencedDashboard(dashboard_id)
            .then(async (res) => {
                setLookInfo(null);
                setDashboardInfo(res);
            })
            .catch((e) => {
                setLookInfo(null);
                setDashboardInfo({
                    id: dashboard_id,
                    name: dashboard_id
                });
            });
    }

    function fetchDistributionLookInfo(distribution_look_id: string) {
        scheduleAPI.getReferencedLook(distribution_look_id)
            .then(async (res) => {
                setDistributionLookInfo(res);
            })
            .catch((e) => {
                setDistributionLookInfo({
                    id: distribution_look_id,
                    name: distribution_look_id
                });
            });
    }

    function fetchOwnerInfo(user_id: string) {
        scheduleAPI.getReferencedUser(user_id)
            .then(async (res) => {
                setOwnerInfo(res);
            })
            .catch((e) => {
                setOwnerInfo({
                    user_id: user_id,
                    first_name: user_id,
                    last_name: ''
                });
            });
    }


    useEffect(() => {
        /*** Fetch details on the referenced looks or dashboard and owner*/
        if (!schedule) {
            setLookInfo(null);
            setOwnerInfo(null);
            setDashboardInfo(null);
            setDistributionLookInfo(null);
            return;
        }

        if (schedule.user_id) {
            fetchOwnerInfo(schedule.user_id);
        }

        if (schedule.look_id) {
            fetchLookInfo(schedule.look_id);
        } else {
            if (schedule.dashboard_id) {
                fetchDashboardInfo(schedule.dashboard_id);
            }
        }

        const destinations = schedule.destinations;
        if (destinations && destinations.length > 0) {
            if (destinations[0].distribution_look_id) {
                fetchDistributionLookInfo(destinations[0].distribution_look_id);
            } else {
                setDistributionLookInfo(null);
            }
        } else {
            setDistributionLookInfo(null);
        }

    }, [schedule]);

    return (
        <Modal
        open={isModalOpen}
        modalHeader={<ModalHeader title="Burst Schedule Details" showClose={true} onCloseClicked={closeModalCb}/>}
        modalFooter={<ModalFooter>
            <ButtonContainer alignButtons="right">
            <Button variant="contained" onClick={closeModalCb}>Close</Button>
            </ButtonContainer>
        </ModalFooter>}
        >
        <ModalBody>
            <ViewBurstSchedule onClose={()=> null} schedule={scheduleExtraData} />
        </ModalBody>
        </Modal>
    );
};

export { ViewBurstScheduleModal };