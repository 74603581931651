import React from 'react';
import {
  Button,
  ButtonContainer,
  Modal,
  ModalFooter,
  Typography,
} from "@emburse/embark-core";

interface WarningNoMappedFieldsModalProps {
  showWarningModal: boolean
  setShowWarningModal: React.Dispatch<React.SetStateAction<boolean>>
  doSave: () => void
}
export const WarningNoMappedFieldsModal = (props: WarningNoMappedFieldsModalProps) => {
  const continueSave = () => {
    props.setShowWarningModal(false);
    props.doSave();
  };
  return (
    <Modal className="noMappedFieldsModal" size="sm" open={props.showWarningModal}>
      <Typography>
        Warning: You have not mapped any fields from your distribution list to a filter on the schedule, which means all users on your distribution list will receive the entire report upon delivery. Do you wish to proceed?
      </Typography>
      <ModalFooter>
        <ButtonContainer alignButtons={"right"}>
          <Button onClick={() => props.setShowWarningModal(false)} variant="outlined">
            cancel
          </Button>
          <Button onClick={continueSave} variant="contained" >
            yes
          </Button>
        </ButtonContainer>
      </ModalFooter>
    </Modal>
  );
};