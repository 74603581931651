import { UPDATE_LOOK, DELETE_LOOK, FETCH_MODELS } from '@src/store/types';
import { modalLoading, modalLoaded, hideModal, addUnauthorizedError, fetchFolderContent, showModalError } from ".";
import { callEndpoint, STATUS_CODES } from "../../utility/FetchErrorHandler";
import { ILook } from '../../interfaces/ILook';
import { IFolder } from '../../interfaces/IFolder';
import { addError } from './appActions';
import { ErrorTypes, Severity } from "@src/enums";
import { BASE_FOLDERS_ROUTE } from "../routs";
import { push } from "connected-react-router";
import { IState } from "@src/interfaces";
import store from "..";

const API_HEADERS = { 'Content-Type': 'application/json;charset=utf-8' };

export function fetchLooks() {
    return async dispatch => {
        const response = await callEndpoint('/app/looks');
        const json = await response.json();

        dispatch({ type: FETCH_MODELS, payload: json });
    };
}

export function deleteLook({ id }) {
    return async dispatch => {
        dispatch(modalLoading());
        const response = await callEndpoint('/app/looks/' + id, { method: 'DELETE' });
        dispatch(modalLoaded());
        if (response.ok) {
            dispatch(hideModal());
            dispatch({ type: DELETE_LOOK, payload: { id } });
        } else if (response.status === STATUS_CODES.Unauthorized) {
            dispatch(addUnauthorizedError(response.statusText));
        } else {
            // TODO: show error message
            console.log("Error when calling delete folder API");
        }
    };
}

export function copyLook(look: ILook, folder: IFolder, inModal: boolean = false) {
    return async dispatch => {
        if (inModal) // if this function is being called from a modal
            dispatch(modalLoading());


        const resp = await callEndpoint("/app/looks/copy", {
            method: 'POST',
            headers: API_HEADERS,
            body: JSON.stringify({
                look_id: look?.id,
                folder_id: folder?.id
            })
        });

        if (!resp.ok) {
            if (resp.status === STATUS_CODES.Unauthorized) {
                dispatch(addUnauthorizedError(resp.statusText));
            } else {
                dispatch(addError({
                    type: ErrorTypes.SILENT,
                    timestamp: Date.now(),
                    message: resp.statusText,
                    severity: Severity.ERROR
                }));
            }
        }
        else {
            let state: IState = store.getState();

            if (state.folders.activeFolderId === folder.id) {
                dispatch(fetchFolderContent(folder.id, state.folders.folderStructure));
            }
            else {
                dispatch(push(`${BASE_FOLDERS_ROUTE}${folder.id}`));
            }
        }

        if (inModal) // if this function is being called from a modal 
            dispatch(modalLoaded());
        dispatch(hideModal());
    };
}

export function moveLook(look: ILook, folder: IFolder, inModal: boolean = false) {
    return async dispatch => {
        if (inModal) // if this function is being called from a modal
            dispatch(modalLoading());


        const resp = await callEndpoint("/app/looks/move", {
            method: 'POST',
            headers: API_HEADERS,
            body: JSON.stringify({
                look_id: look?.id,
                folder_id: folder?.id
            })
        });

        if (!resp.ok) {
            if (resp.status === STATUS_CODES.Unauthorized) {
                dispatch(addUnauthorizedError(resp.statusText));
            } else {
                dispatch(addError({
                    type: ErrorTypes.SILENT,
                    timestamp: Date.now(),
                    message: resp.statusText,
                    severity: Severity.ERROR
                }));
            }
        }
        else {
            let state: IState = store.getState();

            if (state.folders.activeFolderId === folder.id) {
                dispatch(fetchFolderContent(folder.id, state.folders.folderStructure));
            }
            else {
                dispatch(push(`${BASE_FOLDERS_ROUTE}${folder.id}`));
            }
        }

        if (inModal) // if this function is being called from a modal
            dispatch(modalLoaded());
        dispatch(hideModal());
    };
}

export function renameLook({id, name, description}: {id: any, name: any, description: any}) {
    return async dispatch => {
        dispatch(modalLoading());
        const response = await callEndpoint('/app/looks/' + id, {
            method: 'PATCH',
            headers: API_HEADERS,
            body: JSON.stringify({look_name: name, look_description: description})
        });
        dispatch(modalLoaded());
        if (response.ok) {
            const look = await response.json();
            dispatch({type: UPDATE_LOOK, payload: {id: look.id, title: look.look_name, description: look.look_description}});
            dispatch(hideModal());
        } else if (response.status === 400) {
            const error = await response.json();
            dispatch(showModalError(error.message));
        } else if (response.status === STATUS_CODES.Unauthorized){
            dispatch(addUnauthorizedError(response.statusText));
        } else {
            console.error("Unexpected error when calling look API");
        }
    };
}