import React, { useEffect, useContext } from 'react';
import Cactus from '../Icons/Cactus';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { logoutUser, setUserData, userNotAuthed } from '@store/actions/userActions';
import { IUser } from '../../interfaces';
import EmptyPage from '../EmptyPage/EmptyPage';
import { LOGOUT_ROUTE } from '@store/routs';

import { AuthContext } from "@components/AuthProvider/AuthProvider";
import { IUseAuthInterface } from "../../hooks/useAuth";
export default () => {
    const dispatch = useDispatch();

    
  const {user}: IUseAuthInterface = useContext(AuthContext);
    useEffect(() => {
        if(JSON.parse(localStorage.getItem('auth_status') || '')) {
            dispatch(setUserData(userNotAuthed));
            dispatch(logoutUser());
        }
        else if(!JSON.parse(localStorage.getItem('auth_status') || '') && user?.initRoute === LOGOUT_ROUTE){
            localStorage.setItem("user", JSON.stringify(userNotAuthed));
            dispatch(setUserData(userNotAuthed));
        }
    }, [dispatch, user]);
        

    return (
        <EmptyPage>
            <Cactus />
            <div className="da-empty-page-text-block">
                <h6 className="da-empty-page-text-block-heading">Logged Out</h6>
                <p>Please login again to use this application.</p>   
            </div>
        </EmptyPage>
    );
};