import {
    IReportDestinationDefinition,
    IReportScheduleDefinition,
    isEmailDistribution,
    isSFTPDistribution,
} from "@interfaces";
import {ReportScheduleType} from "@enums";
/**
 * @interface ScheduleFormError simple description of an error in the schedule form
 */
export interface ScheduleFormError {
    key: string,
    message: string
}

export class ScheduleValidator {
    /**
     * Implements client-side validation for the schedule form.  As there's a lot of combinations of fields
     * it's easier to implement to test in code than using a schema validator.
     */
    public static validate = (schedule: IReportScheduleDefinition | undefined): ScheduleFormError[] => {
        const formErrors: ScheduleFormError[] = [];

        if (!schedule) {
            return [{
                'key': 'name',
                'message': 'Schedule is not complete'
            }];
        }

        this.validateScheduleName(schedule, formErrors);
        this.validateCronSchedule(schedule, formErrors);

        if (schedule.type === ReportScheduleType.DEFAULT) {

            if (isEmailDistribution(schedule)) {
                this.validateEmailDistribution(schedule, formErrors);
            } else {
                if (isSFTPDistribution(schedule)) {
                    this.validateSFTPDistribution(schedule, formErrors);
                } else {
                    formErrors.push({
                        'key': 'address',
                        'message': 'Please complete at least one destination'
                    });
                }
            }

        } else {
            // burst validation
            if (!schedule.destinations[0].distribution_look_id) {
                formErrors.push({
                    'key': 'distribution_look_id',
                    'message': 'Please select a distribution list'
                });
            }
        }

        return formErrors;
    }

    protected static validateScheduleName(props, formErrors: ScheduleFormError[]) {
        /**
         * Schedule Name must be between 1 and 50 characters, trimmed
         */
        const MAX_LENGTH = 50;

        if (!props['name']) {
            formErrors.push({
                'key': 'name',
                'message': 'Please enter a Schedule Name'
            });
            return;
        }

        const name = props['name'].trim();

        if (name.length < 1 || name.length > MAX_LENGTH) {
            formErrors.push({
                'key': 'name',
                'message': `Schedule Name length must be between 1 and ${MAX_LENGTH} characters`
            });
        }
    }

    protected static validateCronSchedule(schedule: IReportScheduleDefinition, formErrors: ScheduleFormError[]) {

        const pattern = new RegExp(
            /^((?:\*)|(?:[0-5]?[0-9])|(?:(?:(?:[0-5]?[0-9])-(?:[0-5]?[0-9]))(?:,(?:(?:[0-5]?[0-9])-(?:[0-5]?[0-9])))*)|(?:(?:[0-5]?[0-9])(?:,(?:[0-5]?[0-9]))*))\s+((?:\*)|(?:[0-9]|0[0-9]|1[0-9]|2[0-3])|(?:(?:(?:[0-9]|0[0-9]|1[0-9]|2[0-3])-(?:[0-9]|0[0-9]|1[0-9]|2[0-3]))(?:,(?:(?:[0-9]|0[0-9]|1[0-9]|2[0-3])-(?:[0-9]|0[0-9]|1[0-9]|2[0-3])))*)|(?:(?:[0-9]|0[0-9]|1[0-9]|2[0-3])(?:,(?:[0-9]|0[0-9]|1[0-9]|2[0-3]))*))\s+((?:\*)|(?:[1-9]|0[1-9]|1[0-9]|2[0-9]|3[0-1])|(?:(?:(?:[1-9]|0[1-9]|1[0-9]|2[0-9]|3[0-1])-(?:[1-9]|0[1-9]|1[0-9]|2[0-9]|3[0-1]))(?:,(?:(?:[1-9]|0[1-9]|1[0-9]|2[0-9]|3[0-1])-(?:[1-9]|0[1-9]|1[0-9]|2[0-9]|3[0-1])))*)|(?:(?:[1-9]|0[1-9]|1[0-9]|2[0-9]|3[0-1])(?:,(?:[1-9]|0[1-9]|1[0-9]|2[0-9]|3[0-1]))*))\s+((?:\*)|(?:[1-9]|0[1-9]|1[0-2])|(?:(?:(?:[1-9]|0[1-9]|1[0-2])-(?:[1-9]|0[1-9]|1[0-2]))(?:,(?:(?:[1-9]|0[1-9]|1[0-2])-(?:[1-9]|0[1-9]|1[0-2])))*)|(?:(?:[1-9]|0[1-9]|1[0-2])(?:,(?:[1-9]|0[1-9]|1[0-2]))*)|(?:\*)|(?:jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)|(?:(?:(?:jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)-(?:jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec))(?:,(?:(?:jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)-(?:jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)))*)|(?:(?:jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)(?:,(?:jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec))*))\s+((?:\*)|(?:[0-6]|0[0-6])|(?:(?:(?:[0-6]|0[0-6])-(?:[0-6]|0[0-6]))(?:,(?:(?:[0-6]|0[0-6])-(?:[0-6]|0[0-6])))*)|(?:(?:[0-6]|0[0-6])(?:,(?:[0-6]|0[0-6]))*)|(?:\*)|(?:sun|mon|tue|wed|thu|fri|sat)|(?:(?:(?:sun|mon|tue|wed|thu|fri|sat)-(?:sun|mon|tue|wed|thu|fri|sat))(?:,(?:(?:sun|mon|tue|wed|thu|fri|sat)-(?:sun|mon|tue|wed|thu|fri|sat)))*)|(?:(?:sun|mon|tue|wed|thu|fri|sat)(?:,(?:sun|mon|tue|wed|thu|fri|sat))*))$/
        );

        if (!schedule.crontab) {
            formErrors.push({
                'key': 'crontab',
                'message': 'Please complete a schedule'
            });
            return;
        }

        if (!pattern.test(schedule.crontab)) {
            formErrors.push({
                'key': 'crontab',
                'message': 'This schedule is unsupported'
            });
        }
    }

    protected static validateEmailDistribution(schedule: IReportScheduleDefinition, formErrors: ScheduleFormError[]) {

        const additionalFormErrors =
            schedule.destinations.flatMap(destination => this.validateEmailDestination(schedule, destination));

        additionalFormErrors.forEach(error => {
            formErrors.push({
                'key': 'address',
                'message': error['message']
            });
        });
    }

    protected static isValidEmailAddress(address: string): boolean {
        /** http://stackoverflow.com/a/46181 */
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(address);
    }

    protected static validateEmailDestination(schedule: IReportScheduleDefinition, destination: IReportDestinationDefinition) : ScheduleFormError[] {

        const formErrors: ScheduleFormError[] = [];

        if (!destination) {
            return [];
        }

        if (!destination.address) {
            formErrors.push({
                'key': 'address',
                'message': 'Please verify all email addresses are complete'
            });

            return formErrors;
        }

        if (!this.isValidEmailAddress(destination.address)) {
            formErrors.push({
                'key': 'address',
                'message': 'Please check that all email addresses are valid'
            });
        }

        return formErrors;
    }

    protected static validateSFTPDistribution(schedule: IReportScheduleDefinition, formErrors: ScheduleFormError[]) {

        const firstDistribution = schedule.destinations[0];

        if (!firstDistribution['address']) {
            formErrors.push({
                'key': 'address',
                'message': 'Please enter an SFTP server address'
            });
            return;
        }

        if (!firstDistribution['parameters']) {
            formErrors.push({
                'key': 'sftpUsername',
                'message': 'Please enter an SFTP username and password'
            });
            return;
        }

        const decodedParams = JSON.parse(firstDistribution.parameters);
        const sftpUsername : string = ('username' in decodedParams ? decodedParams['username'] : "");

        if (!sftpUsername) {
            formErrors.push({
                'key': 'sftpUsername',
                'message': 'Please enter an SFTP username'
            });
            return;
        }
    }
}
