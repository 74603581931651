export default () => {
  return <svg width="127" height="57" viewBox="0 0 127 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2959_364)">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.5 0.5C4.52944 0.5 0.5 4.52944 0.5 9.5V56.5H33.5C38.4706 56.5 42.5 52.4706 42.5 47.5V0.5H9.5ZM26.5 12.5H33.5V34.5H26.5V12.5ZM24.5 18.5H17.5V34.5H24.5V18.5ZM15.5 24.5H8.5V34.5H15.5V24.5Z" fill="#0097DC"/>
      <path d="M89 30.625C91.0625 30.625 92.75 28.9375 92.75 26.875C92.75 24.8125 91.0625 23.125 89 23.125C86.9375 23.125 85.25 24.8125 85.25 26.875C85.25 28.9375 86.9375 30.625 89 30.625ZM91.1188 32.6875C90.425 32.575 89.7312 32.5 89 32.5C87.1438 32.5 85.3813 32.8938 83.7875 33.5875C82.4 34.1875 81.5 35.5375 81.5 37.0563V40H89.9375V36.9812C89.9375 35.425 90.3688 33.9625 91.1188 32.6875ZM119 30.625C121.062 30.625 122.75 28.9375 122.75 26.875C122.75 24.8125 121.062 23.125 119 23.125C116.938 23.125 115.25 24.8125 115.25 26.875C115.25 28.9375 116.938 30.625 119 30.625ZM126.5 37.0563C126.5 35.5375 125.6 34.1875 124.213 33.5875C122.619 32.8938 120.856 32.5 119 32.5C118.269 32.5 117.575 32.575 116.881 32.6875C117.631 33.9625 118.062 35.425 118.062 36.9812V40H126.5V37.0563ZM111.95 31.8437C109.756 30.8687 107.056 30.1562 104 30.1562C100.944 30.1562 98.2437 30.8875 96.05 31.8437C94.025 32.7437 92.75 34.7687 92.75 36.9812V40H115.25V36.9812C115.25 34.7687 113.975 32.7437 111.95 31.8437ZM96.6312 36.25C96.8 35.8188 96.875 35.5188 98.3375 34.9563C100.156 34.2438 102.069 33.9062 104 33.9062C105.931 33.9062 107.844 34.2438 109.663 34.9563C111.106 35.5188 111.181 35.8188 111.369 36.25H96.6312ZM104 21.25C105.031 21.25 105.875 22.0938 105.875 23.125C105.875 24.1562 105.031 25 104 25C102.969 25 102.125 24.1562 102.125 23.125C102.125 22.0938 102.969 21.25 104 21.25ZM104 17.5C100.888 17.5 98.375 20.0125 98.375 23.125C98.375 26.2375 100.888 28.75 104 28.75C107.112 28.75 109.625 26.2375 109.625 23.125C109.625 20.0125 107.112 17.5 104 17.5Z" fill="#0097DC"/>
      <path d="M73.5 28L65.1364 19.5V25.875H50.5V30.125H65.1364V36.5" fill="#0097DC"/>
    </g>
    <defs>
      <clipPath id="clip0_2959_364">
        <rect width="126" height="56" fill="white" transform="translate(0.5 0.5)"/>
      </clipPath>
    </defs>
  </svg>;
};