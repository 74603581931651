import { Drawer, Grid } from "@emburse/embark-core";
import { IUseDrawer } from "@src/interfaces";
import React, { createContext, useState } from "react";
import { IHasChildrenProps } from "@src/interfaces/IHasChildrenProps";
import { createUseStyles } from "react-jss";
import { v4 } from "uuid";
import { ErrorObject } from "ajv";

export interface DrawerProviderProps extends IHasChildrenProps {}

const useStyles = createUseStyles({
  drawer: {
    padding: "24px",
  },
});

export const DrawerContext = createContext<IUseDrawer>({
  setComponent: () => {},
  setAnchor: () => {},
  setOpen: () => {},
  setSideWidth: () => {},
  setErrors: () => {},
});

export default (props: DrawerProviderProps) => {
  const [open, setOpen] = useState(false);
  const [sideWidth, setSideWidth] = useState(400);
  const [component, setComponent] = useState<JSX.Element>();
  const [anchor, setAnchor] = useState<"top" | "right" | "bottom" | "left">(
    "right"
  );
  const [errors, setErrors] = useState<
    ErrorObject<string, Record<string, any>, unknown>[] | null | undefined
  >();

  const classes = useStyles();

  let values = {
    component: component,
    setComponent: setComponent,
    setAnchor: setAnchor,
    anchor: anchor,
    open: open,
    setOpen: setOpen,
    setSideWidth: setSideWidth,
    sideWidth: sideWidth,
    errors: errors,
    setErrors: setErrors,
  };
  return (
    <DrawerContext.Provider value={values}>
      <Grid container
      direction="column"
      >
        {props.children}
        <Drawer
          open={values.open}
          sideWidth={values.sideWidth}
          anchor={values.anchor}
          openUpdated={values.setOpen}
          key={v4()}
          disableCloseOnBackdropClick={true}
        >
          <div className={classes.drawer}>{values.component}</div>
        </Drawer>
      </Grid>
    </DrawerContext.Provider>
  );
};
