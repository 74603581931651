import { useEffect, useState } from 'react';
import { useDatabase } from '.';
import { APICalls } from '../IDBDatabases';
import IMiddleWareObject from '../interfaces/IMiddleWareObject';

export function useDatabases(): IMiddleWareObject {
  // this is if we need to access a idb in other ways.
  // init state
  const apicalls = useDatabase<APICalls>(new APICalls());
  const [dbs, setDbs] = useState<IMiddleWareObject>();

  useEffect(() => {
    setDbs({ APICallsDB: apicalls });
  }, []);
//@ts-ignore
  return dbs;
}
