import { IAlert, IError } from '@src/interfaces';
import { RootStateOrAny } from 'react-redux';
import { ADD_ALERT, ADD_ERROR, REMOVE_ALERT } from '../types';
export interface IAppState extends RootStateOrAny{
    errors: IError[]
    alerts: IAlert[]
}

const initialState: IAppState = {
    errors: [],
    alerts: []
}

export const appReducer = (state = initialState, action: {type: string, payload: IAlert | IError | any}) => {
    switch (action.type) {
        case ADD_ERROR:
            return {
                ...state,
                errors: [
                    ...state.errors, action.payload
                ]
            }
        case ADD_ALERT:
            return {
                ...state,
                alerts: [
                    ...state.errors, action.payload
                ]
            }
        case REMOVE_ALERT:
            return {
                ...state,
                alerts:[
                    ...state.alerts.filter((alert: IAlert) => alert.timestamp !== action.payload.timestamp)
                ]
            }
        default: 
            return state
    }
}