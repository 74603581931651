import React from 'react';
import { IHasChildrenProps } from '../../interfaces';
import './EmptyPage.scss';


export interface EmptyPageProps extends IHasChildrenProps{
}


export default (props: EmptyPageProps) => {
    return (
        <div className="da-empty-page">
            <div className="da-empty-page-inner">
                {props.children}
            </div>
        </div>
    )
}

