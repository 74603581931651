/**
 * Endpoint options. We add expiry and cacheMe.
 *
 * @export
 * @interface IEndpointOptions
 * @extends {RequestInit}
 */
export interface IEndpointOptions extends RequestInit {
  expiry?: number;
  cacheMe?: boolean;
}

/**
 *  Used to create endpoints.
 *
 * @export
 * @class Endpoint
 */
export class Endpoint {
  /**
   * This returns a formatted parameters object.
   * If no args are even provided it returns a default set.
   *
   * @static
   * @param {{expiry?: number, cacheMe?: boolean, cache?: RequestCache}} args
   * @memberof Endpoint
   */
  static _getStandardParams = (args?: {
    expiry?: number;
    cacheMe?: boolean;
    // eslint-disable-next-line no-undef
    cache?: RequestCache;
  }) => {
    return {
      cacheExpiry: args?.expiry ? args.expiry : 5000,
      cacheMeOutside: args?.cacheMe ? args.cacheMe : true,
      cache: args?.cache ? args.cache : "default",
    };
  };

  /**
   * tldr; Extend an endpoint or create a new one.
   * Wraps _ex and fetch.
   *
   * @static
   * @template Response
   * @param {RequestInfo} input
   * @param {IEndpointOptions} [options]
   * @return {*}
   * @memberof Endpoint
   */
  static extend<_Response>(input: RequestInfo, options?: IEndpointOptions) {
    return this._ex(fetch, input, options)({ input, options });
  }

  /**
   * tldr; creates function that calls fetch with a set of standard parameters and options.
   *
   * @static
   * @template Response
   * @param {(input: RequestInfo, options?: RequestInit) => Promise<Response>} _fetch
   * @param {RequestInfo} i
   * @param {IEndpointOptions} [o]
   * @return {*}
   * @memberof Endpoint
   */
  
  static _ex<Response>(
    _fetch: (input: RequestInfo, options?: RequestInit) => Promise<Response>,
    i: RequestInfo,
    o?: IEndpointOptions,
  ) {
    return (args: {
      input?: RequestInfo;
      options?: RequestInit;
    }): Promise<Response> => {
      return _fetch(
        ((args.input ? args.input : i) as string).concat(
          "?swdata=" +
            encodeURI(
              btoa(
                JSON.stringify(
                  this._getStandardParams({
                    expiry: o?.expiry,
                    cacheMe: o?.cacheMe,
                    cache: o?.cache,
                  })
                )
              )
            )
        ),

        { ...args.options, ...o }
      ).then((response) => {
        console.log(response);
        return response;
      });
    };
  }
}
