import {createBrowserHistory} from 'history'
import {applyMiddleware, compose, createStore} from 'redux'
import {routerMiddleware} from 'connected-react-router'
import thunk from 'redux-thunk'
// import { persistStore } from 'redux-persist'
import {createRootReducer} from './reducers/rootReducer';


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


export const history = createBrowserHistory();



const store = createStore(
    createRootReducer(history),
    composeEnhancers(applyMiddleware(thunk, routerMiddleware(history)))
)


export default store
// export const persistedStore = persistStore(store)

export * from './actions';
export * from './reducers';