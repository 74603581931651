import { IUseAdminUserList } from "@src/interfaces";
import React, { createContext } from "react";
import { IHasChildrenProps } from "../../interfaces/IHasChildrenProps";
import { AnalyticsRole } from "../../enums/AnalyticsRole";

export interface AdminUserListProviderProps extends IHasChildrenProps {}

export const AdminUserListContext = createContext<IUseAdminUserList>({
  users: [],
});

export default (props: AdminUserListProviderProps) => {
  let values: IUseAdminUserList = {
    users: [
      {
        admin: false,
        first_name: "James",
        last_name: "Last",
        email: "email@email1.com",
        analytics_role: AnalyticsRole.Viewer,
      },
      {
        admin: false,
        first_name: "James",
        last_name: "Last",
        email: "email@email2.com",
        analytics_role: AnalyticsRole.ProCreator,
      },
      {
        admin: false,
        first_name: "James",
        last_name: "Last",
        email: "email@email3.com",
        analytics_role: AnalyticsRole.ProViewer,
      },
      {
        admin: false,
        first_name: "James",
        last_name: "Last",
        email: "email@email5.com",
        analytics_role: AnalyticsRole.Viewer,
      },
    ],
  };
  return (
    <AdminUserListContext.Provider value={values}>
      {props.children}
    </AdminUserListContext.Provider>
  );
};
