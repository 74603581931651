import { Button, ButtonProps } from '@emburse/embark-core';
import React, { useRef } from 'react';
import { IHasChildrenProps } from '../../interfaces/IHasChildrenProps';

export interface SubmitButtonProps extends IHasChildrenProps, ButtonProps {
    action: string, 
    method: string
}


export default (props: SubmitButtonProps) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    
    const handleClick = (e) => {
    
        buttonRef?.current?.click();
    
        if(props.onClick)
            props.onClick(e);
    };
    return (
        <>
            <Button {...props} onClick={handleClick} ref={buttonRef}>
                {props.children}
            </Button>
            <form action={props.action} method={props.method}>
                <button style={{display: "none"}} type="submit" ref={buttonRef}></button>
            </form>
        </>
    );
};