import React from 'react'

export default ({error}) => {
    // console.log("Errors----", error.message)
    
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
      </div>
    )
  }