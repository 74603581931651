import { useSelector } from 'react-redux';
import { IState } from '../interfaces';
import { IEmburseFolderStructure } from '../interfaces/IEmburseFolderStructure';
import { IFolder } from '../interfaces/IFolder';

export function addNode(parent: IEmburseFolderStructure, child: IEmburseFolderStructure): IEmburseFolderStructure{
    parent.children?.push(child)
    return parent
}


/**
 * Deletes a node from a tree
 * @param root the root of the tree
 * @param key the key to delete
 */
export function deleteNode(root: IEmburseFolderStructure, key: string | number){
    // let toDelete = findNode(root, key)
    // if(toDelete != null && toDelete.parent?.children){
    //     toDelete.parent.children = toDelete.parent.children.filter(c => c.key !== key)
    // }
}


/**
 * Find a node in a non-binary tree
 * @param node node to search on
 * @param key key to search for
 */
export function findNode(node: IEmburseFolderStructure, key: string | number): IEmburseFolderStructure | null{
    if(node.key === key){
        return node
    }
    else{
        for (let n = 0; n < node.children.length; n++) {
            let child = node.children[n];
            let result = findNode(child, key)
            if(result != null){
                return result
            }
        }
    }

    return null
}

export function getNewRootNode(){
    let rootNode: IEmburseFolderStructure = {
        key: 'all',
        type: 'folder',
        lastUpdate: new Date(),
        parent: null,
        children: []
    }

    return rootNode
}