import * as React from 'react';

import {ButtonContainer, Grid, Icon, IconButton, Skeleton, Spacer, TextField, Typography} from '@emburse/embark-core';

import {IDashboardRef, ILookRef, IReportDestinationDefinition, IReportScheduleDefinition} from "@interfaces";
import {useContext, useEffect, useState} from "react";
import {join} from "lodash";
import cronstrue from "cronstrue";
import {Timezones, FFlags, getFlag, } from "@enums";
import {flagsSelector} from "@src/store/selector";
import {CloseIcon} from "@emburse/embark-icons";
import {IScheduleAPIContext, ScheduleAPIContext} from "@components/ScheduleAPIProvider/ScheduleAPIProvider";
import {IScheduledPlanDestination, IUserPublic} from "@looker/sdk/lib/4.0/models";
import {openDashboard, openLook} from "@src/store";
import {useDispatch, useSelector} from "react-redux";
import {textStyle} from "styled-system";

interface ViewScheduleDetailsProps {
    schedule: IReportScheduleDefinition;
    onClose: () => void; // deprecate with analytics_app_cra_9531_schedule_admin_view_details
    owner?: IUserPublic;
}

const ViewSchedule = ({ schedule, onClose, owner }: ViewScheduleDetailsProps) => {
    const dispatch = useDispatch();
    const scheduleAPI = useContext<IScheduleAPIContext>(ScheduleAPIContext);

    const [dashboardInfo, setDashboardInfo] = useState<IDashboardRef | null>(null);
    const [lookInfo, setLookInfo] = useState<ILookRef | null>(null);
    const flags = useSelector(flagsSelector);
    let ownerName = schedule.user_id;
    if (owner) {
        ownerName = `${owner.first_name} ${owner.last_name}`;
    } else {
        if (schedule.user) {
            ownerName = `${schedule.user.first_name} ${schedule.user.last_name}`;
        }
    }

    function fetchLookInfo(look_id: string) {
        scheduleAPI.getReferencedLook(look_id)
            .then(async (res) => {
                setDashboardInfo(null);
                setLookInfo(res);
            })
            .catch((e) => {
                setDashboardInfo(null);
                setLookInfo({
                    id: look_id,
                    name: look_id
                });
            });
    }

    function fetchDashboardInfo(dashboard_id: string) {
        scheduleAPI.getReferencedDashboard(dashboard_id)
            .then(async (res) => {
                setLookInfo(null);
                setDashboardInfo(res);
            })
            .catch((e) => {
                setLookInfo(null);
                setDashboardInfo({
                    id: dashboard_id,
                    name: dashboard_id
                });
            });
    }

    useEffect(() => {
        /*** Fetch details on the referenced look or dashboard */
        if (!schedule) {
            return;
        }

        if (schedule.look_id) {
            fetchLookInfo(schedule.look_id);
        } else {
            if (schedule.dashboard_id) {
                fetchDashboardInfo(schedule.dashboard_id);
            }
        }
    }, [schedule]);

    const doOpenReferencedItem = (is_dashboard: boolean, ref_id: string) => {
        if (is_dashboard) {
            dispatch(openDashboard(ref_id));
        } else {
            dispatch(openLook(ref_id));
        }
    };

    function renderModelSection() {

        return (

            <Grid container direction="column" spacing={1}>

                <Grid item>
                    <Spacer size={8} direction="vertical"/>
                    {(!lookInfo && !dashboardInfo) && <Skeleton/>}
                    {lookInfo &&
                        <TextField
                            label={"Look"}
                            fullWidth
                            readOnly
                            style={{cursor: "pointer", textDecoration: "underline"}}
                            value={lookInfo.name}
                            onClick={() => doOpenReferencedItem(false, lookInfo.id)}
                        />}
                    {dashboardInfo &&
                        <TextField
                            label={"Dashboard"}
                            fullWidth
                            readOnly
                            style={{cursor: "pointer", textDecoration: "underline"}}
                            value={dashboardInfo.name}
                            onClick={() => doOpenReferencedItem(true, dashboardInfo.id)}
                        />}
                </Grid>
            </Grid>
        );
    }


    function renderScheduleHeaderSection() {

        const cronExp = schedule.crontab||'';
        let cronDescription = '';
        if (cronExp) {
            cronDescription = cronstrue.toString(cronExp, {throwExceptionOnParseError: false, verbose: true});
        }

        let timezone = Timezones.find((f) => f.link === schedule.timezone)?.display;
        if (!timezone) {
            timezone = schedule?.timezone || 'not specified';
        }

        return (

            <Grid container direction="column" spacing={1}>

                <Grid item>
                    <Spacer size={8} direction="vertical"/>
                    <TextField
                        label={"Name"}
                        fullWidth
                        readOnly
                        value={schedule.name}
                    />

                    <Spacer size={16} direction="vertical" />
                    <TextField
                        label={"Schedule"}
                        fullWidth
                        readOnly
                        value={cronDescription}
                    />

                    <Spacer size={16} direction="vertical" />
                    <TextField
                        label={"Timezone"}
                        fullWidth
                        readOnly
                        value={timezone}
                    />

                    <Spacer size={16} direction="vertical" />
                    <TextField
                        label={"Owner"}
                        fullWidth
                        readOnly
                        value={ownerName}
                    />
                </Grid>
            </Grid>
        );
    }

    const renderFormattingOptions = (schedule) => {

        let destination: IReportDestinationDefinition;
        if (!schedule.destinations || schedule.destinations.length == 0) {
            destination = {
                type: 'not specified',
                address: 'not specified',
                format: 'not specified',
                apply_vis: false,
                apply_formatting: false,
                parameters: ''
            };
        } else {
            destination = schedule.destinations[0];
        }

        return (
            <Grid container direction="column" spacing={1}>
                <Grid item>
                    <Typography variant="h6" display="block">
                        Format Options
                    </Typography>
                </Grid>
                <Grid item>
                    <Spacer size={8} direction="vertical"/>
                    { (destination && <TextField
                        label={"Format"}
                        fullWidth
                        readOnly
                        value={destination.format}
                    />) || <Skeleton/> }

                    <Spacer size={16} direction="vertical"/>
                    { (destination && <TextField
                        label={"Apply Visualizations"}
                        fullWidth
                        readOnly
                        value={(destination.apply_formatting? "Yes" : "No")}
                    />) || <Skeleton/> }

                    <Spacer size={16} direction="vertical"/>
                    { !schedule.require_results && !schedule.require_no_results && (
                        <TextField
                            label={"Send Condition"}
                            fullWidth
                            readOnly
                            value={"Send if there are results or no results"}
                        />
                    )}
                    { schedule.require_results && !schedule.require_no_results && (
                        <TextField
                            label={"Send Condition"}
                            fullWidth
                            readOnly
                            value={"Send only if there are results"}
                        />
                    )}
                    { !schedule.require_results && schedule.require_no_results && (
                        <TextField
                            label={"Send Condition"}
                            fullWidth
                            readOnly
                            value={"Send only if there are no results"}
                        />
                    )}
                    <Spacer size={16} direction="vertical"/>
                    <TextField
                        label={"Send All Results"}
                        fullWidth
                        readOnly
                        value={(schedule.send_all_results? "Yes" : "No")}
                    />
                </Grid>
            </Grid>
        );
    };

    function renderSFTPDestination(sftpDestination : IScheduledPlanDestination) {
        let sftpUsername = '';
        if (sftpDestination.parameters) {
            const decodedParams = JSON.parse(sftpDestination.parameters);
            sftpUsername = ('username' in decodedParams ? decodedParams['username'] : "");
        }

        return (
            <>
                <Spacer size={16} direction="vertical"/>
                <TextField
                    label={"SFTP Server Address (sftp://name/path)"}
                    fullWidth
                    readOnly
                    value={sftpDestination.address}
                />
                <Spacer size={16} direction="vertical"/>
                <TextField
                    label={"SFTP Username"}
                    fullWidth
                    value={sftpUsername}
                    readOnly
                />
            </>
        );
    }

    function renderEmailDestinations(destinations) {
        const emailList = join(destinations.map(destination => {
            return destination.address;
        }, ', '));
        return (
            <>
                <Spacer size={16} direction="vertical"/>
                <TextField
                    label={"Email"}
                    fullWidth
                    readOnly
                    value={emailList}
                />
            </>
        );
    }

    function renderOtherDestinations(destinations) {
        const addresses = join(destinations.map(destination => {
            return destination.address;
        }, ', '));
        return (
            <>
                <Spacer size={16} direction="vertical"/>
                <TextField
                    label={"Addresses"}
                    fullWidth
                    value={addresses}
                    readOnly
                />
            </>
        );
    }

    const renderScheduleDestinationSection = (destinations: IReportDestinationDefinition[]) => {

        if (!destinations || destinations.length == 0) {
            return (
                <Skeleton/>
            );
        }
        const destinationType = destinations[0].type;

        return (
            <Grid container direction="column" spacing={1}>
                <Grid item>
                    <Typography variant="h6" display="block">
                        Destination
                    </Typography>
                </Grid>
                <Grid item>
                    {destinationType === 'email' && renderEmailDestinations(destinations)}
                    {destinationType === 'sftp' && renderSFTPDestination(destinations[0])}
                    {destinationType !== 'sftp' && destinationType !== 'email' && renderOtherDestinations(destinations)}
                </Grid>
            </Grid>
        );
    };


    return (
        <div style={{ padding: '24px' }}>
            {flags?.[getFlag(FFlags.ScheduleAdminGridMoreActions)] ? <></> : <Grid container spacing={3} direction="column">
                    <Grid item>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Typography variant="h5">Schedule Details</Typography>

                            <ButtonContainer>
                                <IconButton onClick={() => onClose()} data-qa="schedule-details-close">
                                    <Icon iconUrl={CloseIcon} />
                                </IconButton>
                            </ButtonContainer>
                        </Grid>
                    </Grid>
                </Grid>
            }
            {renderScheduleHeaderSection()}
            {renderModelSection()}
            {schedule.destinations && renderScheduleDestinationSection(schedule.destinations)}
            {renderFormattingOptions(schedule)}

            <Spacer size={30} />
        </div>
    );
};

export { ViewSchedule };