import { ILicenseCount } from "@src/interfaces";

// returns true if total number of licenses for a given type (i.e Creator or Viewer) is greater than license limits
export const hasExceededLicenseLimits = (licenseCounts: Array<ILicenseCount> = []) => 
  licenseCounts.some(lc => {
    // null indicates unlimited licenses available
    if (lc.limit === null) {
      return false;
    }

    return lc.count / Number(lc.limit) > 1;
  });
