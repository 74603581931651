import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  ButtonContainer,
  Grid,
  Icon,
  Modal,
  ModalFooter,
  ModalHeader,
  TextField,
  Typography,
} from "@emburse/embark-core";
import { v4 } from "uuid";

import React, { useState } from "react";
import {
  createDashboard,
  hideModal,
  hideModalError,
  moveLook,
  showModalError,
} from "@src/store";
import { ModalFolderSelect } from "@src/components";
import {
  IEmburseFolderStructure,
  ILook,
  IDashboard,
  IFolder,
  IState,
} from "@interfaces";
import { Menu, MenuDivider, MenuItem } from "@emburse/embark-core";
import { ArrowBackIcon } from "@emburse/embark-icons";
import { isInstanceOfILook } from "@interfaces";
import { moveDashboard } from "../../../store/actions/dashboardActions";
import { findNode } from "@src/utility";

export interface MoveActionModalProps {
  item: ILook | IDashboard | undefined;
  modal: any;
}

export default ({ item, modal }: MoveActionModalProps) => {
  const dispatch = useDispatch();
  const structure = useSelector(
    (state: IState) => state.folders.folderStructure
  );

  const [titleName, setTitleName] = useState("");

  const [currentFolder, setCurrentFolder] = useState<
    (IEmburseFolderStructure | undefined)[]
  >([]);

  const [selectedFolder, setSelectedFolder] = useState<IEmburseFolderStructure>(
    structure as IEmburseFolderStructure
  );

  const isLook: boolean = isInstanceOfILook(item);

  const setNewName = (e) => {
    if (modal.errorMessage) {
      dispatch(hideModalError());
    }
    setTitleName(e.target.value);
  };

  const closeModal = () => {
    dispatch(hideModal());
  };

  const handleMoveClick = () => {
    if (isLook) {
      dispatch(moveLook(item as ILook, selectedFolder?.data as IFolder, true));
    } else {
      dispatch(
        moveDashboard(item as IDashboard, selectedFolder?.data as IFolder, true)
      );
    }
  };

  const modalHeader = (
    <ModalHeader
      title={`Move ${isLook ? "Look" : "Dashboard"} To`}
      showClose={true}
      onCloseClicked={closeModal}
    ></ModalHeader>
  );

  const modalFooter = (
    <ModalFooter>
      <ButtonContainer alignButtons={"right"}>
        <Button
          onClick={closeModal}
          variant="outlined"
          disabled={modal.loading}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleMoveClick}
          disabled={modal.loading}
        >
          Move Here
        </Button>
      </ButtonContainer>
    </ModalFooter>
  );
  return (
    <Modal
      open
      modalHeader={
        <Grid container direction="row">
          {currentFolder[0]?.parent != "all" && (
            <Grid
              item
              style={{
                width: "75px",
                paddingLeft: "28px",
                display: "flex",
                alignItems: "center",
              }}
              direction="column"
              justifyContent="center"
            >
              <div
                onClick={() => {
                  let node = findNode(
                    structure as IEmburseFolderStructure,
                    selectedFolder?.parent as string | number
                  );

                  if (node) {
                    setSelectedFolder(node);
                    setCurrentFolder(node.children);
                  } else {
                    console.log(
                      "Could not find parent node in folder structure."
                    );
                  }
                }}
              >
                <Icon iconUrl={ArrowBackIcon} />
              </div>
            </Grid>
          )}
          <Grid item style={{ flexGrow: 1 }}>
            {modalHeader}
          </Grid>
        </Grid>
      }
      modalFooter={modalFooter}
      onClose={closeModal}
      isLoading={modal.loading}
    >
      <div className="da-modal-form">
        <ModalFolderSelect
          currentFolder={currentFolder}
          setCurrentFolder={setCurrentFolder}
          selectedFolder={selectedFolder}
          setSelectedFolder={setSelectedFolder}
        />
      </div>
    </Modal>
  );
};
