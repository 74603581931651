import React, { useEffect } from "react";
import { useInterval } from "../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserInfoWithSession } from "../../store/actions/userActions";
import { IUserState } from "../../store";
import { SessionStatus } from "../../enums";
import { Redirect } from "react-router";
import { useContext } from "react";
import {
  LICENSE_LIMIT_EXCEEDED_ROUTE,
  PROVISIONED_LICENSE_COUNT_EXCEEDED_ROUTE,
  ADMIN_USER_MANAGEMENT,
  PROVISIONING_ROUTE,
  UNAUTHORIZED_ROUTE,
} from "../../store/routs";
import { LoadingSpinner } from "..";
import { ISession, IState } from "@src/interfaces";
import { AuthContext } from "../AuthProvider/AuthProvider";

const PROVISIONING_POLL_INTERVAL_MS = 500;

export default () => {
  let dispatch = useDispatch();
  const user: IUserState = useSelector((state: IState) => state.user);
  const auth = useContext(AuthContext);

  const authContext = useContext(AuthContext);

  useInterval(() => {
    if (user.session_state === SessionStatus.PROVISIONING ||
        user.session_state === SessionStatus.NEW) {
      dispatch(fetchUserInfoWithSession(authContext));
    }
  }, PROVISIONING_POLL_INTERVAL_MS);

  return (
    <>
      <LoadingSpinner>
        <p>Provisioning</p>
      </LoadingSpinner>

      {user.session_state === SessionStatus.AUTHORIZED && auth?.user?.license_limit_exceeded === false && <Redirect to={"/"} />}
      {(auth?.session?.error_code ===
        "TENANT_LICENSE_LIMIT_EXCEEDED_FOR_EXISTING_USER" || 
        auth?.session?.error_code ===
        "TENANT_LICENSE_LIMIT_EXCEEDED_FOR_NEW_USER") && (
        <Redirect to={LICENSE_LIMIT_EXCEEDED_ROUTE} />
      )}
      {user.session_state === SessionStatus.AUTHORIZED && auth?.user?.license_limit_exceeded === true &&  <Redirect to={PROVISIONED_LICENSE_COUNT_EXCEEDED_ROUTE} />}
      {user.session_state === SessionStatus.UNAUTHORIZED && <Redirect to={UNAUTHORIZED_ROUTE} />}
    </>
  );
};
