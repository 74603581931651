import {
  Alert,
  Button,
  ButtonContainer,
  Checkbox,
  Chip,
  Grid,
  Icon,
  IconButton,
  ModalFooter,
  PasswordField,
  Spacer,
  TextField, Tooltip,
  Typography,
  Skeleton
} from "@emburse/embark-core";
import { IDashboard, IFilter, IFieldSuggestionDict, ILook, IReportDestinationDefinition, IReportScheduleDefinition, isSFTPDistribution, IDistributionField } from "@src/interfaces";
import ReactElement, { useContext, useEffect, useState } from "react"; import { createUseStyles } from "react-jss";
import { v4 } from "uuid";
import { DaySelector, FileBrowserModal, Filter, MonthSelector, SelectField, TimezoneSelectField, } from "@src/components";

import {
  DataFormatEmail,
  DataFormatEmailDashboardList,
  DataFormatEmailLookList,
  DataFormatSFTPDashboardList,
  DataFormatSFTPLookList,
  DeliverySchedule,
  DistributionType,
  ExtraFormats,
  getFlag,
  FFlags,
  Hours,
  IClause,
  LimitTo,
  Minutes,
  MonthDay,
  PaperSizes,
  PaperOrientations,
  Recurrence,
  RecurrenceCalendar,
  RecurrenceTime,
  ScheduleOutput,
  SendScheduleOptions,
  Timezones,
  Weekdays,
} from "@src/enums";
import { Scheduling } from "@src/classes";
import { SupportedFormats } from "@looker/sdk/lib/4.0/models";

import { IScheduleAPIContext, ScheduleAPIContext, } from "../ScheduleAPIProvider/ScheduleAPIProvider";
import Cron from "cron-converter";
import { useDispatch, useSelector } from "react-redux";
import { flagsSelector } from "@src/store/selector";
import { Dispatch } from "redux";
import Book from '../Icons/Book';
import { isValidMonthsAndDayCombo } from "../../utility/UtilFunctions";
import { FilterChangeProps, } from "@looker/filter-components";

import { getExpressionTypeFromField } from "@looker/filter-expressions";
import { WarningNoMappedFieldsModal } from "@components/Schedule/WarningNoFieldsMappedModal";
import { ScheduleFormError, ScheduleValidator } from "@components/Schedule/ScheduleValidator";
import "./Schedule.scss";

export interface ScheduleFormProps {
  isFormReady?: boolean
  setFormReady?: any
  scheduleNameInputRef: any;
  emailInputRef: any;
  item: IDashboard | ILook;
  schedule: Scheduling;
  setSchedule: ReactElement.Dispatch<ReactElement.SetStateAction<Scheduling>>;
  type: "dashboard" | "look";
  formErrors: ScheduleFormError[] | undefined,
  setFormErrors: any;
  layout: "standard" | "burst";
  currentFilterExpressions: { [key: string]: string }; // change the type!
  setCurrentFilterExpressions: ReactElement.Dispatch<ReactElement.SetStateAction<{}>>;
  distributionFields: IDistributionField[];
  setDistributionFields: ReactElement.Dispatch<ReactElement.SetStateAction<IDistributionField[]>>;
}

export interface ScheduleFormFooterProps {
  scheduleNameInputRef: any
  isFormReady?: boolean
  setShowWarningModal?: any;
  showWarningModal?: boolean
  schedule: Scheduling;
  folderId: any;
  setFormErrors: any;
  closeEditForm: () => void;
  closeModal: () => {};
  distributionFields: IDistributionField[];
  currentFilterExpressions: { [key: string]: string }
}

const useStyles = createUseStyles({
  field: {
    minWidth: "100%",
  },
  formContainer: {
    marginBottom: "84px",
  },
  footer: {
    position: "absolute",
    bottom: "0",
    width: "100%",
    maxWidth: "352px",
    background: "#fff",
    padding: "24px",
  }
});

/**
 * @interface SFTPParameters are the parameters entered if destination type is SFTP
 */
export class SFTPParameters {
  private _address: string;
  private _sftpUsername: string;
  private _sftpPassword: string;

  constructor(address, sftpUsername, sftpPassword) {
    this._address = address;
    this._sftpUsername = sftpUsername;
    this._sftpPassword = sftpPassword;
  }

  public encode = (): string => {
    /**
     * Encode the values for use in the HTTP payload
     */
    const payload = {
      "username": this._sftpUsername
    };
    if (this._sftpPassword) {
      payload["password"] = this._sftpPassword;
    }

    return JSON.stringify(payload);
  }

  get address(): string {
    return this._address;
  }

  set address(value: string) {
    this._address = value;
  }

  get sftpUsername(): string {
    return this._sftpUsername;
  }

  set sftpUsername(value: string) {
    this._sftpUsername = value;
  }

  get sftpPassword(): string {
    return this._sftpPassword;
  }

  set sftpPassword(value: string) {
    this._sftpPassword = value;
  }
}


export interface SFTPOptionsFormProps {
  sftpParameters: SFTPParameters;
  setSftpParameters: any
  formErrors: ScheduleFormError[] | undefined,
}

export const SFTPOptionsForm = (props: SFTPOptionsFormProps) => {

  const setSftpAddress = (e) => {
    let targetValue = e.target.value;

    if (!targetValue?.toLowerCase().startsWith('sftp://')) {
      targetValue = 'sftp://' + targetValue;
    }

    const existing = props.sftpParameters;
    props.setSftpParameters(new SFTPParameters(targetValue, existing.sftpUsername, existing.sftpPassword));
  };

  const setSftpUsername = (e) => {
    const existing = props.sftpParameters;
    props.setSftpParameters(new SFTPParameters(existing.address, e.target.value, existing.sftpPassword));
  };

  const setSftpPassword = (e) => {
    const existing = props.sftpParameters;
    props.setSftpParameters(new SFTPParameters(existing.address, existing.sftpUsername, e.target.value));
  };

  return (
    <>
      <Grid data-qa="sftpSection" container direction="column">
        <Grid item data-qa="sftpForm" >
          <Typography variant="subtitle1">
            SFTP Distribution
          </Typography>
          <Spacer size={16} direction="vertical" />
          <TextField
            id={v4()}
            label={"SFTP Server Address (sftp://name/path)"}
            fullWidth
            required
            value={props.sftpParameters.address}
            error={!!props.formErrors?.find((e) => e.key === "sftpAddress")}
            onChange={setSftpAddress}
            helperText={
              props.formErrors?.find((e) => e.key === "sftpAddress")?.message
            }
          />
          <Spacer size={16} direction="vertical" />
          <TextField
            id={v4()}
            label={"SFTP Username"}
            fullWidth
            value={props.sftpParameters.sftpUsername}
            required
            error={!!props.formErrors?.find((e) => e.key === "sftpUsername")}
            onChange={setSftpUsername}
            helperText={
              props.formErrors?.find((e) => e.key === "sftpUsername")?.message
            }
          />
          <Spacer size={16} direction="vertical" />
          <PasswordField
            label="SFTP Password"
            fullWidth
            required={false}
            value={props.sftpParameters.sftpPassword}
            error={!!props.formErrors?.find((e) => e.key === "sftpPassword")}
            onChange={setSftpPassword}
            id={v4()} />
          <Spacer size={16} direction="vertical" />
        </Grid>
      </Grid>
    </>
  );
};

const decodeDestinationParameters = (destinations: IReportDestinationDefinition[] | undefined): SFTPParameters => {
  /**
   * Parse additional parameters out of schedule destination.  These are used for SFTP
   */
  if (!destinations || destinations.length === 0) {
    return new SFTPParameters("", "", "");
  }

  const sftpDestinations = destinations.filter((destination) => destination.type === 'sftp');

  const params: SFTPParameters[] = sftpDestinations.map((destination) => {
    if (destination.parameters) {
      const decodedParams = JSON.parse(destination.parameters);
      const sftpUsername: string = ('username' in decodedParams ? decodedParams['username'] : "");
      // sftp password is not readable of course
      return new SFTPParameters(destination.address, sftpUsername, "");
    } else {
      return new SFTPParameters(destination.address, "", "");
    }
  });

  if (params.length > 0) {
    return params[0];
  } else {
    return new SFTPParameters("", "", "");
  }
};


/**
 * Prefix used in distribution field references when mixed with other suggested values.
 * We use this marker to know it's intended to be a field label.
 */
const DISTLOOK_FIELD_MARKER = '(X) ';

/**
 * Map the current/default DataFileFormat to something useful in the select
 * Todo: the complex dataFormat mapping in and out is that dashboards and looks use different enumerations.
 * This could be simplified by we separated the relevant components for dashboards and looks, and/or adding a
 * encapsulation
 */
const calculateInitialDataFileFormat = (existingFormat: DataFormatEmail, type, layout, remapPNGFormatOnEditFlag) => {

  if (!existingFormat) {
    if (type === "look") {
      if (layout == "burst") {
        return SupportedFormats.html;
      } else {
        return SupportedFormats.xlsx;
      }
    } else {
      return "pdf";
    }
  }

  if (remapPNGFormatOnEditFlag) {
    if (type === "look") {
      if (existingFormat === SupportedFormats.wysiwyg_png || existingFormat === ExtraFormats.inline_visualizations) {
        return ExtraFormats.inline_visualizations;
      }
    }
  }

  if (existingFormat === SupportedFormats.wysiwyg_png || existingFormat === ExtraFormats.inline_visualizations) {
    return "png";
  }

  if (existingFormat === SupportedFormats.wysiwyg_pdf || existingFormat === SupportedFormats.assembled_pdf) {
    return "pdf";
  }

  return existingFormat;
};

const UncontrolledScheduleNameInput = ({ onUpdate, errors, className, name: scheduleName }) => {
  const [name, setName] = useState(scheduleName);
  const handleChange = e => {
    setName(e.target.value);
    onUpdate(e.target.value);
  };

  return <TextField
    label={"Schedule Name"}
    value={name}
    onChange={handleChange}
    fullWidth
    required
    className={className}
    helperText={errors?.find((e) => e.key === "name")?.message}
    error={
      errors?.find((e) =>
        e.key === "name"
      )
        ? true
        : false
    }
  />;

};

const UncontrolledEmailInput = ({ onUpdate, errors, className, setEmails, emails, setEmailError, emailError }) => {
  const [newEmailValue, setNewEmail] = useState('');
  const handleChange = e => {
    setNewEmail(e.target.value);
    onUpdate(e.target.value);
  };
  return <TextField
    label="Emails, Comma separated"
    value={newEmailValue}
    onChange={handleChange}
    fullWidth
    required
    className={className}
    helperText={
      emailError.message ? emailError.message
        : errors?.find((e) =>
          e.key === "address"
        )?.message
    }
    error={
      errors?.find((e) =>
        e.key === "address"
      )
        ? true
        : false
    }
    onKeyDown={(event) => {
      if (event.key !== "Enter") {
        return;
      }

      if (event.key === "Enter") {
        if (newEmailValue != "" && !emails.includes(newEmailValue) && newEmailValue.includes("@")) {
          setEmails([...emails, newEmailValue]);
          setNewEmail("");
          setEmailError({ isError: false });
        } else if (!newEmailValue.includes("@") && newEmailValue != "") {
          setEmailError({
            isError: true,
            message:
              "Please enter a valid recipient email address",
          });
        }
      }
    }}
  />;
};


export default (props: ScheduleFormProps) => {
  const [openFileBrowser, setOpenFileBrowser] = useState<boolean>(false);
  const flags = useSelector(flagsSelector);
  const scheduleAPI = useContext<IScheduleAPIContext>(ScheduleAPIContext);
  const dispatch: Dispatch<any> = useDispatch();

  // suggested values for filters loaded from suggestions API
  const [fieldSuggestions, setFieldSuggestions] = useState<IFieldSuggestionDict>({});

  // for burst layout, list of fields from the distribution look that can also be used as suggestions
  // render a skeleton until initial values for the fields are set after loading the referenced distLook
  const [isFetchingDistributionListFieldsValues, setIsFetchingDistributionListFieldsValues] = useState<boolean>(props.schedule.schedule?.destinations[0]?.distribution_look_id ? true : false);

  const [item, setItem] = useState<ILook | IDashboard>(props.item);

  const [name, setName] = useState<string>(
    props.schedule.schedule?.name ? props.schedule.schedule?.name : ""
  );

  const firstDestination = props.schedule.schedule?.destinations?.[0];

  const [output, setOutput] = useState<ScheduleOutput>(
    // burst is always email, otherwise the first destination determines the output (email|sftp)
    firstDestination?.type as ScheduleOutput || ScheduleOutput.Email
  );
  const [emailError, setEmailError] = useState<{
    isError: boolean;
    message?: string;
  }>({ isError: false });

  const remapPNGFormatOnEditFlag = flags?.[getFlag(FFlags.RemapPNGFormatOnEdit)];
  const [dataFormat, setDataFormat] = useState<
    DataFormatEmail | "pdf" | "png" | ""
  >(calculateInitialDataFileFormat(firstDestination?.format as DataFormatEmail, props.type, props.layout, remapPNGFormatOnEditFlag));

  const [recurrence, setRecurrence] = useState<Recurrence>(
    (props.schedule.type as Recurrence)
      ? (props.schedule.type as Recurrence)
      : Recurrence.Daily
  );
  const [time, setTime] = useState<string>(flags?.[getFlag(FFlags.FormatHours24)] ? props.schedule.getTimeString() : props.schedule.getTimeString_deprecated());
  const [isBurst, setIsBurst] = useState<boolean>(
    // existing schedule is burst or new schedule is burst layout
    props.schedule.schedule?.type === "BURST" || props.layout === "burst"
  );
  const [until, setUntil] = useState<string>("Until");
  const [tz, setTz] = useState<string>(
    props.schedule.getTimezone()
      ? (props.schedule.getTimezone()?.link as string)
      : ""
  );
  const [limitTo, setLimitTo] = useState<LimitTo | "">(
    props.type == "look" && props.schedule.schedule?.send_all_results
      ? LimitTo.AllResults
      : LimitTo.ResultsInTable
  );
  const [send, setSend] = useState<SendScheduleOptions | "">(
    props.schedule.schedule?.require_results
      ? SendScheduleOptions.ContainsResult
      : props.schedule.schedule?.require_no_results
        ? SendScheduleOptions.ContainsNoResults
        : !props.schedule.schedule?.require_results &&
          !props.schedule.schedule?.require_no_results
          ? SendScheduleOptions.ContainsResultsOrNot
          : ""
  );
  const [paperSize, setPaper] = useState<string>(
    (props.schedule.schedule?.pdf_paper_size
      ? props.schedule.schedule?.pdf_paper_size
      : (PaperSizes[0]["FitToDashboard"] as string)) as string
  );
  const [paperOrientation, setOrientation] = useState<string>(
    (props.schedule.schedule?.pdf_landscape
      ? PaperOrientations.Landscape
      : PaperOrientations.Portrait)
  );
  const [weekday, setWeekday] = useState<number>(
    props.schedule.getDay() as number
  );

  const [monthDay, setMonthDay] = useState<number>(
    props.schedule.getMonthDay()
  );
  const [hoursInterval, setHoursInterval] = useState<number>(
    props.schedule.getHourDifference() as number
  );
  const [minutes, setMinutes] = useState<number>(
    props.schedule.getMinuteDifference() as number
  );
  const [months, setMonths] = useState<{ id: number; label: string }[]>(
    props.schedule.getMonthsObject()
  );
  const [days, setDays] = useState<{ id: number; label: string }[]>(
    props.schedule.getDaysObject()
  );
  const [fromTime, setFromTime] = useState<string>(
    props.schedule.getFromTime() as string
  );
  const [distType, setDistType] = useState<DistributionType | "">(
    firstDestination?.distribution_look_id
      ? DistributionType.Dynamic
      : DistributionType.Email
  );
  const [filters, setFilters] = useState<IFilter[]>(
    props.type === "dashboard"
      ? item.filters?.sort((f1, f2) => f1.title.localeCompare(f2.title))
      : item.filters?.sort((f1, f2) => f1.label.localeCompare(f2.label))
  );
  const [distLook, setDistLook] = useState<ILook | undefined>();
  const [toTime, setToTime] = useState<string>(
    props.schedule.getToTime() as string
  );
  const [emails, setEmails] = useState<string[]>(
    props.schedule.schedule
      ? props.schedule.schedule.destinations.map((d) =>
        d.address ? d.address : ""
      )
      : []
  );
  const [customMessage, setCustomMessage] = useState<string | "">(
    firstDestination?.message
      ? firstDestination?.message
      : ""
  );
  const [checkCustomMessage, setCheckCustomMessage] = useState<boolean>(
    firstDestination?.message ? true : false
  );
  const [applyViz, setApplyViz] = useState<boolean>(flags?.[getFlag(FFlags.DefaultAdvancedOpions)] && !firstDestination ? true :
    (firstDestination?.apply_vis ? true : false)
  );
  const [arrangeAsTiles, setArrangeAsTiles] = useState<boolean>(
    firstDestination?.format ===
      SupportedFormats.wysiwyg_pdf ||
      firstDestination?.format ===
      SupportedFormats.wysiwyg_png
      ? false
      : true
  );
  const [applyFormatting, setApplyformatting] = useState<boolean>(flags?.[getFlag(FFlags.DefaultAdvancedOpions)] && !firstDestination ? true :
    (firstDestination?.apply_formatting ? true : false)
  );
  const [incLinks, setIncLinks] = useState<boolean>(
    props.schedule?.schedule?.include_links ? true : false
  );
  const [expandTables, setExpandTables] = useState<boolean>(
    props.schedule?.schedule?.long_tables ? true : false
  );
  const [recGrid, setRecGrid] = useState<{
    sm;
    md;
    lg;
    xl;
  }>({ sm: 12, md: 12, lg: 12, xl: 12 });
  const clauseList = Object.values(IClause).map((v) => {
    return {
      [v.split(":string:")[0]]: v.split(":string:")[1]
        ? v.split(":string:")[1]
        : "",
    };
  });

  const [userAttributes, setUserAttributes] = useState([]);
  const [sftpParameters, setSftpParameters] = useState<SFTPParameters>(
    decodeDestinationParameters(props.schedule?.schedule?.destinations)
  );

  const distributionFields = props.distributionFields;
  const currentFilterExpressions = props.currentFilterExpressions;

  const fetchUserAttributes = async () => {
    /**
     * Some filters can access selected attributes of the current user
     */
    const res = await fetch("/app/user/attributes");
    const uattrs = await res.json();
    setUserAttributes(uattrs);
  };

  useEffect(() => {
    /**
     * run this at the start to set the filter expressions from the filter string (saved values)
     * or populate from the default values on the filter
     */
    const filter_string_obj = Object.fromEntries(
      new URLSearchParams(
        decodeURIComponent(
          props.schedule.schedule?.filters_string?.replace(/\+/g, " ") as string
        )
      )
    );

    // for each filter, the initial expression is either the value read from filter_string (i.e. saved) or
    // the default expression defined on the filter default expression from the config.  Store
    // the initial values (loaded or default) into currentFilterExpressions
    if (filters && filters.length > 0) {
      const initial_filter_expressions: { [key: string]: string } = filters.reduce((acc, f: IFilter) => {
        // note: at this point in time, we don't have labels for fields referenced from a distLook, so
        // this call will likely return expression in its raw form - hook replace it with a label later when
        // distLook fields are available with labels
        let expression = unformatDistLookExpressionForView(filter_string_obj[f.name], distributionFields);
        // we need to disambiguate between blank "", not set, and "use default expression"
        const isBlank = expression === "";
        if (isBlank) {
          acc[f.name] = expression;  // use blank
        } else {
          acc[f.name] = expression ? expression : f.default_expression;
        }
        return acc;
      }, {});

      props.setCurrentFilterExpressions(initial_filter_expressions);
    } else {
      // it shouldn't be empty, but possible if there's been a save error
      props.setCurrentFilterExpressions({});
    }
  }, []);

  useEffect(() => {
    /**
     * For each filter fetch the list of suggested values. These are concurrent API calls that pass through to Looker's
     * suggestion API
     * todo: it would be better if this was deferred to when a filter component receives focus for editing - that's what
     * looker does now
     */
    filters.forEach(async f => {

      if (f.field?.type === 'string') {
        // todo: there is also a boolean in the looker model to indicate whether suggestions are allowed.  i.e. suggestion can be disabled
        const suggestions = await scheduleAPI.getFieldSuggestions(props.item.model.id, (props.item as ILook).explore, f.name);

        //  merge into the dict in state, keyed by field name
        setFieldSuggestions(prevState => ({
          ...prevState,
          [f.name]: suggestions
        }));
      }
    });

  }, []);

  /**
   * The suggested values come from the state fieldSuggestions for the named filter, but if there's a
   * distribution look enabled we include the fields from the distribution look tool
   */
  const suggestedValuesForFilter = (filterName: string) => {

    let suggestions = fieldSuggestions[filterName] || [];

    if (distributionFields.length > 0) {
      const labels = distributionFields.map(df => df.label);

      const additionalSuggestions = [
        ...labels,
        " --- ",
        ...suggestions
      ];
      return additionalSuggestions;

    } else {
      return suggestions;
    }

  };

  const extractDistributionLookFields = (fields: IFilter[]) => {
    /**
     * When bursting, the value of a filter can reference a field in the distribution look
     * if it matches the format ${distribution_look.name} where name is the field name.
     *
     * This format is explicitly referenced in the backend when the reports run.
     *
     * This value is human-readable and shown in "suggestions" on the filters
     *
     */
    return fields?.map((f: IFilter) => {
      return {
        'name': f.name,
        'label': `${DISTLOOK_FIELD_MARKER}${f.label}`    // the MARKER is our prefix used on onFilterChange
      };
    });
  };

  /**
   * When a filter value references a field from the distrubtion look, we encoded it as
   * ${distribution_look.name} and the backend looks for this ${} patten in the filter expression
   *
   * @param fieldName
   */
  const formatDistributionFieldNameForExpression = (fieldName: string) => {
    return `$\{distribution_look.${fieldName}}`;
  };

  function isDistLookFilterExpression(expression: string) {
    return expression?.startsWith('${distribution_look.') && expression?.slice(-1) === '}';
  }

  /**
   * Reverse of formatDistributionFieldNameForExpression, strip the ${distribution_look.} to get the name,
   * and use the name to get the label used in the view
   * @param expression
   * @param distributionFields
   * @return label for use in view
   */
  const unformatDistLookExpressionForView = (expression: string, distributionFields: IDistributionField[]) => {
    if (isDistLookFilterExpression(expression)) {
      const name = expression.substring(expression.indexOf('.') + 1, expression.length - 1);
      const label = lookupDistributionLookFieldByName(name, distributionFields);
      return label || expression;
    }
    return expression;
  };

  /**
   * In the list of distribution fields, search for a field by label (including the MARKER) and return its
   * name in the format for encoding an expression
   * @param label
   * @return name formatted for expression
   */
  const lookupDistributionLookFieldByLabel = (label: string) => {
    if (distributionFields.length > 0) {
      const field: IDistributionField | null = distributionFields.reduce((acc: IDistributionField | null, distributionField: IDistributionField) => {
        if (distributionField.label === label) {
          return distributionField;
        }
        return acc;
      }, null);

      if (field) {
        return formatDistributionFieldNameForExpression(field.name);
      }
    }
  };

  /**
   * In the list of distribution fields, search for a field by name and return its label (including the MARKER) in
   * the format used in the view
   * @param name
   * @param distributionFields
   * @return label formatted for view
   */
  const lookupDistributionLookFieldByName = (name: string, distributionFields: IDistributionField[]) => {
    if (distributionFields.length > 0) {
      const field: IDistributionField | null = distributionFields.reduce((acc: IDistributionField | null, distributionField: IDistributionField) => {
        if (distributionField.name === name) {
          return distributionField;
        }
        return acc;
      }, null);

      if (field) {
        return field.label;
      }
    }
  };

  /**
   * if a filter expression is a "${distribution_look.name}" reference then we don't have its
   * label yet.  Substitute in the labels from newDistributionFields
   */
  const updateFiltersReferencingDistributionFields = (newDistributionFields: IDistributionField[]) => {
    if (isBurst) {
      filters.forEach(f => {
        const expression = currentFilterExpressions[f.name];
        if (isDistLookFilterExpression(expression)) {
          // substitute the expression with the label. This is a state change
          let new_fo = { ...currentFilterExpressions, [f.name]: unformatDistLookExpressionForView(expression, newDistributionFields) };
          props.setCurrentFilterExpressions(new_fo);
        }
      });
    }
  };

  const mapDataFormatForLooker = (dataFormat: string): string => {
    /**
     * Map the data format specializations to the dataFormat value expected by looker by combining
     * some of the UI options.  Email supports more options than SFTP
     */
    let dataFormatForLooker = dataFormat === "pdf"
      ? arrangeAsTiles
        ? SupportedFormats.assembled_pdf
        : SupportedFormats.wysiwyg_pdf
      : dataFormat === "png"
        ? arrangeAsTiles
          ? ExtraFormats.inline_visualizations
          : SupportedFormats.wysiwyg_png
        : dataFormat;

    return dataFormatForLooker;
  };

  useEffect(() => {
    if (!isFetchingDistributionListFieldsValues && props.setFormReady) {
      props.setFormReady(true);
    }
  }, []);

  useEffect(() => {
    /**
     * If a Distribution Look is referenced as the source of the distribution list, we allow fields from the
     * distribution look to be referenced in the filter values.
     *
     * When the distribution look changes, make the distribution looks fields available in distributionFields
     */
    if (distLook?.id) {
      fetch(`/app/looks/${distLook.id}/info?include_fields=true`).then(
        async (r) => {
          let body = (await r.json()) as ILook;

          const newDistributionFields = extractDistributionLookFields(body.fields);
          setIsFetchingDistributionListFieldsValues(true);
          props.setDistributionFields(newDistributionFields);
          // note - fields in the distribution look are merged with field suggestions.

          // tricky, when we load an existing schedule, if a filter value is a reference to a
          // distlook field, then we need to substitute it with the field's label, but we don't have
          // field labels until this data arrive.  (It's a pity label is not available at load time)
          updateFiltersReferencingDistributionFields(newDistributionFields);
          setIsFetchingDistributionListFieldsValues(false);
          props.setFormReady && props.setFormReady(true);
        }
      );
    } else {
      props.setDistributionFields([]);
    }
  }, [distLook]);

  useEffect(() => {
    /**
     * If a Distribution Look is referenced as the source of the distribution list, we allow fields from the
     * distribution look to be referenced in the filter values.
     *
     * Fetch the fields of the distribution look, and make available in distributionFields
     */
    let distLookId = firstDestination?.distribution_look_id;
    if (distLookId)
      fetch(
        `/app/looks/${distLookId}/info?include_fields=true`
      ).then(async (r) => {
        let body = (await r.json()) as ILook;

        setDistLook(body);
      });
  }, []);

  /**
   * Prior to saving, reformat the filterExpressions (filter_string).
   * The main change is to resolve cross-references to the distribution look when bursting is in use,
   * which is to replace the DISTLOOK_FIELD_MARKER with ${...}
   */
  function reformatFilterExpressionsForPost() {
    if (isBurst) {
      // if the filter expression values contain cross-references to fields in the distribution look, as
      // indicated by the MARKER prefix, reformat these now to expressions expected by the back-end.
      return filters.reduce((acc, f: IFilter) => {
        const expression = currentFilterExpressions[f.name];
        if (expression?.startsWith(DISTLOOK_FIELD_MARKER)) {
          // this may be a cross-reference to a field from the distribution look - look up the label exact match
          // and return the name
          const distributionLookFieldName = lookupDistributionLookFieldByLabel(expression);
          if (distributionLookFieldName) {
            // use the reformatted expression
            acc[f.name] = distributionLookFieldName;
          } else {
            acc[f.name] = expression;
          }
        } else {
          acc[f.name] = expression;
        }

        return acc;
      }, {});
    } else {
      return currentFilterExpressions;
    }
  }

  //hacky but we need to save this by clicking save...
  /**
   * This is the main hander that prepares the model used later on save
   */
  useEffect(() => {
    try {
      /// we need to turn the fields back into a cron exp
      var cronInstance = new Cron();

      let fields: {
        parts: [
          { options; unit: { name; min; max }; values: number[] },
          { options; unit: { name; min; max }; values: number[] },
          { options; unit: { name; min; max }; values: number[] },
          { options; unit: { name; min; max }; values: number[] },
          { options; unit: { name; min; max }; values: number[] }
        ];
      } = cronInstance.fromString("* * * * *"); ///start out with a blank slate so we can fill all the defaults

      // Split a few times
      let splitTime = time.split(":");
      let splitFromTime = fromTime.split(":");
      let splitToTime = toTime.split(":");

      // each recurrence type requires different logic
      if (recurrence === Recurrence.Daily) {
        fields.parts[0].values = [parseInt(splitTime[1])];
        fields.parts[1].values = [parseInt(splitTime[0])];
      } else if (recurrence === Recurrence.Weekly) {
        fields.parts[0].values = [parseInt(splitTime[1])];
        fields.parts[1].values = [parseInt(splitTime[0])];
        fields.parts[4].values = [weekday as number];
      } else if (recurrence === Recurrence.Monthly) {
        fields.parts[0].values = [parseInt(splitTime[1])];
        fields.parts[1].values = [parseInt(splitTime[0])];
        fields.parts[2].values = [monthDay];
      } else if (recurrence === Recurrence.Hourly) {
        if (flags?.[getFlag(FFlags.PreserveMinuteValueForHourlySchedule)]) {
          const startMinute: number[] = [parseInt(splitFromTime[1])];
          fields.parts[0].values = startMinute;
          const startHour: number = parseInt(splitFromTime[0]);

          /**
           * if end minute is after starting minute, include the end hour
           *  i.e. hourly 4:05 to 12:30 (last report sent at 12:05)
           * if end minute is before starting minute, decrement end hour
           *  i.e. hourly 4:05 to 12:00 (last report sent at 11:05 - don't want sent at 12:05)  
           */
          const endHour: number = parseInt(splitToTime[1]) >= parseInt(splitFromTime[1]) ? parseInt(splitToTime[0]) : parseInt(splitToTime[0]) - 1;
          const hrsRange = Scheduling.findIncrements(startHour, endHour, hoursInterval);

          fields.parts[1].values = hrsRange;
        } else {
          fields.parts[0].values = [parseInt(splitTime[1])];
          fields.parts[1].values = Scheduling.findIncrements(
            parseInt(splitFromTime[0]),
            parseInt(splitToTime[1]) >= parseInt(splitFromTime[0])
              ? parseInt(splitToTime[0])
              : parseInt(splitToTime[0]) - 1,
            hoursInterval
          );
        }
      } else if (recurrence === Recurrence.Minutely) {
        // same reasoning as hourly
        fields.parts[0].values = Scheduling.findIncrements(0, 59, minutes);
        fields.parts[1].values = Scheduling.findIncrements(
          parseInt(splitFromTime[0]),
          parseInt(splitToTime[0]),
          1
        );
      } else if (recurrence === Recurrence.SpecificMonths) {
        //we have a list of selected months to iterate
        fields.parts[0].values = [parseInt(splitTime[1])];
        fields.parts[1].values = [parseInt(splitTime[0])];
        fields.parts[2].values = [monthDay];
        fields.parts[3].values = months.map((m) => m.id + 1);
      } else if (recurrence === Recurrence.SpecificDays) {
        //same as above
        fields.parts[0].values = [parseInt(splitTime[1])];
        fields.parts[1].values = [parseInt(splitTime[0])];
        fields.parts[4].values = days.map((d) => d.id);
      }

      let cronStr: string = "";
      try {
        cronStr = cronInstance.toString();
      } catch (e) {
        console.error(e);
      }

      // turn it back into cron exp
      let timezone = Timezones.find((f) => f.link === tz);
      //populate the body

      // serialize the additional destination parameters
      const serializedDestinationParameters: string = sftpParameters.encode();

      // prepare the list of destinations in Looker's format
      let destinations_body: IReportDestinationDefinition[] = [];

      if (isBurst) {
        destinations_body = [{
          distribution_look_id: distLook?.id,
          apply_formatting: applyFormatting,
          apply_vis: applyViz,
          type: "email",
          message: customMessage,
          format: mapDataFormatForLooker(dataFormat)
        }
        ];
      } else {
        if (flags?.[getFlag(FFlags.SaveEditSftpSchedules)] && output === ScheduleOutput.SFTP) {
          destinations_body = [{
            address: sftpParameters.address,
            parameters: serializedDestinationParameters,
            apply_formatting: applyFormatting,
            apply_vis: applyViz,
            type: "sftp",
            format: mapDataFormatForLooker(dataFormat)
          }];
        } else if (isSFTPDistribution(props.schedule.schedule)) {
          destinations_body = [{
            address: sftpParameters.address,
            parameters: serializedDestinationParameters,
            apply_formatting: applyFormatting,
            apply_vis: applyViz,
            type: "sftp",
            format: mapDataFormatForLooker(dataFormat)
          }
          ];
        } else {
          if (emails && emails.length > 0) {
            destinations_body = emails.map((email: string) => {
              let destination: IReportDestinationDefinition = {
                address: email,
                apply_formatting: applyFormatting,
                apply_vis: applyViz,
                type: "email",
                message: customMessage,
                format: mapDataFormatForLooker(dataFormat)
              };

              return destination;
            });
          }
        }
      }

      const reformattedFilterExpressions = reformatFilterExpressionsForPost();

      let body: IReportScheduleDefinition = {
        name: name,
        enabled: true,
        type: isBurst ? "BURST" : "DEFAULT",
        crontab: cronStr,
        filters_string: "?" + new URLSearchParams(reformattedFilterExpressions).toString(),
        timezone: timezone ? timezone.link : "Etc/GMT-0", //default to UTC
        destinations: destinations_body
      };

      //dashboard and look specific fields
      if (props.item.id) {
        if (props.type === "dashboard") {
          body.dashboard_id = props.item.id;
          // Fit to Dashboard is the null option
          body.pdf_paper_size = paperSize === PaperSizes[0]["FitToDashboard"] ? null : paperSize;
          body.pdf_landscape = paperOrientation === PaperOrientations.Landscape;
        } else {
          body.look_id = props.item.id;
          body.require_results = send === SendScheduleOptions.ContainsResult;
          body.require_no_results = send === SendScheduleOptions.ContainsNoResults;
          body.require_change = false;
        }
      }

      try {
        let edited: Scheduling = Object.assign(props.schedule);
        body.id = props.schedule.schedule?.id;

        edited.schedule = body;

        // check if the schedule is valid using the new schema validation library (ajv)
        // modalContext.setErrors([]);
        props.setSchedule(edited);
      } catch (e) {
        console.error(e);
      }
    } catch (e) {
      console.error(e);
    }
  }, [
    time,
    tz,
    fromTime,
    toTime,
    recurrence,
    weekday,
    monthDay,
    hoursInterval,
    minutes,
    months,
    days,
    name,
    isBurst,
    distLook,
    applyFormatting,
    applyViz,
    customMessage,
    dataFormat,
    arrangeAsTiles,
    emails,
    props.item,
    props.type,
    send,
    currentFilterExpressions,
    sftpParameters,
    paperSize,
    paperOrientation,
  ]);

  // change the grid spacing
  useEffect(() => {
    switch (recurrence) {
      case Recurrence.Daily:
        setRecGrid({ sm: 6, md: 6, lg: 6, xl: 6 });
        break;

      case Recurrence.Weekly:
        setRecGrid({ sm: 4, md: 4, lg: 4, xl: 4 });
        break;

      case Recurrence.Monthly:
        setRecGrid({ sm: 4, md: 4, lg: 4, xl: 4 });
        break;

      case Recurrence.Quarterly:
        setRecGrid({ sm: 6, md: 6, lg: 6, xl: 6 });
        break;

      case Recurrence.Hourly:
        setRecGrid({ sm: 3, md: 3, lg: 3, xl: 3 });
        break;

      case Recurrence.Minutely:
        setRecGrid({ sm: 3, md: 3, lg: 3, xl: 3 });
        break;

      case Recurrence.SpecificMonths:
        setRecGrid({ sm: 4, md: 4, lg: 4, xl: 4 });
        break;

      case Recurrence.SpecificDays:
        setRecGrid({ sm: 6, md: 6, lg: 6, xl: 6 });
        break;

      default:
        break;
    }
    return () => { };
  }, [recurrence]);

  /**
   * When a filter changes, recalculate the currentFilterExpression
   * @param filter
   * @param v
   */
  const handleFilterChange = (filter: IFilter, v: FilterChangeProps) => {
    let new_fo = { ...currentFilterExpressions, [filter.name]: v.expression };
    if (currentFilterExpressions[filter.name] != v.expression) {
      props.setCurrentFilterExpressions(new_fo);
    }
  };

  const classes = useStyles();


  return (
    <>
      {!!props.formErrors?.find((e) => e.key === "general") && (
        <>
          <Alert severity={"error"}>{props.formErrors?.find((e) => e.key === "general")?.message}</Alert>
          <Spacer size={16} direction="vertical" />
        </>
      )}
      <Grid className={classes.formContainer} sm={12} md={12} lg={12} xl={12} container>
        {item && (
          <>
            <Spacer size={8} direction="vertical" />
            <UncontrolledScheduleNameInput
              name={name}
              errors={props.formErrors}
              onUpdate={val => {
                props.scheduleNameInputRef.current = val;
              }}
              className={classes.field}
            />
            {!isBurst && (
              <>
                {/*  Burst is always email, so don't show output */}
                <Spacer size={16} direction="vertical" />
                <SelectField
                  label={"Destination"}
                  selectList={ScheduleOutput}
                  useKeysAsLabel
                  val={output}
                  onChange={setOutput}
                  fullWidth
                />
              </>)}
            <Spacer size={16} direction="vertical" />
            <SelectField
              label={"Data File Format"}
              displayEmpty
              useKeysAsLabel
              selectList={
                output == ScheduleOutput.Email
                  ? props.type == "dashboard" ? DataFormatEmailDashboardList : DataFormatEmailLookList
                  :
                  output == ScheduleOutput.SFTP
                    ? props.type == "dashboard" ? DataFormatSFTPDashboardList : DataFormatSFTPLookList
                    : {}
              }
              val={dataFormat}
              onChange={(v) => {
                setDataFormat(v);
              }}
              fullWidth
            />

            <>
              <Spacer size={16} direction="vertical" />
              <Grid item sm={12} md={12} lg={12} xl={12}>
                <Spacer size={40} direction="vertical" />
                {output === ScheduleOutput.SFTP && (
                  <SFTPOptionsForm sftpParameters={sftpParameters}
                    setSftpParameters={setSftpParameters}
                    formErrors={props.formErrors} />
                )}
                {output === ScheduleOutput.Email && !isBurst && (
                  <>
                    <Grid data-qa="emailDistributionSection" container direction="column">
                      <Grid item data-qa="emailDistribution" >
                        <Typography variant="subtitle1">
                          Email Distribution
                        </Typography>
                        <Spacer size={16} direction="vertical" />
                        {/* start email distribution section - fixed list of emails */}
                        <UncontrolledEmailInput
                          errors={props.formErrors}
                          onUpdate={val => {
                            props.emailInputRef.current = val;
                          }}
                          className={classes.field}
                          emailError={emailError}
                          setEmails={setEmails}
                          setEmailError={setEmailError}
                          emails={emails}
                        />
                        <Spacer size={16} direction="vertical" />
                        {emails?.map((e) => (
                          <Chip
                            key={v4()}
                            label={e}
                            onDelete={() => {
                              setEmails([...emails.filter((el) => el !== e)]);
                            }}
                            style={{
                              marginTop: "8px",
                              marginRight: "8px",
                            }}
                          />
                        ))}
                        {/* end email distribution section */}
                      </Grid>
                      <Grid data-qa="customMessageSection" item>
                        <Grid container direction="row" alignItems="center">
                          <Checkbox
                            edge="start"
                            checked={checkCustomMessage}
                            onChange={(e, checked: boolean) => {
                              setCheckCustomMessage(checked);
                            }}
                          />
                          <Typography>Custom Message</Typography>
                        </Grid>
                        {checkCustomMessage && (
                          <Grid item style={{ flexGrow: 1 }}>
                            <Spacer size={8} direction="vertical" />
                            <TextField
                              variant="outlined"
                              fullWidth
                              multiline
                              rows={4}
                              value={customMessage}
                              className={classes.field}
                              onChange={(e: any) =>
                                setCustomMessage(e.target.value)
                              }
                              placeholder="Custom Message"
                            ></TextField>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Spacer size={40} direction="vertical" />
                  </>
                )}

                {output === ScheduleOutput.Email && isBurst && (
                  <>
                    {/* When bursting, need to show control to select a distribution look */}
                    <Grid data-qa="emailDistributionSection" container direction="column">
                      <Grid item data-qa="emailDistribution" >
                        <Tooltip title={"Select a report that lists potential recipients. Fields in this report can be used in the Filters below"}>
                          <Typography variant="subtitle1">
                            Distribution List
                          </Typography>
                        </Tooltip>
                        <Typography variant="caption">
                          A report that produces a list of emails and fields to reference in the filters below.
                        </Typography>
                        <Spacer size={16} direction="vertical" />
                        {/* start email distribution section */}
                        {isFetchingDistributionListFieldsValues ? <Skeleton /> : <TextField
                          label="Distribution List"
                          fullWidth
                          required
                          error={
                            props.formErrors?.find((e) =>
                              e.key === "distribution_look_id"
                            )
                              ? true
                              : false
                          }
                          {...(flags?.[getFlag(FFlags.HasDistributionListSelected)] && ({
                            helperText:
                              props.formErrors?.find((e) => e.key === "distribution_look_id")?.message
                          }))}
                          value={distLook ? distLook.title : ''}
                          // onChange={(e) => setDistLook(e.currentTarget.value)}
                          className={classes.field}
                          endAdornment={
                            <IconButton
                              onClick={() => setOpenFileBrowser(true)}
                              size="small"
                            >
                              <Icon iconUrl={<Book />} />
                            </IconButton>
                          }
                        />
                        }

                        {/* show chips for the fields in the selected distribution look */}
                        {isFetchingDistributionListFieldsValues ? <Skeleton /> : distributionFields?.length > 0 && (
                          <>
                            <Typography variant="caption">
                              Fields available for cross-reference:
                            </Typography>
                            <Spacer size={8} direction="vertical" />
                            {distributionFields?.map((f) => (
                              <Chip
                                key={v4()}
                                label={f.label}
                                size={"small"}
                                style={{
                                  marginTop: "8px",
                                  marginRight: "8px",
                                }}
                              />
                            ))}
                          </>
                        )}

                        <Spacer size={16} direction="vertical" />
                        {openFileBrowser && (
                          <FileBrowserModal
                            title={"Select a Distribution List"}
                            includeLooks
                            isOpen={openFileBrowser}
                            setIsOpen={setOpenFileBrowser}
                            setSelectedLook={setDistLook}
                            selectedLook={distLook}
                            okButtonText="Confirm"
                            cancelButtonText="Cancel"
                          />
                        )}
                        {/* end email distribution section */}
                      </Grid>
                      <Grid data-qa="customMessageSection" item>
                        <Grid container direction="row" alignItems="center">
                          <Checkbox
                            edge="start"
                            checked={checkCustomMessage}
                            onChange={(e, checked: boolean) => {
                              setCheckCustomMessage(checked);
                            }}
                          />
                          <Typography>Custom Message</Typography>
                        </Grid>
                        {checkCustomMessage && (
                          <Grid item style={{ flexGrow: 1 }}>
                            <Spacer size={8} direction="vertical" />
                            <TextField
                              variant="outlined"
                              fullWidth
                              multiline
                              rows={4}
                              value={customMessage}
                              className={classes.field}
                              onChange={(e: any) =>
                                setCustomMessage(e.target.value)
                              }
                              placeholder="Custom Message"
                            ></TextField>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Spacer size={40} direction="vertical" />
                  </>
                )}

                <Typography variant="subtitle1">Delivery Schedule</Typography>
                {props.formErrors?.find((e) =>
                  e.key === "crontab"
                ) && (
                    <Typography variant="caption" color="error">
                      {props.formErrors?.filter(e => e.key === "crontab")[0].message}
                    </Typography>
                  )}

                <Spacer size={16} direction="vertical" />

                <Grid container spacing={1}>
                  <Grid item {...recGrid}>
                    <SelectField
                      style={{ minWidth: "0" }}
                      fullWidth
                      val={recurrence}
                      onChange={setRecurrence}
                      selectList={
                        DeliverySchedule as { [key: string]: string | number }[]
                      }
                      label={"Recurrence"}
                    />
                  </Grid>

                  {[
                    RecurrenceCalendar.Weekly,
                    RecurrenceCalendar.Monthly,
                    DeliverySchedule[2]["SpecificMonths"],
                  ].includes(recurrence as Recurrence) && (
                      <Grid item {...recGrid}>
                        {recurrence === Recurrence.Weekly && (
                          <SelectField
                            style={{ minWidth: "0" }}
                            fullWidth
                            val={weekday}
                            onChange={setWeekday}
                            selectList={Weekdays}
                            useKeysAsLabel
                            label={"On"}
                          />
                        )}
                        {[
                          RecurrenceCalendar.Monthly,
                          DeliverySchedule[2]["SpecificMonths"],
                        ].includes(recurrence) && (
                            <SelectField
                              style={{ minWidth: "0" }}
                              fullWidth
                              val={monthDay}
                              onChange={setMonthDay}
                              selectList={MonthDay}
                              useKeysAsLabel
                              noSeparators
                              label={"On"}
                              error={
                                !isValidMonthsAndDayCombo(
                                  months.map((m) => m.label),
                                  monthDay
                                )
                              }
                              helperText={
                                !isValidMonthsAndDayCombo(
                                  months.map((m) => m.label),
                                  monthDay
                                )
                                  ? "Please select a valid day for the selected months."
                                  : ""
                              }
                            />
                          )}
                      </Grid>
                    )}
                  {[
                    ...Object.keys(RecurrenceCalendar),
                    DeliverySchedule[2]["SpecificMonths"],
                    DeliverySchedule[2]["SpecificDays"],
                  ].includes(recurrence) && (
                      <Grid item {...recGrid}>
                        <TextField
                          fullWidth
                          value={time}
                          onChange={(e) => setTime(e.target.value)}
                          type="time"
                          label={"At"}
                        />
                      </Grid>
                    )}
                  {Object.keys(RecurrenceTime).includes(recurrence) && (
                    <Grid item {...recGrid}>
                      {recurrence === Recurrence.Hourly && (
                        <SelectField
                          style={{ minWidth: "0" }}
                          fullWidth
                          val={hoursInterval}
                          onChange={setHoursInterval}
                          selectList={Hours}
                          useKeysAsLabel
                          noSeparators
                          label={"Every"}
                        />
                      )}
                      {recurrence === Recurrence.Minutely && (
                        <SelectField
                          style={{ minWidth: "0" }}
                          fullWidth
                          val={minutes}
                          onChange={setMinutes}
                          selectList={Minutes}
                          useKeysAsLabel
                          noSeparators
                          label={"Every"}
                        />
                      )}
                    </Grid>
                  )}
                  {Object.keys(RecurrenceTime).includes(recurrence) && (
                    <Grid item {...recGrid}>
                      <TextField
                        fullWidth
                        value={fromTime}
                        onChange={(e) => setFromTime(e.target.value)}
                        type="time"
                        error={fromTime > toTime}
                        helperText={
                          fromTime > toTime
                            ? "From should be earlier than To"
                            : null
                        }
                        label={"From"}
                      />
                      {/* <FieldTimeSelect
                      defaultValue={fromTime}
                      onChange={(val as string) => setFromTime(val as string)}
                    /> */}
                    </Grid>
                  )}
                  {Object.keys(RecurrenceTime).includes(recurrence) && (
                    <Grid item {...recGrid}>
                      <TextField
                        fullWidth
                        value={toTime}
                        onChange={(e) => setToTime(e.target.value)}
                        type="time"
                        label={"To"}
                        error={toTime < fromTime}
                        helperText={
                          fromTime > toTime
                            ? "To should be later than From"
                            : null
                        }
                      />
                    </Grid>
                  )}

                  <Spacer size={8} direction="vertical" />

                  <Grid item sm={12} md={12} lg={12} xl={12}>
                    {[
                      DeliverySchedule[2]["SpecificMonths"],
                      DeliverySchedule[2]["SpecificDays"],
                    ].includes(recurrence) && (
                        <>
                          {recurrence === DeliverySchedule[2]["SpecificMonths"] && (
                            <MonthSelector selected={months} onChange={setMonths} />
                          )}
                          {recurrence === DeliverySchedule[2]["SpecificDays"] && (
                            <DaySelector selected={days} onChange={setDays} />
                          )}
                          <Spacer size={16} direction="vertical" />
                        </>
                      )}
                    {DeliverySchedule[0]["SendNow"] !== recurrence && recurrence && (
                      <>
                        {false && (
                          <TextField
                            value={until}
                            onChange={(e) => setUntil(e.target.value)}
                            fullWidth
                            type="date"
                            label="Until"
                          />
                        )}

                        <TimezoneSelectField
                          fullWidth
                          noSeparators
                          val={tz}
                          onChange={setTz}
                        />
                      </>
                    )}
                  </Grid>
                </Grid>
                {currentFilterExpressions && filters && filters.length > 0 && (
                  <Grid container direction="column" data-qa="filters_section">
                    <Spacer size={40} direction="vertical" />
                    <Grid item>
                      <Typography variant="subtitle1">Filters ({filters.length})</Typography>
                      {isBurst && (
                        <Typography variant="caption">
                          Reference fields in the distribution list starting with {DISTLOOK_FIELD_MARKER}
                        </Typography>
                      )}
                      <Spacer size={16} direction="vertical" />

                      {filters?.map((f: IFilter, idx: number) => {
                        return (
                          <Grid key={f.name} item direction="row">
                            <Grid container direction="column" spacing={1}>
                              <Grid item sm={12} md={12} lg={12} xl={12}>
                                <Typography variant={"overline"}>
                                  {props.type === "dashboard" ? f.title : f.label}
                                </Typography>
                              </Grid>
                              <Grid
                                data-qa="filterGroup"
                                item
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                zeroMinWidth
                              >
                                {flags?.[getFlag(FFlags.DisableSuggestionsForDynamicFilters)] ? <Filter
                                  name={f.name}
                                  onChange={(v: FilterChangeProps) =>
                                    handleFilterChange(f, v)
                                  }
                                  // kick off async load of user attributes
                                  loadUserAttributes={fetchUserAttributes}
                                  userAttributes={userAttributes}

                                  // type and field determine the type of component that will be displayed.
                                  // These are defined by the Field
                                  type={getExpressionTypeFromField(f.field)}
                                  field={f.field}

                                  // config determines how it will render and behave.  This is defined by the Filter.
                                  config={f.filter_config}

                                  // expression determines its initial value.  This is calculated
                                  expression={currentFilterExpressions[f.name]}

                                  inline
                                  // skipFilterConfigCheck

                                  // conditional prop `suggestions, are loaded asynchronously
                                  // suggestions will not populate for dynamic fields (filters with custom values)
                                  {...(!f.is_dynamic_field && ({ suggestions: suggestedValuesForFilter(f.name) }))}
                                /> :
                                  <Filter
                                    name={f.name}
                                    onChange={(v: FilterChangeProps) =>
                                      handleFilterChange(f, v)
                                    }
                                    loadUserAttributes={fetchUserAttributes}
                                    userAttributes={userAttributes}

                                    // type and field determine the type of component that will be displayed.
                                    // These are defined by the Field
                                    type={getExpressionTypeFromField(f.field)}
                                    field={f.field}

                                    // config determines how it will render and behave.  This is defined by the Filter.
                                    config={f.filter_config}

                                    // expression determines its initial value.  This is calculated
                                    expression={currentFilterExpressions[f.name]}

                                    inline
                                    // skipFilterConfigCheck

                                    // suggestions are loaded asynchronously
                                    suggestions={suggestedValuesForFilter(f.name)}
                                  />
                                }
                              </Grid>
                            </Grid>
                            {idx !== filters.length - 1 && (
                              <Spacer size={16} direction="vertical" />
                            )}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                )}
                <Grid container direction="column">
                  <Spacer size={40} direction="vertical" />
                  <Grid item>
                    <Typography variant="subtitle1">Advanced Options</Typography>
                    {!flags?.[getFlag(FFlags.DefaultAdvancedOpions)] && <Spacer size={16} direction="vertical" />}
                    {!flags?.[getFlag(FFlags.DefaultAdvancedOpions)] && !(
                      [SupportedFormats.csv_zip, "pdf", "png"] as string[]
                    ).includes(dataFormat) && (
                        <>
                          <SelectField
                            fullWidth
                            val={send}
                            onChange={setSend}
                            selectList={SendScheduleOptions}
                            label={"Send Schedule"}
                          />
                        </>
                      )}
                    {props.type === "look" ||
                      ((["pdf"] as string[]).includes(dataFormat) && (
                        <>
                          <Spacer size={16} direction="vertical" />
                          <SelectField
                            fullWidth
                            val={limitTo}
                            onChange={setLimitTo}
                            selectList={LimitTo}
                            label={"Limit To..."}
                          />
                        </>
                      ))}
                    {props.type === "dashboard" &&
                      !([SupportedFormats.csv_zip, "png"] as string[]).includes(
                        dataFormat
                      ) && (
                        <>
                          <Spacer size={16} direction="vertical" />
                          <SelectField
                            fullWidth
                            val={paperSize}
                            onChange={setPaper}
                            useKeysAsLabel
                            selectList={PaperSizes as { [key: string]: string }[]}
                            label={"Paper Size"}
                          />
                          <Spacer size={16} direction="vertical" />
                        </>
                      )}

                    {flags?.[getFlag(FFlags.EnableOrientationInScheduleForm)] &&
                      props.type === "dashboard" &&
                      !([SupportedFormats.csv_zip, "png"] as string[]).includes(
                        dataFormat
                      ) &&
                      paperSize != (PaperSizes[0]["FitToDashboard"] as string) &&
                      (
                        <>
                          <SelectField
                            fullWidth
                            val={paperOrientation}
                            onChange={setOrientation}
                            useKeysAsLabel
                            selectList={PaperOrientations}
                            label={"Orientation"}
                          />
                          <Spacer size={16} direction="vertical" />
                        </>
                      )}

                    {props.type === "look" && (
                      <>
                        <Grid container sm={12}>
                          <Spacer size={16} direction="vertical" />
                          <Grid item sm={1}>
                            <Checkbox
                              edge="start"
                              checked={applyViz}
                              onChange={(e, checked) => setApplyViz(checked)}
                            />
                          </Grid>
                          <Grid item sm={11}>
                            <Tooltip title={"Apply row numbers, totals, full field names, custom labels etc."}>
                              <Typography>Apply Visualizations</Typography>
                            </Tooltip>
                            <Typography color="textSecondary" variant="subtitle2">
                              Format visual as a clean data table
                            </Typography>
                          </Grid>
                        </Grid>
                        <Spacer size={16} direction="vertical" />
                        <Grid container sm={12}>
                          <Grid item sm={1}>
                            <Checkbox
                              edge="start"
                              checked={applyFormatting}
                              onChange={(e, checked) =>
                                setApplyformatting(checked)
                              }
                            />
                          </Grid>
                          <Grid item sm={11}>
                            <Tooltip title={"Apply formatting to values. e.g. $22,200 instead of 22200 etc"}>
                              <Typography>Format Data Values</Typography>
                            </Tooltip>
                            <Typography color="textSecondary" variant="subtitle2">
                              Data is formatted similarly to Explore view
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    )}

                    {props.type === "dashboard" && (
                      <>
                        {dataFormat == SupportedFormats.csv_zip && (
                          <>
                            <Grid container>
                              <Grid item>
                                <Checkbox
                                  edge="start"
                                  checked={applyViz}
                                  onChange={(e, checked) => setApplyViz(checked)}
                                />
                              </Grid>
                              <Grid item>
                                <Tooltip title={"Apply row numbers, totals, full field names, custom labels etc."}>
                                  <Typography>Apply Visualizations</Typography>
                                </Tooltip>
                                <Typography
                                  color="textSecondary"
                                  variant="subtitle2"
                                >
                                  Format visual as a clean data table
                                </Typography>
                              </Grid>
                            </Grid>
                            <Spacer size={16} direction="vertical" />
                            <Grid container sm={12}>
                              <Grid item sm={1}>
                                <Checkbox
                                  edge="start"
                                  checked={applyFormatting}
                                  onChange={(e, checked) =>
                                    setApplyformatting(checked)
                                  }
                                />
                              </Grid>
                              <Grid item sm={11}>
                                <Tooltip title={"Apply formatting to values. e.g. $22,200 instead of 22200 etc"}>
                                  <Typography>Format Data Values</Typography>
                                </Tooltip>
                                <Typography color="textSecondary" variant="subtitle2">
                                  Data is formatted similarly to Explore view
                                </Typography>
                              </Grid>
                            </Grid>
                          </>
                        )}

                        {dataFormat == "png" && (
                          <>
                            <Grid container>
                              <Grid item>
                                <Checkbox
                                  edge="start"
                                  checked={arrangeAsTiles}
                                  onChange={(e, checked) => {
                                    setArrangeAsTiles(checked);
                                  }}
                                />
                              </Grid>
                              <Grid item>
                                <Typography>Arrange as Tiles</Typography>
                                <Typography
                                  color="textSecondary"
                                  variant="subtitle2"
                                >
                                  Display dashboard as a single column of tiles
                                </Typography>
                              </Grid>
                            </Grid>
                          </>
                        )}

                        {dataFormat == "pdf" && (
                          <>
                            <Grid container>
                              <Grid item>
                                <Checkbox
                                  edge="start"
                                  checked={arrangeAsTiles}
                                  onChange={(e, checked) => {
                                    setArrangeAsTiles(checked);
                                  }}
                                />
                              </Grid>
                              <Grid item>
                                <Typography>Arrange as Tiles</Typography>
                                <Typography
                                  color="textSecondary"
                                  variant="subtitle2"
                                >
                                  Display dashboard as a single column of tiles
                                </Typography>
                              </Grid>
                            </Grid>
                          </>
                        )}

                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </>
          </>
        )}
      </Grid>
    </>
  );
};

export const ScheduleFormFooter = (props: ScheduleFormFooterProps) => {
  const scheduleAPI = useContext<IScheduleAPIContext>(ScheduleAPIContext);
  const [isFetching, setIsFetching] = useState(false);
  const styles = useStyles();
  const flags = useSelector(flagsSelector);

  const reportAPIError = (e) => {
    props.setFormErrors([{
      'key': 'general',
      'message': 'The API reported an error response'
    }]);
    console.log('Schedule API error', e);
  };

  const handleSave = () => {
    let schedule;
    if (flags?.[getFlag(FFlags.PreventMultipleBurstCreation)]) {
      schedule = { ...props.schedule?.schedule };
    } else {
      schedule = props.schedule?.schedule;
    }

    if (schedule) {
      schedule.name = props.scheduleNameInputRef.current || schedule.name;
    }

    // check if the schedule is valid using the new schema validation library (ajv)
    // let isValid = SReportScheduleDefinitionValidator(props.schedule.schedule);
    let errors = ScheduleValidator.validate(flags?.[getFlag(FFlags.PreventMultipleBurstCreation)] ? schedule : props.schedule.schedule);

    let isValid = errors.length === 0;
    // if valid call the backend api
    if (isValid) {
      if (flags?.[getFlag(FFlags.WarnDistributionFieldsNotMappedToFilters)]) {
        if (schedule.type === "DEFAULT") {
          doSave();
        } else if (schedule.type === 'BURST') {
          // check if at least one field from distribution look is mapped to a filter
          const hasFieldMappedToFilter = () => {
            const fieldLabels: string[] = props.distributionFields.map(f => f.label);

            return fieldLabels.filter(label => {
              const values = Object.values(props.currentFilterExpressions);
              return values.find(val => val.indexOf(label) > -1);
            }).length > 0;
          };
          if (hasFieldMappedToFilter()) {
            doSave();
          } else {
            props.setShowWarningModal(true);
          }
        }
      } else {
        doSave();
      }
    } else {
      if (!errors) {
        // const unknownError: ErrorObject = {
        //   keyword:'Unknown',
        //   message:'A validation error occurred in the schedule form'
        // }
        props.setFormErrors([]);
      } else {
        props.setFormErrors(errors);
      }
    }
  };


  const doSave = () => {
    let callbackToRefreshList = () => {
      scheduleAPI.getSchedules({ folder_id: props.folderId });
    };

    let schedule;
    if (flags?.[getFlag(FFlags.PreventMultipleBurstCreation)]) {
      schedule = { ...props.schedule?.schedule };
    } else {
      schedule = props.schedule?.schedule;
    }

    if (schedule) {
      schedule.name = props.scheduleNameInputRef.current || schedule.name;
    }

    if (flags?.[getFlag(FFlags.PreventMultipleBurstCreation)]) {
      setIsFetching(true);
    }

    if (props.schedule?.schedule?.id) {
      let id = props.schedule.schedule?.id;

      scheduleAPI.setScheduleList?.(
        scheduleAPI.scheduleList?.map((s) => {
          return s.id === id ? props.schedule?.schedule : s;
        }) as IReportScheduleDefinition[]
      );
      if (flags?.[getFlag(FFlags.PreventMultipleBurstCreation)]) {
        delete schedule.id;
      } else {
        delete props.schedule.schedule?.id;
        setIsFetching(true);
      }

      scheduleAPI
        .updateSchedule(id, flags?.[getFlag(FFlags.PreventMultipleBurstCreation)] ? schedule : props.schedule.schedule)
        .then(callbackToRefreshList)
        .then(props.closeEditForm)
        .catch((e) => {
          if (flags?.[getFlag(FFlags.PreventMultipleBurstCreation)]) {
            props.schedule.schedule && (props.schedule.schedule.id = id);
          }
          setIsFetching(false);
          reportAPIError(e);
        });

      //this will roll back is something fails.
    } else {
      scheduleAPI
        .createSchedule(flags?.[getFlag(FFlags.PreventMultipleBurstCreation)] ? schedule : props.schedule.schedule as IReportScheduleDefinition)
        .then(callbackToRefreshList)
        .then(props.closeEditForm)
        .catch((e) => {
          if (flags?.[getFlag(FFlags.PreventMultipleBurstCreation)]) {
            setIsFetching(false);
          }
          reportAPIError(e);
        });
    }
  };

  return (
    <ModalFooter>
      {/* <Grid item> */}
      {/*<Button */}
      {/*data-qa="sendTest"*/}
      {/*variant="outlined" onClick={() => {props.showScheduleForm(false);}}>Send Test</Button>*/}
      {/* </Grid> */}
      {flags?.[getFlag(FFlags.WarnDistributionFieldsNotMappedToFilters)] && props.showWarningModal &&
        <WarningNoMappedFieldsModal
          doSave={doSave}
          showWarningModal={props.showWarningModal}
          setShowWarningModal={props.setShowWarningModal}
        />
      }
      <Grid item spacing={1} direction="row" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <ButtonContainer>
          <Button
            data-qa="cancel"
            variant="outlined"
            disabled={isFetching}
            onClick={props.closeModal}
          >
            Cancel
          </Button>
          <Button data-qa="save" id="saveSchedulebtn" onClick={handleSave} disabled={!props.isFormReady || isFetching}>
            Save
          </Button>
        </ButtonContainer>
      </Grid>
    </ModalFooter>
  );
};
