import './AlertHandler.scss';
import React from 'react'
import { useSelector } from 'react-redux';
import { Alert } from '..';
import { IAlert, IState } from '../../interfaces';

export default () => {
    const alerts = useSelector((state: IState) => state.app.alerts)

    return (
        <div className="da-alert-handler">

            {alerts.map((alert: IAlert, index: number) => 
                <Alert alert={alert} key={`alert-${index}`}/>
            )}

        </div>
    )
}