import React from 'react';

import AssessmentIcon from '@mui/icons-material/Assessment';
import './Icons.scss';


export {default as FolderIcon} from '@mui/icons-material/Folder';
export {default as NewFolderIcon} from '@mui/icons-material/CreateNewFolder';
export {default as EditFolderIcon} from '@mui/icons-material/Edit';
export {default as Edit} from '@mui/icons-material/Edit';
export {default as DeleteFolderIcon} from '@mui/icons-material/Delete';
export {default as ShareFolderIcon} from '@mui/icons-material/PersonAdd';
export {default as FileCopyIcon } from '@mui/icons-material/FileCopy';
export {default as CalendarIcon} from '@mui/icons-material/CalendarToday';
export {default as DriveFileMove} from '@mui/icons-material/DriveFileMove';
export {default as FolderMoveIcon} from './FolderMoveIcon';
export {default as EAAddIcon} from '@mui/icons-material/Add';
export {default as DashboardIcon} from './Dashboard';
export * from "@emburse/embark-icons";


export const LookIcon = () => <AssessmentIcon className="da-icon-look"/>;