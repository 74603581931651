import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {renameFolder} from '../../../store/actions/foldersActions';
import {hideModal, hideModalError, showModalError} from '../../../store/actions/modalActions';
import {
    Button,
    ButtonContainer,
    Modal,
    ModalFooter,
    ModalHeader,
    TextField
} from '@emburse/embark-core';
import {v4} from 'uuid';
import { IDashboard, ILook, isInstanceOfILook, IState } from '@interfaces';
import { renameDashboard, renameLook } from '@src/store';
import { createUseStyles } from 'react-jss';
import { IFFlags} from '../../../enums/FFlags';
import { flagsSelector } from '../../../store/selector';

export interface RenameModalProps{
    item: ILook | IDashboard | undefined,
    modal: any
}

const useStyles = createUseStyles({
    textField:{
    },
    spacer: {
        marginBottom: "15px"
    },
    characterCount:{
        width:"100%",
        justifySelf: "left",
        marginTop: "5px"
    }
});

export default ({item, modal}: RenameModalProps) => {
    const dispatch = useDispatch();
    const looks: ILook[] = useSelector((state: IState) => state.folders.folderContent.looks);
    const dashboards: IDashboard[] = useSelector((state: IState) => state.folders.folderContent.dashboards);
    const flags: IFFlags = useSelector(flagsSelector) as IFFlags;
    const classes = useStyles();
    const [itemName, setitemName] = useState(item?.title);
    const [itemDescription, setitemDescription] = useState(item?.description);
    const isLook: boolean = isInstanceOfILook(item);
    const itemDescMaxLength = 256;
    const itemNameMaxLength = 200;
    const typeString: string = isLook ? 'Look' : 'Dashboard';
    console.log(flags);

    const modalErrors = {
        emptyError: `${typeString} name cannot be empty`,
        existsError: `${typeString} name already exists`
    };

    const setNewName = (e) => {
        if (modal.errorMessage) {
            dispatch(hideModalError());
        }
        if(e.target.value.length <= itemNameMaxLength){
            setitemName(e.target.value);
        }
        
    };

    const setNewDescription = (e) => {
        if (modal.errorMessage) {
            dispatch(hideModalError());
        }
       if(e.target.value.length <= itemDescMaxLength){
            setitemDescription(e.target.value);
       }
    };

    const closeModal = () => {
        dispatch(hideModal());
        setitemName('');
    };

    const handleRenameFolderClick = () => {
        if (!itemName) {
            dispatch(showModalError(modalErrors.emptyError));
        } else {
            let titleArr = isLook ? looks.map((l: ILook) => l.title) : dashboards.map((d: IDashboard) => d.title);   
                
            if(item?.title === itemName && item?.description === itemDescription){
                closeModal();
            }
            else if(titleArr.includes(itemName) && item?.title !== itemName){
                dispatch(showModalError(modalErrors.existsError));
            }
            else{   
                dispatch(isLook ? 
                    renameLook({id: item?.id, name: itemName, description: itemDescription ? itemDescription.slice(0, itemDescMaxLength) : ""}) : 
                    renameDashboard({id: item?.id, name: itemName, description: itemDescription ? itemDescription.slice(0, itemDescMaxLength): ""})
                );
            }            
        }
    };

    const modalHeader = (
        <ModalHeader title={`Rename ${typeString}`} showClose={true} onCloseClicked={closeModal}/>
    );

    const modalFooter = (
        <ModalFooter>
            <ButtonContainer alignButtons={'right'}>
                <Button onClick={closeModal} variant="outlined" disabled={modal.loading}>Cancel</Button>
                <Button variant="contained" onClick={handleRenameFolderClick} disabled={modal.loading}>
                    Rename
                </Button>
            </ButtonContainer>
        </ModalFooter>
    );
    return (
        <Modal open modalHeader={modalHeader} modalFooter={modalFooter} onClose={closeModal} isLoading={modal.loading}>
            <div className="da-modal-form">
                <TextField
                    label={`${typeString} Name`}
                    id={v4()}
                    value={itemName}
                    onChange={setNewName}
                    fullWidth
                    required
                    autoFocus
                    helperText={modal.errorMessage}
                    error={Boolean(modal.errorMessage)}
                    className={classes.textField}
                />
                
                <>
                        <div className={classes.spacer}></div>
                    <TextField
                        label={`${typeString} Description`}
                        id={v4()}
                        value={itemDescription != null ? itemDescription : ""}
                        onChange={setNewDescription}
                        fullWidth
                        multiline
                        rows={3}
                        maxRows={3}
                    />
                    <span className={classes.characterCount} >{itemDescription?.length ? itemDescription.length : 0} / {itemDescMaxLength}</span>
                </>
            </div>
        </Modal>
    );
};
