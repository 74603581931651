import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { foldersReducer, lookerFrameReducer, modalReducer, modelReducer, userReducer } from './index';

import { appReducer } from './appReducer';

// const folderPersistConfig = {
//     key: 'folders',
//     storage: storage,
//     whitelist: ['folderStructure']
// }

export const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    app: appReducer,
    modal: modalReducer,
    folders: foldersReducer,
    lookerFrame: lookerFrameReducer,
    models: modelReducer,
    user: userReducer
})

export type RootState = ReturnType<typeof createRootReducer>