//todo: THIS NEEDS TO MOVE TO Components package.

import {
  ButtonOuter as XButtonOuter,
  Chip as XChip,
  IconButton,
  Span as XSpan,
} from "@looker/components";
import styled, { css } from "styled-components/macro";
import {
  // EmburseThemeProvider,
  Theme,
  Chip as EChip,
} from "@emburse/embark-core";
import { reset } from "@looker/design-tokens";

// const theme: Theme = EmburseThemeProvider({
//   ignoreFont: false,
//   children: [],
// }).props.children.props.theme;

let extChip = styled(EChip)``;

export const chipStyles = css`
  ${reset}

  background-color: "#e0e0e0";
  height: 32px;
  border-radius: 16px;
  border: none;
  padding-left: 12px;
  padding-right: 12px;
  margin-right: 4px;
  margin-left: 4px;

  ${XSpan} {
    color: "#222222";
    font-size: 13px;
  }

  ${IconButton} {
    margin-left: 8px;
    &:hover {
      background-color: none;
    }
  }

  ${XButtonOuter} {
    &: {
      background-color: none;
    }
  }
`;

export default styled(XChip).attrs({
  className: `${(XChip as any).styledComponentId}`,
  parentComponentID: `.${(XChip as any).styledComponentId}`,
})`
  ${chipStyles}
`;
