import { DrawerProvider, ModalContext } from "..";
import { IHasChildrenProps } from "../../interfaces/IHasChildrenProps";

export interface ContextsProps extends IHasChildrenProps {}

export default (props: ContextsProps) => {
  return (
    <>
      <ModalContext>
        <DrawerProvider>{props.children}</DrawerProvider>
      </ModalContext>
    </>
  );
};
