interface IGutters {
    Padding: typeof createGutter,
    Margin: typeof createGutter
}

let createGutter = (multiplier: number): string => {
    let gutter = 8 * multiplier

    return gutter + "px"
}

export default {
    Padding: createGutter,
    Margin: (m) => createGutter(m)
}