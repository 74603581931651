import React from 'react'

export default () => {
    return(
        <svg width="120" height="164" viewBox="0 0 120 164" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M106.9 112.2C105.4 112.3 104.3 113.5 104.3 115V120.3H101.6V112.2C101.6 110.7 100.4 109.5 98.9001 109.5C97.4001 109.5 96.2001 110.7 96.2001 112.2V128.4H93.5001V121.7C93.5001 120.2 92.4001 119 90.9001 118.9C89.4001 118.8 88.1001 120.1 88.1001 121.6V128.3V131C88.1001 132.5 89.3001 133.7 90.8001 133.7H96.2001V152.4L101.6 153.1V125.5H107C108.5 125.5 109.7 124.3 109.7 122.8V120.1V114.7C109.7 113.4 108.5 112.2 106.9 112.2Z" fill="#E0E6ED"/>
            <path d="M1.7998 162.7C9.5998 154.9 39.9998 136.8 72.5998 136.8C105.2 136.8 59.1998 154 119.1 154" stroke="#3B4655" strokeWidth="2" strokeMiterlimit="10"/>
            <path d="M5.5 39.4001L12 49.7001" stroke="#3B4655" strokeWidth="2" strokeMiterlimit="10"/>
            <path d="M27.4999 39.4001L20.8999 49.7001" stroke="#3B4655" strokeWidth="2" strokeMiterlimit="10"/>
            <path d="M16.7002 34.1001V48.8001" stroke="#3B4655" strokeWidth="2" strokeMiterlimit="10"/>
            <path d="M101.9 34.8H87.2002" stroke="#3B4655" strokeWidth="2" strokeMiterlimit="10"/>
            <path d="M101.9 41.5H87.2002" stroke="#3B4655" strokeWidth="2" strokeMiterlimit="10"/>
            <path d="M14.8 67.6001H0" stroke="#3B4655" strokeWidth="2" strokeMiterlimit="10"/>
            <path d="M14.8 74.3H0" stroke="#3B4655" strokeWidth="2" strokeMiterlimit="10"/>
            <path d="M67.9001 80.7002H53.1001" stroke="#3B4655" strokeWidth="2" strokeMiterlimit="10"/>
            <path d="M67.9001 87.4001H53.1001" stroke="#3B4655" strokeWidth="2" strokeMiterlimit="10"/>
            <path d="M48.6999 117.7H33.8999" stroke="#3B4655" strokeWidth="2" strokeMiterlimit="10"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M84.6999 12.4C78.4999 12.6 73.6999 17.9 73.6999 24V46.5H62.3999V12.4C62.3999 6.1 57.2999 1 50.9999 1C44.6999 1 39.5999 6.1 39.5999 12.4V80.7H28.1999V52.5C28.1999 46.3 23.3999 41.1 17.1999 40.9C10.6999 40.7 5.3999 45.9 5.3999 52.3V80.8V92.3C5.3999 98.5 10.3999 103.5 16.5999 103.5H39.4999V154.7H62.2999V69.3H85.1999C91.3999 69.3 96.3999 64.3 96.3999 58.1V46.5V23.8C96.4999 17.3 91.1999 12.2 84.6999 12.4Z" fill="#E0E6ED"/>
            <path d="M51 147.9V8.6001" stroke="#00A185" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16.7002 50V92.1H33.3002" stroke="#00A185" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M85.6002 21.2V57.9H68.7002" stroke="#00A185" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M84.6999 12.4C78.4999 12.6 73.6999 17.9 73.6999 24V46.5H62.3999V12.4C62.3999 6.1 57.2999 1 50.9999 1C44.6999 1 39.5999 6.1 39.5999 12.4V80.7H28.1999V52.5C28.1999 46.3 23.3999 41.1 17.1999 40.9C10.6999 40.7 5.3999 45.9 5.3999 52.3V80.8V92.3C5.3999 98.5 10.3999 103.5 16.5999 103.5H39.4999V154.7H62.2999V69.3H85.1999C91.3999 69.3 96.3999 64.3 96.3999 58.1V46.5V23.8C96.4999 17.3 91.1999 12.2 84.6999 12.4Z" stroke="#3B4655" strokeWidth="2" strokeMiterlimit="10"/>
            <path d="M45.5999 42.4C46.2626 42.4 46.7999 41.8627 46.7999 41.2C46.7999 40.5373 46.2626 40 45.5999 40C44.9372 40 44.3999 40.5373 44.3999 41.2C44.3999 41.8627 44.9372 42.4 45.5999 42.4Z" fill="#00A185"/>
            <path d="M45.5999 22.4C46.2626 22.4 46.7999 21.8627 46.7999 21.2C46.7999 20.5373 46.2626 20 45.5999 20C44.9372 20 44.3999 20.5373 44.3999 21.2C44.3999 21.8627 44.9372 22.4 45.5999 22.4Z" fill="#00A185"/>
            <path d="M56.9002 32.4C57.5629 32.4 58.1002 31.8627 58.1002 31.2C58.1002 30.5373 57.5629 30 56.9002 30C56.2375 30 55.7002 30.5373 55.7002 31.2C55.7002 31.8627 56.2375 32.4 56.9002 32.4Z" fill="#00A185"/>
            <path d="M73.5999 52.4C74.2626 52.4 74.7999 51.8627 74.7999 51.2C74.7999 50.5373 74.2626 50 73.5999 50C72.9372 50 72.3999 50.5373 72.3999 51.2C72.3999 51.8627 72.9372 52.4 73.5999 52.4Z" fill="#00A185"/>
            <path d="M91.2 41.2999C91.8627 41.2999 92.4 40.7626 92.4 40.0999C92.4 39.4372 91.8627 38.8999 91.2 38.8999C90.5373 38.8999 90 39.4372 90 40.0999C90 40.7626 90.5373 41.2999 91.2 41.2999Z" fill="#00A185"/>
            <path d="M79.8001 32.4C80.4628 32.4 81.0001 31.8627 81.0001 31.2C81.0001 30.5373 80.4628 30 79.8001 30C79.1374 30 78.6001 30.5373 78.6001 31.2C78.6001 31.8627 79.1374 32.4 79.8001 32.4Z" fill="#00A185"/>
            <path d="M56.9002 72.4C57.5629 72.4 58.1002 71.8627 58.1002 71.2C58.1002 70.5373 57.5629 70 56.9002 70C56.2375 70 55.7002 70.5373 55.7002 71.2C55.7002 71.8627 56.2375 72.4 56.9002 72.4Z" fill="#00A185"/>
            <path d="M11.2 72.4C11.8627 72.4 12.4 71.8627 12.4 71.2C12.4 70.5373 11.8627 70 11.2 70C10.5373 70 10 70.5373 10 71.2C10 71.8627 10.5373 72.4 11.2 72.4Z" fill="#00A185"/>
            <path d="M56.9002 52.4C57.5629 52.4 58.1002 51.8627 58.1002 51.2C58.1002 50.5373 57.5629 50 56.9002 50C56.2375 50 55.7002 50.5373 55.7002 51.2C55.7002 51.8627 56.2375 52.4 56.9002 52.4Z" fill="#00A185"/>
            <path d="M45.5999 62.4C46.2626 62.4 46.7999 61.8627 46.7999 61.2C46.7999 60.5373 46.2626 60 45.5999 60C44.9372 60 44.3999 60.5373 44.3999 61.2C44.3999 61.8627 44.9372 62.4 45.5999 62.4Z" fill="#00A185"/>
            <path d="M45.5999 99.6C46.2626 99.6 46.7999 99.0627 46.7999 98.4C46.7999 97.7372 46.2626 97.2 45.5999 97.2C44.9372 97.2 44.3999 97.7372 44.3999 98.4C44.3999 99.0627 44.9372 99.6 45.5999 99.6Z" fill="#00A185"/>
            <path d="M45.5999 79.6C46.2626 79.6 46.7999 79.0627 46.7999 78.4C46.7999 77.7372 46.2626 77.2 45.5999 77.2C44.9372 77.2 44.3999 77.7372 44.3999 78.4C44.3999 79.0627 44.9372 79.6 45.5999 79.6Z" fill="#00A185"/>
            <path d="M22.4998 62.4C23.1625 62.4 23.6998 61.8627 23.6998 61.2C23.6998 60.5373 23.1625 60 22.4998 60C21.8371 60 21.2998 60.5373 21.2998 61.2C21.2998 61.8627 21.8371 62.4 22.4998 62.4Z" fill="#00A185"/>
            <path d="M22.4998 79.6C23.1625 79.6 23.6998 79.0627 23.6998 78.4C23.6998 77.7372 23.1625 77.2 22.4998 77.2C21.8371 77.2 21.2998 77.7372 21.2998 78.4C21.2998 79.0627 21.8371 79.6 22.4998 79.6Z" fill="#00A185"/>
            <path d="M56.9002 89.6C57.5629 89.6 58.1002 89.0627 58.1002 88.4C58.1002 87.7372 57.5629 87.2 56.9002 87.2C56.2375 87.2 55.7002 87.7372 55.7002 88.4C55.7002 89.0627 56.2375 89.6 56.9002 89.6Z" fill="#00A185"/>
            <path d="M56.9002 129.6C57.5629 129.6 58.1002 129.063 58.1002 128.4C58.1002 127.737 57.5629 127.2 56.9002 127.2C56.2375 127.2 55.7002 127.737 55.7002 128.4C55.7002 129.063 56.2375 129.6 56.9002 129.6Z" fill="#00A185"/>
            <path d="M56.9002 109.6C57.5629 109.6 58.1002 109.063 58.1002 108.4C58.1002 107.737 57.5629 107.2 56.9002 107.2C56.2375 107.2 55.7002 107.737 55.7002 108.4C55.7002 109.063 56.2375 109.6 56.9002 109.6Z" fill="#00A185"/>
            <path d="M100.1 87.2999C98.9998 86.7999 97.9998 86.2999 96.9998 85.7999C96.1998 86.2999 95.2998 86.7999 94.4998 87.2999C93.3998 86.7999 92.2998 86.2999 91.1998 85.7999C90.3998 86.2999 89.5998 86.7999 88.7998 87.2999C87.8998 86.7999 86.9998 86.2999 86.1998 85.7999C85.4998 86.2999 84.7998 86.7999 84.1998 87.2999C83.0998 86.7999 81.9998 86.2999 80.8998 85.7999C80.2998 86.2999 79.6998 86.7999 79.0998 87.2999C75.6998 76.7999 75.6998 65.3999 79.0998 54.8999C86.0998 54.8999 93.0998 54.8999 100.1 54.8999C96.6998 65.3999 96.6998 76.6999 100.1 87.2999Z" fill="white" stroke="#3B4655" strokeWidth="2" strokeMiterlimit="10"/>
            <path d="M100.1 87.2999C98.9998 86.7999 97.9998 86.2999 96.9998 85.7999C96.1998 86.2999 95.2998 86.7999 94.4998 87.2999C93.3998 86.7999 92.2998 86.2999 91.1998 85.7999C90.3998 86.2999 89.5998 86.7999 88.7998 87.2999C87.8998 86.7999 86.9998 86.2999 86.1998 85.7999C85.4998 86.2999 84.7998 86.7999 84.1998 87.2999C83.0998 86.7999 81.9998 86.2999 80.8998 85.7999C80.2998 86.2999 79.6998 86.7999 79.0998 87.2999C75.6998 76.7999 75.6998 65.3999 79.0998 54.8999C86.0998 54.8999 93.0998 54.8999 100.1 54.8999C96.6998 65.3999 96.6998 76.6999 100.1 87.2999Z" fill="white" stroke="#3B4655" strokeWidth="2" strokeMiterlimit="10"/>
            <path d="M45.5999 119.6C46.2626 119.6 46.7999 119.063 46.7999 118.4C46.7999 117.737 46.2626 117.2 45.5999 117.2C44.9372 117.2 44.3999 117.737 44.3999 118.4C44.3999 119.063 44.9372 119.6 45.5999 119.6Z" fill="#00A185"/>
            <path d="M28.1001 154.7H73.6001" stroke="#3B4655" strokeWidth="2" strokeMiterlimit="10"/>
            <path d="M89.9998 47.2L88.2998 60.7999" stroke="#3B4655" strokeWidth="2" strokeMiterlimit="10"/>
            <path d="M92.6 47.7L87.5 46.7" stroke="#3B4655" strokeWidth="2" strokeMiterlimit="10"/>
            <path d="M91.3998 59.4001C90.7998 60.2001 89.6998 60.7001 88.3998 60.7001C87.0998 60.7001 85.8998 60.1 85.2998 59.3" stroke="#3B4655" strokeWidth="2" strokeMiterlimit="10"/>
            <path d="M80.7998 65.7H87.2998" stroke="#E0E6ED" strokeMiterlimit="10"/>
            <path d="M80.7998 72.7H90.6998" stroke="#E0E6ED" strokeMiterlimit="10"/>
            <path d="M92 65.7H94.4" stroke="#E0E6ED" strokeMiterlimit="10"/>
            <path d="M80.7998 69.3H87.2998" stroke="#E0E6ED" strokeMiterlimit="10"/>
            <path d="M92 69.3H94.4" stroke="#E0E6ED" strokeMiterlimit="10"/>
            <path d="M86.6001 80.4001C87.9001 78.8001 89.0001 77.6001 89.5001 77.8001C90.1001 78.1001 89.2001 80.8001 89.6001 80.9001C90.1001 81.1001 91.8001 77.1001 92.6001 77.4001C93.2001 77.6001 92.8001 79.7001 93.6001 80.0001C94.0001 80.1001 94.5001 79.8001 94.8001 79.5001" stroke="#E0E6ED" strokeMiterlimit="10" strokeLinecap="round"/>
        </svg>
    )
}