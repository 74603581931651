import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { push } from "connected-react-router";
import { 
  ADMIN_SCHEDULE_MANAGEMENT,
  ADMIN_USER_MANAGEMENT,
  ADMIN_BURST_SCHEDULE_MANAGEMENT,
} from "@src/store/routs";
import {
  AdminSchedules,
  AdminUserManagement,
  Page,
} from "@src/components";
import {
  Sidebar
} from "@emburse/embark-core";
import {IFFlags, FFlags, getFlag} from "@src/enums";
import {TeamFieldsIcon, CalendarIcon} from "@emburse/embark-icons";
import { createUseStyles } from "react-jss";
import AdminSchedulesV2 from "@components/AdminSchedules/AdminSchedulesV2";
import AdminBurstSchedules from "@components/AdminBurstSchedules/AdminBurstSchedules";

const useStyles = createUseStyles({
  manageUserBar: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",

    "& > span:last-child": {
      marginLeft: "auto",
    },
    "& > :first-child": {
      marginRight: "15px",
    },
  },
  sidebar: {
    width: '251px',
    zIndex: '0'
  },
  pageWithSidebar: {
    flex: 'auto',
    padding: '3rem'
  },
  limitWarning: {
    maxWidth: '60%'
  }
});

export interface AdminProps {}
const Admin = (props: AdminProps) => {
  const dispatch = useDispatch();

  const flags: IFFlags = useSelector((state: RootStateOrAny) => state.user.userData.flags);
  const classes = useStyles();

  const renderNewSchedulesPage = flags?.[getFlag(FFlags.UseNewScheduleAdminComponent)];
  const renderBurstSchedulesPage = flags?.[getFlag(FFlags.EnableBurstScheduleAdminComponent)];

  const [selectedMenuItem, setSelectedMenuItem] = useState<string | null>("Users");
  
  useEffect(() => {
    switch(location.pathname) {
      case ADMIN_SCHEDULE_MANAGEMENT: 
        setSelectedMenuItem('Schedules');
        break;
      case ADMIN_BURST_SCHEDULE_MANAGEMENT:
        setSelectedMenuItem('Bursts');
        break;
      case ADMIN_USER_MANAGEMENT:
      default:
          setSelectedMenuItem('Users');
    }

  }, [selectedMenuItem]);

  let menu = {
    menus: [
      {
        sections: [
          {
            header: "Admin Settings",
            items: [
              {
                icon: TeamFieldsIcon,
                iconText: "User Management",
                onClick: () => dispatch(push(ADMIN_USER_MANAGEMENT))
              },
              {
                icon: CalendarIcon,
                iconText: "Schedules",
                onClick: () => dispatch(push(ADMIN_SCHEDULE_MANAGEMENT))
              },
              {
                icon: CalendarIcon,
                iconText: "Burst Schedules",
                onClick: () => dispatch(push(ADMIN_BURST_SCHEDULE_MANAGEMENT))
              },
              // {
              //   icon: SettingsIcon,
              //   iconText: "Connect",
              // },
              // {
              //   icon: SettingsIcon,
              //   iconText: "Landing Page",
              // },
            ],
          },
        ],
      },
    ],
  };

  return (
    <div className="admin" style={{display: 'flex', height: '100vh'}}>
      <Page sidebar={<Sidebar className={classes.sidebar} variant={"permanent"} sidebarMenu={menu} />}>
        {selectedMenuItem == 'Users' && <AdminUserManagement/>}
        {renderNewSchedulesPage && selectedMenuItem == 'Schedules' && <AdminSchedulesV2/>}
        {!renderNewSchedulesPage && selectedMenuItem == 'Schedules' && <AdminSchedules/>}
        {renderBurstSchedulesPage && selectedMenuItem == 'Bursts' && <AdminBurstSchedules/>}
      </Page>
    </div>
  );
};

export default Admin;
