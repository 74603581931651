import React, { useContext, useRef, useState } from "react";
import {
  Card,
  Link,
  Button,
  TextField,
  Typography,
  Grid,
} from "@emburse/embark-core";
import { Formik, Form, Field, useFormikContext } from "formik";
import { LookerRegionSelect, FormikTextField } from "@src/components";
import { IUseAuthInterface } from "@src/hooks/useAuth";
import { IDashboard, IUser } from "@src/interfaces";
import { AuthContext } from "../AuthProvider/AuthProvider";
import { FFlags, getFlag } from "@src/enums";
import { useSelector } from "react-redux";
import { flagsSelector } from "@src/store/selector";
import { IFolder } from "../../interfaces/IFolder";

const ActionButton = (props: { buttonText: string; loading: boolean }) => {
  const { submitForm } = useFormikContext();

  return (
    <Button
      size="small"
      variant="outlined"
      onClick={submitForm}
      disabled={props.loading}
    >
      {props.buttonText}
    </Button>
  );
};

export default () => {
  const [isOk, setIsOk] = useState<boolean | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [dashInfo, setDashInfo] = useState<IDashboard | undefined>(undefined);
  const [dashSearchError, setDashSearchError] = useState<boolean>(false);
  const [folderSearchError, setFolderSearchError] = useState<boolean>(false);
  const [folderInfo, setFolderInfo] = useState<IFolder | undefined>(undefined);
  const [fetching, setFetching] = useState(false);
  const userData: IUser = useContext<IUseAuthInterface>(AuthContext)
    .user as IUser;
  const flags = useSelector(flagsSelector);
  let folderAC: AbortController;
  let dashAC: AbortController;
  const formRef = useRef<any>();

  let initialValues;

  initialValues = {
    dashboard_id: "",
    folder_id: "",
    region: userData.looker_region,
  };

  const handleDashIDOnChange = async (e) => {
    setDashSearchError(false);
    setDashInfo(undefined);

    // we want to handle the race condition here.
    if (dashAC) {
      dashAC.abort();
    }

    dashAC = new AbortController();

    if (e.target.value != "") {
      await fetch(`/app/dashboards/${e.target.value}/info`, {
        signal: dashAC.signal,
      })
        .then(async (res: Response) => {
          if (res.ok) {
            setDashSearchError(false);
            let body = await res.json();
            console.log(body);
            setDashInfo(body);
          } else {
            throw Error(res.toString());
          }
        })
        .catch((err) => {
          setDashSearchError(true);
          setDashInfo(undefined);
          console.log(err);
        });
    }
  };

  const handleFolderIDOnChange = (e) => {
    searchFolder(e.target.value, formRef.current.values.region);
  };

  const handleRegionOnChange = (e) => {
    searchFolder(formRef.current.values.folder_id, e.target.value);
  };

  const searchFolder = (id, region) => {
    setFolderSearchError(false);
    setFolderInfo(undefined);

    if (folderAC) {
      folderAC.abort();
    }

    fetch(`/app/folders/${id}/info?include_path=true&region=${region}`, {
      signal: folderAC?.signal,
    })
      .then(async (res: Response) => {
        if (res.ok) {
          setFolderSearchError(false);
          let body = await res.json();
          if (body === null) {
            throw Error("Not Found");
          }

          setFolderInfo(body);
        } else {
          throw Error(res.toString());
        }
      })
      .catch((err) => {
        setFolderSearchError(true);
        setFolderInfo(undefined);
      });
  };

  const formikSubmit = (values) => {
    setIsOk(undefined);
    setLoading(true);
    console.log("Values", values);
    fetch(`/app/admin/dashboards/copy`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values),
    }).then((res: Response) => {
      res.json().then((value) => {
        console.log(value);

        if (value["to_dashboard_id"]) {
          setIsOk(res.ok ? true : false);
        } else {
          setIsOk(false);
        }
      });

      setLoading(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => formikSubmit(values)}
      innerRef={formRef}
    >
      <Card
        variant="elevation"
        actions={
          <ActionButton
            buttonText={loading ? "Copying..." : "Copy"}
            loading={loading}
          />
        }
      >
        <Typography variant="h5" gutterBottom>
          Copy A Dashboard
        </Typography>
        <Typography variant="body1" gutterBottom>
          Copy a Dashboard and its Looks to a Customer's folder. If a dashboard
          exists with the same name in the target folder, the new copy will be
          renamed and this will be visible to end users. e.g Dashboard (2). If a
          referenced look already exists in the folder it will be reused. If a
          different look with the same name exists the new one will be renamed
          and this will be visible to end users. e.g Look (2) Dashboards and
          Looks can be deleted in{" "}
          <Link
            href="https://emburse.cloud.looker.com/folders/4"
          >
            Looker's folder browser
          </Link>
          .
        </Typography>
        {flags?.[getFlag(FFlags.ShowCopyInfo)] && (
          <Grid container direction="row" spacing={1}>
            <Grid item sm={6}>
              <Typography variant="subtitle1">Dashboard Info</Typography>
              {dashInfo && (
                <>
                  <Typography variant="body1">ID: {dashInfo?.id}</Typography>
                  <Typography variant="body1">
                    Title: {dashInfo?.title}
                  </Typography>
                  <Typography variant="body1">
                    Description: {dashInfo?.description}
                  </Typography>
                </>
              )}
            </Grid>
            <Grid item sm={6}>
              <Typography variant="subtitle1">Folder Info</Typography>
              {folderInfo && (
                <>
                  <Typography variant="body1">ID: {folderInfo?.id}</Typography>
                  <Typography variant="body1">
                    Title: {folderInfo?.name}
                  </Typography>
                  <Typography variant="body1">
                    Path: {folderInfo?.path?.join("/")}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Is Personal: {folderInfo?.isPersonal.toString()}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        )}
        <br />
        <Form>
          <Grid container direction="row" wrap="wrap">
            <Grid item sm={6}>
              <Field
                component={FormikTextField}
                id="dashboard_id"
                name="dashboard_id"
                label="Dashboard ID"
                onChange={
                  flags?.[getFlag(FFlags.ShowCopyInfo)]
                    ? handleDashIDOnChange
                    : () => {}
                }
                error={dashSearchError}
                helperText={
                  dashSearchError
                    ? "Invalid Dashboard"
                    : "The dashboard to copy"
                }
              />
            </Grid>
            <Grid item sm={6}>
              <Field
                component={FormikTextField}
                id="folder_id"
                name="folder_id"
                label="Folder ID"
                error={folderSearchError}
                onChange={
                  flags?.[getFlag(FFlags.ShowCopyInfo)]
                    ? handleFolderIDOnChange
                    : () => {}
                }
                helperText={
                  folderSearchError ? "Invalid Folder" : "The folder to copy"
                }
              />
            </Grid>
            <Grid item sm={6}>
              <></>
            </Grid>
            <Grid item sm={6}>
              <Field
                component={LookerRegionSelect}
                id="region"
                name="region"
                label="Region"
                onChange={
                  flags?.[getFlag(FFlags.ShowCopyInfo)]
                    ? handleRegionOnChange
                    : () => {}
                }
              />
            </Grid>

            {isOk && <p>Dashboard Copied</p>}
            {!isOk && isOk !== undefined && <p>Dashboard Copy Error</p>}
          </Grid>
        </Form>
      </Card>
    </Formik>
  );
};
