import React, { createContext, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { IHasChildrenProps, IUseModal } from "@src/interfaces";
import { Modal } from "@emburse/embark-core";
import { ErrorObject } from "ajv";

export interface ModalContextProps extends IHasChildrenProps {}

const useStyles = createUseStyles({});

export const ModalContext = createContext<IUseModal>({
  open: false,
  setOpen: () => {},
  setSize: () => {},
  setHeader: () => {},
  setBody: () => {},
  setFooter: () => {},
  setErrors: () => {},
  errors: null,
});

export default (props: ModalContextProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [size, setSize] = useState<"xs" | "sm" | "md" | "lg" | "xl">("sm");
  const [header, setHeader] = useState<JSX.Element | undefined>();
  const [footer, setFooter] = useState<JSX.Element | undefined>();
  const [body, setBody] = useState<JSX.Element | undefined>();
  const [errors, setErrors] = useState<
    ErrorObject<string, Record<string, any>, unknown>[] | null | undefined
  >();

  const classes = useStyles();

  let values = {
    open: open,
    setOpen: setOpen,
    size: size,
    setSize: setSize,
    header: header,
    setHeader: setHeader,
    footer: footer,
    setFooter: setFooter,
    body: body,
    setBody: setBody,
    errors: errors,
    setErrors: setErrors,
  };

  useEffect(() => {
    if (!open) {
      setHeader(undefined);
      setBody(undefined);
      setFooter(undefined);
    }
  }, [open]);

  return (
    <ModalContext.Provider value={values}>
      <Modal
        onClose={(_e, reason) => {
          if (reason === 'backdropClick') {
            setOpen(false);
          }
        }}
        open={values.open}
        size={values.size}
        modalHeader={values.header}
        modalFooter={values.footer}
      >
        {values.body}
      </Modal>
      {props.children}
    </ModalContext.Provider>
  );
};
