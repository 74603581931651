//A react component for a new page
// Language: typescript
// Path: src/components/views/LicenseLimitExceeded/LicenseLimitExceeded.tsx

import { Page } from "@src/components";
import {
  Card,
  Container,
  GridItem,
  GridRow,
  Icon,
  Typography,
} from "@emburse/embark-core";
import { IHasChildrenProps } from "../../../interfaces/IHasChildrenProps";
import { theme } from "@looker/sdk";
import { TeamFieldsIcon, TransactionsIcon, CardsIcon } from "@emburse/embark-icons";
import { fontSize } from "styled-system";
import LicensesImage from '../../Icons/LicensesImage';

export interface LicenseLimitProps extends IHasChildrenProps {
  title: string,
}

const LicenseLimitWrapper = (props: LicenseLimitProps) => {
  return (
    <Page>
      <Container style={{marginTop: '40px'}}>
        <GridRow alignItems="center" justifyContent="center">
          <GridItem>
            <LicensesImage/>
          </GridItem>
        </GridRow>
      </Container>
      <Container 
        maxWidth="md"
      >
        <Typography
          align='center'
          variant="h5"
          verticalSpacingPosition="top"
          verticalSpacingSize={3}
        >
          {props.title}
        </Typography>
        <Card style={{ border: "none" }}>
          <GridRow>
            <GridItem style={{width: '100%'}}>
              <Typography 
                variant='body1'
                align="center"
                >
                {props.children}
              </Typography>
            </GridItem>
          </GridRow>
        </Card>
      </Container>
    </Page>
  );
};

export default LicenseLimitWrapper;
