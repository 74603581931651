import { MenuItem, Select, MenuDivider } from "@emburse/embark-core";
import React, { CSSProperties, ReactNode, SyntheticEvent } from "react";
import { createUseStyles } from "react-jss";
import { JsxEmit } from "typescript";
import { v4 } from "uuid";
import ReactElement from "react";

export interface SelectFieldProps {
  fullWidth?: boolean;
  label?: string;
  val: any;
  onChange: React.Dispatch<React.SetStateAction<any>>;
  selectList:
    | { [key: string]: string | number }
    | { [key: string]: string | number }[];
  useKeysAsLabel?: boolean;
  addBlank?: boolean;
  tailListItem?: JSX.Element;
  disabled?: boolean;
  helperText?: string | ReactNode;
  displayEmpty?: boolean;
  noSeparators?: boolean;
  style?: CSSProperties;
  multiple?: boolean;
  error?: boolean;
}

const useStyles = createUseStyles({});

export default (props: SelectFieldProps) => {
  const classes = useStyles();

  let list: JSX.Element[] = [];

  if (!Array.isArray(props.selectList)) {
    let arr;
    if (props.useKeysAsLabel) {
      arr = Object.keys(props.selectList).filter((e) => isNaN(parseInt(e)));
    } else {
      arr = Object.values(props.selectList);
    }
    list = Object.entries(props.selectList)
      .filter((v) => {
        if (props.useKeysAsLabel) {
          return isNaN(parseInt(v[0]));
        } else {
          return true;
        }
      })
      .map((v) => {
        // if(v[1])
        return (
          <MenuItem key={v4()} value={v[1]}>
            {props.useKeysAsLabel ? v[0] : v[1]}
          </MenuItem>
        );
      });
  } else {
    let arr;
    if (props.useKeysAsLabel) {
      if (Array.isArray(props.selectList)) {
        arr = props.selectList;
      } else {
        arr = Object.entries(props.selectList).filter((v) => {
          return isNaN(parseInt(v[0]));
        });
      }
    } else {
      arr = Object.values(props.selectList);
    }

    Array.from(arr).forEach((l, i) => {
      let item: Array<unknown> = l as Array<unknown>;
      list.push(
        ...Object.entries(item).map((v) => {
          return (
            <MenuItem key={v4()} value={v[1]}>
              {props.useKeysAsLabel
                ? v[0].indexOf(" ") > -1
                  ? v[0]
                  : v[0].replace(/([A-Z])/g, " $1")
                : v[1]}
            </MenuItem>
          );
        })
      );
      if (!props.noSeparators && arr.length != i + 1) {
        list.push(<MenuDivider key={v4()} />);
      }
    });
  }

  return (
    <Select
      {...props}
      disabled={props.disabled}
      fullWidth={props.fullWidth}
      id={v4()}
      value={props.val}
      onChange={(event) => {
        props.onChange((event as any).target.value);
      }}
      label={props.label}
      helperText={props.helperText}
      displayEmpty={props.displayEmpty}
    >
      {props.addBlank && <MenuItem value=""></MenuItem>}
      {list.map((el) => {
        // console.log(el)

        // if(el === "asd"){

        // }
        return el;
      })}
      {props.tailListItem && (
        <MenuItem key={v4()}>{props.tailListItem}</MenuItem>
      )}
    </Select>
  );
};
