import React, {useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import {createFolder} from '../../../store/actions/foldersActions';
import {hideModal, hideModalError, showModalError} from '../../../store/actions/modalActions';
import './Modal.scss';

import {
    Button,
    ButtonContainer,
    Modal,
    ModalFooter,
    ModalHeader,
    TextField
} from '@emburse/embark-core'
import {v4} from 'uuid';
import { IEmburseFolderStructure } from '../../../interfaces/IEmburseFolderStructure';
import { StoreContext } from '@src/components/StoreAccessor/StoreAccessor';

export default ({folder, modal}) => {
    const dispatch = useDispatch()
    const [folderName, setFolderName] = useState('')
    const folderStuctureState = useContext<{state: IEmburseFolderStructure}>(StoreContext)
    
    const setNewName = (e) => {
        if (modal.errorMessage) {
            dispatch(hideModalError())
        }
        setFolderName(e.target.value)
    }

    const closeModal = () => {
        dispatch(hideModal())
    };

    const handleCreateFolderClick = () => {
        if (!folderName) {
            dispatch(showModalError("Folder name cannot be empty"))
        } else {
            dispatch(createFolder({name: folderName, parentId: folder.id}, folderStuctureState.state));
        }
    };

    const modalHeader = <ModalHeader title="Create Folder" showClose={true} onCloseClicked={closeModal}/>;

    const modalFooter = (
        <ModalFooter>
            <ButtonContainer alignButtons={'right'}>
                <Button onClick={closeModal} variant="outlined" disabled={modal.loading}>Cancel</Button>
                <Button variant="contained" onClick={handleCreateFolderClick} disabled={modal.loading}>
                    Create
                </Button>
            </ButtonContainer>
        </ModalFooter>
    );
    return (
        <Modal open modalHeader={modalHeader} modalFooter={modalFooter} onClose={closeModal} isLoading={modal.loading}>
            <div className="da-modal-form">
                <TextField label="Folder Name"
                        id={v4()}
                        value={folderName}
                        onChange={setNewName}
                        fullWidth
                        required
                        autoFocus
                        helperText={modal.errorMessage}
                        error={Boolean(modal.errorMessage)}
                />
            </div>
        </Modal>
    )

}
