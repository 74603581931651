import React from 'react';
import { ErrorTypes } from '../../enums/ErrorTypes';
import { LOGOUT_ROUTE } from '@store/routs';
import { Redirect } from 'react-router';
import { IError } from '@src/interfaces';
export interface ErrorBlockProps{
    error: IError
}
export default (props: ErrorBlockProps) => {

    return(
        <>
            {
                props.error.type === ErrorTypes.UNAUTHORIZED &&
                <Redirect to={LOGOUT_ROUTE} />
            }
            {`${props.error.message}`}
        </>
    )
}