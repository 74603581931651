export enum IClause {
    IsEqualTo = "is equal to:string:",
    Contains = "contains:string:contains",
    StartsWith = "starts with:string:starts",
    EndsWith = "ends with:string:ends",
    IsBlank = "is blank:string:empty",
    IsNull = "is null:string:null",
    IsNotEqualTo = "is not equal to:string:!= ",
    DoesntContain = "doesn\'t contain:string:!contains",
    DoesntStartWith = "doesn\'t start with:string:!starts",
    DoesntEndWith = "doesn\'t end with:string:!ends",
    IsNotBlank = "is not blank:string:!empty",
    IsNotNull = "is not null:string:!null",
    MatchesAUserAttribute = "matches a user attribute:string:user_attribute",
    MatchesAdvanced = "matches (advanced):string:advanced"
}