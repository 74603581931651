import React, { useState } from "react";
import { Icon, IconButton, Menu, MenuItem } from "@emburse/embark-core";
import { MoreVertIcon } from "@emburse/embark-icons";

export interface IRowActionMenuItem {
  disabled?: boolean;
  dataQa?: string;
  children: React.ReactNode;
  onClick?: () => void;
}
export interface RowActions {
  containerClassName?: string;
  containerDataQa?: string;
  iconButtonDataQa?: string;
  menuItems: IRowActionMenuItem[];
}

export const standardSettingsForRowActions = (renderCell) => {
  return {
    field: "actions",
    width: 100,
    headerName: " ",
    renderCell,
  };
};

export function RowActions(props: RowActions) {
  const [anchor, setAnchor] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const openMenu = (event) => {
    setAnchor(event.currentTarget);
    setMenuOpen(true);
  };
  const closeMenu = () => {
    setMenuOpen(false);
    setAnchor(null);
  };

  return (
    <>
      <span
        className={props.containerClassName ?? ""}
        data-qa={props.containerDataQa ?? ""}
      >
        <IconButton
          size="small"
          data-qa={props.iconButtonDataQa ?? ""}
          onClick={(event) => {
            return openMenu(event);
          }}
        >
          <Icon iconUrl={MoreVertIcon} />
        </IconButton>
      </span>
      <Menu
        open={menuOpen}
        anchorEl={anchor}
        onClose={closeMenu}
        disablePortal={false}
      >
        {props.menuItems.map((itemProps, key) => {
          return (
            <MenuItem
              key={key}
              {...itemProps}
              onClick={() => {
                if (itemProps.onClick) {
                  itemProps.onClick();
                }
                closeMenu();
              }}
            />
          );
        })}
      </Menu>
    </>
  );
}
