import React from 'react';
import EmptyFolder from '../Icons/EmptyFolder';
import EmptyPage from '../EmptyPage/EmptyPage';
import { useSelector } from 'react-redux';
import { AnalyticsProStatus } from '../../enums/AnalyticsProStatus';
import { AnalyticsRole, Tier } from '../../enums';
import { IState } from '@src/interfaces';

export default () => {
    const {userData} = useSelector((state: IState) => state.user);
    return(
        <EmptyPage>
            <EmptyFolder />
            <div className="da-empty-page-text-block">
                <h6 className="da-empty-page-text-block-heading">There's Nothing Here Yet</h6>
                {(userData.tier === Tier.FREE && userData.analytics_role === AnalyticsRole.FreeCreator) &&
                    <p>Use the "Create" button, or move Reports to this folder when accessing them.</p>
                }

                {(userData.tier === Tier.PRO && userData.analytics_role === AnalyticsRole.ProCreator) &&
                    <p>Use the "Create" button, or move Reports and Dashboards to this folder when accessing them.</p>
                }
            </div>
        </EmptyPage>
    );
};