import React, { useContext } from "react";
import {Link} from "react-router-dom";
import {Redirect, Route} from "react-router";
import {Button, ButtonContainer} from "@emburse/embark-core";
import LicenseLimitWrapper from ".";
import { IUseAuthInterface } from "@src/hooks/useAuth";
import { AuthContext } from "../../../components/AuthProvider/AuthProvider";
import {SessionErrors} from "@src/enums";

const pageDisplayByErrorCode = {
  [SessionErrors.TENANT_LICENSE_LIMIT_EXCEEDED_FOR_EXISTING_USER]: {
    title: "License Count Exceeded",
    body: (
      <>
        Your organization has exceeded the number of available licenses.
        <br />
        To continue using Emburse Analytics, please contact your system administrator.
      </>
    )
  },
  [SessionErrors.TENANT_LICENSE_LIMIT_EXCEEDED_FOR_NEW_USER]: {
    title: "No Licenses Available",
    body: (
      <>
        Your organization has no available licenses remaining.
      <br />
      To continue using Emburse Analytics, please contact your system administrator.
      </>
    )
  },
  [SessionErrors.LICENSE_LIMIT_EXCEEDED]: {
    title: "License Count Exceeded",
    body: (
      <>
        The number of user licenses issued has been exceeded. User access is currently restricted. To restore access, please go to User Management.
      </>
    )
  }
};

export const LicenseLimitExceeded = () => {
  const auth: IUseAuthInterface = useContext(AuthContext);
  let errorCode = auth?.session?.error_code || '';

  // there is a use case when a customer is downgraded and their licenses are maxed out.
  // it is indicated by the User model property license_limit_exceeded = 'true'. session.status can still be 'authorized' and session.error_code = ''.
  // the user will only be given the option to navigate to User Management Screen to de-provision users
  if (auth?.user?.license_limit_exceeded === true) {
    errorCode = SessionErrors.LICENSE_LIMIT_EXCEEDED;
  }

  return (
    <LicenseLimitWrapper title={pageDisplayByErrorCode[errorCode].title}>
      {pageDisplayByErrorCode[errorCode].body}
    </LicenseLimitWrapper>
  );
};

export const LicenseLimitExceededForProvisionedUser = () => {
  return (
    <>
      <LicenseLimitWrapper title={pageDisplayByErrorCode[SessionErrors.LICENSE_LIMIT_EXCEEDED].title}>
        {pageDisplayByErrorCode[SessionErrors.LICENSE_LIMIT_EXCEEDED].body}
      </LicenseLimitWrapper>
      <ButtonContainer style={{textAlign:'center', marginTop: '10px'}}>
        <Button>
          <Link to="/app/p/admin/users" style={{textDecoration: "none",color: '#FFF'}}>go to user management
          </Link>
        </Button>
    </ButtonContainer>
    </>
  );
};
