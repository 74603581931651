import { IReportScheduleDefinition, ISchedulingCronParse, ITimezone } from "@src/interfaces";
import { Recurrence } from '../enums/Recurrence';
import CronParseError from './errors/CronParseError';
import later from '@breejs/later';
import {monthsShort} from 'moment';
import moment from 'moment';
import { weekdaysShort } from 'moment';
import momentTz from 'moment-timezone';
import { Timezones } from "@src/enums";

interface IScheduling{
    isDaily: (cronExp: string) => boolean
    isWeekly: (cronExp: string) => boolean
    isMonthly: (cronExp: string) => boolean
    isQuarterly: (cronExp: string) => boolean
    isHourly: (cronExp: string) => boolean
    isMinutely: (cronExp: string) => boolean
    isSpecificMonths: (cronExp: string) => boolean
    isSpecificDays: (cronExp: string) => boolean,
    getDay: () => Array<number> | number,
    getMonthDay: () => number | undefined,
    getMonthsObject: () => Array<{id: number, label: string}>
    getDaysObject: () => Array<{id: number, label: string}>
    getHourSpan: () => Array<number> | undefined,
    getHourDifference: () => number | undefined,
    getMinuteDifference: () => number | undefined,
    getFromTime: () => string | undefined,
    getToTime: () => string | undefined,
    getTimezone: () => void,
    parse: (cronExp: string) => ISchedulingCronParse,
    getTimeString: () => string, //gets the times in HH:mm format
    day?: number[],
    days?: number[],
    month?: number[],
    hour?: number[],
    minute?: number[],
    second?: number[],
    schedule?: IReportScheduleDefinition
}
export class Scheduling implements IScheduling{
    public type?: Recurrence
    public day?: number[]
    public days?: number[]
    public month?: number[]
    public hour?: number[]
    public minute?: number[]
    public second?: number[]
    public schedule?: IReportScheduleDefinition
    private parsed?: {schedules: Array<{D: Array<number>, d: Array<number>, M: Array<number>, h: Array<number>, m: Array<number>, s: Array<number>}>}

    constructor(schedule?: IReportScheduleDefinition) {
        if(schedule){
            this.schedule = schedule;
            this.parse(schedule.crontab);
        }
        else{
            this.schedule = undefined;
        }
    }

    isDaily(cronExp: string): boolean{
        let pattern: RegExp = /^[0-9]{1,2} [0-9]{1,2} \* \* \*$/;
        return pattern.test(cronExp);
    }

    isWeekly(cronExp: string): boolean {
        let pattern: RegExp = /^[0-9]{1,2} [0-9]{1,2} \* \* [0-6]$/;
        return pattern.test(cronExp);
    }

    isMonthly(cronExp: string): boolean {
        let pattern: RegExp = /^[0-9]{1,2} [0-9]{1,2} [0-9]{1,2} \* \*$/;
        return pattern.test(cronExp);
    }

    isQuarterly(cronExp: string): boolean {
        throw Error("Not Implemented");
    }

    isHourly(cronExp: string): boolean {
        let pattern: RegExp = /^[0-9]{1,2} [0-9]{1,2}-{0,1}[0-9]{1,2}\/{0,1}[0-6]{0,1} \* \* \*$/;
        return pattern.test(cronExp);
    }

    isMinutely(): boolean {
        // let pattern: RegExp = /^([0-9]{1,2},{0,1}){1,20} [0-9]{1,2}-{0,1}[0-9]{0,2} \* \* \*$/
        return this.minute?.length && this.minute?.length > 1 ? true : false;
    }

    isSpecificMonths(cronExp: string): boolean {
        let pattern: RegExp = /^[0-9]{1,2} [0-9]{1,2} [0-9]{1,2} (([0-9]{1,2},)|([0-9]{1,2})|([0-9]{1,2}-[0-9]{1,2})|([0-9]{1,2}-[0-9]{1,2},))+ \*$/;
        return pattern.test(cronExp);
    }

    isSpecificDays(cronExp: string): boolean {
        let pattern: RegExp = /^([0-9]|[1-6][0-9]) ([0-1]?[0-9]|2[0-3]) \* \* ((\*?\/?(([0-6])|([0-6]-[0-6])),?){0,7})$/;
        return pattern.test(cronExp);
    }

    // the later library has a day range of 1-7 which is odd because we usually 
    // do 0 based so adding a function correct that.. subtract 1 from every number
    getDay(): Array<number> | number{
        if(this.checkParsed()){
            if(this.day){
                return this.day.length > 1 ? this.day.map(num => num - 1) : this.day[0] - 1;
            }
            else{
                return moment().weekday();
            }
        }
        else{
            return moment().weekday();
        }
    
    }

    getMonthDay(): number{
        if(this.checkParsed()){
            return this.days?.length === 1 ? this.days?.[0] : moment().date();
        }
        else{
            return moment().date();
        }
    }

    getHourSpan(): Array<number> | undefined {
        if(this.checkParsed()){
            return this.getNumberSpan(this.hour);
        }
        else{
            return undefined;
        }
    }

    getMinuteSpan(): Array<number> | undefined {
        if(this.checkParsed()){
            return this.getNumberSpan(this.minute);
        }
        else{
            return undefined;
        }
    }

    /**
     * Check's if this objects crontab has been parsed else it throws and error
     *
     * @private
     * @return {*}  {boolean}
     * @memberof Scheduling
     */
    private checkParsed(): boolean{
        if(this.parsed){
            return true;
        }
        else{
            return false;
        }
    }

    /**
     * Returns a list of 0 or more objects that has a month id (integer of month) and the short name for the month.
     *
     * @return {*}  {Array<{id: number, label: string}>}
     * @memberof Scheduling
     */
    getMonthsObject(): Array<{id: number, label: string}>{
        if(this.checkParsed()){
            if(this.month){
                let months = monthsShort();
                let mArr: Array<{id: number, label: string}> = [];
                for(let m of this.month){
                    mArr.push({id: m-1, label: months[m - 1]});
                }
                return mArr;
            }
        }
        return [];
    }
    
    /**
     * Returns a list of 0 or more object that has a day id (integer of week) and the short name for the day
     *
     * @return {*}  {Array<{id: number, label: string}>}
     * @memberof Scheduling
     */
    getDaysObject(): Array<{id: number, label: string}> {
        if(this.checkParsed()){
            if(this.day){
                let days = weekdaysShort();
                let dArr: Array<{id: number, label: string}> = [];

                for(let d of this.day){
                    dArr.push({id: d-1, label: days[d-1]});
                }
                return dArr;
            }
        }
        return [];
    }
    /**
     * Get a difference array. This finds the difference between each number in an array. 
     *
     * @param {(number[] | undefined)} Returns and array of differences
     * @return {*}  {(Array<number> | undefined)}
     * @memberof Scheduling
     */
    getNumberSpan(array: number[] | undefined): Array<number> | undefined{
            if(array){
            
                let arr: Array<number> = [];
            
                for (let i = 0; i < array?.length; i++) {
                
    
                    if(!isNaN(array[i]) && !isNaN(array[i+1])){ // zero is treated as false so we need to simply check if it's a number
                        let diff = Math.abs(array[i] - array[i+1]);

                        arr.push(diff);
                    }
                    
                }
                return arr.length > 0 ? arr : undefined;
            }
            
            return;
    }

    /**
     * Get the from time in HH:mm format this is when a schedule starts in a day
     *
     * @return {*}  {(string | undefined)}
     * @memberof Scheduling
     */
    getFromTime(): string {
        if(this.checkParsed()){
            if(this.hour && this.minute){

                return `${this.hour[0].toString().padStart(2, "0")}:${this.minute[0].toString().padStart(2, "0")}`;
            }
            return moment().format("hh:mm");
        }
        else{
            return moment().format("hh:mm");
        }
        
    }

    /**
     * Gets the to time in HH:mm format this is when a schedule ends in a day
     *
     * @return {*}  {(string | undefined)}
     * @memberof Scheduling
     */
    getToTime(): string {
        if(this.checkParsed()){
            if(this.hour && this.minute){
                return `${this.hour[this.hour.length - 1].toString().padStart(2, "0")}:${this.minute[this.minute.length - 1].toString().padStart(2, "0")}`;
            }
            return moment().format("hh:mm");
        }
        else{
            return moment().format("hh:mm");
        }
        
    }


    
    /**
     * Gets the span of time between each hour if it's the same. Otherwise returns undefined
     *
     * @return {*}  {(number | undefined)}
     * @memberof Scheduling
     */
    getHourDifference(): number
    {
        let hours = this.getHourSpan();
        return hours?.every( val => val === hours?.[0]) ? hours[0] : 1;
    }
    /**
     *Get the span of time between each minute if it's the same. Otherwise returns undefined
     *
     * @return {*}  {(number | undefined)}
     * @memberof Scheduling
     */
    getMinuteDifference(): number
    {
        let minutes = this.getMinuteSpan();
        return minutes?.every( val => val === minutes?.[0]) ? minutes[0] : 5;
    }

    /**
     * Returns the Timezone object from the schedule. UTC is the same as GMT
     *
     * @return {*}  {(ITimezone | undefined)}
     * @memberof Scheduling
     */
    getTimezone(): ITimezone | undefined {
        if(this.checkParsed()){
            if(this.schedule){

                if(this.schedule?.timezone === "UTC" || this.schedule?.timezone === "GMT"){
                    return Timezones.filter(tz => tz.link === "GMT")[0];
                }
                return Timezones.filter(tz => {return tz.link === this.schedule?.timezone;})[0];
            }
            return;
        }
        else{
            return Timezones.filter(tz => {return tz.link === momentTz.tz.guess();})[0];
        }
    }

    /**
     * Parses a crontab expression and sets the class properties
     *
     * @param {string} [cronExp]
     * @return {*}  {Scheduling}
     * @memberof Scheduling
     */
    parse(cronExp?: string | null): Scheduling {
        cronExp = cronExp ? cronExp : this.schedule?.crontab;

        if(cronExp){
            this.parsed = later.parse.cron(cronExp);

            this.day = this.parsed?.schedules[0].d;
            this.days = this.parsed?.schedules[0].D;
            this.month = this.parsed?.schedules[0].M;
            this.hour = this.parsed?.schedules[0].h;
            this.minute = this.parsed?.schedules[0].m;
            this.second = this.parsed?.schedules[0].s;
            try{
                if(this.isDaily(cronExp)){
                    this.type = Recurrence.Daily;
                }
                else if(this.isWeekly(cronExp)){
                    this.type = Recurrence.Weekly;
                }
                else if(this.isMonthly(cronExp)){
                    this.type = Recurrence.Monthly;
                }
                else if(this.isHourly(cronExp)){
                    this.type = Recurrence.Hourly;
                }
                else if(this.isMinutely()){
                    this.type = Recurrence.Minutely;
                }
                else if(this.isSpecificMonths(cronExp)){
                    this.type = Recurrence.SpecificMonths;
                }
                else if(this.isSpecificDays(cronExp)){
                    this.type = Recurrence.SpecificDays;
                }
                
                if(this.type == undefined){
                    throw new CronParseError("Cannot parse scheduling crontab");
                }
            }
            catch(e){
                console.log(e);
            }
            

            return this;
        }

        return this;
    }

    /**
     *Get the first time a schedule will run in a day. This could also be the only time it runs.
     * remove when deprecating analytics_app_CRA_8503_format_hours_24
     * @return {*} 
     * @memberof Scheduling
     */
    getTimeString_deprecated (){
        let date: Date;
        if(this.parsed && this.hour && this.minute){
            date = new Date(0,0,0, this.hour[0], this.minute[0]);
        }
        else{
            date = new Date();
        }

        return date.toLocaleTimeString(navigator.language, {
            hour: '2-digit',
            minute:'2-digit',
            hour12: false,
        }).replace(/ AM|PM/,'').replace(" ", ""); 
        
    }

    /**
     * Get the first time a schedule will run in a day. This could also be the only time it runs.
     *
     * @return string 
     * @memberof Scheduling
     */
     getTimeString ():string{
        let date: Date;
        if(this.parsed && this.hour && this.minute){
            date = new Date(0,0,0, this.hour[0], this.minute[0]);
        }
        else{
            date = new Date();
        }

        /**
         * The structure of the formatToParts() method returns, looks like this:
         * [{ type: "day", value: "17" },{ type: "weekday", value: "Monday" }]
         */
        const dateParts = new Intl.DateTimeFormat('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        }).formatToParts(date);

        // extract hour/minute parts
        //@ts-ignore
        const {hour, minute} = dateParts.reduce((acc, cur) => {acc[cur.type] = cur.value; return acc;}, {});

        const formatHour = (hr:string) => {
            if (hr === '24') {
                return '00';
            }
            return hr;
        };
        const formattedHr = formatHour(hour);
        return `${formattedHr}:${minute}`.replace(/ AM|PM/,'').replace(" ", ""); 
    }

    /**
     * Returns an array of a given increment. Can be used to increment hours or minutes.
     *
     * @param {number} start
     * @param {number} end
     * @param {number} increment
     * @return {*}  {number[]}
     * @memberof Scheduling
     */
    static findIncrements(start: number, end: number, increment: number): number[]{
        try{
            return this._findIncrements(start, end, start, increment, []);
        }catch(e){
            console.log(e);
        }

        return [];
        
    }

    /**
     * Internatl find increments (recursive)
     * @param start 
     * @param end 
     * @param counter 
     * @param increment 
     * @param increments 
     * @returns 
     */
    static  _findIncrements(start: number, end: number, counter: number, increment: number, increments: number[]){
        if(counter > end)
            return increments;

        increments.push(counter);
        counter = counter + increment;
        
        return this._findIncrements(start, end, counter, increment, increments);
    }

    static toString(): string {
        return "";
    }
    
}

export default (schedule?: IReportScheduleDefinition): Scheduling => new Scheduling(schedule);