import React from 'react'
import { createUseStyles } from 'react-jss'

export interface LookerRegionSelectProps {
    onChange: React.ChangeEventHandler<HTMLSelectElement>
}

const useStyles = createUseStyles({
    label:{
        fontSize: "12px",
        color: "#7E7E7E",
        marginLeft:"14px",
        
    },
    select: {
        display: "block",
        minWidth: "196px",
        minHeight: "40px",
        marginTop: "10px",
        borderColor: "#7E7E7E"
    }
})

export default ({field, form, ...props}) => { 
    const classes = useStyles()

    const change = (e) => {
        field.onChange(e)
        props.onChange(e)
    }



    return(
        <div>
            
            <select {...field} {...props} className={classes.select} onChange={change}>
                <option value="US">US</option>
                <option value="CA">CA</option>
                <option value="EU">EU</option>
            </select>
            <label className={classes.label}>Select Region</label>
        </div>
    )
}