import React, {useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import {renameFolder} from '../../../store/actions/foldersActions';
import {hideModal, hideModalError, showModalError} from '../../../store/actions/modalActions';
import {
    Button,
    ButtonContainer,
    Modal,
    ModalFooter,
    ModalHeader,
    TextField
} from '@emburse/embark-core';
import {v4} from 'uuid';
import { IEmburseFolderStructure } from '@src/interfaces';
import { StoreContext } from '@src/components/StoreAccessor/StoreAccessor';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    span: {
        marginTop: "15px",
        width:"100%",
        display: "inline-block"
    }
});

export default ({folder, modal}) => {
    const dispatch = useDispatch();
    const [folderName, setFolderName] = useState(folder.name);
    const folderStuctState = useContext<{state: IEmburseFolderStructure}>(StoreContext);
    const styles = useStyles();
    const maxCharacterLen: number = 100;

    const setNewName = (e) => {
        if (modal.errorMessage) {
            dispatch(hideModalError());
        }
        if(e.target.value.length <= maxCharacterLen){
            setFolderName(e.target.value);
        }
        else{
            dispatch(showModalError("Folder name cannot be greater than 100 characters."));
        }
        
    };

    const closeModal = () => {
        dispatch(hideModal());
        setFolderName('');
    };

    const handleRenameFolderClick = () => {
        if (!folderName) {
            dispatch(showModalError("Folder name cannot be empty"));
        } else {
            dispatch(renameFolder({id: folder.id, name: folderName}, folderStuctState.state));
        }
    };

    const modalHeader = <ModalHeader title="Rename Folder" showClose={true} onCloseClicked={closeModal}/>;

    const modalFooter = (
        <ModalFooter>
            <ButtonContainer alignButtons={'right'}>
                <Button onClick={closeModal} variant="outlined" disabled={modal.loading}>Cancel</Button>
                <Button variant="contained" onClick={handleRenameFolderClick} disabled={modal.loading}>
                    Rename
                </Button>
            </ButtonContainer>
        </ModalFooter>
    );
    return (
        <Modal open modalHeader={modalHeader} modalFooter={modalFooter} onClose={closeModal} isLoading={modal.loading}>
            <div className="da-modal-form">
                <TextField
                    label="Folder Name"
                    id={v4()}
                    value={folderName}
                    onChange={setNewName}
                    fullWidth
                    required
                    autoFocus
                    helperText={modal.errorMessage}
                    error={Boolean(modal.errorMessage)}
                />
                <span className={styles.span}>{folderName.length} / {maxCharacterLen}</span>
            </div>
        </Modal>
    );
};
