import { TextField } from '@emburse/embark-core'
import React from 'react'

export interface FormikTextFieldProps {
    field: any, 
    form: { touched, errors },
    [x: string]: any
}

export default (props: FormikTextFieldProps) => { 
    const onChange = (event) => {
        props.onChange(event)
        props.field.onChange(props.field.name)(event)
    }
    return(<TextField {...props.field} {...props} onChange={onChange} />)
}