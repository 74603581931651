import {
  Alert,
  Button,
  ButtonContainer,
  FormControlLabel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Radio,
  RadioGroup,
  Spacer,
  LinearProgress,
  Typography,
  Link
} from "@emburse/embark-core";
import { ITenantTableUser } from "@src/interfaces";
import { Role, TenantUser, useResource } from "@src/middleware";
import { useEffect, useState } from "react";
import { uniqueId } from "lodash";
import {useSelector, RootStateOrAny} from 'react-redux';
import {IFFlags} from "@src/enums";
export interface AdminUserManagementModalProps {
  isOpen: boolean;
  onClose: (changed?: boolean) => void;
  modalType: string;
  user: ITenantTableUser | null;
  loading?: boolean;
  handleModalSuccess: (successMessage:string) => void;
  handleModalError: (obj: {title:string, message: string}) => void;
}
export const EDIT_USER_TYPE = 'editUserType';
export const REMOVE_USER = 'removeUser';

export const AdminUserManagementModal = (props: AdminUserManagementModalProps) => {
  const [modalSelectedUserType, setUserType] = useState(props.user?.raw.role);
  const [isFetching, setIsFetching] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const allRoles = useResource(Role.getAll());
  useEffect(() => {
  }, [allRoles]);
  // interior components

  //functions for handle
  const closeModal = props.onClose;



  const handleUpdateUserType = () => {
    setIsFetching(true);
    setHasError(false);

    let currentUserType;
    if (props.user) {
      currentUserType = props.user.userType;
    }

    
    // the user clicked the 'apply' button but no user type changes were made, so just close the modal
    if (modalSelectedUserType && getLabelByRoleName(modalSelectedUserType) === currentUserType) {
      return closeModal(false);
    }

    if (props.user?.raw && modalSelectedUserType != undefined) {
      console.log(props.user);
      fetch(`/app/users/${props.user.raw.user_id}`, {
        method: "PATCH",
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
        body: JSON.stringify({ role: modalSelectedUserType }),
      }).then(async (res) => {
        const body = await res.json();

        if (res.status === 200) {
          setIsFetching(false);
          setHasError(false);
          closeModal(true);
          props.handleModalSuccess('User successfully updated.');
        } else {
          setIsFetching(false);
          setHasError(true);
          if (body.Message.includes(`User doesn't have enough licenses`)) {
            closeModal(false);
            props.handleModalError(
              {
                title: 'User not updated',
                message: 'There are no available licenses for this change.'
              }
            );
          } else {
            setErrorMessage(body.Message);
          }
        }
      });
    }
  };

  const handleDeprovisionUser = () => {
    setIsFetching(true);
    setHasError(false);

    if (props.user?.raw) {
      fetch(`/app/users/${props.user.raw.user_id}`, {
        method: "DELETE",
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
        body: JSON.stringify({ role: modalSelectedUserType }),
      }).then(async (res) => {
        if (res.status === 200) {
          setIsFetching(false);
          setHasError(false);
          closeModal(true);
          props.handleModalSuccess("User successfully removed.");
        } else {
          setIsFetching(false);
          setHasError(true);
          closeModal(false);
          props.handleModalError(
            {
              title: 'User not removed',
              message: 'We had trouble deleting the user'
            }
          );
        }
      });
    }
  };

  const onCloseClicked = () => {
    // if a request is in process, prevent user from trying to cancel request by closing modal
    if (isFetching) {
      return;
    } 
    
    closeModal(false);
  };

  const getLabelByRoleName = roleName => allRoles?.find(r => r.name === roleName)?.label;

  const onUserTypeChange = (event) => {
    setUserType(event.target.value);
  };

  const CancelButton = () => (
    <Button
      onClick={() => closeModal(false)}
      variant="outlined"
      disabled={isFetching}
    >
      Cancel
    </Button>
  );
  const getModalHeader = (title: string) => (
    <ModalHeader title={title} showClose={true} onCloseClicked={onCloseClicked} />
  );
  const getModalBody = (jsx) => (
    <ModalBody>
      {isFetching && <LinearProgress color="primary" variant="indeterminate"/>}
      {hasError && errorMessage.length > 0 && (
        <>
          <Alert severity={"error"}>{errorMessage}</Alert>
          <Spacer size={16} direction="vertical" />
        </>
      )}
      {jsx}
    </ModalBody>
  );

  const getModalFooter = (jsx) => (
    <ModalFooter>
    <ButtonContainer alignButtons={"right"}>
      <CancelButton data-qa='cancel'/>
      {jsx}
    </ButtonContainer>
  </ModalFooter>
  );

  const editUserTypeModalBodyJSX = () => {
    const getLabelText = allowedRole => {
      let roleLabel;
      let roleCaption;
      switch (allowedRole) {
        case 'PRO_VIEWER':
        case 'FREE_VIEWER': {
          roleLabel =  'Viewer (Default)',
          roleCaption =  'Can only view existing reports and dashboards.';
        }
        break;
        case 'PRO_CREATOR':
        case 'FREE_CREATOR': {
          roleLabel = 'Creator',
          roleCaption =  'Can build custom reports and organize them into folders for users to view.';
        }
        break;
        case 'CREATOR_NOT_ALLOWED': {
          roleLabel = 'Creator',
          roleCaption = 
            <span>Permissions inherited from source application does not allow this user to be a creator. &nbsp;
              <Link data-qa="learnMoreLink" href="https://help.analytics.emburse.com/hc/en-us/articles/14478748147469" target="_blank"  color="primary" style={{fontWeight: '700'}}>Learn More</Link>
            </span>;
        }
      }
      const isSecondaryTextColor = allowedRole === 'CREATOR_NOT_ALLOWED'? 'textSecondary' : 'inherit';
      return (
        <>
          <Typography variant="body1" style={{fontWeight: '700'}} color={isSecondaryTextColor} data-qa='role'>{roleLabel}</Typography>
          <Typography variant="body1" gutterBottom={true} style={{fontWeight: '400'}} color={isSecondaryTextColor} data-qa='caption'>{roleCaption}</Typography>
        </>
      );
    };

    const usersAllowedRolesList = (props.user?.raw as TenantUser).allowed_roles;
    if (usersAllowedRolesList.length < 2) {
      usersAllowedRolesList.push('CREATOR_NOT_ALLOWED');
    }

    return <RadioGroup name="userType" onChange={onUserTypeChange} value={modalSelectedUserType}>
      {usersAllowedRolesList.map((allowedRole) => (
        <FormControlLabel
          key={uniqueId()}
          value={allowedRole}
          control={<Radio color="primary" />}
          label={getLabelText(allowedRole)}
          disabled={allowedRole === 'CREATOR_NOT_ALLOWED'}
          data-qa={allowedRole}
        />
      ))}
    </RadioGroup>;
  };

  const removeUserModalBodyJSX = () => <Typography color='inherit' variant="body1" data-qa="confirmCopy">Are you sure you want to remove this user? They will no longer have access to Emburse Analytics and their content will be transferred to you.</Typography>;

  const updateUserTypeButton = <Button variant="contained" onClick={handleUpdateUserType} disabled={isFetching} data-qa="apply">Apply</Button>;

  const removeUserButton = <Button color="error" variant="contained" onClick={handleDeprovisionUser} disabled={isFetching} data-qa="remove">Remove</Button>;

  const modalConfig = {
    [EDIT_USER_TYPE] : {
      header: getModalHeader("Edit User Type"),
      body: getModalBody(editUserTypeModalBodyJSX()),
      footer: getModalFooter(updateUserTypeButton)
    },
    [REMOVE_USER]: {
        header: getModalHeader('Remove User'),
        body: getModalBody(removeUserModalBodyJSX()),
        footer: getModalFooter(removeUserButton)
    }
  };
  const getModalConfig = (modalSection: string) => modalConfig[props.modalType][modalSection];
  return (
    <Modal
      open={props.isOpen}
      modalHeader={getModalConfig('header')}
      modalFooter={getModalConfig('footer')}
    >
      {getModalConfig('body')}
    </Modal>
  );
};
