import moment from "moment";
import { EnumType } from "typescript";

export function inEnum(e: EnumType, value: any) {
  return Object.keys(e).includes(value);
}

export function ordinal_suffix_of(i) {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
}

export function isValidMonthsAndDayCombo(
  selected_months: string[],
  selected_day: number
): boolean {
  for (let i = 0; i < selected_months.length; i++) {
    const month = selected_months[i];
    let numberDays = moment(month, "MMM").daysInMonth();

    if (selected_day > numberDays) {
      return false;
    }
  }

  return true;
}

export function toUpperFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// will return the singular or plural form of the word based on the input number.
//If the first argument is an object, return a function which can use the supplied dictionary to resolve the correct plural form of the word.
export function pluralize (val, word, plural = word + 's') {
  const _pluralize = (num, word, plural = word + 's') =>
    [1, -1].includes(Number(num)) ? word : plural;
  if (typeof val === 'object') return (num, word) => _pluralize(num, word, val[word]);
  return _pluralize(val, word, plural);
}

export function toMonthDayYearString(timestamp) {
  const date = new Date(timestamp * 1000);

  const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun", 
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const monthName = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${monthName} ${day}, ${year}`;
}