import React, { useContext, useEffect, useState, useRef } from "react";
import { useDispatch } from 'react-redux';
import { hideModal} from '../../store';
import { ErrorObject } from "ajv";
import {
  EAAddIcon,
  ScheduleFormFooter,
  ScheduleForm,
  ScheduleTypes,
  SchedulesList,
} from "@src/components";
import {
  IDashboard,
  ILook, IUser,
  IDistributionField
} from "@src/interfaces";
import { AnalyticsRole } from "../../enums/AnalyticsRole";
import { Scheduling } from "@src/classes";
import {
  Button,
  Container,
  Modal,
  ModalFooter,
  ModalHeader,
  Typography
} from '@emburse/embark-core';
import { AuthContext } from "../AuthProvider/AuthProvider";
import { IUseAuthInterface } from "@src/hooks/useAuth";
import {toMonthDayYearString} from '../../utility/UtilFunctions';
import {
  SCHEDULE_FORM_VIEW,
  SELECT_SCHEDULE_TYPE_VIEW,
  SCHEDULES_LIST_VIEW,
  SCHEDULE_BURST_FORM_VIEW
} from './constants';
import {ScheduleFormError} from "@components/Schedule/ScheduleValidator";
import {FFlags, getFlag} from "@enums";
import { useSelector } from "react-redux";
import { flagsSelector } from "@src/store/selector";
export interface ScheduleModalProps{
  dashboard?: IDashboard;
  look?: ILook;
  folderId: any;
  schedule?: Scheduling;
  type?: "dashboard" | "look";
  modal?: any
}

/***
 * True if the user is a reviewer or using the two internal email domains.
 * This does not cover all cases but the best we have on FE for test purposes (not for security purposes)
 * @param user
 */
export const isInternalUser = (user: IUser | undefined) => {
  return ((user?.bu_user_unique_id && user?.bu_user_unique_id?.startsWith("review:")) ||
      (user?.email && user?.email?.endsWith("@chromewallet.com")) ||
      (user?.email && user?.email?.endsWith("@emburse.com")));
};

export default (props: ScheduleModalProps) => {
  const { user } = useContext<IUseAuthInterface>(AuthContext);
  const [schedule, setSchedule] = useState<Scheduling>(new Scheduling);
  const [modalState, setModalState] = useState(SCHEDULES_LIST_VIEW); 
  const [item, setItem] = useState<ILook | IDashboard >();
  const dispatch = useDispatch();
  const [formErrors, setFormErrors] = useState<ScheduleFormError[]>();
  const [formType, setFormType] = useState<string>();
  const flags = useSelector(flagsSelector);
  let scheduleNameInput = useRef("");
  const emailInput = useRef();
  const [isFormReady, setFormReady] = useState<boolean>(false);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [distributionFields, setDistributionFields] = useState<IDistributionField[]>([]);
  const [currentFilterExpressions, setCurrentFilterExpressions] = useState<{ [key: string]: string }>(
    {}
  );
  useEffect(() => {
    fetch(
      `/app/${props.dashboard ? "dashboard" : "look"}s/${
        props.dashboard ? props.dashboard.id : props.look?.id
      }${"/info?include_filters=true&include_fields=true"}`
    )
      .then(async (i) => {
        let body = await i.json();
        setItem(body);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  const closeModal = () => {
    return dispatch(hideModal());
  };

  const handleCreateScheduleButton = () => {

    if (flags?.[getFlag(FFlags.BurstEnabledForInternalUsersOnly)]) {
      if (isInternalUser(user)) {
        // internal user special case
        const modalState = user?.analytics_role === AnalyticsRole.ProCreator && flags?.[getFlag(FFlags.EnableSaveBurst)] ? SELECT_SCHEDULE_TYPE_VIEW : SCHEDULE_FORM_VIEW;
        setModalState(modalState);
      } else {
        // not an internal user, send to standard UI
        setModalState(SCHEDULE_FORM_VIEW);
      }
      return;
    }
    setModalState(user?.analytics_role === AnalyticsRole.ProCreator && flags?.[getFlag(FFlags.EnableSaveBurst)] ? SELECT_SCHEDULE_TYPE_VIEW : SCHEDULE_FORM_VIEW);
  };

  const closeEditForm = () => {
    setFormErrors([]);
    setSchedule(new Scheduling);
    if(flags?.[getFlag(FFlags.EmptyScheduleFormNameInput)]) {
      scheduleNameInput.current = "";
    }
    setModalState && setModalState(SCHEDULES_LIST_VIEW);
  };
  const getModalHeaderOpeningText = () => {
    if (modalState === SCHEDULES_LIST_VIEW) return 'Schedules using';
    if (modalState === SCHEDULE_FORM_VIEW) return 'Schedule using';
    if (modalState === SELECT_SCHEDULE_TYPE_VIEW) return 'Create a new schedule using';
  };

  const getModalBodyJSX = () => {
    if (modalState === SCHEDULES_LIST_VIEW) {
      return <SchedulesList
        item={item as IDashboard | ILook}
        dashboard={props.dashboard}
        look={props.look}
        type={props.dashboard ? "dashboard" : "look"}
        setSchedule={setSchedule}
        setModalState={setModalState}
      />;
    } else if (modalState === SCHEDULE_FORM_VIEW || modalState === SCHEDULE_BURST_FORM_VIEW) {
      return <ScheduleForm
        setFormReady={setFormReady}
        scheduleNameInputRef={scheduleNameInput}
        emailInputRef={emailInput}
        schedule={schedule}
        setSchedule={setSchedule as React.Dispatch<React.SetStateAction<Scheduling>>}
        item={item as IDashboard | ILook}
        type={props.dashboard ? "dashboard" : "look"}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        layout={modalState === SCHEDULE_BURST_FORM_VIEW ? "burst" : "standard"}
        currentFilterExpressions={currentFilterExpressions}
        setCurrentFilterExpressions={setCurrentFilterExpressions}
        distributionFields={distributionFields}
        setDistributionFields={setDistributionFields}

      />;
    } else if (modalState === SELECT_SCHEDULE_TYPE_VIEW) {

      return <ScheduleTypes setModalState={setModalState}/>;
    }
  };

  const getModalFooterJSX = () => {
    const hasAllowedRoles = () => {
      if (flags?.[getFlag(FFlags.FreeCreatorHasCreateScheduleButton)]) {
        return [AnalyticsRole.ProCreator, AnalyticsRole.FreeCreator].includes(user?.analytics_role as AnalyticsRole);
      }
      return [AnalyticsRole.ProCreator].includes(user?.analytics_role as AnalyticsRole);
    };

    if (modalState === SCHEDULES_LIST_VIEW) {
      return <ModalFooter>
        { hasAllowedRoles()  && (
          <Button
            startIcon={<EAAddIcon />}
            disabled={!item}
            sx={{width: '100%'}}
            onClick={handleCreateScheduleButton}
          >
            Create Schedule
          </Button>
        )}
      </ModalFooter>;
    } else if (modalState === SCHEDULE_FORM_VIEW || modalState === SCHEDULE_BURST_FORM_VIEW ) {
      return <ScheduleFormFooter
        isFormReady={isFormReady}
        setShowWarningModal={setShowWarningModal}
        showWarningModal={showWarningModal}
        closeEditForm={closeEditForm}
        scheduleNameInputRef={scheduleNameInput}
        schedule={schedule}
        closeModal={closeModal}
        setFormErrors={setFormErrors}
        folderId={props.folderId}
        currentFilterExpressions={currentFilterExpressions}
        distributionFields={distributionFields}
        />;
    } else if (modalState === SELECT_SCHEDULE_TYPE_VIEW) {
      // there is no footer for this screen
      return;
    }
  };

  function schedulesListViewHeader() {
    return <>
      <Typography color='secondary' >{getModalHeaderOpeningText()}</Typography>
      <Typography color='secondary' variant='h5'>{props.look ? props.look.title : props.dashboard?.title}</Typography>
      <Typography color='secondary'>
            <span>{
              props.look ?
                  `${toMonthDayYearString(props.look.updatedAt)} | ${props.look.model.label}` :
                  `${toMonthDayYearString(props.dashboard?.createdAt)}`
            }</span>
      </Typography>
    </>;
  }

  function scheduleFormViewHeader() {

    if (modalState === SCHEDULE_BURST_FORM_VIEW) {
      return <>
        <Typography color='secondary'>{props.schedule ? "Update Burst Schedule using" : "Create new Burst Schedule using"}</Typography>
        <Typography color='secondary' variant='h5'>{props.look ? props.look.title : props.dashboard?.title}</Typography>
      </>;
    } else {
      return <>
        <Typography color='secondary'>{props.schedule ? "Update Schedule using" : "Create new Schedule using"}</Typography>
        <Typography color='secondary' variant='h5'>{props.look ? props.look.title : props.dashboard?.title}</Typography>
      </>;
    }

  }

  const modalHeaderJSX =
    <>
      <ModalHeader
        title={undefined} // title is rendered in a separate component, but this way, we keep the close modal functionality that comes from modalHeader
        showClose={true}
        onCloseClicked={closeModal}
        sx={{
          padding: 0,
          ":after": {
            'width': '100%',
            'bottom': '-94px',
            'left': '0'
          },
          "& > h5": {
            display: 'none'
          },
          "& > button": {
            position: 'absolute',
            top: '20px',
            right: '30px'
          } 
        }}
      />
      <Container sx={{p: '20px'}}>
        {modalState == SCHEDULES_LIST_VIEW && schedulesListViewHeader()}
        {modalState == SCHEDULE_FORM_VIEW && scheduleFormViewHeader()}
        {modalState == SCHEDULE_BURST_FORM_VIEW && scheduleFormViewHeader()}
        {modalState == SELECT_SCHEDULE_TYPE_VIEW && schedulesListViewHeader()}
      </Container>
    </>;

  return (
    <Modal 
      open 
      modalHeader={modalHeaderJSX}
      modalFooter={getModalFooterJSX()}
      onClose={closeModal}
      isLoading={props.modal.loading}
      disableBackdropClick={true}
      sx={{minHeight: '400px'}}
    >
      {getModalBodyJSX()}
    </Modal>
    );
};
