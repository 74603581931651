import {
  Autocomplete,
  Modal,
  ModalHeader,
  ModalFooter,
  Grid,
  Button,
  Typography,
  Spacer,
} from '@emburse/embark-core';
import { 
  IHasChildrenProps,
  IReportScheduleDefinition,
  ITenantTableUser,
 } from '@src/interfaces';
import React, { useEffect, useRef, useState } from 'react';
import {Roles} from '../../../enums';
export interface ReassignOwnerModalProps extends IHasChildrenProps {
  open: boolean;
  showClose?: boolean;
  schedule: IReportScheduleDefinition | null;
  onCloseClicked: () => void;
  onConfirm: (
    selectedOwner: ICreatorUser, event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => Promise<boolean>;
}

interface ICreatorUser extends ITenantTableUser {
  title?: string;
}
export const ReassignOwnerModal = (props: ReassignOwnerModalProps) => {
  const unmounted = useRef(false);
  const [creatorUsers, setCreatorUsers] = useState<ICreatorUser[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedOwner, setSelectedOwner] = useState<ICreatorUser>();


  useEffect(() => {
    fetchUsers();
  },[]);
  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);
  /**
   * This handles the confirm action. Sets the loading to true and waits until callback is finished.
   *
   * @param {*} e
   */
  const handleReassignOwner = async (e) => {
    setErrorMessage('');
    setIsLoading(true);
    // @ts-ignore
    const updated = await props.onConfirm(selectedOwner).catch(() => {
      setIsLoading(false);
      setErrorMessage(
        'An error occured when attempting to update schedule owner.'
      );
    });
    if (!unmounted.current) {
      if (updated) {
        props.onCloseClicked();
      } else {
        setIsLoading(false);
        setErrorMessage(
          'An error occured when attempting to update schedule owner.'
        );
      }
    }
  };
  async function fetchUsers() {
    fetch('/app/users')
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error("Failed to fetch users");
        }
      })
      .then((data: { users: ITenantTableUser[]; total: number }) => {
        setCreatorUsers(data.users.filter(user => user.role === Roles.PRO_CREATOR).sort((a,b) => `${a.first_name} ${a.last_name}`.localeCompare(`${b.first_name} ${b.last_name}))`)));
      })
      .catch((error) => {
        return error;
      });
  }

  const handleOwnerChange = (_e: any, v: ICreatorUser) => {
    if (v) setSelectedOwner(v);
  };

  const header = (
    <ModalHeader
      showClose={true}
      title="Reassign Owner"
      onCloseClicked={props.onCloseClicked}
    />
  );

  const footer = (
    <ModalFooter>
      <Grid container justifyContent="flex-end" spacing={1}>
        <Grid item>
          <Button
            onClick={props.onCloseClicked}
            variant="outlined"
            color="primary"
            disabled={isLoading}
            data-tag="cancelReassignOwner"
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={handleReassignOwner}
            variant="contained"
            color="primary"
            disabled={isLoading}
            data-tag="confirmReassignOwner"
          >
            Reassign
          </Button>
        </Grid>
      </Grid>
    </ModalFooter>
  );

  return (
    <>
      <Modal
        open={props.open}
        onClose={(_e, reason) => {
          if (reason === 'backdropClick') {
            props.onCloseClicked();
          }
        }}
        modalHeader={header}
        modalFooter={footer}
        isLoading={isLoading}
      >
        <Grid>
          <Typography>
            Reassign schedule owner for {props.schedule?.name} to
          </Typography>
          <Spacer size={16} direction="vertical" />
          <Autocomplete
            label={'Search User'}
            options={
              creatorUsers.reduce((acc:{title:string}[], curr) => {
                acc.push({
                  ...curr,
                  title: `${curr.first_name} ${curr.last_name}`,
                });
                return acc;
              }, [])
            }
            getOptionLabel={(option) => { return option.title; }}
            onChange={handleOwnerChange}
            fullWidth
          />
          {errorMessage !== '' && (
            <Grid item>
              <Typography variant="caption" color="error">
                {errorMessage}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Modal>
    </>
  );
};
