import React from 'react'
import EmptyPage from '../EmptyPage/EmptyPage'
import Cactus from '../Icons/Cactus'

export default () => {
    return (
        <EmptyPage>
            <Cactus />
            <div className="da-empty-page-text-block">
                <h6 className="da-empty-page-text-block-heading">404 Page Not Found</h6>
                <p>Somethings not quite right.</p>   
            </div>
        </EmptyPage>
    )
}