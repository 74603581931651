import React, { useRef, useState } from 'react';
import { Card, Link, Button, TextField, Typography, Grid } from '@emburse/embark-core';
import {Formik} from 'formik';
import { useFormikContext, Field, Form } from 'formik';
import { v4 } from 'uuid';
import { useContext } from 'react';
import { AuthContext } from '../AuthProvider/AuthProvider';
import { IUseAuthInterface } from '../../hooks/useAuth';
import { IFolder, ILook, IUser } from '@src/interfaces';
import { FormikTextField, LookerRegionSelect } from '@src/components';
import { flagsSelector } from '../../store/selector';
import { useSelector } from 'react-redux';
import { FFlags, getFlag } from '@src/enums';
import {useEffect} from 'react';



export const dashboardIdField = ({field, form: { touched, errors }, ...props}) => { 
    return(<TextField {...field} {...props} label="Look ID" helperText="The look to copy" />);
};

export const folderIdField = ({field, form: { touched, errors }, ...props}) => { 
    return(<TextField {...field} {...props} label="Folder ID" helperText="The folder to copy to" />);
};

const ActionButton = (props: {buttonText: string, loading: boolean}) => {
    const { submitForm } = useFormikContext();

    return (
        <Button size="small" variant="outlined" disabled={props.loading} onClick={submitForm}>{props.buttonText}</Button>
    );
};

export default () => {
    const [isOk, setIsOk] = useState<boolean | undefined>(undefined); 
    const [loading, setLoading] = useState<boolean>(false); 
    const userData: IUser = useContext<IUseAuthInterface>(AuthContext).user as IUser;
    const flags = useSelector(flagsSelector);
    const [folderSearchError, setFolderSearchError] = useState<boolean>(false);
    const [folderInfo, setFolderInfo] = useState<IFolder | undefined>(undefined);
    const [lookInfo, setLookInfo] = useState<ILook | undefined>(undefined);
    const [lookSearchError, setLookSearchError] = useState<boolean>(false);
    let folderAC: AbortController;
    let lookAC: AbortController;
    const formRef = useRef<any>();

    let initialValues; 

    initialValues = {
        look_id: '',
        folder_id: '',
        region: userData.looker_region
    };

    const handleLookIDOnChange = async (e) => {
        setLookSearchError(false);
        setLookInfo(undefined);  

        // we want to handle the race condition here. 
        if(lookAC){
            lookAC.abort();
        }

        lookAC = new AbortController();
        
        if(e.target.value != ""){
            await fetch(`/app/looks/${e.target.value}/info`, {signal: lookAC.signal}).then(async (res: Response) => {
                
                if(res.ok){
                    setLookSearchError(false);
                    let body = await res.json();
                    console.log(body);
                    setLookInfo(body);
                }
                else{
                    throw Error(res.toString());
                }
            }).catch(err => {
                setLookSearchError(true);
                setLookInfo(undefined);  
                console.log(err);
            });
        }
    };

    const handleFolderIDOnChange = (e) => {
        searchFolder(e.target.value, formRef.current.values.region);
    };

    const handleRegionOnChange = (e) => {
        searchFolder(formRef.current.values.folder_id, e.target.value);
    };

    // Duplication Function needs to get moved or Merge CopyDashboard into this component
    const searchFolder = (id, region) => {
        setFolderSearchError(false);
        setFolderInfo(undefined);  

        if(folderAC){
            folderAC.abort();
        }

        fetch(`/app/folders/${id}/info?include_path=true&region=${region}`, {signal: folderAC?.signal}).then(async (res: Response) => {

            if(res.ok){
                setFolderSearchError(false);
                let body = await res.json();

                if(body === null){
                    throw Error("Not Found");
                }
                setFolderInfo(body);
            }
            else{
                throw Error(res.toString());
            }
        }).catch(err => {
            setFolderSearchError(true);
            setFolderInfo(undefined);    
        });
    };


    const formikSubmit = (values) => {
        console.log(values);
        setIsOk(undefined);
        setLoading(true);
        
        fetch(`/app/admin/looks/copy`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values)
        }).then((res: Response) => {
            res.json().then(value => {
                console.log(value);

                if(value["to_look_id"]){
                    setIsOk(res.ok ? true : false);
                }
                else{
                    setIsOk(false);
                }
            });

            setLoading(false);
        });
    };

    return (
        <>
        <Formik initialValues={initialValues} onSubmit={(values) => formikSubmit(values)} innerRef={formRef}>
            <Card variant="elevation" actions={<ActionButton buttonText={loading ? "Copying..." : "Copy"} loading={loading}/>}>
                <Typography variant="h5" gutterBottom>Copy A Look</Typography>
                <Typography variant="body1" gutterBottom>
                    Copy a Look to a Customer's folder. If a look with the same name exists it the new one will be renamed and this will be visible to end users. e.g Look (2). Looks can be deleted in <Link href="https://emburse.cloud.looker.com/folders/4">Looker's folder browser</Link>
                </Typography>
                {flags?.[getFlag(FFlags.ShowCopyInfo)] && 
                    <Grid container direction="row" spacing={1}>
                        <Grid item sm={6}>
                            <Typography variant="subtitle1">Look Info</Typography>
                            {lookInfo &&
                                <>
                                    <Typography variant="body1">ID: {lookInfo?.id}</Typography>
                                    <Typography variant="body1">Title: {lookInfo?.title}</Typography>
                                    <Typography variant="body1">Description: {lookInfo?.description}</Typography>
                                </>
                            }
                        </Grid>
                        <Grid item sm={6}>
                                <Typography variant="subtitle1">Folder Info</Typography>
                                {folderInfo && 
                                    <>
                                        <Typography variant="body1">ID: {folderInfo?.id}</Typography>
                                        <Typography variant="body1">Title: {folderInfo?.name}</Typography>
                                        <Typography variant="body1">Path: {folderInfo?.path?.join("/")}</Typography>
                                        <Typography variant="body1" gutterBottom >Is Personal: {folderInfo?.isPersonal.toString()}</Typography>
                                    </>
                                }
                        </Grid>
                    </Grid>
                }
                <br />

                <Form>
                    <Grid container direction="row" wrap="wrap">
                        <Grid item sm={6}>
                            <Field  component={FormikTextField} 
                                id="look_id" 
                                name="look_id" 
                                label="Look ID"
                                onChange={flags?.[getFlag(FFlags.ShowCopyInfo)] ? handleLookIDOnChange : () => {}} 
                                error={lookSearchError} 
                                helperText={lookSearchError ? "Invalid Look" : "The look to copy"}/>
                        </Grid>
                        <Grid item sm={6}>
                            <Field  component={FormikTextField} 
                                    id="folder_id" 
                                    name="folder_id" 
                                    label="Folder ID"
                                    error={folderSearchError} 
                                    onChange={flags?.[getFlag(FFlags.ShowCopyInfo)] ? handleFolderIDOnChange : () => {}} 
                                    helperText={folderSearchError ? "Invalid Folder" : "The folder to copy"}/>
                        </Grid>
                        <Grid item sm={6}>
                            <>
                            </>
                        </Grid>
                        <Grid item sm={6}>
                            <Field component={LookerRegionSelect} id="region" name="region" label="Region"  onChange={flags?.[getFlag(FFlags.ShowCopyInfo)] ? handleRegionOnChange : () => {}}/>
                        </Grid>
                    </Grid>
                </Form>
                {
                    isOk &&
                    <p>Look Copied</p>
                }
                {
                    !isOk && isOk !== undefined && 
                    <p>Look Copy Error</p>
                }
            </Card>
           
        </Formik>
        </>
    );
};
