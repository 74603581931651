import { RequestCache } from "../common";
import { Entity, BaseResource } from "./BaseResource";

export interface ISchedule extends Entity {}

/**
 * The Schedule resource. Used for fetch entities of ISchedule
 *
 * @export
 * @class Schedule
 * @extends {BaseResource<ISchedule>}
 */
export class ScheduleResource extends BaseResource<ISchedule> {
  public readonly expiry = 300000; //5 minutes random...
  public readonly urlRoot = "/app/schedule";
  public readonly cache = true;
  public readonly cacheType = RequestCache.RELOAD;
}

export const Schedule = new ScheduleResource();
