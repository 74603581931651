import {
  Modal,
  ModalHeader,
  ModalFooter,
  Grid,
  Button,
  Typography
} from '@emburse/embark-core';
// import { createUseStyles } from 'react-jss';
import { IHasChildrenProps } from '@src/interfaces';
import React, { useEffect, useRef, useState } from 'react';

export interface SimpleConfirmModalProps extends IHasChildrenProps {
  showClose?: boolean;
  headerTitle: string;
  onCloseClicked: () => void;
  onConfirm?: (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => Promise<boolean>;
  confirmText: string;
  open: boolean;
  cancelDataTag?: string;
  confirmDeleteDataTag?: string;
}

// const useStyles = createUseStyles({});

export default (props: SimpleConfirmModalProps) => {
  // const classes = useStyles();
  const unmounted = useRef(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /**
   * This handles the confirm action. Sets the loading to true and waits until callback is finished.
   *
   * @param {*} e
   */
  const confirmHandle = async (e) => {
    setErrorMessage('');
    setIsLoading(true);
    const deleted = await props.onConfirm?.().catch(() => {
      setIsLoading(false);
      setErrorMessage(
        'An error occured when attempting to delete delivery schedule.'
      );
    });
    if (!unmounted.current) {
      if (deleted) {
        props.onCloseClicked();
      } else {
        setIsLoading(false);
        setErrorMessage(
          'An error occured when attempting to delete delivery schedule.'
        );
      }
    }
  };

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  const header = (
    <ModalHeader
      showClose={props.showClose}
      title={props.headerTitle}
      onCloseClicked={props.onCloseClicked}
    />
  );

  const footer = (
    <ModalFooter>
      <Grid container justifyContent="flex-end" spacing={1}>
        <Grid item>
          <Button
            onClick={props.onCloseClicked}
            variant="outlined"
            color="primary"
            disabled={isLoading}
            data-tag={props.cancelDataTag}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={confirmHandle}
            variant="contained"
            color="error"
            disabled={isLoading}
            data-tag={props.confirmDeleteDataTag}
          >
            {props.confirmText}
          </Button>
        </Grid>
      </Grid>
    </ModalFooter>
  );

  return (
    <>
      <Modal
          onClose={(_e, reason) => {
            if (reason === 'backdropClick') {
              props.onCloseClicked();
            }
          }}
        modalHeader={header}
        open={props.open}
        modalFooter={footer}
        isLoading={isLoading}
      >
        <Grid container spacing={2}>
          <Grid item>{props.children}</Grid>
          {errorMessage !== '' && (
            <Grid item>
              <Typography variant="caption" color="error">
                {errorMessage}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Modal>
    </>
  );
};
