import { ILookerFrameActiveContent, ILookerFrameRequestedContent } from "../../interfaces";
import {INIT, LOADED_CONTENT, LOADING_CONTENT, LOOKER_ERROR, LOOKER_RUNNING, LOOKER_SUCCESS} from "../types";

const LOOKER_STATE_INITIALIZING = 'Initializing'
const LOOKER_STATE_INITIALIZED = 'Initialized'
const LOOKER_STATE_LOADING = 'Loading frame'
const LOOKER_STATE_RUNNING = 'Loading data'
const LOOKER_STATE_DONE = 'Done'
const LOOKER_STATE_ERROR = 'Error'


interface ILookerFrameState{
    activeContent: ILookerFrameActiveContent,
    requestedContent: ILookerFrameRequestedContent,
    lookerState: String 
}

const initialState: ILookerFrameState = {
    activeContent: {
        id: null,
        type: null
    },
    requestedContent: {
        id: null,
        type: null,
        url: null
    },
    lookerState: LOOKER_STATE_INITIALIZING
}

export const lookerFrameReducer = (state = initialState, action) => {
    switch (action.type) {
        case INIT:
            return {...state, lookerState: LOOKER_STATE_INITIALIZED}
        case LOOKER_RUNNING:
            return {...state, lookerState: LOOKER_STATE_RUNNING}
        case LOOKER_SUCCESS:
            return {...state, lookerState: LOOKER_STATE_DONE}
        case LOOKER_ERROR:
            return {...state, lookerState: LOOKER_STATE_ERROR}
        case LOADING_CONTENT:
            return {...state, requestedContent: action.payload, lookerState: LOOKER_STATE_LOADING}
        case LOADED_CONTENT:
            return {...state, activeContent: action.payload}
        default:
            return state
    }
};
