export const ROOT_ROUTE = "/";
export const API_ROUTE_PREFIX = "/app/";
export const APP_ROUTE_PREFIX = "/app/p/";

export const BASE_FOLDERS_ROUTE = APP_ROUTE_PREFIX + "folders/";
export const FOLDERS_ROUTE = BASE_FOLDERS_ROUTE + ":folderId";
export const FOLDERS_ALL_ROUTE = BASE_FOLDERS_ROUTE + "all";
export const FOLDERS_HOME_ROUTE = BASE_FOLDERS_ROUTE + "home";

export const BASE_DASHBOARDS_ROUTE = APP_ROUTE_PREFIX + "dashboards/";
export const DASHBOARDS_ROUTE = BASE_DASHBOARDS_ROUTE + ":contentId";

export const BASE_LOOKS_ROUTE = APP_ROUTE_PREFIX + "looks/";
export const LOOKS_ROUTE = BASE_LOOKS_ROUTE + ":contentId";

export const BASE_EXPLORE_ROUTE = APP_ROUTE_PREFIX + "explore/";
export const EXPLORE_ROUTE = BASE_EXPLORE_ROUTE + ":contentId";

export const UNAUTHORIZED_ROUTE = "/unauthorized";
export const LOGOUT_ROUTE = "/logout";

export const PROVISIONING_ROUTE = "/provisioning";
export const ERROR_ROUTE = "/error";

export const ADMIN_PAGE_ROUTE = APP_ROUTE_PREFIX + "admin";
export const BASE_ADMIN_ROUTE = APP_ROUTE_PREFIX + "admin/";

export const ADMIN_INTERNAL_TOOLS_ROUTE = BASE_ADMIN_ROUTE + "internal/tools";
export const ADMIN_INTERNAL_CUSTOMERS_ROUTE = BASE_ADMIN_ROUTE + "internal/customers";
export const ADMIN_CONNECT_MANAGEMENT = BASE_ADMIN_ROUTE + "connect";
export const ADMIN_SCHEDULE_MANAGEMENT = BASE_ADMIN_ROUTE + "schedules";
export const ADMIN_BURST_SCHEDULE_MANAGEMENT = BASE_ADMIN_ROUTE + "burst-schedules";
export const ADMIN_USER_MANAGEMENT = BASE_ADMIN_ROUTE + "users";

export const LICENSE_LIMIT_EXCEEDED_ROUTE = APP_ROUTE_PREFIX + "license-limit-exceeded";
export const PROVISIONED_LICENSE_COUNT_EXCEEDED_ROUTE = APP_ROUTE_PREFIX + "license-count-exceeded";