// APP CONSTANTS
export const ADD_ERROR = 'APP/ADD_ERROR'
export const ADD_ALERT = 'APP/ADD_ALERT'
export const REMOVE_ALERT = 'APP/REMOVE_ALERT'
// FOLDER CONSTANTS
export const FETCH_FOLDERS = 'FOLDER/FETCH_FOLDERS'
export const ADD_FOLDER = 'FOLDER/ADD_FOLDER'
export const UPDATE_FOLDER = 'FOLDER/UPDATE_FOLDER'
export const DELETE_FOLDER = 'FOLDER/DELETE_FOLDER'
export const FETCH_FOLDER_CONTENT = 'FOLDER/FETCH_FOLDER_CONTENT'
export const SET_ACTIVE_FOLDER = 'FOLDER/SET_ACTIVE_FOLDER'
export const SELECT_FOLDER_ITEM = 'FOLDER/SELECT_FOLDER_ITEM'
export const SET_FOLDER_STRUCTURE = 'FOLDER/SET_STRUCTURE'

// MODAL CONSTANTS
export const SHOW_MODAL = 'MODAL/SHOW_MODAL'
export const HIDE_MODAL = 'MODAL/HIDE_MODAL'
export const SHOW_MODAL_ERROR = 'MODAL/SHOW_MODAL_ERROR'
export const HIDE_MODAL_ERROR = 'MODAL/HIDE_MODAL_ERROR'
export const SET_MODAL_LOADING = 'MODAL/SET_MODAL_LOADING'
export const SET_MODAL_LOADED = 'MODAL/SET_MODAL_LOADED'
export const SET_MODAL_DATA = 'MODAL/SET_MODAL_DATA'

export const INIT = 'LOOKER_FRAME/INIT'
export const LOADING_CONTENT = 'LOOKER_FRAME/LOADING_CONTENT'
export const LOADED_CONTENT = 'LOOKER_FRAME/LOADED_CONTENT'
export const LOOKER_RUNNING = 'LOOKER_FRAME/RUNNING'
export const LOOKER_SUCCESS = 'LOOKER_FRAME/SUCCESS'
export const LOOKER_ERROR = 'LOOKER_FRAME/ERROR'

// MODEL CONSTANTS
export const FETCH_MODELS = 'FETCH_MODELS';
// DASHBOARD CONSTANTS
export const ADD_DASHBOARD = 'DASHBOARD/ADD_DASHBOARD'
export const DELETE_DASHBOARD = "DASHBOARD/DELETE_DASHBOARD"
export const COPY_DASHBOARD = "DASHBOARD/COPY_DASHBOARD"
export const MOVE_DASHBOARD = "DASHBOARD/MOVE_DASHBOARD"
export const UPDATE_DASHBOARD = "DASHBOARD/UPDATE_DASHBOARD"

// USER CONSTANTS 
export const FETCH_USER_DATA = 'USER/FETCH_DATA'
export const SET_USER_DATA = 'USER/SET_DATA'
export const SET_USER_FEATURE_FLAGS = 'USER/SET_FLAGS'
export const LOGOUT_USER = 'USER/LOGOUT'
export const SET_USER_SESSION_STATUS = 'USER/SET_SESSION_STATUS'
export const SET_USER_SESSION_ID = 'USER/SET_SESSION_ID'

//LOOK CONSTANTS
export const DELETE_LOOK = "LOOK/DELETE_LOOK"
export const COPY_LOOK = "LOOK/COPY_LOOK"
export const MOVE_LOOK = "LOOK/MOVE_LOOK"
export const UPDATE_LOOK = "LOOK/UPDATE_LOOK"