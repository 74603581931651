import { IPermissions } from '../interfaces/IPermissions';

export const FalsyPermissions: IPermissions  = {
    create: false,
    update: false,
    destroy: false,
    editContent: false
}

export const TruthyPermissions: IPermissions = {
    create: true,
    update: true,
    destroy: true,
    editContent: true
}