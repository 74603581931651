import { Button, ButtonGroup, Grid } from '@emburse/embark-core';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { weekdaysShort } from 'moment';

export interface DaySelectorProps {
    selected: {id:number, label: string}[],
    onChange: React.Dispatch<React.SetStateAction<{id: number, label: string}[]>>,
}

const useStyles = createUseStyles({
});

export default (props: DaySelectorProps) => {
    const classes = useStyles();

    const handleOnChange =  (name, index) => {
        if(props.selected.filter(v => (v.id === index && v.label === name)).length > 0){
            props.onChange(props.selected.filter(v => (v.id !== index && v.label !== name)));
        }
        else{
            props.onChange([...props.selected, {id: index, label: name}]);
        }
    };
    const selectContainsItem = (name, index) => { 
        return props.selected.filter(v => (v.id === index && v.label === name)).length > 0;
    };

    return (
        <Grid container justifyContent="space-between" spacing={2}>
            {weekdaysShort().map((v,i) => {
                return (
                    <Grid item key={i}>
                        <Button style={{width: "100%"}} onClick={() => handleOnChange(v,i)} variant={selectContainsItem(v,i) ? "contained" : "outlined"} color="primary">{v}</Button>
                    </Grid>
                );
            })}
        </ Grid>
    );
};