import React from 'react'
import EmptyPage from '@components/EmptyPage/EmptyPage'
import Cactus from '../Icons/Cactus'

export default () => {
    let list 
    return(
        <EmptyPage>
                <Cactus />
                <div className="da-empty-page-text-block">
                    <h6 className="da-empty-page-text-block-heading">Unauthorized</h6>
                    <p>You are not authorized to view this page. Please try logging in again.</p>   
                </div>
        </EmptyPage>
    )
}