import React, { useContext, useRef, useState } from "react";
import {
  Card,
  Checkbox,
  Link,
  Button,
  Typography,
  Grid,
} from "@emburse/embark-core";
import { Formik, Form, Field, useFormikContext } from "formik";
import { LookerRegionSelect, FormikTextField } from "@src/components";
import { IUseAuthInterface } from "@src/hooks/useAuth";
import { IDashboard, IUser } from "@src/interfaces";
import { AuthContext } from "../AuthProvider/AuthProvider";
import { useSelector } from "react-redux";
import { flagsSelector } from "@src/store/selector";

const ActionButton = (props: { buttonText: string; loading: boolean }) => {
  const { submitForm } = useFormikContext();

  return (
    <Button
      size="small"
      variant="outlined"
      onClick={submitForm}
      disabled={props.loading}
    >
      {props.buttonText}
    </Button>
  );
};

export default () => {
  const [isOk, setIsOk] = useState<boolean | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const [srcDashInfo, setSrcDashInfo] = useState<IDashboard | undefined>(undefined);
  const [srcDashSearchError, setSrcDashSearchError] = useState<boolean>(false);

  const [destDashInfo, setDestDashInfo] = useState<IDashboard | undefined>(undefined);
  const [destDashSearchError, setDestDashSearchError] = useState<boolean>(false);

  const [createBackup, setCreateBackup] = useState(true);

  const userData: IUser = useContext<IUseAuthInterface>(AuthContext)
    .user as IUser;
  const flags = useSelector(flagsSelector);
  let dashAC: AbortController;
  const formRef = useRef<any>();

  let initialValues;

  initialValues = {
    src_dashboard_id: "",
    dest_dashboard_id: "",
    dest_region: userData.looker_region,
  };

  const handleSrcDashIDOnChange = async (e) => {
    setSrcDashSearchError(false);
    setSrcDashInfo(undefined);

    // we want to handle the race condition here.
    if (dashAC) {
      dashAC.abort();
    }

    dashAC = new AbortController();

    if (e.target.value != "") {
      await fetch(`/app/dashboards/${e.target.value}/info`, {
        signal: dashAC.signal,
      })
        .then(async (res: Response) => {
          if (res.ok) {
            setSrcDashSearchError(false);
            let body = await res.json();
            console.log(body);
            setSrcDashInfo(body);
          } else {
            throw Error(res.toString());
          }
        })
        .catch((err) => {
          setSrcDashSearchError(true);
          setSrcDashInfo(undefined);
          console.log(err);
        });
    }
  };


  const searchDestDashboard = (id, region) => {
    setDestDashSearchError(false);
    setDestDashInfo(undefined);

    // we want to handle the race condition here.
    if (dashAC) {
      dashAC.abort();
    }

    dashAC = new AbortController();

    if (id != "") {
      fetch(`/app/dashboards/${id}/info?region=${region}`, {
        signal: dashAC.signal,
      })
        .then(async (res: Response) => {
          if (res.ok) {
            setDestDashSearchError(false);
            let body = await res.json();
            console.log(body);
            setDestDashInfo(body);
          } else {
            throw Error(res.toString());
          }
        })
        .catch((err) => {
          setDestDashSearchError(true);
          setDestDashInfo(undefined);
          console.log(err);
        });
    }
  };

  const handleDestDashIDOnChange = (e) => {
    console.log(formRef.current.values)
    searchDestDashboard(e.target.value, formRef.current.values.dest_region)
  }

  const handleRegionOnChange = (e) => {
    searchDestDashboard(formRef.current.values.dest_dashboard_id, e.target.value);
  };

  const formikSubmit = (values) => {
    setIsOk(undefined);
    setLoading(true);

    // Add create_backup flag to Values
    values.create_backup = createBackup
    console.log("Values", values);

    fetch(`/app/admin/dashboards/replace`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values),
    }).then((res: Response) => {
      res.json().then((value) => {
        console.log(value);

        if (value["to_dashboard_id"]) {
          setIsOk(res.ok ? true : false);
        } else {
          setIsOk(false);
        }
      });

      setLoading(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => formikSubmit(values)}
      innerRef={formRef}
    >
      <Card
        variant="elevation"
        actions={
          <ActionButton
            buttonText={loading ? "Replacing..." : "Replace"}
            loading={loading}
          />
        }
      >
        <Typography variant="h5" gutterBottom>
          Replace A Dashboard
        </Typography>
        <Typography variant="body1" gutterBottom>
          Replace an existing dashboard with an updated one.
          If create_backup is enabled, a copy of the dashboard to be replaced
          will be created in the same folder with the timestamp appended to its
          name.
        </Typography>
        <Grid container direction="row" spacing={1}>
          <Grid item sm={6}>
            <Typography variant="subtitle1">Source Dashboard Info</Typography>
            {srcDashInfo && (
              <>
                <Typography variant="body1">ID: {srcDashInfo?.id}</Typography>
                <Typography variant="body1">
                  Title: {srcDashInfo?.title}
                </Typography>
                <Typography variant="body1">
                  Description: {srcDashInfo?.description}
                </Typography>
              </>
            )}
          </Grid>
          <Grid item sm={6}>
            <Typography variant="subtitle1">Destinaiton Dashboard Info</Typography>
            {destDashInfo && (
              <>
                <Typography variant="body1">ID: {destDashInfo?.id}</Typography>
                <Typography variant="body1">
                  Title: {destDashInfo?.title}
                </Typography>
                <Typography variant="body1">
                  Description: {destDashInfo?.description}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
        <br />
        <Form>
          <Grid container direction="row" wrap="wrap">
            <Grid item sm={6}>
              <Field
                component={FormikTextField}
                id="src_dashboard_id"
                name="src_dashboard_id"
                label="Source Dashboard ID"
                onChange={handleSrcDashIDOnChange}
                error={srcDashSearchError}
                helperText={
                  srcDashSearchError
                    ? "Invalid Dashboard"
                    : "The dashboard to copy"
                }
              />
            </Grid>
            <Grid item sm={6}>
              <Field
                component={FormikTextField}
                id="dest_dashboard_id"
                name="dest_dashboard_id"
                label="Destination Dashboard ID"
                onChange={handleDestDashIDOnChange}
                error={destDashSearchError}
                helperText={
                  destDashSearchError
                    ? "Invalid Dashboard"
                    : "The dashboard to replace"
                }
              />
            </Grid>
            <Grid item sm={6}>
            </Grid>
            <Grid item sm={6}>
              <Field
                component={LookerRegionSelect}
                id="dest_region"
                name="dest_region"
                label="dest_region"
                onChange={handleRegionOnChange}
              />
            </Grid>

            <Grid item sm={6}>
              <Grid container direction="row" alignItems="center">
                <Checkbox
                  id="create_backup"
                  color="monochrome"
                  size="small"
                  checked={createBackup}
                  onChange={(e, checked) => setCreateBackup(checked)}
                />
                <Typography>Create Backup</Typography>
              </Grid>
            </Grid>

            {isOk && <p>Dashboard Replaced</p>}
            {!isOk && isOk !== undefined && <p>Dashboard Replace Error</p>}
          </Grid>
        </Form>
      </Card>
    </Formik>
  );
};
