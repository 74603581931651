import React, { useContext } from 'react';
import { Grid, Container } from '@emburse/embark-core';
import { ChangeCustomerAssociation, CopyDashboard, CopyLook, Page, ReplaceDashboard, SaveDescription } from '@src/components';
import { IUseAuthInterface } from '../../hooks/useAuth';
import { AuthContext } from '../AuthProvider/AuthProvider';
import { ADMIN_INTERNAL_CUSTOMERS_ROUTE } from '@src/store/routs';
import {Link} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { flagsSelector } from '../../store/selector';
import { FFlags, getFlag } from '@src/enums';

export default () => {
    const {user} = useContext(AuthContext);
    const flags = useSelector(flagsSelector);
    return (
        <Page>
            <h1>Tools</h1>
            <a href="/app/admin">{user?.looker_region} Home</a> | <a href="/app/admin/temp?page=tenants&has_embed_group_id=True">Tenants</a> | <Link to={ADMIN_INTERNAL_CUSTOMERS_ROUTE}>Customers</Link>
            <br />
            <Grid container spacing={2}>
                <Grid item sm={4}>
                    <CopyDashboard />
                </Grid>
                {flags?.[getFlag(FFlags.EnableReplaceDashboard)] &&
                    <Grid item sm={4}>
                        <ReplaceDashboard />
                    </Grid>
                }
                <Grid item sm={4}>
                    <CopyLook />
                </Grid>
                <Grid item sm={4}>
                    <ChangeCustomerAssociation />
                </Grid>
                {flags?.[getFlag(FFlags.EnableInternalEditDesc)] &&
                    <Grid item sm={4}>
                        <SaveDescription />
                    </Grid>
                }
            </Grid>
        </Page>
    );
};
