import * as React from 'react';

import {ButtonContainer, Grid, Icon, IconButton, Skeleton, Spacer, TextField, Typography} from '@emburse/embark-core';

import {IDashboardRef, ILookRef, IReportDestinationDefinition, IReportScheduleDefinition, IUserRef} from "@interfaces";
import {useContext, useEffect, useState} from "react";
import {join} from "lodash";
import cronstrue from "cronstrue";
import {Timezones, FFlags, getFlag} from "@enums";
import {CloseIcon} from "@emburse/embark-icons";
import {IScheduleAPIContext, ScheduleAPIContext} from "@components/ScheduleAPIProvider/ScheduleAPIProvider";
import {openDashboard, openLook} from "@src/store";
import {flagsSelector} from "@src/store/selector";
import {useDispatch, useSelector} from "react-redux";
import {IUserPublic} from "@looker/sdk/lib/4.0/models";

interface ViewBurstScheduleDetailsProps {
    schedule: IReportScheduleDefinition;
    onClose: () => void;
}

const ViewBurstSchedule = ({ schedule, onClose}: ViewBurstScheduleDetailsProps) => {
    const dispatch = useDispatch();
    const scheduleAPI = useContext<IScheduleAPIContext>(ScheduleAPIContext);
    const flags = useSelector(flagsSelector);

    const [dashboardInfo, setDashboardInfo] = useState<IDashboardRef | null>(null);
    const [lookInfo, setLookInfo] = useState<ILookRef | null>(null);
    const [distributionLookInfo, setDistributionLookInfo] = useState<ILookRef | null>(null);
    const [ownerInfo, setOwnerInfo] = useState<IUserRef | null>(null);

    function fetchLookInfo(look_id: string) {
        scheduleAPI.getReferencedLook(look_id)
            .then(async (res) => {
                setDashboardInfo(null);
                setLookInfo(res);
            })
            .catch((e) => {
                setDashboardInfo(null);
                setLookInfo({
                    id: look_id,
                    name: look_id
                });
            });
    }

    function fetchDashboardInfo(dashboard_id: string) {
        scheduleAPI.getReferencedDashboard(dashboard_id)
            .then(async (res) => {
                setLookInfo(null);
                setDashboardInfo(res);
            })
            .catch((e) => {
                setLookInfo(null);
                setDashboardInfo({
                    id: dashboard_id,
                    name: dashboard_id
                });
            });
    }

    function fetchDistributionLookInfo(distribution_look_id: string) {
        scheduleAPI.getReferencedLook(distribution_look_id)
            .then(async (res) => {
                setDistributionLookInfo(res);
            })
            .catch((e) => {
                setDistributionLookInfo({
                    id: distribution_look_id,
                    name: distribution_look_id
                });
            });
    }

    function fetchOwnerInfo(user_id: string) {
        scheduleAPI.getReferencedUser(user_id)
            .then(async (res) => {
                setOwnerInfo(res);
            })
            .catch((e) => {
                setOwnerInfo({
                    user_id: user_id,
                    first_name: user_id,
                    last_name: ''
                });
            });
    }


    useEffect(() => {
        /*** Fetch details on the referenced looks or dashboard and owner*/
        if (!schedule) {
            setLookInfo(null);
            setOwnerInfo(null);
            setDashboardInfo(null);
            setDistributionLookInfo(null);
            return;
        }

        if (schedule.user_id) {
            fetchOwnerInfo(schedule.user_id);
        }

        if (schedule.look_id) {
            fetchLookInfo(schedule.look_id);
        } else {
            if (schedule.dashboard_id) {
                fetchDashboardInfo(schedule.dashboard_id);
            }
        }

        const destinations = schedule.destinations;
        if (destinations && destinations.length > 0) {
            if (destinations[0].distribution_look_id) {
                fetchDistributionLookInfo(destinations[0].distribution_look_id);
            } else {
                setDistributionLookInfo(null);
            }
        } else {
            setDistributionLookInfo(null);
        }

    }, [schedule]);

    const doOpenReferencedItem = (is_dashboard: boolean, ref_id: string) => {
        if (is_dashboard) {
            dispatch(openDashboard(ref_id));
        } else {
            dispatch(openLook(ref_id));
        }
    };

    function renderModelSection() {

        return (

            <Grid container direction="column" spacing={1}>
                <Grid item>
                    <Spacer size={8} direction="vertical"/>
                    {(!lookInfo && !dashboardInfo) &&
                        <Skeleton>
                            <TextField
                                label={"Look/Dashboard"}
                                fullWidth
                                readOnly
                                value=""
                            />
                        </Skeleton>}
                    {lookInfo &&
                        <TextField
                            label={"Look"}
                            fullWidth
                            readOnly
                            style={{cursor: "pointer", textDecoration: "underline"}}
                            value={lookInfo.name}
                            onClick={() => doOpenReferencedItem(false, lookInfo.id)}
                        />}
                    {dashboardInfo &&
                        <TextField
                            label={"Dashboard"}
                            fullWidth
                            readOnly
                            style={{cursor: "pointer", textDecoration: "underline"}}
                            value={dashboardInfo.name}
                            onClick={() => doOpenReferencedItem(true, dashboardInfo.id)}
                        />}
                </Grid>
            </Grid>
        );
    }


    function renderScheduleHeaderSection() {

        const cronExp = schedule?.crontab||'';
        let cronDescription = '';
        if (cronExp) {
            cronDescription = cronstrue.toString(cronExp, {throwExceptionOnParseError: false, verbose: true});
        }

        let timezone = Timezones.find((f) => f.link === schedule?.timezone)?.display;
        if (!timezone) {
            timezone = schedule?.timezone || 'not specified';
        }

        return (

            <Grid container direction="column" spacing={1}>

                <Grid item>
                    <Spacer size={8} direction="vertical"/>
                    <TextField
                        label={"Name"}
                        fullWidth
                        readOnly
                        value={schedule?.name}
                    />

                    <Spacer size={16} direction="vertical" />
                    <TextField
                        label={"Schedule"}
                        fullWidth
                        readOnly
                        value={cronDescription}
                    />

                    <Spacer size={16} direction="vertical" />
                    <TextField
                        label={"Timezone"}
                        fullWidth
                        readOnly
                        value={timezone}
                    />

                    <Spacer size={16} direction="vertical" />
                    { ownerInfo &&
                        <TextField
                            label={"Owner"}
                            fullWidth
                            readOnly
                            value={ownerInfo ? `${ownerInfo.first_name} ${ownerInfo.last_name}` : 'not available'}
                        />}
                    {!ownerInfo && <Skeleton/>}
                </Grid>
            </Grid>
        );
    }

    const renderFormattingOptions = (schedule) => {

        let destination: IReportDestinationDefinition;
        if (!schedule.destinations || schedule.destinations.length == 0) {
            destination = {
                type: 'not specified',
                address: 'not specified',
                format: 'not specified',
                apply_vis: false,
                apply_formatting: false,
                parameters: ''
            };
        } else {
            destination = schedule.destinations[0];
        }

        return (
            <Grid container direction="column" spacing={1}>
                <Grid item>
                    <Typography variant="h6" display="block">
                        Format Options
                    </Typography>
                </Grid>
                <Grid item>
                    <Spacer size={8} direction="vertical"/>
                    { (destination && <TextField
                        label={"Format"}
                        fullWidth
                        readOnly
                        value={destination.format}
                    />) || <Skeleton/> }

                    <Spacer size={16} direction="vertical"/>
                    { (destination && <TextField
                        label={"Apply Visualizations"}
                        fullWidth
                        readOnly
                        value={(destination.apply_formatting? "Yes" : "No")}
                    />) || <Skeleton/> }

                </Grid>
            </Grid>
        );
    };

    const renderDistributionSection = (schedule: IReportScheduleDefinition) => {

        return (
            <Grid container direction="column" spacing={1}>
                <Grid item>
                    <Spacer size={16} direction="vertical"/>
                    {distributionLookInfo?
                        <TextField
                            label={"Distribution Look"}
                            fullWidth
                            readOnly
                            style={{cursor: "pointer", textDecoration: "underline"}}
                            value={distributionLookInfo.name}
                            onClick={() => doOpenReferencedItem(false, distributionLookInfo.id)}
                        />
                    : <Skeleton/>}
                </Grid>
            </Grid>
        );
    };


    return (
        <div style={{ padding: '24px' }}>
            {flags?.[getFlag(FFlags.ViewScheduleOnScheduleAdminNameClick)] ? <></> :<Grid container spacing={3} direction="column">
                <Grid item>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Typography variant="h5">Burst Schedule Details</Typography>

                        <ButtonContainer>
                            <IconButton onClick={() => onClose()} data-qa="schedule-details-close">
                                <Icon iconUrl={CloseIcon} />
                            </IconButton>
                        </ButtonContainer>
                    </Grid>
                </Grid>
            </Grid>}

            {renderScheduleHeaderSection()}
            {renderModelSection()}
            {renderDistributionSection(schedule)}
            {renderFormattingOptions(schedule)}

            <Spacer size={30} />
        </div>
    );
};

export { ViewBurstSchedule };