import { IUserState } from "../store";
import {
  LICENSE_LIMIT_EXCEEDED_ROUTE,
  LOGOUT_ROUTE,
  PROVISIONING_ROUTE,
  UNAUTHORIZED_ROUTE,
} from "../store/routs";
import { useContext, useEffect, useState } from "react";
import { SessionStatus } from "../enums";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { ISession, IState } from "@src/interfaces";
import { parse } from "querystring-es3";
import { AuthContext } from "@src/components/AuthProvider/AuthProvider";
import { IUseAuthInterface } from "./useAuth";

export function useInitRoute(): String {
  const user: IUserState = useSelector((state: IState) => state.user);
  const authContext: IUseAuthInterface = useContext(AuthContext);
  const location = useLocation();
  const [initRoute, setInitRoute] = useState(
    calcInitRoute(user, location, authContext.session)
  ); //want to calc this at least once.

  useEffect(() => {
    setInitRoute(calcInitRoute(user, location, authContext.session));
  }, [location, user, authContext.session?.error_code]);

  return initRoute;
}

export function calcInitRoute(
  user: IUserState,
  location,
  session: ISession | undefined
): string {
  let init: string = parse(location.search.substring(1))["init"] as string;

  let initRoute = init ? init : "";

  if (user.session_state === (SessionStatus.PROVISIONING || SessionStatus.NEW)) {
    initRoute = PROVISIONING_ROUTE;
  } else if (user.session_state === SessionStatus.UNAUTHORIZED) {
    initRoute = UNAUTHORIZED_ROUTE;
  } else {
    if (localStorage.getItem('auth_status') === 'true' && location.pathname !== LOGOUT_ROUTE) {
      initRoute = `/app/p${JSON.parse(localStorage.getItem('landing_page')|| '/all')}`;
    } else if (user.userData.initRoute === LOGOUT_ROUTE) {
      initRoute = LOGOUT_ROUTE;
    }
  }

  return initRoute;
}
