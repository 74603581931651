import React, {useEffect, useState, Fragment, useContext} from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import {addAlert, openDashboard, openLook} from "../../store/actions/appActions";
import {
  DataTable,
  TextWithCaption,
} from "@src/components";
import {
  Button,
  Container,
  GridRow,
  Popover,
  Tooltip
} from "@emburse/embark-core";
import { IDataTableColumn } from "@src/interfaces/IDataTableColumn";
import {IFFlags, Severity} from "@src/enums";
import {
  Alert,
  Typography,
  Spacer
} from "@emburse/embark-core";
import { createUseStyles } from "react-jss";

import {IReportScheduleDefinition, IDashboardRef, IUserRef, ILookRef} from "../../interfaces";
import {IAlert } from "@src/interfaces";
import {IScheduleAPIContext, ScheduleAPIContext} from "@components/ScheduleAPIProvider/ScheduleAPIProvider";
import cronstrue from "cronstrue";
import { v4 } from "uuid";

const useStyles = createUseStyles({
  main: {
    "& .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textLeft[data-field=destinations]": {
      alignItems: 'left !important'
    },

    "& .distributionAddress:nth-child(3)": {
      display: 'inline'
    }
  },

  manageUserBar: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",

    "& > span:last-child": {
      marginLeft: "auto",
    },
    "& > :first-child": {
      marginRight: "15px",
    },
  },
  sidebar: {
    width: '251px',
  },
  pageWithSidebar: {
    flex: 'auto',
    padding: '3rem'
  },
  limitWarning: {
    maxWidth: '60%'
  },
  distribution: {
    fontSize: '12px'
  },
});

/**
 * @interface UsersDict
 * Dictionary of known users referenced in the results.
 * key is user_id reported by Looker (looker_user_id)
 * value is the minimal set of attributes of the user for display
 */
interface UsersDict {
  [user_id: string]: { IUserRef }
}


/**
 * @interface DashboardsDict
 * Dictionary of known dashboards referenced in the results.
 * key is id of the dashboard
 * value is the minimal set of attributes for display
 */
interface DashboardsDict {
  [id: string]: { IDashboardRef }
}

/**
 * @interface LooksDict
 * Dictionary of known Looks referenced in the results.
 * key is id of the look
 * value is the minimal set of attributes  for display
 */
interface LooksDict {
  [id: string]: { ILookRef }
}


export interface AdminSchedulesProps {}
const AdminSchedules = (props: AdminSchedulesProps) => {
  const dispatch = useDispatch();
  const flags: IFFlags = useSelector((state: RootStateOrAny) => state.user.userData.flags);
  const scheduleAPI = useContext<IScheduleAPIContext>(ScheduleAPIContext);
  const classes = useStyles();

  // row state and menu
  const [selectedUser, setSelectedSchedule] = useState<IReportScheduleDefinition | null>(
    null
  );
  const [schedules, setSchedules] = useState<IReportScheduleDefinition[]>([]);
  const [knownUsers, setKnownUsers ] =  useState<UsersDict>({});
  const [knownDashboards, setKnownDashboards ] =  useState<DashboardsDict>({});
  const [knownLooks, setKnownLooks ] =  useState<LooksDict>({});

  // Table state
  const [pageSize, setPageSize] = useState<number>(100);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [filterString, setFilterString] = useState<string>("");
  const [searchString, setSearchString] = useState<string>("");
  const [searchChanged, setSearchChanged] = useState<boolean>(false);
  const [rowCount, setRowCount] = useState<number>(0);

  // a table state for loading
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function fetchSchedules() {
    scheduleAPI.getSchedules({ }).then((res) => {
      setSchedules(res);
      const referenced_user_ids = new Set(res.flatMap(value=> value ? [value.user_id] :[]));
      const referenced_look_ids = new Set(res.map(value => value.look_id).filter(value => value !== null && value !== undefined));
      const referenced_dashboard_ids = new Set(res.map(value => value.dashboard_id).filter(value => value !== null && value !== undefined));

      // fetchReferencedUsers([])
      // fetchReferencedLooks([])
      // fetchReferencedDashboards([])

      setRowCount(res.length);
      setIsLoading(false);
      setSearchChanged(false);
    }).catch(e => {
      let alert: IAlert = {
        timestamp: new Date().getTime(),
        severity: Severity.ERROR,
        message: "An error occurred when fetching the schedules."
      };
      dispatch(addAlert(alert));
    });
  }

  useEffect(() => {
    setIsLoading(true);
    fetchSchedules();
  }, []);


  const doRowAction = (row: any, action: string) => {
    setSelectedSchedule(row);
  };

  const doOpenReferencedItem = (is_dashboard: boolean, ref_id: string) => {
    if (is_dashboard) {
      dispatch(openDashboard(ref_id));
    } else {
      dispatch(openLook(ref_id));
    }
  };

  const scheduleColumns: IDataTableColumn[] = [
    {
      field: "name",
      headerName: "Schedule Name",
      flex: 3,
      renderCell: (params) => {
        return (
          <TextWithCaption
              text={params.row.name}
              caption={cronstrue.toString(params.row.schedule, { verbose: true }) + ", " + params.row.timezone}
          />
        );
      },
    },
    {
      field: "dashboard_look_id",
      headerName: "Dashboard/Look",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
          <span style={{cursor: "pointer", textDecoration: "underline"}} onClick={() => doOpenReferencedItem(params.row.is_dashboard, params.row.dashboard_look_id)}>{params.row.dashboard_look_id}</span>
          </>
        );
      },
    },
    {
      field: "user_id",
      headerName: "Owner",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        let name = '';
        if (params.row.user) {
          name = `${params.row.user.first_name} ${params.row.user.last_name}`;
        } else {
          name = params.row.user_id;
        }
        return (
            <Tooltip title={name}>
              <span style={{cursor: 'default', overflow: 'hidden', textOverflow: 'ellipsis'}}>{name}</span>
            </Tooltip>
        );
      }
    },
    // {
    //   field: "type",
    //   headerName: "Type",
    //   flex: 1,
    //   sortable: true,
    //   renderCell: (params) => params.row.type == 'DEFAULT' ? 'Standard' : 'Burst'
    // },
    {
      field: "destinations",
      headerName: "Distribution",
      flex: 3,
      sortable: false,
      renderCell: (params) => {
        let fullText = '';
        const addressesList = params.row.destinations?.map(destination => {
          fullText = fullText + ' ' + destination.address;
          return (<Typography 
            key={v4()}
            display={"block"}
            variant={"caption"}
            data-qa="distributionAddress"
            className="distributionAddress"
          >
            {destination.type != 'email' ? `${destination.type}:` : ''} {destination.address}
          </Typography>);
        });

        const firstThreeAddresses = addressesList.slice(0,3);
       
        const showMorePopover = () => {
          if (addressesList.length <= 3) return;

          const popoverContent = <Container sx={{padding: '14px'}}>
              <Typography variant='h5' data-qa='distribution'>Distribution</Typography>
              {addressesList}
            </Container>;

          const [anchorEl, setAnchorEl] = React.useState(undefined);
          const handlePopoverOpen = (event: any) => {
            setAnchorEl(event.currentTarget);
          };
        
          const handlePopoverClose = () => {
            setAnchorEl(undefined);
          };
        
          const open = Boolean(anchorEl);

          if (addressesList.length > 3) {
            return <>
              <Button
                sx={{textDecoration:"underline", padding: '0', textTransform:'lowercase'}}
                variant="text"
                color="primary"
                size="small"
                data-qa="distributionMoreBtn"
                onClick={handlePopoverOpen}
              >
                {`+ ${addressesList.length - 3} more`}
              </Button>
              <Popover
                open={open}
                anchorEl={anchorEl}
                anchorOriginVertical={'bottom'}
                anchorOriginHorizontal={'center'}
                transformOriginHorizontal={'center'}
                transformOriginVertical={'top'}
                onClose={handlePopoverClose}
              >{popoverContent}</Popover>
            </>;
          }
        };
        
        return (
            <div className={classes.distribution}>
              {firstThreeAddresses} 
              {showMorePopover()}
            </div>
        );
      },
    }
  ];

  let filterSchedule = function(schedule) {
    /**
     * Apply client-side filtering
     * */
    return true;
  };

  let handleScheduleFilter = (): any[] => {
    /**
     * Provides the list of schedules after filtering
     */
    return schedules
        ?.map((schedule: IReportScheduleDefinition) => {
          return {
            name: `${schedule.name}`,
            id: schedule.id,
            dashboard_look_id: schedule.dashboard_id ? schedule.dashboard_id : schedule.look_id,
            is_dashboard: !!schedule.dashboard_id,
            user_id: schedule.user_id,
            user: schedule.user,
            type: schedule.type,
            schedule: schedule.crontab,
            timezone: schedule.timezone,
            destinations: schedule.destinations,
            raw: schedule,
          };
        })
        .filter((el) => {
          // client side filtering
          return filterSchedule(el);
        })
        // alphabetically sort by name field
        .sort((a,b) => a.name.localeCompare(b.name));
  };


  return (
    <>
      <Fragment>
        <GridRow justifyContent={'space-between'}>
          <Typography variant="h4">Schedules</Typography>
        </GridRow>
        <Spacer size={16} direction="vertical" />
      </Fragment>
      <DataTable
        className={classes.main}
        autoHeight
        allowSelection={false}
        loading={isLoading}
        rowHeight={68}
        rows={handleScheduleFilter()}
        columns={scheduleColumns}
      />
    </>
  );
};

export default AdminSchedules;
