import { Endpoint } from "../common";
import { RequestCache } from "../common/RequestCache";

/**
 * Resources are centered around one type of entity.
 * T is the entity that we are trying to fetch.
 *
 * tldr; the purpose of this class is to provide
 * the basic method and functions to the classes
 * inheriting this one. All resources should in
 * someway inherit this one.
 *
 * @export
 * @abstract
 * @class BaseResource
 * @template T
 */
export abstract class BaseResource<T> {
  /**
   * the duration in ms that this resource will expire from Date.now()
   *
   * tldr; Date.now() + expiry = time the fetch call will expire
   * if it doesn't exist already
   *
   * @abstract
   * @type {number}
   * @memberof BaseResource
   */
  public abstract readonly expiry: number;

  /**
   * The url root of the resource (ex: /app/schedule)
   *
   * @abstract
   * @type {string}
   * @memberof BaseResource
   */
  public abstract readonly urlRoot: string;

  /**
   * The property that is use to determine if the resource should be cached.
   *
   * @abstract
   * @type {string}
   * @memberof BaseResource
   */
  public abstract readonly cache: boolean;

  /**
   * The property that is use to determine how the resource should be cached.
   *
   * @abstract
   * @type {string}
   * @memberof BaseResource
   */
  public abstract readonly cacheType: RequestCache;

  /**
   * Returns a function the can be used to
   * get a Promise that resolves to Entity T.
   * This is meant for use with the useBackend hook.
   *
   * @param {{id: number}} args
   * @memberof BaseResource
   * @returns () => Promise<T>
   */
  public getOne = (args: { id: number }) => {
    return (): Promise<T> => {
      return Endpoint.extend(`${this.urlRoot}/${args.id}`, {
        expiry: this.expiry,
        cache: this.cache ? RequestCache.FORCE_CACHE : RequestCache.DEFAULT,
      }).then(async (resp) => {
        let json: T = await resp.json();
        return json;
      });
    };
  };

  /**
   * Returns a function the can be used to
   * get a Promise that resolves to an Array of Entity T.
   * This is meant for use with the useBackend hook.
   *
   * @param {{}} [_args]
   * @memberof BaseResource
   */
  public getAll = (_args?: {}) => {
    return (): Promise<T[]> => {
      return Endpoint.extend(`${this.urlRoot}`, {
        expiry: this.expiry,
        cache: this.cache ? RequestCache.FORCE_CACHE : RequestCache.DEFAULT,
      }).then(async (resp) => {
        let json: T[] = await resp.json();
        return json;
      });
    };
  };
}

export abstract class Entity {
  // todo: move this to somewhere else.
}
