import React from 'react'

export default() => {
    return(
        <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.08">
            <circle cx="80" cy="80" r="80" fill="#2681DD"/>
            </g>
            <path d="M72.5194 56.649C71.8719 56.6497 71.2513 56.9073 70.7935 57.365C70.3357 57.8228 70.0782 58.4435 70.0774 59.0909V102.713C70.0812 106.228 71.4793 109.598 73.9649 112.084C76.4504 114.569 79.8205 115.967 83.3356 115.971C83.983 115.97 84.6037 115.713 85.0615 115.255C85.5193 114.797 85.7768 114.177 85.7776 113.529V69.9072C85.7738 66.3921 84.3757 63.022 81.8901 60.5364C79.4045 58.0509 76.0345 56.6528 72.5194 56.649ZM82.5831 112.749C80.0525 112.556 77.6878 111.415 75.9617 109.555C74.2355 107.694 73.2749 105.251 73.2718 102.713V59.8709C75.8025 60.0639 78.1671 61.2046 79.8933 63.0652C81.6195 64.9258 82.5801 67.3692 82.5831 69.9072V112.749Z" fill="#2681DD"/>
            <path d="M96.7684 36.5582C96.121 36.559 95.5003 36.8166 95.0425 37.2744C94.5847 37.7322 94.3272 38.3529 94.3264 39.0003V102.713C94.3302 106.228 95.7283 109.598 98.2139 112.084C100.699 114.569 104.07 115.967 107.585 115.971C108.232 115.97 108.853 115.713 109.311 115.255C109.768 114.797 110.026 114.177 110.027 113.529V49.8164C110.023 46.3013 108.625 42.9312 106.139 40.4457C103.654 37.9601 100.284 36.562 96.7684 36.5582ZM106.832 112.749C104.302 112.556 101.937 111.415 100.211 109.555C98.4846 107.694 97.524 105.251 97.5209 102.713V39.7801C100.051 39.9732 102.416 41.1139 104.142 42.9745C105.868 44.8351 106.829 47.2784 106.832 49.8164V112.749Z" fill="#2681DD"/>
            <path d="M48.2705 78.0742C47.623 78.075 47.0024 78.3325 46.5445 78.7903C46.0867 79.2481 45.8292 79.8688 45.8284 80.5162V102.713C45.8322 106.228 47.2303 109.598 49.7158 112.084C52.2014 114.569 55.5715 115.967 59.0866 115.971C59.734 115.97 60.3547 115.713 60.8125 115.255C61.2702 114.797 61.5278 114.177 61.5286 113.529V91.3324C61.5247 87.8173 60.1267 84.4473 57.6411 81.9617C55.1556 79.4762 51.7856 78.0781 48.2705 78.0742ZM58.3341 112.749C55.8035 112.556 53.439 111.415 51.7128 109.555C49.9867 107.694 49.026 105.251 49.0228 102.713V81.2961C51.5535 81.4891 53.9181 82.6299 55.6443 84.4905C57.3704 86.3511 58.331 88.7944 58.3341 91.3324V112.749Z" fill="#2681DD"/>
        </svg>

    )
}