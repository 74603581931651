import React, { createContext } from 'react'
import { useSelector } from 'react-redux';
import { folderStructureSelector } from '../../store/selector';
import { IHasChildrenProps } from '../../interfaces/IHasChildrenProps';


export interface StoreAccessorProps extends IHasChildrenProps{
    selector: any
}

export const StoreContext = createContext<any>({
    state: null
})

export default (props: StoreAccessorProps) => {
    const state = useSelector(props.selector)

    return (
        <StoreContext.Provider value={{state: state}}>
            {props.children}
        </ StoreContext.Provider>
    )
}