import { COPY_DASHBOARD, COPY_LOOK, DELETE_DASHBOARD, DELETE_LOOK, MOVE_DASHBOARD, MOVE_LOOK } from '@src/store/types';
import {
    HIDE_MODAL,
    HIDE_MODAL_ERROR, SET_MODAL_DATA,
    SET_MODAL_LOADED,
    SET_MODAL_LOADING,
    SHOW_MODAL,
    SHOW_MODAL_ERROR
} from '../types';
import {COPY_ACTION_MODAL, DELETE_DASHBOARD_MODAL, DELETE_FOLDER_MODAL, DELETE_LOOK_MODAL, MOVE_ACTION_MODAL, OPEN_SCHEDULE_MODAL} from '../constants';
import { callEndpoint } from '../../utility/FetchErrorHandler';

export function showScheduleModal() {
    return async dispatch => {
        dispatch(showModal(OPEN_SCHEDULE_MODAL));
        dispatch(modalLoading());
        dispatch(modalLoaded());
    };
}

export function showDeleteFolderModal({id}) {
    return async dispatch => {
        dispatch(showModal(DELETE_FOLDER_MODAL));
        dispatch(modalLoading());
        const response = await callEndpoint('/app/folders/' + id + '/content');
        const folder = await response.json();
        const foldersCount = folder.folders.length;
        const dashboardsCount = folder.dashboards.length;
        const looksCount = folder.looks.length;
        dispatch({type: SET_MODAL_DATA, payload: {foldersCount, dashboardsCount, looksCount}});
        dispatch(modalLoaded());
    };
}

export function showDeleteModal({id}, type){
    return async dispatch => {
        
        if(type === DELETE_DASHBOARD){
            dispatch(showModal(DELETE_DASHBOARD_MODAL));
        }

        if(type === DELETE_LOOK){
            dispatch(showModal(DELETE_LOOK_MODAL));
        }
        
        dispatch(modalLoading());
        dispatch(modalLoaded());
        // const response = await callEndpoint('/app')

    };
}

export function showCopyModal({id}, type){
    return async dispatch => {
        
        if(type === COPY_DASHBOARD){
            dispatch(showModal(COPY_ACTION_MODAL));
        }

        if(type === COPY_LOOK){
            dispatch(showModal(COPY_ACTION_MODAL));
        }
        
        dispatch(modalLoading());
        dispatch(modalLoaded());

    };
}

export function showMoveModal({id}, type){
    return async dispatch => {
        
        if(type === MOVE_DASHBOARD){
            dispatch(showModal(MOVE_ACTION_MODAL));
        }

        if(type === MOVE_LOOK){
            dispatch(showModal(MOVE_ACTION_MODAL));
        }
        
        dispatch(modalLoading());
        dispatch(modalLoaded());

    };
}


export function showModal(type) {
    return {type: SHOW_MODAL, payload: {type}};
}

export function hideModal() {
    return {type: HIDE_MODAL};
}

export function showModalError(errorMessage) {
    return {type: SHOW_MODAL_ERROR, payload: {errorMessage}};
}


export function hideModalError() {
    return {type: HIDE_MODAL_ERROR};
}


export function modalLoading() {
    return {type: SET_MODAL_LOADING};
}


export function modalLoaded() {
    return {type: SET_MODAL_LOADED};
}
