export enum AnalyticsRole{
    Viewer = 'Analytics Viewer',
    FreeCreator = 'Analytics Creator V2',
    ProViewer = 'Analytics Pro Viewer',
    ProCreator = 'Analytics Pro Creator'
}

export enum Roles{
    PRO_VIEWER = 'PRO_VIEWER',
    FREE_VIEWER = 'FREE_VIEWER',
    PRO_CREATOR = 'PRO_CREATOR',
    FREE_CREATOR = 'FREE_CREATOR',
}