import React, { useContext } from "react";
import { Route, Redirect } from "react-router";
import { useEffect } from "react";
import { AuthContext } from "@components/AuthProvider/AuthProvider";
export enum JWT_KEY_STATUS {
  FETCHED,
  ERROR,
  FETCHING,
}

export default ({ component: Component, ...rest }) => {
  const { user } = useContext(AuthContext);

  useEffect(() => {
    console.log(user);
  }, [user]);

  return user ? (
    <Route
      {...rest}
      render={(props) =>
        user?.admin ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  ) : (
    <></>
  );
};
