import { DBSchema, openDB } from 'idb';

import { DBDatabases } from '../enums/DBDatabases'; 

/**
 * This is the schema used for the indexeddb database.
 * We define the version any changes need to bump up the version.
 *
 * @export
 * @interface AnalyticsIDBSchema
 * @extends {DBSchema}
 */
export interface AnalyticsIDBSchema extends DBSchema {
  apicalls: {
    version: 2;
    key: string;
    value: {
      url: string;
      expiry: number;
      data: any;
    };
  };
}

/**
 * This is an abstract base DB to use when creating new store.
 *
 * @export
 * @abstract
 * @class BaseDB
 */
export default abstract class BaseDB {
  private readonly dbname = '::analytics-api-store::';
  private readonly version = 2;
  abstract tablename: DBDatabases;
  abstract keyPath: string;

  constructor() {}

  /**
   * This handles the instantiation and creation of the different stores (tables)
   * in the database. we can log other errors so that we may handle them and merge different versions.
   *
   * @memberof BaseDB
   */
  public initDB = (): Promise<this> => {
    return Promise.resolve(
      openDB<AnalyticsIDBSchema>(this.dbname, this.version, {
        upgrade: (db, _oldVersion, _newVersionm, _trx) => {
          if (!db.objectStoreNames.contains(this.tablename)) {
            db.createObjectStore(this.tablename, {
              keyPath: this.keyPath,
            });
          }
        },
        blocked: () => {
          console.error(
            `${this.dbname} cannot be opened it is currently blocked by an older version. see: https://developer.mozilla.org/en-US/docs/Web/API/IDBOpenDBRequest/blocked_event and https://github.com/jakearchibald/idb#opendb`,
          );
        },
        blocking: () => {
          console.error(
            `${this.dbname} is currently blocking a newer version from opening, see: https://developer.mozilla.org/en-US/docs/Web/API/IDBDatabase/versionchange_event and https://github.com/jakearchibald/idb#opendb`,
          );
        },
        terminated: () => {
          console.error(`${this.dbname} abruptly terminated, see: https://github.com/jakearchibald/idb#opendb`);
        },
      }),
    ).then((db) => {
      db.close(); // always close the connection so it doesn't block another
      return this;
    });
  };

  /**
   * Similar to the initDB method but we are going to return
   * the promise from openDB so that the connection can be used.
   *
   * The main purpose of this method is to open the connection for use.
   *
   * @memberof BaseDB
   */
  public openDB = () => {
    return openDB<AnalyticsIDBSchema>(this.dbname, this.version, {
      blocked: () => {
        console.error(
          `${this.dbname} cannot be opened it is currently blocked by an older version. see: https://developer.mozilla.org/en-US/docs/Web/API/IDBOpenDBRequest/blocked_event and https://github.com/jakearchibald/idb#opendb`,
        );
      },
      blocking: () => {
        console.error(
          `${this.dbname} is currently blocking a newer version from opening, see: https://developer.mozilla.org/en-US/docs/Web/API/IDBDatabase/versionchange_event and https://github.com/jakearchibald/idb#opendb`,
        );
      },
      terminated: () => {
        console.error(`${this.dbname} abruptly terminated, see: https://github.com/jakearchibald/idb#opendb`);
      },
    });
  };
}
