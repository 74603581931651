import React from 'react';
import { Typography } from '@emburse/embark-core';

export interface TextWithCaptionProps {
  text: string;
  caption: string;
}

export default (props: TextWithCaptionProps) => {
  return (
    <div style={{ lineHeight: 'normal' }}>
      <Typography variant="body1" color="textPrimary">
        <span>{props.text}</span>
      </Typography>
      <Typography variant="caption" color="textSecondary">
        <span>{props.caption}</span>
      </Typography>
    </div>
  );
};
