import React from "react";
import {
  AdminUserListProvider,
  CustomerListProvider,
  CustomerSearch,
} from "@src/components";

export interface CustomerSearchPageProps {}

export default (props: CustomerSearchPageProps) => {
  return (
    <CustomerListProvider>
      <CustomerSearch />
    </CustomerListProvider>
  );
};
