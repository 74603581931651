import { IScheduledPlan } from "@looker/sdk/lib/4.0/models";
import {ReportScheduleType, ScheduleOutput} from "@src/enums";
import { IReportDestinationDefinition } from ".";
import Ajv, { JSONSchemaType, Schema } from "ajv";
import ajvErrors from "ajv-errors";
import addFormats from "ajv-formats";
const ajv = new Ajv({ allErrors: true, verbose: true });
addFormats(ajv, ["email"]);
ajvErrors(ajv);

// Ajv option allErrors is required

/**
 * @interface IReportScheduleDefinition Extends the IScheduledPlan defined by Looker to support both default and burst schedules, and
 * simplify the destination configuration.
 */
export interface IReportScheduleDefinition extends Omit<IScheduledPlan, "scheduled_plan_destination"> {
  destinations: IReportDestinationDefinition[];
  type: "DEFAULT" | "BURST";
}

export interface IDashboardRef  {
  id: string;
  name: string;
}

export interface ILookRef  {
  id: string;
  name: string;
}

export interface IUserRef  {
  user_id: string;
  first_name: string;
  last_name: string;
}

export const isEmailDistribution = (schedule: IReportScheduleDefinition | undefined) => {
  /***
   * Has at least one destination that is email
   */
  if (!schedule) {
    return false;
  }
  const anyEmail = schedule.destinations.reduce((accumulator, destination) => {
    return accumulator || destination.type == 'email';
  }, false);

  return anyEmail;
};

export const isSFTPDistribution = (schedule: IReportScheduleDefinition | undefined) => {
  /***
   * Has at least one destination that is sftp
   */
  if (!schedule) {
    return false;
  }
  const anySFTP = schedule.destinations.reduce((accumulator, destination) => {
    return accumulator || destination.type == 'sftp';
  }, false);

  return anySFTP;
};


/**
 * This schema is used to validate the save/create api call to the backend.
 */
// @ts-ignore-next-line
export const SReportScheduleDefinition: JSONSchemaType<IReportScheduleDefinition> =
    {
      $schema: "http://json-schema.org/draft-07/schema#",

      definitions: {
        default_destination: {
          type: "object",
          properties: {
            address: {
              type: "string",
              if: {
                properties: {
                  type: {const: "email"}
                }
              },
              then: {
                format: "email",
                errorMessage: {
                  format: "Please enter a valid recipient email address",
                },
              },
              else: {
                errorMessage: {
                  format: "Please enter a valid SFTP server address with optional path",
                },
              }
            }
          },
          required: ["address"],
          additionalProperties: true,
        },

        email_destination: {
          type: "object",
          properties: {
            address: {
              type: "string",
              format: "email",
              errorMessage: {
                format: "Please enter a valid recipient email address",
              },
            }
          },
          required: ["address"],
          additionalProperties: true,
        },
        burst_destination: {
          type: "object",
          properties: {
            distribution_look_id: {
              type: "number",
            },
          },
          required: ["distribution_look_id"],
          additionalProperties: true,
        },
        sftp_destination: {
          type: "object",
          properties: {
            address: {
              type: "string",
              errorMessage: {
                format: "Please enter a valid SFTP server",
              },
            },
            sftpUsername: {
              type: "string",
              errorMessage: {
                format: "Please enter a SFTP username",
              },
            },
          },
          required: ["address", "sftpUsername"],
          additionalProperties: true,
        },
      },

      type: "object",

      properties: {
        type: {
          type: "string",
        },
        name: {
          type: "string",
          maxLength: 50,
          minLength: 1,
          errorMessage: {
            required: "Please enter a Schedule Name",
            minLength: "Please enter a Schedule Name",
            maxLength: "Schedule Name cannot be longer than 50 characters",
          },
        },
        crontab: {
          type: "string",
          // This regex will test for valid crontab
          pattern: new RegExp(
              /^((?:\*)|(?:[0-5]?[0-9])|(?:(?:(?:[0-5]?[0-9])-(?:[0-5]?[0-9]))(?:,(?:(?:[0-5]?[0-9])-(?:[0-5]?[0-9])))*)|(?:(?:[0-5]?[0-9])(?:,(?:[0-5]?[0-9]))*))\s+((?:\*)|(?:[0-9]|0[0-9]|1[0-9]|2[0-3])|(?:(?:(?:[0-9]|0[0-9]|1[0-9]|2[0-3])-(?:[0-9]|0[0-9]|1[0-9]|2[0-3]))(?:,(?:(?:[0-9]|0[0-9]|1[0-9]|2[0-3])-(?:[0-9]|0[0-9]|1[0-9]|2[0-3])))*)|(?:(?:[0-9]|0[0-9]|1[0-9]|2[0-3])(?:,(?:[0-9]|0[0-9]|1[0-9]|2[0-3]))*))\s+((?:\*)|(?:[1-9]|0[1-9]|1[0-9]|2[0-9]|3[0-1])|(?:(?:(?:[1-9]|0[1-9]|1[0-9]|2[0-9]|3[0-1])-(?:[1-9]|0[1-9]|1[0-9]|2[0-9]|3[0-1]))(?:,(?:(?:[1-9]|0[1-9]|1[0-9]|2[0-9]|3[0-1])-(?:[1-9]|0[1-9]|1[0-9]|2[0-9]|3[0-1])))*)|(?:(?:[1-9]|0[1-9]|1[0-9]|2[0-9]|3[0-1])(?:,(?:[1-9]|0[1-9]|1[0-9]|2[0-9]|3[0-1]))*))\s+((?:\*)|(?:[1-9]|0[1-9]|1[0-2])|(?:(?:(?:[1-9]|0[1-9]|1[0-2])-(?:[1-9]|0[1-9]|1[0-2]))(?:,(?:(?:[1-9]|0[1-9]|1[0-2])-(?:[1-9]|0[1-9]|1[0-2])))*)|(?:(?:[1-9]|0[1-9]|1[0-2])(?:,(?:[1-9]|0[1-9]|1[0-2]))*)|(?:\*)|(?:jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)|(?:(?:(?:jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)-(?:jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec))(?:,(?:(?:jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)-(?:jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)))*)|(?:(?:jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)(?:,(?:jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec))*))\s+((?:\*)|(?:[0-6]|0[0-6])|(?:(?:(?:[0-6]|0[0-6])-(?:[0-6]|0[0-6]))(?:,(?:(?:[0-6]|0[0-6])-(?:[0-6]|0[0-6])))*)|(?:(?:[0-6]|0[0-6])(?:,(?:[0-6]|0[0-6]))*)|(?:\*)|(?:sun|mon|tue|wed|thu|fri|sat)|(?:(?:(?:sun|mon|tue|wed|thu|fri|sat)-(?:sun|mon|tue|wed|thu|fri|sat))(?:,(?:(?:sun|mon|tue|wed|thu|fri|sat)-(?:sun|mon|tue|wed|thu|fri|sat)))*)|(?:(?:sun|mon|tue|wed|thu|fri|sat)(?:,(?:sun|mon|tue|wed|thu|fri|sat))*))$/
          ).source,
        },
        destinations: {
          type: "array",
          minItems: 1,
          items: {
            if: {
              properties: {
                type: {const: "BURST"},
              },
            },
            // if it's burst, verify at least one burst destination, otherwise verify it's an email or sftp destination
            then: {
              properties: {
                destinations: {
                  type: "array",
                  items: {$ref: "#/definitions/burst_destination"},
                  minItems: 1,
                },
              },
            },
            else: {
              properties: {
                destinations: {
                  type: "array",
                  items: {$ref: "#/definitions/default_destination"},
                  minItems: 1,
                },
              },
            }
          }
        }
      },
      required: ["name", "crontab", "destinations", "type"],
      additionalProperties: true,
    };

// compile it so use it for validation.
// export const SReportScheduleDefinitionValidator =
//   ajv.compile<IReportDestinationDefinition>(SReportScheduleDefinition);
