import { useEffect, useState } from 'react';
import { BaseDB } from '../IDBDatabases';
export function useDatabase<T extends BaseDB>(
  type: T,
  ): {
    ready: boolean;
    db: T;
  } {
  // init state
  const [ready, setReady] = useState<boolean>(false);
  const [db, setDb] = useState<T>();
  
  useEffect(() => {
    type
    .initDB()
    .then((v) => {
      setDb(v);
      setReady(true);
    })
    .catch((_e) => {
      setDb(undefined);
      setReady(false);
    });
  }, []);
  
  return {
    ready,
    // @ts-ignore
    db,
  };
}
