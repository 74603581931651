import { Timezones, Timezones_deprecated } from "@src/enums";
import { SelectFieldProps } from "../SelectField/SelectField";
import { Autocomplete } from "@emburse/embark-core";
import { ITimezone } from "../../../../interfaces/ITimezone";

import { getFlag, FFlags } from '@src/enums';
import { useSelector } from "react-redux";
import { flagsSelector } from "@src/store/selector";
export interface TimezoneSelectFieldProps
  extends Omit<SelectFieldProps, "selectList"> { }

export default (props: TimezoneSelectFieldProps) => {
  const flags = useSelector(flagsSelector);

  const list = Timezones.map((el) => {
    return el;
  });

  const onChangeHandler = (_e: any, v: ITimezone) => {
    if (v) props.onChange(v.link ? v.link : "");
  };

  return (
    <>
      <Autocomplete
        label="Timezone"
        options={list}
        defaultValue={list.find((tz) => tz.link === props.val)}
        getOptionLabel={(option) => option.display}
        onChange={onChangeHandler}
      />
    </>
  );
};
