import {
    ADD_FOLDER,
    DELETE_FOLDER,
    FETCH_FOLDER_CONTENT,
    FETCH_FOLDERS,
    SELECT_FOLDER_ITEM,
    SET_ACTIVE_FOLDER,
    UPDATE_FOLDER
} from "../types";
import { openFolder, addAlert, addUnauthorizedError } from './appActions';
import {hideModal, modalLoaded, modalLoading, showModalError} from './modalActions';
import {callEndpoint, STATUS_CODES} from '../../utility/FetchErrorHandler'
import { IAlert, IState } from "@src/interfaces";
import { Severity } from "@src/enums";
import { IEmburseFolderStructure } from '../../interfaces/IEmburseFolderStructure';
import { findNode } from '../../utility/folderStructure';
import { IFolder } from "@src/interfaces";
import { SET_FOLDER_STRUCTURE } from '@src/store/types';
import { IFolderContent } from '../../interfaces/IFolderContent';
import store from "..";

const FOLDER_ALREADY_EXIST_ERROR_CODE = 'FOLDER_ALREADY_EXIST'
const FOLDER_ALREADY_EXIST_ERROR_MESSAGE = 'Name already exists. Please enter a unique folder name'

const API_HEADERS = {'Content-Type': 'application/json;charset=utf-8'}

const FOLDER_LIST_AFTER_ERROR = [{
    "id": "all",
    "parentId": null,
    "name": "All"
}]

function dispatchFolderAPIError(dispatch, response, message) {
    /**
     * Helper method to report a Folder API error response as an Alert
     */
    if (response.status === STATUS_CODES.Unauthorized) {
        dispatch(addUnauthorizedError(response.statusText))
    } else {
        let alert: IAlert = {
            timestamp: new Date().getTime(),
            severity: Severity.ERROR,
            message: message
        }

        dispatch(addAlert(alert))
    }
}

export function fetchFolders() {
    /**
     * A failure to fetch folders (aside from a 401 Unauthorized) will return the root 'all' object (as defined
     * by FOLDER_LIST_AFTER_ERROR)
     */

    return async dispatch => {
        const response = await callEndpoint('/app/folders')
        if (!response.ok) {
            dispatchFolderAPIError(dispatch, response, "An error occurred when fetching the folder list")

            // ensure root folder is visible after errors
            dispatch({type: FETCH_FOLDERS, payload: FOLDER_LIST_AFTER_ERROR})
            dispatch(initFolderStructure(FOLDER_LIST_AFTER_ERROR))
            return
        }
        const json = await response.json();

        dispatch({type: FETCH_FOLDERS, payload: json})
        dispatch(initFolderStructure(json))
    }
}

export function selectFolderItem(folderItem) {
    return {type: SELECT_FOLDER_ITEM, payload: folderItem.contentMetadataId}
}

export function fetchFolderContent(folderId, folderStructure: IEmburseFolderStructure | null = null) {
    return async dispatch => {
        dispatch({type: SET_ACTIVE_FOLDER, payload: folderId})
        const response = await callEndpoint('/app/folders/' + folderId + '/content');
        if (!response.ok) {
            dispatchFolderAPIError(dispatch, response, "An error occurred when fetching the folder content.")
        } else {
            const json = await response.json();

            // if(folderStructure != null){
            //     dispatch(fillFolderStructureChildren(folderId, folderStructure, json))
            // }
            dispatch({type: FETCH_FOLDER_CONTENT, payload: {...json, id: folderId}})
        }
    }
}

export function refreshFolderStructure(folderId, folderStructure: IEmburseFolderStructure | null = null) {
    return async dispatch => {
        const response = await callEndpoint('/app/folders/' + folderId + '/content');
        if(!response.ok){
            if(response.status === STATUS_CODES.Unauthorized){
                dispatch(addUnauthorizedError(response.statusText))
            }
        }
        const json = await response.json();
        
        if(folderStructure != null){
            dispatch(fillFolderStructureChildren(folderId, folderStructure, json))
        }
    }
}

export function deleteFolder({id}, folderStructure: IEmburseFolderStructure | null = null) {
    return async dispatch => {
        dispatch(modalLoading())
        const response = await callEndpoint('/app/folders/' + id, {method: 'DELETE'});
        dispatch(modalLoaded())
        if (response.ok) {
            dispatch(hideModal())
            dispatch({type: DELETE_FOLDER, payload: {id}})
            
            if(folderStructure != null){
                dispatch(removeNode(folderStructure, id))
            }
        } else {

            let alert: IAlert = {
                timestamp: new Date().getTime(),
                severity: Severity.ERROR,
                message: "Error when calling delete folder API."
            }
            
            dispatch(addAlert(alert))
            
            console.log("Error when calling delete folder API")
        }
    }
}


export function renameFolder({id, name}, folderStructure: IEmburseFolderStructure | null = null) {
    return async dispatch => {
        dispatch(modalLoading())
        const response = await callEndpoint('/app/folders/' + id, {
            method: 'PATCH',
            headers: API_HEADERS,
            body: JSON.stringify({name})
        });
        dispatch(modalLoaded())
        if (response.ok) {
            const folder = await response.json();
            dispatch({type: UPDATE_FOLDER, payload: folder})
            dispatch(hideModal())
            
            if(folderStructure){
                let found = findNode(folderStructure, id)

                if(found){
                    found.data = folder
                    dispatch(updateNode(folderStructure, found))
                }

                
            }

        } else if (response.status === 400) {
            const error = await response.json();
            if (error.errorCode === FOLDER_ALREADY_EXIST_ERROR_CODE) {
                dispatch(showModalError(FOLDER_ALREADY_EXIST_ERROR_MESSAGE))
            } else {
                dispatch(showModalError(error.message))
            }
        } else if (response.status === STATUS_CODES.Unauthorized){
            dispatch(addUnauthorizedError(response.statusText))
        } else {
            console.error("Unexpected error when calling folder API")
        }
    }
}


export function createFolder({name, parentId}, folderStructure: IEmburseFolderStructure | null = null) {
    return async dispatch => {
        dispatch(modalLoading())
        const response = await callEndpoint('/app/folders', {
            method: 'POST',
            headers: API_HEADERS,
            body: JSON.stringify({name, parentId})
        });
        dispatch(modalLoaded())
        if (response.ok) {
            const folder = await response.json();
            dispatch({type: ADD_FOLDER, payload: folder})
            dispatch(hideModal())

            if(folderStructure != null){
                dispatch(addNode(parentId, folderStructure, folder))
            }

            dispatch(openFolder(folder.id))

        } else if (response.status === 400) {
            const error = await response.json();
            if (error.errorCode === FOLDER_ALREADY_EXIST_ERROR_CODE) {
                dispatch(showModalError(FOLDER_ALREADY_EXIST_ERROR_MESSAGE))
            } else {
                dispatch(showModalError(error.message))
            }
        } else if (response.status === STATUS_CODES.Unauthorized){
            dispatch(addUnauthorizedError(response.statusText))
        } else {
            console.error("Unexpected error when calling folder API")
        }
    }
}

export function initFolderStructure(allFolders){
    /**
     * Converts the array of folders (returned by API) into a tree with the `all` folder at the root and each
     * node having a reference to its children and parent.
     */
    return async dispatch => {
        let all = Object.assign({}, allFolders)
        
    
        let arrAll = Object.keys(all).map(function(index){
            let folder = all[index];
            
            return folder;
        });
        for (const [key, value] of Object.entries(all) as any) {
            //find children
            
            let folder: IFolder = value
            
            
            let children = arrAll.filter((f) => f.parentId === folder.id)
        

            for (let c = 0; c < children.length; c++) {
                const element: IFolder = children[c];
                let childNode: IEmburseFolderStructure = {
                    key: element.id,
                    type: 'folder',
                    lastUpdate: new Date(),
                    parent: key,
                    data: children[c] as IFolder,
                    children: []
                }

                children[c] = childNode
            }
            let node: IEmburseFolderStructure = {
                key: key,
                type: 'folder',
                data: value,
                lastUpdate: new Date(),
                parent: null,
                children: []
            }

            all[key] = node
        }

        for (const [key, value] of Object.entries(all) as any) {
            let node: IEmburseFolderStructure = value
            let folder = node.data
            
            if(folder){
                all[folder['parentId']]?.children.push(node)
                all[key].parent = all[folder['parentId']]?.key
            }
        }
    
        dispatch({type: SET_FOLDER_STRUCTURE, payload: all['all']})
        // dispatch(getFolderContents(all['all']))  //this can get all the folder permissions and children (dashboards/etc) well see I'm not going to enable it now.
    }
}

export function fillFolderStructureChildren(parentId, folderStructure: IEmburseFolderStructure, folderContent: IFolderContent){
    return async dispatch => {
        
        let dashboardChildren = folderContent.dashboards.map((d) => {
            let dashboard: IEmburseFolderStructure = {
                key: d.id,
                children: [],
                type: 'dashboard',
                lastUpdate: new Date(),
                parent: parentId,
                data: d
            }

            return dashboard
        })

        let lookChildren = folderContent.looks.map((l) => {
            let look: IEmburseFolderStructure = {
                key: l.id,
                children: [],
                type: 'look',
                lastUpdate: new Date(),
                parent: parentId,
                data: l
            }

            return look
        })


        let found = findNode(folderStructure, parentId)
        
        //we need to make sure we aren't duplicating node here
        // maybe build an array of ids to check against
        //add the new nodes

        let newChildren = found?.children.concat([...dashboardChildren, ...lookChildren])
        let data: IFolder = found?.data as IFolder
        
        if(data)
            data.permissions = folderContent.permissions
        
        if(found != null && newChildren)
            found.children = newChildren



        dispatch({type: SET_FOLDER_STRUCTURE, payload: folderStructure})
    }
}

export function getFolderContents(folderStructure: IEmburseFolderStructure){
    return async dispatch => {
        let state: IState = store.getState()
        
        for(let key in state.folders.allFolders){
            if(state.folders.allFolders.hasOwnProperty(key)){
                let folder: IFolder = state.folders.allFolders[key]
                
                if(folder.parentId != null){
                    const response = callEndpoint('/app/folders/' + folder.id + '/content').then(response => {
                        if(!response.ok){
                            if(response.status === STATUS_CODES.Unauthorized){
                                dispatch(addUnauthorizedError(response.statusText))
                            }
                        }
                        response.json().then(json => {
                            if(folderStructure != null){
                                dispatch(fillFolderStructureChildren(folder.id, folderStructure, json))
                            }
                        });
                    })
                    
                }
            }
        }
    }
}

export function addNode(parentId, folderStructure: IEmburseFolderStructure, data: IFolder){
    return async dispatch => {
        let found = findNode(folderStructure, parentId)
    
        let node: IEmburseFolderStructure = {
            key: data.id,
            type: 'folder',
            data: data,
            lastUpdate: new Date(),
            parent: null,
            children: []
        }
        if(found != null){
            found.children.push(node)
        }

        dispatch({type: SET_FOLDER_STRUCTURE, payload: folderStructure})
    }
}

export function removeNode(folderStructure: IEmburseFolderStructure, id: string){
    return async dispatch => {
        let found = findNode(folderStructure, id) // find node
        let parent = findNode(folderStructure, found?.parent as string) // find parent
        
        if(found != null && parent != null){
            parent.children = parent.children.filter(child => child.key != id)
        }

        dispatch({type: SET_FOLDER_STRUCTURE, payload: folderStructure})
    }
}

export function updateNode(folderStructure: IEmburseFolderStructure, node: IEmburseFolderStructure){
    return async dispatch => {
        let found = findNode(folderStructure, node.key as string)

        if(found){
            found = node
        }

        dispatch({type: SET_FOLDER_STRUCTURE, payload: folderStructure})
    }
}