export enum Recurrence{
    SendNow = 'Send Now (One Time Distribution)',
    Daily = 'Daily',
    Weekly = 'Weekly',
    Monthly = 'Monthly',
    Quarterly = 'Quarterly',
    Hourly = 'Hourly',
    Minutely = 'Minutely',
    SpecificMonths = 'Specific Months',
    SpecificDays = 'Specific Days'
}