import React from 'react';
import { useDatabases } from './hooks';

import { BDObject } from './IDBDatabases/index';

export interface IAPICall {
  expiry: number;
  url: string;
  data: any;
}


export interface IDBDatabaseCacheContext {
  dbs?: BDObject;
}

export const DBDatabaseCacheContext = React.createContext<IDBDatabaseCacheContext>({});

export default (props: { children }) => {
  const dbs = useDatabases();

  return <DBDatabaseCacheContext.Provider value={{ dbs: dbs }}>{props.children}</DBDatabaseCacheContext.Provider>;
};
