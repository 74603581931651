import React, {Dispatch, ReactElement} from "react";
import {MenuItem, MenuSubHeader} from '@emburse/embark-core';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { openExplore } from "../../store";
import {ILookerExplore, ILookMLModel, IState} from "../../interfaces";
import { FFlags, getFlag } from '@src/enums';
// V6 of Embark doesn't expose 'disableEnforceFocus' of Popover, so we need to use the MUI Popover directly.
// If this isn't exposed in v7, contact the Embark team to expose it and replace this MUI import.
import Popover from '@mui/material/Popover';
import "./ModelLookMenu.scss";

export interface ModelLookMenuProps{
    anchorEl: any,
    onClose: Dispatch<any>
}

export default ({anchorEl, onClose}: ModelLookMenuProps) => {
    const dispatch: Dispatch<any> = useDispatch();
    const {userData} = useSelector((state: IState) => state.user);
    const models: ILookMLModel[] = useSelector((state: RootStateOrAny) => state.models.lookMlModels);
    const elItems: ReactElement[] = [];

    if (userData.flags?.[getFlag(FFlags.EnableMenuModelSorting)]) {
        const explores: [ILookerExplore, ILookMLModel][] = models
            .flatMap(m => m.explores.map(e => [e, m] as [ILookerExplore, ILookMLModel]))
            .sort((e1, e2) => e1[1].label.localeCompare(e2[1].label) || e1[0].label.localeCompare(e2[0].label));
        for (let i = 0; i < explores.length; i++) {
            const explore = explores[i][0];
            const model = explores[i][1];
            if (i === 0 || model.label !== explores[i - 1][1].label) {
                elItems.push(<MenuSubHeader key={model.name}>{model.label}</MenuSubHeader>);
            }
            elItems.push(<MenuItem key={explore.label} onClick={() => dispatch(openExplore(model.name, explore.name))}>{explore.label}</MenuItem>);
        }
    } else {
        for (let i = 0; i < models.length; i++) {
            const model: ILookMLModel = models[i];
            elItems.push(<MenuSubHeader key={model.name}>{model.label}</MenuSubHeader>);
            for (let i = 0; i < model.explores.length; i++) {
                const explore = model.explores[i];
                elItems.push(<MenuItem key={explore.label} onClick={() => dispatch(openExplore(model.name, explore.name))}>{explore.label}</MenuItem>);
            }
        }
    }
    // @todo - This needs to get moved to Embark.
    return(
      <Popover
        disableEnforceFocus
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        classes={{ paper: "da-MuiPopover-paper" }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
          <div>
            <ul style={{paddingLeft: 0}}>
              {elItems}
            </ul>
          </div>
      </Popover>
    );
};