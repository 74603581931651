import {
    HIDE_MODAL,
    HIDE_MODAL_ERROR,
    SET_MODAL_DATA,
    SET_MODAL_LOADED,
    SET_MODAL_LOADING,
    SHOW_MODAL,
    SHOW_MODAL_ERROR
} from "../types";

export interface IModalState {
    type: string,
    errorMessage: string,
    loading: boolean,
    data: any
}
const initialState: IModalState = {
    type: '',
    errorMessage: '',
    loading: false,
    data: {}
}

export const modalReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_MODAL:
            return {...initialState, ...action.payload}
        case HIDE_MODAL:
            return {...initialState}
        case SHOW_MODAL_ERROR:
            return {...state, ...action.payload}
        case HIDE_MODAL_ERROR:
            return {...state, errorMessage: ''}
        case SET_MODAL_LOADING:
            return {...state, loading: true}
        case SET_MODAL_LOADED:
            return {...state, loading: false}
        case SET_MODAL_DATA:
            return {...state, data: action.payload}
        default:
            return state
    }
}
