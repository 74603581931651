import { useDispatch } from 'react-redux';
import {
    Button,
    ButtonContainer,
    Modal,
    ModalFooter,
    ModalHeader,
    TextField
} from '@emburse/embark-core'
import {v4} from 'uuid';

import React, { useState } from 'react';
import { createDashboard, hideModal, hideModalError, showModalError } from '../../../store';

export default ({folder, modal}) => {
    const dispatch = useDispatch()
    const [titleName, setTitleName] = useState('')
    const setNewName = (e) => {
        if (modal.errorMessage) {
            dispatch(hideModalError())
        }
        setTitleName(e.target.value)

        
    }

    const closeModal = () => {
        dispatch(hideModal())
    };

    const handleCreateFolderClick = () => {
        if (!titleName) {
            dispatch(showModalError("Dashboard name cannot be empty"))
        } else {
            console.log("DISPATCH")
            dispatch(createDashboard(titleName, folder.id));
        }
    };

    const modalHeader = (<ModalHeader title="Create Dashboard" showClose={true} onCloseClicked={closeModal}/>);

    const modalFooter = (
        <ModalFooter>
            <ButtonContainer alignButtons={'right'}>
                <Button onClick={closeModal} variant="outlined" disabled={modal.loading}>Cancel</Button>
                <Button variant="contained" onClick={handleCreateFolderClick} disabled={modal.loading}>
                    Create
                </Button>
            </ButtonContainer>
        </ModalFooter>
    );
    return (
        <Modal open modalHeader={modalHeader} modalFooter={modalFooter} onClose={closeModal} isLoading={modal.loading}>
            <div className="da-modal-form">
                <TextField label="Dashboard Name"
                        id={v4()}
                        value={titleName}
                        onChange={setNewName}
                        fullWidth
                        required
                        autoFocus
                        helperText={modal.errorMessage}
                        error={Boolean(modal.errorMessage)}
                />
            </div>
        </Modal>
    )
}