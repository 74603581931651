import React from 'react'
import Arc from './Arc'
import './LoadingSpinner.scss'
import {EmburseAnalyticsIcon} from '../../assets/logo/bu/icons'
import EmptyPage from '../EmptyPage/EmptyPage'

export interface LoadingProps{
    children?: React.ReactNode
}

export default (props: LoadingProps) => {
    return (
        <EmptyPage>
            <div className="da-loading-container">
                <div className="da-loading">
                    <div className="da-loading-arc">
                        <Arc/>
                    </div>
                    <div className="da-loading-icon">
                        <img src={EmburseAnalyticsIcon} alt="React Logo"/>
                    </div>
                </div>
                {props.children}
            </div>
        </EmptyPage>
    )
}

