import { callEndpoint } from "../../utility/FetchErrorHandler";
import { FETCH_MODELS} from "../types";

export function fetchModels(){
    return async dispatch => {
        const response: Response = await callEndpoint('/app/models')
        
        const json = await response.json();
        dispatch({type: FETCH_MODELS, payload: json})
    }
}