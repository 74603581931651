import { Entity } from "../resources";
import { useEffect, useState } from "react";

export const useResource = <E extends Entity>(
  resource: () => Promise<E> | Promise<E[]>
): E | undefined => {
  let [element, setElement] = useState<E | undefined>(undefined); // set a state

  useEffect(() => {
    resource().then((v) => setElement(v)); //trigger the function call and set the element
  }, []);

  return element; //return the element
};
