export const FRAME_CONTENT_LOOK = 'look';
export const FRAME_CONTENT_DASHBOARD = 'dashboard';
export const FRAME_CONTENT_EXPLORE = 'explore';

export const CREATE_FOLDER_MODAL = 'CREATE_FOLDER';
export const RENAME_FOLDER_MODAL = 'RENAME_FOLDER';
export const DELETE_FOLDER_MODAL = 'DELETE_FOLDER';

export const CREATE_LOOK_MODAL = 'CREATE_LOOK';
export const DELETE_LOOK_MODAL = 'DELETE_LOOK';

export const CREATE_DASHBOARD_MODAL = 'CREATE_DASHBOARD';
export const DELETE_DASHBOARD_MODAL = 'DELETE_DASHBOARD';
export const UPGRADE_TO_PRO_MODAL = 'UPGRADE_TO_PRO';
export const COPY_ACTION_MODAL = 'COPY_ACTION';
export const MOVE_ACTION_MODAL = 'MOVE_ACTION';
export const RENAME_MODAL = 'RENAME_ACTION';

export const OPEN_SCHEDULE_MODAL = 'OPEN_SCHEDULE_MODAL';