import { Button, Icon, Menu, MenuItem, Tooltip } from "@emburse/embark-core";
import { CalendarIcon } from "@src/components/Icons/Icons";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import React, { useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Route } from "react-router";
import { showDeleteFolderModal, showDeleteModal, showModal } from "../../store";
import {
  COPY_ACTION_MODAL,
  CREATE_DASHBOARD_MODAL,
  CREATE_FOLDER_MODAL,
  DELETE_DASHBOARD_MODAL,
  DELETE_FOLDER_MODAL,
  DELETE_LOOK_MODAL,
  MOVE_ACTION_MODAL,
  RENAME_FOLDER_MODAL,
  RENAME_MODAL,
  OPEN_SCHEDULE_MODAL
} from "../../store/constants";
import { FOLDERS_ROUTE } from "../../store/routs";
import FolderBreadcrumb from "../FolderBreadcrumb/FolderBreadcrumb";
import {
  AddIcon,
  DashboardIcon,
  DeleteFolderIcon,
  EditFolderIcon,
  LookIcon,
  NewFolderIcon,
  FileCopyIcon,
  FolderMoveIcon,
  Edit,
  EAAddIcon,
} from "../Icons/Icons";
import ModelLookMenu from "../Models/ModelLookMenu";
import "./ActionBar.scss";
import CreateDashboardModal from "./modals/CreateDashboardModal";
import CreateFolderModal from "./modals/CreateFolderModal";
import DeleteFolderModal from "./modals/DeleteFolderModal";
import RenameFolderModal from "./modals/RenameFolderModal";
import RenameModal from "./modals/RenameModal";
import CopyActionModal from "./modals/CopyActionModal";
import MoveActionModal from "./modals/MoveActionModal";
import DeleteModal from "./modals/DeleteModal";
import { AnalyticsProStatus } from "../../enums/AnalyticsProStatus";
import { AnalyticsRole } from "../../enums/AnalyticsRole";
import UTPButton from "../UTPButton/UTPButton";
import { IFolder, IState, IUseDrawer } from "@src/interfaces";
import { FalsyPermissions } from "../../objects/Permissions";
import {
  DELETE_DASHBOARD,
  DELETE_LOOK,
  COPY_DASHBOARD,
  COPY_LOOK,
  MOVE_LOOK,
  MOVE_DASHBOARD,
} from "@src/store/types";
import { FFlags, getFlag } from "@src/enums";
import { showCopyModal, showMoveModal, showScheduleModal } from "../../store/actions/modalActions";
import { useContext } from "react";
import { AuthContext } from "../AuthProvider/AuthProvider";
import { IUseAuthInterface } from "../../hooks/useAuth";
import { StoreAccessor, ScheduleModal} from "..";
import { folderStructureSelector } from "../../store/selector";
const ActionBar = () => {
  const { allFolders, activeFolderId } = useSelector(
    (state: RootStateOrAny) => state.folders
  );
  const {user: userData}: IUseAuthInterface = useContext(AuthContext);
  const showUTPButton = (userData?.tier === "0" && [AnalyticsRole.FreeCreator, AnalyticsRole.Viewer].includes(userData.analytics_role as AnalyticsRole));
  return (
    <div className="da-action-bar action-bar">
      <FolderBreadcrumb folderId={activeFolderId} folders={allFolders} />
      <div style={{display:'flex'}}>
        {showUTPButton && <UTPButton />}
        <Route exact path={FOLDERS_ROUTE} component={FolderActions} />
      </div>
    </div>
  );
};

export default ActionBar;

const FolderActions = () => {
  const dispatch = useDispatch();
  const modal = useSelector((state: IState) => state.modal);
  const authContext: IUseAuthInterface = useContext(AuthContext);

  const { folderContent, allFolders } = useSelector(
    (state: IState) => state.folders
  );
  const currentFolder: IFolder = allFolders[folderContent.id];
  const { permissions: currentFolderPermissions = FalsyPermissions } =
    folderContent;

  const selectedFolder = folderContent.folders.find(
    (f) => f.contentMetadataId === folderContent.selectedItemId
  );
  const selectedDashboard = folderContent.dashboards.find(
    (d) => d.contentMetadataId === folderContent.selectedItemId
  );
  const selectedLook = folderContent.looks.find(
    (l) => l.contentMetadataId === folderContent.selectedItemId
  );

  // What is selected

  const selectedFolderPermissions = selectedFolder
    ? selectedFolder.permissions
    : FalsyPermissions;

  const [lookMenuEl, setLookMenuEl] = useState(null);

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [showMenu, setShowMenu] = useState(false);

  const handleCreateButtonClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
    setShowMenu(true);
  };

  const handleCreateFolderClick = () => {
    dispatch(showModal(CREATE_FOLDER_MODAL));
    setShowMenu(false);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
    setShowMenu(false);
  };

  const handleLookClick = (event) => {
    setLookMenuEl(event.currentTarget);
  };

  const handleLookClose = () => {
    setLookMenuEl(null);
  };

  // DASHBOARD ACTIONS
  const handleDashboardClick = () => {
    dispatch(showModal(CREATE_DASHBOARD_MODAL));
    setShowMenu(false);
  };

  const activeModal = () => {
    switch (modal.type) {
      case OPEN_SCHEDULE_MODAL:
        return <ScheduleModal
          folderId={currentFolder.id}
          look={selectedLook}
          dashboard={selectedDashboard}
          modal={modal}
        />;
      case CREATE_FOLDER_MODAL:
        return (
          <StoreAccessor selector={folderStructureSelector}>
            <CreateFolderModal folder={currentFolder} modal={modal} />
          </StoreAccessor>
        );
      case RENAME_FOLDER_MODAL:
        return (
          <StoreAccessor selector={folderStructureSelector}>
            <RenameFolderModal folder={selectedFolder} modal={modal} />
          </StoreAccessor>
        );
      case DELETE_FOLDER_MODAL:
        return (
          <StoreAccessor selector={folderStructureSelector}>
            <DeleteFolderModal folder={selectedFolder} modal={modal} />
          </StoreAccessor>
        );
      case DELETE_DASHBOARD_MODAL:
        return selectedDashboard ? (
          <DeleteModal
            lookerObj={selectedDashboard}
            modal={modal}
            type="dashboard"
          />
        ) : (
          <></>
        );
      case DELETE_LOOK_MODAL:
        return selectedLook ? (
          <DeleteModal lookerObj={selectedLook} modal={modal} type="look" />
        ) : (
          <></>
        );
      case CREATE_DASHBOARD_MODAL:
        return <CreateDashboardModal folder={currentFolder} modal={modal} />;
      case COPY_ACTION_MODAL:
        return <CopyActionModal
          item={selectedLook ? selectedLook : selectedDashboard}
          modal={modal}
        />;
      case MOVE_ACTION_MODAL:
        return (
          <MoveActionModal
            item={selectedLook ? selectedLook : selectedDashboard}
            modal={modal}
          />
        );
      case RENAME_MODAL:
          return (
            <RenameModal
              item={selectedLook ? selectedLook : selectedDashboard}
              modal={modal}
            />
          );
      default:
        return null;
    }
  };

  const lookText = (
    <div className="da-look-text">
      Look{" "}
      <ChevronRightIcon
        className="da-look-text-icon"
        style={{ fontSize: "1rem" }}
      />
    </div>
  );

    return (
      <div className="da-action-bar-btn-set">
        {currentFolderPermissions.editContent && (
          <Button
            id="menuButton"
            onClick={handleCreateButtonClick}
            variant="contained"
            startIcon={<EAAddIcon />}
          >
            Create
          </Button>
        )}
        <Menu
          open={showMenu}
          anchorEl={menuAnchorEl}
          onClose={handleCloseMenu}
        >
          {currentFolderPermissions.create && (
            <MenuItem
              iconText="Folder"
              icon={<NewFolderIcon />}
              onClick={() => handleCreateFolderClick()}
            />
          )}
          {(authContext.user?.analytics_role === AnalyticsRole.FreeCreator) ? null : 
            currentFolderPermissions.editContent && (
              <MenuItem
                iconText="Dashboard"
                icon={<DashboardIcon />}
                onClick={handleDashboardClick}
              />
            )}
          {currentFolderPermissions.editContent && (
            <MenuItem
              iconText={lookText}
              icon={<LookIcon />}
              onClick={handleLookClick}
            />
          )}
          <ModelLookMenu anchorEl={lookMenuEl} onClose={handleLookClose} />
        </Menu>

        {activeModal()}
      </div>
    );
};
