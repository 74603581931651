import React from 'react';
import { Paper, Alert } from '@emburse/embark-core';
import { IAlert } from '../../interfaces/IAlert';
import { useDispatch } from 'react-redux';
import { removeAlert } from '@src/store/actions';

export interface AlertProps{
    alert: IAlert
}

export default (props: AlertProps) => {
    const dispatch = useDispatch();

    setTimeout(() => {
        dispatch(removeAlert(props.alert));
    }, 10000);

    return (
        <Paper elevation={3}>
            <Alert severity={props.alert.severity} alertTitle={props.alert.title}>{props.alert.message}</Alert>
        </Paper>
    );
};