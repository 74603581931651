import React from 'react';
import { ChipsGroup, FilterBar, FilterBarProps, Spacer } from '@emburse/embark-core';
import { FilterChipsProps } from "../FilterChips/FilterChips";
import SearchField, { SearchFieldProps }  from "../SearchField";
import FlexDiv from '../common/FlexDiv';

export interface TableFilterBarProps extends Omit<FilterBarProps, 'children'>, SearchFieldProps {
  data?: FilterChipsProps[];
  onSearchChange?: (value: string) => void;
}

export const TableFilterBar = (props: TableFilterBarProps) => {
  const searchRef = React.useRef<any>();
  const onSearchChange = (value: string) => {
    props.onSearchChange && props.onSearchChange(value);
  };

  const data = props.data || [];

  return (
    <FlexDiv>
      <SearchField
        onChangeTimeout={props.onChangeTimeout}
        doSearchOnClick={props.doSearchOnClick}
        doSearchOnEnter={props.doSearchOnEnter}
        doSearchOnChange={props.doSearchOnChange}
        searchAction={onSearchChange}
        functionRef={searchRef}
        style={{width:'34%'}}
      />
      <Spacer size={16} direction={'horizontal'} />
      <FilterBar
        {...props}
        onChange={(filters) => {
          let rolesData = filters.find((f) => f.id === "role")?.data || [];
          let typesData = filters.find((f) => f.id === "type")?.data || [];

          // clears the search field when 'reset filters' is clicked
          const resetAll = !rolesData.concat(typesData).find(data => data.selected);
          if (resetAll) {
            searchRef.current.clear();
          }

          props.onChange && props.onChange(filters);
        }}
      >
        {data.map((f) => (
          <ChipsGroup data={f.data} id={f.id} key={f.id} />
        ))}
      </FilterBar>
    </FlexDiv>
  );
};
