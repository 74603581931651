import { SupportedFormats } from '@looker/sdk/lib/4.0/models';
import {ExtraFormats} from "@src/enums/DataFormatEmail";

export type DataFormatSFTP = SupportedFormats

export const DataFormatSFTPLookList = [{
    "CSV": SupportedFormats.csv,
    "Excel (XLSX)": SupportedFormats.xlsx,
    "JSON - Simple": SupportedFormats.json,
    "JSON - Detailed": SupportedFormats.json_detail,
    "Text (Tab Separated)":SupportedFormats.txt,
    "HTML": SupportedFormats.html
}]

export const DataFormatSFTPDashboardList = [{
    "Text (CSV Zip)": SupportedFormats.csv_zip,
    "Visualization (PDF)": "pdf",
    "Visualization (PNG)": "png"
}]