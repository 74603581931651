import {useContext, useEffect, useState} from "react";
import {
  Button,
  ButtonContainer,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@emburse/embark-core";
import {ViewSchedule} from "@components/AdminSchedules/ViewSchedule";
import {IReportScheduleDefinition} from "@interfaces";
import {IUserPublic} from "@looker/sdk/lib/4.0/models";
import {IScheduleAPIContext, ScheduleAPIContext} from "@components/ScheduleAPIProvider/ScheduleAPIProvider";

export interface ViewScheduleModalProps {
  isModalOpen: boolean;
  closeModalCb: () => void;
  schedule: IReportScheduleDefinition | null;
  owner?: IUserPublic
}

export const ViewScheduleModal = ({ schedule, isModalOpen, owner, closeModalCb }: ViewScheduleModalProps) => {

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const scheduleAPI = useContext<IScheduleAPIContext>(ScheduleAPIContext);
  // @ts-ignore
  const [scheduleExtraData, setScheduleExtraData] = useState<IReportScheduleDefinition>(schedule);

  useEffect(() => {
    /** We need to fetch additional information about the schedule */
    if (!schedule) {
        return;
    }
    scheduleAPI.getSchedule(schedule)
        .then(async (res) => {
            setIsLoading(false);
            setScheduleExtraData(res);
        })
        .catch((e) => {
            setIsLoading(false);
        });
}, []);

  return (
    <Modal
      open={isModalOpen}
      modalHeader={<ModalHeader title="Schedule Details" showClose={true} onCloseClicked={closeModalCb}/>}
      modalFooter={<ModalFooter>
        <ButtonContainer alignButtons="right">
          <Button variant="contained" onClick={closeModalCb}>Close</Button>
        </ButtonContainer>
      </ModalFooter>}
      >
      <ModalBody>
        <ViewSchedule schedule={scheduleExtraData} onClose={()=> null} owner={owner} />
      </ModalBody>
    </Modal>
  );
};