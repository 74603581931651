import { ReactNode } from "react";
import { render } from "react-dom";
import "./index.css";
import App from "./components/App/App";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import store, { history } from "./store";
import { EmbarkProvider } from "@emburse/embark-core";
import { ConfigCatProvider} from "configcat-react";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { ProviderConfig } from "launchdarkly-react-client-sdk/lib/types";
import { AuthProvider } from "./components";
import DBCacheProvider from "./middleware/DBCacheProvider";
import {register} from "./middleware/workers/service/serviceWorkerRegistration";
(async () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  // Launch Darkly
  let ldProvider: ProviderConfig = {
    clientSideID: localStorage.getItem("launchDarklyToken") || "",
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
    user: {
      key:  user.bu_unique_id,
      custom: {
        bu: user.business_unit,
        bu_env: user.bu_env
      }
    }
  };  

  /**
   * @deprecated
   * remove LDProvider when deprecating analytics_app_enableconfigcat
   */
  const LDProvider = await asyncWithLDProvider(ldProvider);
  const CCProvider = ({children}: {
    children: ReactNode;
}) => <ConfigCatProvider sdkKey={localStorage.getItem("configCatToken") || ""}>{children}</ConfigCatProvider>;

  render(
    <DBCacheProvider>
      {/* remove LDProvider when deprecating analytics_app_enableconfigcat */}
      <LDProvider> 
        <CCProvider>
          <Provider store={store}>
            <EmbarkProvider>
              <ConnectedRouter history={history}>
                <AuthProvider>
                  <App />
                </AuthProvider>
              </ConnectedRouter>
            </EmbarkProvider>
          </Provider>
        </CCProvider>  
      </LDProvider>
    </DBCacheProvider>,
    document.getElementById("root")
  );

  register();
})();
