import React, {FunctionComponent, useEffect} from 'react';
import {useParams} from 'react-router';
import './LookerFrame.scss';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import {LookerEmbedSDK} from "@looker/embed-sdk";
import {FRAME_CONTENT_DASHBOARD, FRAME_CONTENT_EXPLORE, FRAME_CONTENT_LOOK} from "../../store/constants";
import {
    fetchContentDetails,
    initLooker,
    loadingData,
    loadingDataComplete,
    loadingIframe,
    loadingIframeComplete,
    loadingIframeError
} from "../../store/actions/lookerFrameActions";

const ROOT_LOOKER_FRAME_ID = 'embedded-analytics';

const LookerFrame = ({contentType}) => {
    const dispatch = useDispatch()
    const requestedContent = useSelector((state: RootStateOrAny) => state.lookerFrame.requestedContent)
    const contentPath = useSelector((state: RootStateOrAny) => state.router.location.pathname)
    const {contentId} = useParams()

    useEffect(() => {
        dispatch(initLooker())

        if (contentId !== requestedContent.id && contentType) {
            dispatch(loadingIframe(contentId, contentType))
            dispatch(fetchContentDetails(contentId, contentType))
            return;
        }

        const builder = contentBuilder(requestedContent)
        if (!builder) return;
        builder
            .appendTo('#' + ROOT_LOOKER_FRAME_ID)
            .on(requestedContent.type + ':run:start', () => dispatch(loadingData())
            )
            .on(requestedContent.type + ':run:complete', () => dispatch(loadingDataComplete())
            )
            .build()
            .connect()
            .then(() => dispatch(loadingIframeComplete(requestedContent)))
            .catch((error) => dispatch(loadingIframeError(error)))
    }, [dispatch, requestedContent, contentId, contentType, contentPath])

    return (
        <div id={ROOT_LOOKER_FRAME_ID}/>
    );
}

export const LookFrame: FunctionComponent = () => (<LookerFrame contentType={FRAME_CONTENT_LOOK}/>)

export const DashboardFrame: FunctionComponent = () => (<LookerFrame contentType={FRAME_CONTENT_DASHBOARD}/>)

export const ExploreFrame: FunctionComponent = () => (<LookerFrame contentType={FRAME_CONTENT_EXPLORE} />)

const contentBuilder = (requestedContent) => {
    if (requestedContent.type === FRAME_CONTENT_LOOK) {
        return LookerEmbedSDK.createLookWithId(requestedContent.id)
    } else if (requestedContent.type === FRAME_CONTENT_DASHBOARD) {
        return LookerEmbedSDK.createDashboardWithId(requestedContent.id)
    } else if(requestedContent.type === FRAME_CONTENT_EXPLORE){
        return LookerEmbedSDK.createExploreWithId(requestedContent.id)
    }
}
