
import { ILookMLModel } from "../../interfaces";
import { FETCH_MODELS } from "../types"

export interface IModelState{
    lookMlModels: ILookMLModel[]
}

const initialState: IModelState = {
    lookMlModels: []
}

export const modelReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MODELS:
            return {...state, lookMlModels: action.payload}
        default:
            return state;
    }
}