/**
 * Store the STATUS CODES from our backend API
 */
export enum STATUS_CODES{
    Unauthorized = 401
}

/**
 * Somewhere in here we'll want to handle the unauthorization we are getting from the backend and redirect
 */
function handleUnauthorizedError(){
    localStorage.setItem('user', JSON.stringify({authorized: false}))
    // window.location.href = '/unauthorized'
}
/**
 * 
 * The purpose of this function to capture errors from our endpoints.  
 * For instance when we hit a 401 we should do a logout action to redirect the user to unauthorized
 * 
 * So what we can do it create a wrapper for fetch and pass in the same arguments
 * do the fetch and capture the errors. Then just pass the response on for usage.
 * 
 * Would have called this "fetch" but it would have collided with the other fetch method
 * 
 * @param input 
 * @param init 
 */

export async function callEndpoint (input: RequestInfo, init?: RequestInit | undefined): Promise<Response>{
    /** invokes fetch with a default behavior for unauthorized API response */
    let response: Response = await fetch(input, init)

    if (!response.ok) {
        console.log("Handling unauthorized response:", response.status, response.statusText)

        if (response.status === STATUS_CODES.Unauthorized) {
            handleUnauthorizedError()
        }
        
    }

    return response
}