import React from 'react';
import EmptyPage from '../EmptyPage/EmptyPage';
import { useSelector } from 'react-redux';
import { IState, IError } from '@src/interfaces';
import ErrorBlock from './ErrorBlock'
import { Redirect } from 'react-router';
import { ROOT_ROUTE } from '@src/store/routs';

export default () => {
    const errors: IError[] = useSelector((state: IState) => state.app.errors)
    return (
        <EmptyPage>
            {
                // this is used when the page gets refreshed and there aren't any errors. 
                // Send them back to root
                errors.length < 1 &&
                <Redirect to={ROOT_ROUTE} />
            }
            <div role="alert">
                <p>Something went wrong:</p>
                {errors.map((err: IError, index: number) => {
                    return(
                        <ErrorBlock error={err} key={`error-${index}`}/>
                    )
                })}
            </div>
        </EmptyPage>
    )
}