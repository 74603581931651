import { IHasChildrenProps, IState } from "@src/interfaces";
import React from "react";
import { ScheduleAPIProvider } from "..";
import { IFFlags, FFlags } from "../../enums/FFlags";
import { useSelector } from "react-redux";

export interface AnalyticsAPIProviderProps extends IHasChildrenProps {
  flags: IFFlags;
}

export default (props: AnalyticsAPIProviderProps) => {
  const folder = useSelector((state: IState) => state.folders.activeFolderId);
  return (
    <ScheduleAPIProvider currentFolder_id={folder as string}>
      {props.children}
    </ScheduleAPIProvider>
  );
};
