import React from 'react'
import './Arc.scss';
export default () => {
    return (
        <svg width="125" height="125" viewBox="0 0 125 125" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-outside-1" maskUnits="userSpaceOnUse" x="-1.07324e-07" y="5.48545e-07" width="74" height="90" fill="black">
                <rect fill="white" x="-1.07324e-07" y="5.48545e-07" width="74" height="90"/>
                <path d="M10.8911 78.8645C8.68779 70.6415 8.40561 62.0219 10.0664 53.6725C11.7272 45.3231 15.2865 37.4676 20.4689 30.7137C25.6513 23.9599 32.3179 18.4888 39.953 14.7236C47.5881 10.9584 55.987 9 64.5 9L64.5 64.5L10.8911 78.8645Z"/>
            </mask>
            <path d="M10.8911 78.8645C8.68779 70.6415 8.40561 62.0219 10.0664 53.6725C11.7272 45.3231 15.2865 37.4676 20.4689 30.7137C25.6513 23.9599 32.3179 18.4888 39.953 14.7236C47.5881 10.9584 55.987 9 64.5 9L64.5 64.5L10.8911 78.8645Z" stroke="url(#paint0_linear)" strokeWidth="17.6" strokeMiterlimit="4.58952" mask="url(#path-1-outside-1)"/>
            <circle r="55.5" transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 64.5 64.5)" fill="white"/>
            <defs>
            <linearGradient id="paint0_linear" x1="57.4364" y1="1.6" x2="57.1" y2="113.609" gradientUnits="userSpaceOnUse">
                <stop stopColor="#0097DC"/>
                <stop offset="0.348958" stopColor="#0097DC" stopOpacity="0.296875"/>
                <stop offset="1" stopColor="#0097DC" stopOpacity="0"/>
            </linearGradient>
            </defs>
        </svg>

    )
}