import {
    INIT,
    LOADED_CONTENT,
    LOADING_CONTENT,
    LOOKER_ERROR,
    LOOKER_RUNNING,
    LOOKER_SUCCESS,
    SET_ACTIVE_FOLDER
} from "../types";
import {LookerEmbedSDK} from "@looker/embed-sdk";
import { FRAME_CONTENT_EXPLORE } from "../constants";
import { callEndpoint } from "../../utility/FetchErrorHandler";

export function initLooker() {
    // todo: these will be props derived from the  BU/logger in user
    const lookerHostName = 'emburse.cloud.looker.com'
    const lookerSSOPath = '/app/sign'
    LookerEmbedSDK.init(lookerHostName, lookerSSOPath)
    return {type: INIT}
}

export function loadingIframe(id, type, url?) {
    return {type: LOADING_CONTENT, payload: {id, type, url}}
}

export function loadingData() {
    return {type: LOOKER_RUNNING}
}

export function loadingDataComplete() {
    return {type: LOOKER_SUCCESS}
}

export function loadingIframeComplete(requestedContent) {
    return {type: LOADED_CONTENT, payload: requestedContent}
}

export function loadingIframeError(error) {
    console.error('An unexpected error occurred while loading Looker Iframe', error)
    return {type: LOOKER_ERROR}
}

export function fetchContentDetails(contentId, contentType) {
    return async dispatch => {
        if(contentType !== FRAME_CONTENT_EXPLORE) {
            const response = await callEndpoint(`/app/${contentType}s/${contentId}/info`);
            const {folderId} = await response.json();
            dispatch({type: SET_ACTIVE_FOLDER, payload: folderId})
        }
    }
}
