import { callEndpoint } from "../../utility/FetchErrorHandler";
import {
  SET_USER_DATA,
  SET_USER_FEATURE_FLAGS,
  SET_USER_SESSION_ID,
  SET_USER_SESSION_STATUS,
} from "../types";
import { IFFlags } from "../../enums/FFlags";
import { SessionStatus } from "../../enums";
import { IUser, IUserWithSession } from "@src/interfaces";
import { IUseAuthInterface } from "../../hooks/useAuth";
import { addUnauthorizedError } from "@src/store";

export const userNotAuthed: IUser = {
  authorized: false,
  initRoute: undefined,
  admin: false,
};

export function fetchUserInfoWithSession(authContext?: IUseAuthInterface) {
  /**
   * Calls the /app/user/data endpoint to determine whether the current user has provisioned completely
   * and is an authorized user.  Provisioning can take several seconds.
   * Will update local storage
   * Will update authContext
   * Will dispatch:  SET_USER_DATA, SET_USER_SESSION_STATUS, SET_USER_SESSION_ID
   * Will dispatch unauthorized error if response is not ok
   */
  return async (dispatch) => {
    try {
      const response = await callEndpoint("/app/user/data");
      let json: IUserWithSession;
      if (!response.ok) {
        dispatch(addUnauthorizedError(response.statusText));
      } else {
        json = await response.json();

        if (json.session?.status === SessionStatus.AUTHORIZED) {
          json.user.authorized = true;
        }
        if (json.session.status === SessionStatus.UNAUTHORIZED) {
          json.user.authorized = false;
        }

        if (authContext)
          authContext.updateContext({
            ...authContext,
            isAuthenticated: json.session.status === SessionStatus.AUTHORIZED,
            user: json.user,
            session: json.session,
          });

        localStorage.setItem("session_id", json.session.id);
        localStorage.setItem("session_status", json.session.status);
        localStorage.setItem("auth_status", JSON.stringify(json.user.authorized));
        localStorage.setItem("landing_page", JSON.stringify(json.user.landing_page));

        dispatch({ type: SET_USER_DATA, payload: json.user });
        dispatch({
          type: SET_USER_SESSION_STATUS,
          payload: json.session.status,
        });
        dispatch({ type: SET_USER_SESSION_ID, payload: json.session.id });
      }
    } catch (error: any) {
      console.error(error.stack);
    }
  };
}

export function setUserData(user: IUser) {
  return async (dispatch) => {
    dispatch({ type: SET_USER_DATA, payload: user });
  };
}

export function logoutUser() {
  /**
   * Clear local storage and request a server-side logout via redirect
   */
  return async () => {
    localStorage.setItem("user", JSON.stringify(userNotAuthed));
    localStorage.removeItem("session_id");
    localStorage.removeItem("auth_status");
    localStorage.setItem("session_status", SessionStatus.UNAUTHORIZED);
    window.location.href = "/app/logout";
  };
}

export function getUserFeatureFlags(flags: IFFlags) {
  return async (dispatch) => {
    dispatch({ type: SET_USER_FEATURE_FLAGS, payload: flags });
  };
}
